import styled from 'styled-components';
import MUITypography from '@material-ui/core/Typography';
import MUIGrid from '@material-ui/core/Grid';
import MUIToolbar from '@material-ui/core/Toolbar';

import { FormConfirmButton } from '@shared/components/Form';

export { default as AddIcon } from '@material-ui/icons/Add';
export { default as IconButton } from '@material-ui/core/IconButton';
export { HasPermissionComponent } from '@shared/components/HasPermissionComponent';

export const Container = styled(MUIToolbar)`
  padding-top: 0.5rem;
  padding-right: 5rem;
  z-index: 999;
  position: fixed;
  background: ${({ theme }) => theme.palette.secondary_background.main};
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
`;

export const AddProjectContainer = styled(MUIGrid).attrs({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})``;

export const Typography = styled(MUITypography).attrs({
  variant: 'h6',
})``;

export const ConfirmButton = styled(FormConfirmButton).attrs({
  gridProps: { xs: 3 },
})``;
