import {
  TBaseProjectStageObject,
  TProjectStageShowObject,
  TProjectStageCreateObject,
  TProjectStageUpdateObject,
  TProjectStageDestroyObject,
  TProjectStageObject,
} from '@modules/projects/@types/TProjectStage';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import {
  ToBaseShowSanitizer,
  ToBaseCreateSanitizer,
  ToBaseUpdateSanitizer,
  ToBaseDestroySanitizer,
  FromBaseApi,
} from '@shared/sanitizers/shared';

import {
  ToCreate as projectSubStageToCreate,
  FromApi as projectSubStageFromApi,
} from './projectSubStage';

function ToBaseSanitizer({
  register,
}: {
  register: TBaseProjectStageObject;
}): TBaseProjectStageObject {
  const parsedObject: TBaseProjectStageObject = {
    project_uuid: register.project_uuid,
    stage_name_uuid: register.stage_name_uuid,
    template_stage_uuid: register.template_stage_uuid,
    responsible_uuid: register.responsible_uuid,
    description: register.description,
    status: register.status,
    is_reopen: register.is_reopen,
  };

  return parsedObject;
}

export function ToShow({
  register,
}: {
  register: TProjectStageShowObject;
}): TProjectStageShowObject {
  const parsedObject: TProjectStageShowObject = {
    ...ToBaseShowSanitizer({ register }),
  };

  return parsedObject;
}

export function ToCreate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TProjectStageCreateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TProjectStageCreateObject {
  const parsedObject: TProjectStageCreateObject = {
    ...ToBaseCreateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register: { ...register, is_reopen: false } }),
    project_sub_stages:
      register.project_sub_stages?.map(projectSubStage =>
        projectSubStageToCreate({
          register: projectSubStage,
          logged_branch,
          logged_user,
        }),
      ) || [],
  };

  return parsedObject;
}

export function ToUpdate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TProjectStageUpdateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TProjectStageUpdateObject {
  const parsedObject: TProjectStageUpdateObject = {
    ...ToBaseUpdateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToDestroy({
  register,
  logged_branch,
}: {
  register: TProjectStageUpdateObject;
  logged_branch: TLoggedBranch;
}): TProjectStageDestroyObject {
  const parsedObject: TProjectStageDestroyObject = {
    ...ToBaseDestroySanitizer({ register, logged_branch }),
  };

  return parsedObject;
}

export function FromApi({
  registers,
}: {
  registers: TProjectStageObject[];
}): TProjectStageObject[] {
  const parsedObjects: TProjectStageObject[] = registers.map(register => ({
    ...register,
    ...FromBaseApi({ registers: [register] })[0],
    project_sub_stages: projectSubStageFromApi({
      registers: register?.project_sub_stages || [],
    }),
  }));

  return parsedObjects;
}
