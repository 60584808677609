import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

import { AppHooks } from '@shared/hooks';

import { ThemeConfig } from '@shared/theme';

import { SharedRoutes } from '@shared/routes/index.routes';
import { ErrorHandlerDialog } from '@shared/providers/error/components/Dialog';

import 'react-toastify/dist/ReactToastify.css';

export function App(): JSX.Element {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <AppHooks>
          <ThemeConfig>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={ptBRLocale}
            >
              <SharedRoutes />
              <ErrorHandlerDialog />
              <ToastContainer />
            </LocalizationProvider>
          </ThemeConfig>
        </AppHooks>
      </BrowserRouter>
    </HelmetProvider>
  );
}
