import styled from 'styled-components';

import MUIGrid from '@material-ui/core/Grid';
import MUITypography from '@material-ui/core/Typography';
import MUITooltip from '@material-ui/core/Tooltip';

export { GoToTopButton } from '@shared/components/GoToTopButton';
export { default as CircularProgress } from '@material-ui/core/CircularProgress';
export { default as IconButton } from '@material-ui/core/IconButton';
export { default as Refresh } from '@material-ui/icons/Refresh';

export const Container = styled(MUIGrid).attrs({
  container: true,
  component: 'main',
})`
  margin-top: 85px;
  align-items: center;
  justify-content: center;
`;

export const CircularProgressContainer = styled(MUIGrid)`
  position: fixed;
  top: 60%;
`;

export const Text = styled(MUITypography).attrs({ variant: 'h4' })`
  color: ${({ theme }) => theme.palette.grey_scale_1.main};
  margin-top: 3rem;
`;

export const Tooltip = styled(MUITooltip)`
  z-index: 9999;
  position: fixed;
  margin-top: -55px;
  margin-left: 150px;
`;
