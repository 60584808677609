import styled from 'styled-components';

import MUIGrid from '@material-ui/core/Grid';
import MUIIconButton from '@material-ui/core/IconButton';
import MUIDialog from '@material-ui/core/Dialog';
import MUITablePagination from '@material-ui/core/TablePagination';
import { FormConfirmButton, FormCancelButton } from '@shared/components/Form';

export { default as DialogTitle } from '@material-ui/core/DialogTitle';
export { default as DialogContent } from '@material-ui/core/DialogContent';
export { default as Paper } from '@material-ui/core/Paper';
export { default as TableContainer } from '@material-ui/core/TableContainer';
export { default as Table } from '@material-ui/core/Table';
export { default as TableBody } from '@material-ui/core/TableBody';
export { default as TableRow } from '@material-ui/core/TableRow';
export { default as TableCell } from '@material-ui/core/TableCell';
export { default as CloseIcon } from '@material-ui/icons/Close';
export { default as SearchIcon } from '@material-ui/icons/Search';
export { default as CircularProgress } from '@material-ui/core/CircularProgress';
export { default as FormControlLabel } from '@material-ui/core/FormControlLabel';
export { default as Checkbox } from '@material-ui/core/Checkbox';

export {
  FormGridContainer,
  FormContainer,
  FormTextInput,
} from '@shared/components/Form';

export const Container = styled(MUIDialog)``;

export const GridContainer = styled(MUIGrid).attrs({
  container: true as boolean,
})``;

export const IconButton = styled(MUIIconButton)`
  width: 55px;
  height: 55px;
`;

export const DialogHeader = styled(MUIGrid)`
  display: flex;
  justify-content: space-between;
`;

export const CircularProgressContainer = styled(MUIGrid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PaginationComponent = styled(MUITablePagination).attrs({
  component: 'div',
})``;

export const ConfirmButton = styled(FormConfirmButton).attrs({
  gridProps: { xs: 6 },
})``;

export const CancelButton = styled(FormCancelButton).attrs({
  gridProps: { xs: 6 },
})``;
