import { ISharedListDTO } from '@shared/dtos/IShared';
import { ISharedListAppRepository } from '@shared/repositories/app/implementations/IShared';
import { ISharedListAppService } from '@shared/services/app/implementations/IShared';

import { TSharedObject, TSharedPaginatedList } from '@shared/@types/TShared';

export class SharedListAppService<TRegister extends TSharedObject>
  implements ISharedListAppService<TRegister>
{
  constructor(
    private readonly listAppRepository: ISharedListAppRepository<TRegister>,
  ) {}

  public async execute(
    ListDTO: ISharedListDTO<TRegister>,
  ): Promise<TSharedPaginatedList<TRegister>> {
    const listAppRepositoryResult = await this.listAppRepository.execute(
      ListDTO,
    );

    return listAppRepositoryResult;
  }
}
