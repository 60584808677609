import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { APP_COPYRIGHT_NAME } from '@shared/constants/shared';

import logoHenriqueGasparini from '@shared/assets/logoHenriqueGasparini.svg';
import architectImg from '@modules/sessions/assets/architect.png';

import { Page } from '@shared/components/Page';
import { SignInContent } from './Content';

import {
  Container,
  ImageContent,
  Grid,
  ArchitectImage,
  FormContent,
  Divider,
  LogoImage,
  ForgotButton,
  Link,
  Copyright,
  RecoverPasswordContent,
  ForgotPasswordContent,
  Title,
  Avatar,
  GroupIcon,
  SwipeableContainer,
  SwipeableBox,
} from './styles';

export function SignIn(): JSX.Element {
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const passwordToken = query.get('password_token') || '';

  const [swipIndex, setSwipIndex] = useState(passwordToken ? 0 : 1);

  function onSuccessRecoverPassword() {
    setSwipIndex(1);
  }

  useEffect(() => {
    setSwipIndex(passwordToken ? 0 : 1);
  }, [passwordToken]);

  return (
    <Page title="Entrar">
      <Container>
        <ImageContent>
          <Grid />

          <ArchitectImage alt="Arquiteto" src={architectImg} />

          <Divider />
        </ImageContent>

        <FormContent>
          <LogoImage alt="Logo" src={logoHenriqueGasparini} />

          <SwipeableContainer index={swipIndex}>
            <SwipeableBox>
              <RecoverPasswordContent
                passwordToken={passwordToken}
                onSuccess={onSuccessRecoverPassword}
              />

              <ForgotButton onClick={() => setSwipIndex(1)}>
                Já tenho senha
              </ForgotButton>
            </SwipeableBox>
            <SwipeableBox>
              <Avatar>
                <GroupIcon />
              </Avatar>

              <Title>Entrar</Title>

              <SignInContent />

              <ForgotButton onClick={() => setSwipIndex(2)}>
                Esqueceu sua senha?
              </ForgotButton>
            </SwipeableBox>
            <SwipeableBox>
              <ForgotPasswordContent />

              <ForgotButton onClick={() => setSwipIndex(1)}>
                Já tenho senha
              </ForgotButton>
            </SwipeableBox>
          </SwipeableContainer>

          <Copyright>
            Direitos Reservados © Powered by{'  '}
            <Link color="inherit" href="https://facilesistemas.com.br/">
              {APP_COPYRIGHT_NAME}
            </Link>{' '}
            {new Date().getFullYear()}.
          </Copyright>
        </FormContent>
      </Container>
    </Page>
  );
}
