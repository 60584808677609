import { ApiProvider } from '@shared/providers/api';

import { ISharedFormDataAppRepository } from '@shared/repositories/app/implementations/IShared';

import { TSharedObject } from '@shared/@types/TShared';
import { ISharedFormDataDTO } from '@shared/dtos/IShared';

export class SharedFormDataAppRepository<
  TRelationsRegister extends TSharedObject = TSharedObject,
> implements ISharedFormDataAppRepository<TRelationsRegister>
{
  protected readonly apiProvider = new ApiProvider();

  constructor(protected readonly baseURL: string) {}

  public async execute(
    _FormDataDTO: ISharedFormDataDTO<TRelationsRegister>,
  ): Promise<Record<string, TRelationsRegister[]>> {
    const apiResult = await this.apiProvider.execute<{
      data: Record<string, TRelationsRegister[]>;
    }>({
      method: 'get',
      url: `${this.baseURL}/form-data`,
    });

    return apiResult.data;
  }
}
