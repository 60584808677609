import {
  TBaseFileObject,
  TFileObject,
  TFileCreateObject,
} from '@modules/files/@types/TFile';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';

import { FromBaseApi, ToBaseCreateSanitizer } from '@shared/sanitizers/shared';

function ToBaseSanitizer({
  register,
}: {
  register: TBaseFileObject;
}): TBaseFileObject {
  const parsedObject: TBaseFileObject = {
    image: register.image,
    name: register.name,
    url: register.url,
  };

  return parsedObject;
}

export function ToCreate({
  registers,
  logged_user,
  logged_branch,
}: {
  registers: TFileCreateObject[];
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}) {
  const parsedObject: TFileCreateObject[] = registers.map(register => ({
    ...ToBaseCreateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  }));

  return parsedObject;
}

export function FromApi({
  registers,
}: {
  registers: TFileObject[];
}): TFileObject[] {
  const parsedObjects: TFileObject[] = registers.map(register => ({
    ...register,
    ...FromBaseApi({ registers: [register] })[0],
  }));

  return parsedObjects;
}
