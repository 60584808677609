import { SharedAppServices } from '@shared/services/app/shared';

import { ProjectSubStageAppRepositories } from '@modules/projects/repositories/app/projectSubStage';

import {
  TProjectSubStageObject,
  TProjectSubStageShowObject,
  TProjectSubStageCreateObject,
  TProjectSubStageUpdateObject,
  TProjectSubStageDestroyObject,
  TProjectSubStageRelations,
} from '@modules/projects/@types/TProjectSubStage';

export class ProjectSubStageAppServices extends SharedAppServices<
  TProjectSubStageObject,
  TProjectSubStageShowObject,
  TProjectSubStageCreateObject,
  TProjectSubStageUpdateObject,
  TProjectSubStageDestroyObject,
  TProjectSubStageRelations
> {
  constructor() {
    const appRepositories = new ProjectSubStageAppRepositories();

    super(appRepositories);
  }
}
