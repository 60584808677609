import add from 'date-fns/add';

import { ToInteger } from '@shared/sanitizers/number';

import {
  TBaseTemplateObject,
  TTemplateShowObject,
  TTemplateCreateObject,
  TTemplateUpdateObject,
  TTemplateDestroyObject,
  TTemplateObject,
} from '@modules/templates/@types/TTemplate';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import {
  ToBaseShowSanitizer,
  ToBaseCreateSanitizer,
  ToBaseUpdateSanitizer,
  ToBaseDestroySanitizer,
  FromBaseApi,
} from '@shared/sanitizers/shared';

import { FromApi as templateStageFromApi } from './templateStage';

function ToBaseSanitizer({
  register,
}: {
  register: TBaseTemplateObject;
}): TBaseTemplateObject {
  const parsedObject: TBaseTemplateObject = {
    name: register.name,
    description: register.description,
    responsible_uuid: register.responsible_uuid,
    status: register.status || undefined,
    estimated_delivery_days: ToInteger(register.estimated_delivery_days || 0),
  };

  return parsedObject;
}

export function ToShow({
  register,
}: {
  register: TTemplateShowObject;
}): TTemplateShowObject {
  const parsedObject: TTemplateShowObject = {
    ...ToBaseShowSanitizer({ register }),
  };

  return parsedObject;
}

export function ToCreate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TTemplateCreateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TTemplateCreateObject {
  const parsedObject: TTemplateCreateObject = {
    ...ToBaseCreateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToUpdate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TTemplateUpdateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TTemplateUpdateObject {
  const parsedObject: TTemplateUpdateObject = {
    ...ToBaseUpdateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToDestroy({
  register,
  logged_branch,
}: {
  register: TTemplateUpdateObject;
  logged_branch: TLoggedBranch;
}): TTemplateDestroyObject {
  const parsedObject: TTemplateDestroyObject = {
    ...ToBaseDestroySanitizer({ register, logged_branch }),
  };

  return parsedObject;
}

export function FromApi({
  registers,
}: {
  registers: TTemplateObject[];
}): TTemplateObject[] {
  const parsedObjects: TTemplateObject[] = registers.map(register => ({
    ...register,
    ...FromBaseApi({ registers: [register] })[0],
    estimated_delivery_at: add(new Date(), {
      days: register.estimated_delivery_days || 0,
    }).toISOString(),
    template_stages: templateStageFromApi({
      registers: register?.template_stages || [],
    }),
  }));

  return parsedObjects;
}
