import { ISessionShowAppService } from '@modules/sessions/services/app/implementations/ISession';
import { ISessionShowAppRepository } from '@modules/sessions/repositories/app/implementations/ISession';

import { TCodeAuthSession } from '@modules/sessions/@types/TSession';

export class SessionShowAppService implements ISessionShowAppService {
  constructor(
    private readonly sessionShowAppRepository: ISessionShowAppRepository,
  ) {}

  public async execute(): Promise<TCodeAuthSession> {
    const showAppRepositoryResult =
      await this.sessionShowAppRepository.execute();

    return showAppRepositoryResult;
  }
}
