import * as yup from 'yup';

import {
  sharedBaseCreateValidatorSchema,
  sharedBaseUpdateValidatorSchema,
} from '@shared/validators/shared';

import {
  TProjectMessageCreateObject,
  TProjectMessageUpdateObject,
} from '@modules/projects/@types/TProjectMessage';

export const projectMessageBaseCreateOrUpdateValidatorSchema = yup
  .object()
  .shape({
    project_sub_stage_uuid: yup
      .string()
      .required('Este campo é obrigatório')
      .uuid('Este campo deve ser um UUID válido'),
    text: yup.string().required('Este campo é obrigatório'),
    notificate_users: yup.array().of(yup.string()),
  });

export const createValidatorSchema = yup
  .object()
  .concat(projectMessageBaseCreateOrUpdateValidatorSchema)
  .concat(
    sharedBaseCreateValidatorSchema,
  ) as yup.SchemaOf<TProjectMessageCreateObject>;

export const updateValidatorSchema = yup
  .object()
  .concat(projectMessageBaseCreateOrUpdateValidatorSchema)
  .concat(
    sharedBaseUpdateValidatorSchema,
  ) as yup.SchemaOf<TProjectMessageUpdateObject>;
