import { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';

import { createValidatorSchema } from '@modules/projects/validators/projectSubStage';
import { updateValidatorSchema } from '@modules/projects/validators/projectStage';
import { ToCreate } from '@modules/projects/sanitizers/projectSubStage';
import { ToUpdate } from '@modules/projects/sanitizers/projectStage';

import { MOCK_UUID } from '@modules/templates/constants/templateSubStage';
import { SEARCH_TYPES } from '@shared/constants/shared';

import { statusesOptions } from '@modules/projects/helpers/project';
import { listSelectorSubStageName } from '@modules/projects/helpers/projectSubStage';

import { useSession } from '@modules/sessions/hooks/session';
import { useUser } from '@modules/users/hooks/user';
import { useProjectStage } from '@modules/projects/hooks/projectStage';
import { useProjectSubStage } from '@modules/projects/hooks/projectSubStage';
import { useProject } from '@modules/projects/hooks/project';

import { TUserObject } from '@modules/users/@types/TUser';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import { TProjectSubStageCreateObject } from '@modules/projects/@types/TProjectSubStage';
import { TProjectStageUpdateObject } from '@modules/projects/@types/TProjectStage';
import { TStageNameObject } from '@modules/templates/@types/TStageName';
import { DefaultFormContainer } from '@shared/components/Form/DefaultFormContainer';
import {
  FormContainer,
  FormGridContainer,
  TextInput,
  FormSelector,
  CancelButton,
  ConfirmButton,
  NotificateUsersDialog,
  ContentFormContainer,
} from './styles';

type TProjectSubStageFormContent = {
  project_stage_uuid: string;
  toUpdate: boolean;
  onSuccess?: (...args: unknown[]) => unknown;
  onCancel?: (...args: unknown[]) => unknown;
};

export function ProjectSubStageFormContent({
  project_stage_uuid,
  toUpdate,
  onSuccess,
  onCancel,
}: TProjectSubStageFormContent): JSX.Element {
  const { logged_user, logged_branch } = useSession();
  const {
    registerShow: projectStageRegisterShow,
    formInitialData: projectStageFormInitialData,
    update: projectStageUpdate,
    showLoading: projectStageShowLoading,
  } = useProjectStage();
  const {
    formInitialData: projectSubStageFormInitialData,
    showLoading: projectSubStageShowLoading,
    formLoading,
    validations,
    create: projectSubStageCreate,
  } = useProjectSubStage();
  const { listSelector: userListSelector } = useUser();
  const { registerShow: projectRegisterShow, show: projectShow } = useProject();

  const [dialogState, setDialogState] = useState<{
    isOpen: boolean;
    register?: TProjectSubStageCreateObject | TProjectStageUpdateObject;
    toUpdate: boolean;
  }>({
    isOpen: false,
    register: undefined,
    toUpdate: false,
  });

  const formRef = useRef<FormHandles>(null);

  const validatorSchema = projectStageRegisterShow
    ? updateValidatorSchema
    : createValidatorSchema;

  const canEdit = !(toUpdate && !projectStageRegisterShow);

  function handleSanitize(
    register: TProjectSubStageCreateObject | TProjectStageUpdateObject,
  ): TProjectSubStageCreateObject | TProjectStageUpdateObject {
    const parsedRegister = projectStageRegisterShow
      ? ToUpdate({
          register: {
            ...register,
            responsible_uuid: register.responsible_uuid || '',
            project_uuid: projectStageRegisterShow?.project_uuid || '',
            template_stage_uuid:
              projectStageRegisterShow?.template_stage_uuid || '',
            uuid: projectStageRegisterShow?.uuid || '',
          },
          logged_user: logged_user as TLoggedUser,
          logged_branch: logged_branch as TLoggedBranch,
        })
      : ToCreate({
          register: {
            ...register,
            template_sub_stage_uuid: MOCK_UUID,
            project_stage_uuid,
          },
          logged_user: logged_user as TLoggedUser,
          logged_branch: logged_branch as TLoggedBranch,
        });

    return parsedRegister;
  }

  function handleSubmit(
    register: TProjectSubStageCreateObject | TProjectStageUpdateObject,
  ): void {
    setDialogState({ isOpen: true, register, toUpdate });
  }

  function notificateUsersSubmit(
    register: TProjectSubStageCreateObject | TProjectStageUpdateObject,
  ) {
    if (toUpdate) {
      projectStageUpdate({
        register: register as TProjectStageUpdateObject,
        onSuccess,
      });

      setTimeout(() => {
        projectShow({ register: { uuid: projectRegisterShow?.uuid || '' } });
      }, 200);
    } else
      projectSubStageCreate({
        register: register as TProjectSubStageCreateObject,
        onSuccess,
      });
  }

  function handleCancel(): void {
    onCancel?.();
  }

  function handleCloseDialog() {
    setDialogState({ isOpen: false, toUpdate });
  }

  useEffect(() => {
    formRef.current?.setErrors(validations || {});
  }, [validations]);

  return (
    <DefaultFormContainer>
      <ContentFormContainer>
        <FormContainer<TProjectSubStageCreateObject | TProjectStageUpdateObject>
          formRef={formRef}
          validatorSchema={validatorSchema}
          sanitizer={handleSanitize}
          onSubmit={handleSubmit}
          initialData={projectStageRegisterShow}
        >
          <NotificateUsersDialog<
            TProjectSubStageCreateObject | TProjectStageUpdateObject
          >
            dialogState={dialogState}
            formRef={formRef}
            projectUuid={projectRegisterShow?.uuid}
            handleCloseDialog={handleCloseDialog}
            onSubmit={notificateUsersSubmit}
          />

          <FormGridContainer mt={1}>
            <FormGridContainer gap={1}>
              <FormSelector<TStageNameObject>
                label="Nome"
                name="stage_name_uuid"
                idColumn="uuid"
                nameColumn="name"
                handleSearch={listSelectorSubStageName}
                options={
                  toUpdate
                    ? ((projectStageFormInitialData?.stage_names ||
                        []) as TStageNameObject[])
                    : ((projectSubStageFormInitialData?.stage_names ||
                        []) as TStageNameObject[])
                }
                required
                showLoading={
                  toUpdate
                    ? projectStageShowLoading
                    : projectSubStageShowLoading
                }
                disabled={!canEdit}
                gridProps={{ xs: 8, sm: 5 }}
              />

              <TextInput
                label="Descrição"
                name="description"
                required
                disabled={formLoading || !canEdit}
                showLoading={
                  toUpdate
                    ? projectStageShowLoading
                    : projectSubStageShowLoading
                }
              />
            </FormGridContainer>
            <FormGridContainer gap={1}>
              <FormSelector<TUserObject>
                label="Dirigente"
                name="responsible_uuid"
                idColumn="uuid"
                nameColumn="name"
                handleSearch={() =>
                  userListSelector({
                    search: [
                      {
                        field: 'project_users',
                        type: SEARCH_TYPES.relation,
                        values: [
                          {
                            field: 'project_uuid',
                            type: SEARCH_TYPES.normal,
                            values: [projectRegisterShow?.uuid || ''],
                          },
                        ],
                      },
                    ],
                  })
                }
                options={
                  (projectSubStageFormInitialData?.responsibles ||
                    []) as TUserObject[]
                }
                disabled={formLoading || !canEdit}
                showLoading={
                  toUpdate
                    ? projectStageShowLoading
                    : projectSubStageShowLoading
                }
                gridProps={{ xs: 8, sm: 5 }}
              />

              <FormSelector
                label="Status"
                name="status"
                idColumn="key"
                nameColumn="label"
                options={statusesOptions}
                required
                showLoading={
                  toUpdate
                    ? projectStageShowLoading
                    : projectSubStageShowLoading
                }
                disabled={!canEdit || toUpdate}
                gridProps={{ xs: 8, sm: 5 }}
              />
            </FormGridContainer>
            <FormGridContainer gap={1}>
              <CancelButton
                loading={
                  toUpdate
                    ? projectStageShowLoading
                    : projectSubStageShowLoading || formLoading
                }
                onClick={handleCancel}
              >
                CANCELAR
              </CancelButton>
              <ConfirmButton
                loading={
                  toUpdate
                    ? projectStageShowLoading
                    : projectSubStageShowLoading || formLoading || !canEdit
                }
                disabled={formLoading}
              >
                SALVAR
              </ConfirmButton>
            </FormGridContainer>
          </FormGridContainer>
        </FormContainer>
      </ContentFormContainer>
    </DefaultFormContainer>
  );
}

ProjectSubStageFormContent.defaultProps = {
  onSuccess: undefined,
  onCancel: undefined,
};
