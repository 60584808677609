import { STAGE_NAME_TYPES } from '@modules/templates/constants/stageName';

export const stageNamesOptions: { key: STAGE_NAME_TYPES; label: string }[] = [
  {
    key: STAGE_NAME_TYPES.STAGE,
    label: 'Etapa',
  },
  {
    key: STAGE_NAME_TYPES.SUB_STAGE,
    label: 'Atividade',
  },
];
