import { ApiProvider } from '@shared/providers/api';

import { BASE_URL as DASHBOARD_BASE_URL } from '@modules/projects/constants/dashboard';
import { BASE_URL as PROJECT_BASE_URL } from '@modules/projects/constants/project';

import { TDashboardRelationsObject } from '@modules/projects/@types/TDashboard';

export class DashboardAppRepositories {
  protected readonly apiProvider = new ApiProvider();

  public async formData(): Promise<TDashboardRelationsObject> {
    const apiResult = await this.apiProvider.execute<TDashboardRelationsObject>(
      {
        method: 'get',
        url: `${PROJECT_BASE_URL}/${DASHBOARD_BASE_URL}/form-data`,
      },
    );

    return apiResult;
  }
}
