import { ReactNode } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ptBR } from '@material-ui/core/locale';
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@material-ui/core/styles';

import { useTheme } from '@shared/hooks/theme';

import { theme as lightTheme } from './light';
import { theme as darkTheme } from './dark';

type TThemeConfig = {
  children: ReactNode;
};

export function ThemeConfig({ children }: TThemeConfig): JSX.Element {
  const { mode } = useTheme();

  const theme = createTheme(mode === 'light' ? lightTheme : darkTheme, ptBR);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
