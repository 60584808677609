import styled from 'styled-components';
import MUIGrid from '@material-ui/core/Grid';
import MUITypography from '@material-ui/core/Typography';
import MUIButton from '@material-ui/core/Button';
import MUIIconButton from '@material-ui/core/IconButton';
import MUIArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MUIDialogContent from '@material-ui/core/DialogContent';
import MUICollapse from '@material-ui/core/Collapse';

import { STATUSES } from '@modules/projects/constants/project';

export { ProjectSubStageFormContent } from '@modules/projects/pages/ProjectSubStage/Form/Content';
export { ReopenProject } from '@modules/projects/components/ReopenProject';
export { GoToTopButton } from '@shared/components/GoToTopButton';
export { HasPermissionComponent } from '@shared/components/HasPermissionComponent';
export { RemoveItemDialog } from '@modules/projects/components/RemoveItemDialog';
export { default as DialogTitle } from '@material-ui/core/DialogTitle';
export { default as Dialog } from '@material-ui/core/Dialog';
export { default as Tooltip } from '@material-ui/core/Tooltip';
export { default as CircularProgress } from '@material-ui/core/CircularProgress';
export { default as CloseIcon } from '@material-ui/icons/Close';
export { default as ArrowDropDownIcon } from '@material-ui/icons/ArrowDropDown';
export { default as ArrowLeftIcon } from '@material-ui/icons/ArrowLeft';
export { default as EditIcon } from '@material-ui/icons/Edit';
export { default as DeleteIcon } from '@material-ui/icons/Delete';

type TPalette = {
  backgroundcolor: 'primary' | 'secondary';
};

type TStatus = {
  status: STATUSES;
};

type TProjectCardContainer = {
  sub_stage?: string;
};

export const Container = styled(MUIGrid).attrs({ container: true })`
  height: calc(100vh - 8rem);
  width: 100%;
  overflow: auto;
`;

export const IconButton = styled(MUIIconButton)`
  width: 45px;
  height: 45px;
`;

export const DialogHeader = styled(MUIGrid)`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.secondary_background.main};
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.2);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
`;

export const ProjectContainer = styled(MUIGrid)`
  background: ${({ theme }) => theme.palette.secondary_background.main};
  margin-top: 10px;
  padding: 10px;
  width: 100%;

  border-radius: 0.5rem;
`;

export const TitleContent = styled(MUIGrid)`
  height: 55px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 2rem;
`;

export const LoadingAfterDeleteContainer = styled(MUIGrid)`
  width: 500px;
  display: flex;
  justify-content: center;
`;

export const Title = styled(MUITypography).attrs({ variant: 'h5' })`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey_scale_3.main};
`;

export const StagesContainer = styled(MUIGrid)``;

export const DialogContentContainer = styled(MUIDialogContent)`
  background: ${({ theme }) => theme.palette.background.default};
`;

export const ProjectCardContainer = styled(MUIGrid)<TProjectCardContainer>`
  height: ${props => (props.sub_stage === 'true' ? '170px' : '143px')};
  width: 100%;
  margin-top: 1rem;
  transition: all ease 2s;
`;

export const ProjectCard = styled(MUIGrid)`
  /* display: flex;
  justify-content: space-between; */
  background: ${({ theme }) => theme.palette.grey_scale_4.main};
  width: 500px;
  border: 1px solid ${({ theme }) => theme.palette.grey_scale_2.main};
  border-radius: 0.25rem;
`;

export const ProjectCardHeader = styled(MUIGrid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

export const ProjectCardHeaderLabelContainer = styled(MUIGrid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HeaderLabel = styled(MUITypography).attrs({ variant: 'h6' })`
  font-weight: 600;
`;

export const ProjectCardBody = styled(MUIGrid)`
  display: grid;
  grid-template-columns: 340px 140px;
  align-items: center;
  padding: 10px;
`;

export const StageActionsContainer = styled(MUIGrid)`
  display: flex;
  flex-direction: column;
`;

export const CardCollapse = styled(MUICollapse)`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
`;

export const BodyLabel = styled(MUITypography).attrs({ variant: 'subtitle1' })`
  font-weight: 600;
`;

export const BodyLinesContainer = styled(MUIGrid)``;

export const BodyLine = styled(MUIGrid)`
  align-items: center;
`;

export const BodyButton = styled(MUIButton)<TPalette>`
  background: ${props =>
    props.backgroundcolor === 'primary'
      ? props.theme.palette.primary.main
      : props.theme.palette.secondary.main};
  transition: 0.3s;
  color: white;

  &:hover {
    background: ${props =>
      props.backgroundcolor === 'primary'
        ? props.theme.palette.primary.main
        : props.theme.palette.secondary.main};
    color: white;
    opacity: 80%;
    transition: 0.3s;
  }
`;

export const PrefixOfLabels = styled(MUITypography).attrs({
  variant: 'body2',
})`
  color: ${({ theme }) => theme.palette.grey_scale_1.main};
  font-weight: 600;

  margin-right: 10px;
`;

export const ProjectCardStatus = styled(MUIGrid)<TStatus>`
  width: 100%;
  height: 12px;

  background: ${({ theme, status }) => theme.palette[status].main};
`;

export const Divider = styled(MUIGrid)`
  background: ${({ theme }) => theme.palette.grey_scale_2.main};
  width: 100%;
  height: 1px;
`;

export const SubStageContainer = styled(MUIGrid)`
  display: grid;
  grid-template-columns: 250px 500px;
`;

export const ArrowContainer = styled(MUIGrid)`
  width: 280px;
  height: 100px;

  display: flex;
  justify-content: center;
`;

export const ArrowForward = styled(MUIArrowForwardIcon)`
  margin-top: 89px;
  margin-left: -4px;
  color: ${({ theme }) => theme.palette.grey_scale_2.main};
`;

export const VerticalLine = styled(MUIGrid)`
  background: ${({ theme }) => theme.palette.grey_scale_2.main};
  height: 187px;
  width: 2px;
`;

export const LastVerticalLine = styled(MUIGrid)`
  background: ${({ theme }) => theme.palette.grey_scale_2.main};
  height: 102px;
  width: 2px;
`;

export const HorizontalLine = styled(MUIGrid)`
  background: ${({ theme }) => theme.palette.grey_scale_2.main};
  height: 2px;
  width: 50%;
  margin-top: 100px;
`;
