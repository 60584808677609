import { createContext, ReactNode, useContext, useState } from 'react';

import { UserAppServices } from '@modules/users/services/app/user';

import { useErrorHandler } from '@shared/providers/error/hook';

import {
  TUserObject,
  TUserShowObject,
  TUserCreateObject,
  TUserUpdateObject,
  TUserDestroyObject,
} from '@modules/users/@types/TUser';
import {
  SharedHookInitialState,
  useSharedHook,
  TSharedHookInitialState,
  TSharedHookReturn,
} from '@shared/hooks/shared';

const INITIAL_STATE: TSharedHookInitialState<TUserObject> =
  SharedHookInitialState;

type TUserContext = TSharedHookInitialState<TUserObject> &
  TSharedHookReturn<
    TUserObject,
    TUserShowObject,
    TUserCreateObject,
    TUserUpdateObject,
    TUserDestroyObject
  >;

const UserContext = createContext<TUserContext>(INITIAL_STATE as TUserContext);

const AppServices = new UserAppServices();

export function UserProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [state, setState] =
    useState<TSharedHookInitialState<TUserObject>>(INITIAL_STATE);

  const { setErrorHandlerData } = useErrorHandler();

  const sharedHook = useSharedHook<
    TUserObject,
    TUserShowObject,
    TUserCreateObject,
    TUserUpdateObject,
    TUserDestroyObject
  >({
    setState,
    AppServices,
    setErrorHandlerData,
  });

  return (
    <UserContext.Provider value={{ ...state, ...sharedHook }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser(): TUserContext {
  const context = useContext(UserContext);

  if (!context) throw new Error('useUser must be used within an UserProvider');

  return context;
}
