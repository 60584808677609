import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { ErrorProvider } from '@shared/providers/error';

import {
  ISharedListDTO,
  ISharedShowDTO,
  ISharedCreateDTO,
  ISharedUpdateDTO,
  ISharedDestroyDTO,
  ISharedFormDataDTO,
  ISharedListSelectorDTO,
} from '@shared/dtos/IShared';
import { ISharedAppServices } from '@shared/services/app/implementations/IShared';

import { useSession } from '@modules/sessions/hooks/session';

import {
  TSharedObject,
  TSharedShowObject,
  TSharedCreateObject,
  TSharedUpdateObject,
  TSharedDestroyObject,
} from '@shared/@types/TShared';
import { TAppError } from '@shared/providers/error/@types/TError';
import { TSetErrorHandlerDataProps } from '@shared/providers/error/hook';

export type TSharedHookInitialState<
  TRegister extends TSharedObject,
  TRelationsRegister extends TSharedObject = TSharedObject,
> = {
  formInitialData?: Record<string, TRelationsRegister[]>;
  formLoading: boolean;
  registerShow?: TRegister;
  showLoading: boolean;
  registerList: TRegister[];
  listLoading: boolean;
  listTotal: number;
  validations?: Record<string, string>;
  currentPage: number;
};

export const SharedHookInitialState = {
  formInitialData: undefined,
  formLoading: false,
  registerShow: undefined,
  showLoading: false,
  registerList: [],
  listLoading: false,
  listTotal: 0,
  validations: undefined,
  currentPage: 0,
};

type TSharedHookProps<
  TRegister extends TSharedObject,
  TShowObject extends TSharedShowObject,
  TCreateObject extends TSharedCreateObject,
  TUpdateObject extends TSharedUpdateObject,
  TDestroyObject extends TSharedDestroyObject,
  TRelationsRegister extends TSharedObject,
> = {
  setState: React.Dispatch<
    React.SetStateAction<TSharedHookInitialState<TRegister, TRelationsRegister>>
  >;
  AppServices: ISharedAppServices<
    TRegister,
    TShowObject,
    TCreateObject,
    TUpdateObject,
    TDestroyObject,
    TRelationsRegister
  >;
  setErrorHandlerData: (
    setErrorHandlerDataProps: TSetErrorHandlerDataProps,
  ) => void;
};

export type TSharedHookReturn<
  TRegister extends TSharedObject,
  TShowObject extends TSharedShowObject,
  TCreateObject extends TSharedCreateObject,
  TUpdateObject extends TSharedUpdateObject,
  TDestroyObject extends TSharedDestroyObject,
  TRelationsRegister extends TSharedObject = TSharedObject,
> = {
  errorHandler: (
    error: TAppError,
    handleFunction: (...args: unknown[]) => unknown,
    extraData?:
      | Partial<TSharedHookInitialState<TRegister, TRelationsRegister>>
      | undefined,
  ) => void;
  setStateSafety: (
    newData:
      | Partial<TSharedHookInitialState<TRegister, TRelationsRegister>>
      | ((
          oldData: TSharedHookInitialState<TRegister, TRelationsRegister>,
        ) => Partial<TSharedHookInitialState<TRegister, TRelationsRegister>>),
  ) => void;
  formData: (
    FormDataDTO: ISharedFormDataDTO<TRelationsRegister>,
  ) => Promise<void>;
  show: (
    ShowDTO: ISharedShowDTO<TShowObject, TRegister, TRelationsRegister>,
  ) => Promise<void>;
  list: (ListDTO: ISharedListDTO<TRegister>) => Promise<void>;
  clearState: () => void;
  create: (
    CreateDTO: ISharedCreateDTO<TCreateObject, TRegister>,
  ) => Promise<void>;
  update: (
    UpdateDTO: ISharedUpdateDTO<TUpdateObject, TRegister>,
  ) => Promise<void>;
  destroy: (
    DestroyDTO: ISharedDestroyDTO<TDestroyObject, TRegister>,
  ) => Promise<void>;
  listSelector: (
    ListSelectorDTO: ISharedListSelectorDTO<TRegister>,
  ) => Promise<TRegister[]>;
};

export function useSharedHook<
  TRegister extends TSharedObject,
  TShowObject extends TSharedShowObject,
  TCreateObject extends TSharedCreateObject,
  TUpdateObject extends TSharedUpdateObject,
  TDestroyObject extends TSharedDestroyObject,
  TRelationsRegister extends TSharedObject = TSharedObject,
>({
  setState,
  AppServices,
  setErrorHandlerData,
}: TSharedHookProps<
  TRegister,
  TShowObject,
  TCreateObject,
  TUpdateObject,
  TDestroyObject,
  TRelationsRegister
>): TSharedHookReturn<
  TRegister,
  TShowObject,
  TCreateObject,
  TUpdateObject,
  TDestroyObject,
  TRelationsRegister
> {
  const { logged_user } = useSession();

  const setStateSafety = useCallback(
    (
      newData:
        | Partial<TSharedHookInitialState<TRegister, TRelationsRegister>>
        | ((
            oldData: TSharedHookInitialState<TRegister, TRelationsRegister>,
          ) => Partial<TSharedHookInitialState<TRegister, TRelationsRegister>>),
    ) => {
      if (typeof newData === 'function')
        setState(oldData => ({ ...oldData, ...newData(oldData) }));

      setState(oldData => ({ ...oldData, ...newData }));
    },
    [setState],
  );

  const errorHandler = useCallback(
    (
      error: TAppError,
      handleFunction: (...args: unknown[]) => unknown,
      extraData?: Partial<
        TSharedHookInitialState<TRegister, TRelationsRegister>
      >,
    ): void => {
      const parsedError =
        error instanceof ErrorProvider
          ? error
          : new ErrorProvider({
              code: error?.code || 'E_UNRECOGNIZED',
              error,
              data: { defaultMessage: error.message },
            });

      const JSONError = parsedError.toJSON();

      setErrorHandlerData({
        error: JSONError,
        handleFunction,
      });

      setStateSafety({
        validations: JSONError?.validations as Record<string, string>,
        ...(extraData || {}),
      });
    },
    [setErrorHandlerData, setStateSafety],
  );

  const formData = useCallback(
    async (
      FormDataDTO: ISharedFormDataDTO<TRelationsRegister>,
    ): Promise<void> => {
      try {
        setStateSafety({ showLoading: true });

        const serviceData = await AppServices.formData(FormDataDTO);

        setStateSafety({
          formInitialData: serviceData.form_data,
          showLoading: false,
        });

        FormDataDTO.onSuccess?.(serviceData);
      } catch (error) {
        const parsedError = error as TAppError;

        errorHandler(parsedError, () => formData(FormDataDTO), {
          showLoading: false,
        });

        FormDataDTO.onError?.(error);
      }
    },
    [AppServices, errorHandler, setStateSafety],
  );

  const show = useCallback(
    async (
      ShowDTO: ISharedShowDTO<TShowObject, TRegister, TRelationsRegister>,
    ): Promise<void> => {
      try {
        setStateSafety({ showLoading: true });

        const serviceData = await AppServices.show(ShowDTO);

        setStateSafety({
          formInitialData: serviceData.form_data,
          registerShow: serviceData.register,
          showLoading: false,
        });

        ShowDTO.onSuccess?.(serviceData);
      } catch (error) {
        const parsedError = error as TAppError;

        errorHandler(parsedError, () => show(ShowDTO), { showLoading: false });

        ShowDTO.onError?.(error);
      }
    },
    [AppServices, errorHandler, setStateSafety],
  );

  const list = useCallback(
    async (ListDTO: ISharedListDTO<TRegister>): Promise<void> => {
      try {
        setStateSafety({ listLoading: true });

        const serviceData = await AppServices.list(ListDTO);

        setStateSafety({
          registerList: serviceData.data,
          listTotal: serviceData.total,
          listLoading: false,
          currentPage: serviceData.current_page,
        });

        ListDTO.onSuccess?.(serviceData);
      } catch (error) {
        const parsedError = error as TAppError;

        errorHandler(parsedError, () => list(ListDTO), { listLoading: false });

        ListDTO.onError?.(error);
      }
    },
    [AppServices, errorHandler, setStateSafety],
  );

  const create = useCallback(
    async (
      CreateDTO: ISharedCreateDTO<TCreateObject, TRegister>,
    ): Promise<void> => {
      try {
        setStateSafety({ formLoading: true });

        const serviceData = await AppServices.create(CreateDTO);
        const { register: createdRegister, error } = serviceData.data[0];

        if (error) throw error;

        setStateSafety(oldData => {
          const parsedRegister = {
            ...createdRegister,
            user_alteration: logged_user,
          };

          const parsedRegisterList = [parsedRegister, ...oldData.registerList];

          return {
            registerList: parsedRegisterList,
            formLoading: false,
          };
        });

        toast.success('Registro criado!', { toastId: 'CREATED' });

        CreateDTO.onSuccess?.(serviceData);
      } catch (error) {
        const parsedError = error as TAppError;

        errorHandler(parsedError, () => create(CreateDTO), {
          formLoading: false,
        });

        CreateDTO.onError?.(error);
      }
    },
    [AppServices, errorHandler, logged_user, setStateSafety],
  );

  const update = useCallback(
    async (
      UpdateDTO: ISharedUpdateDTO<TUpdateObject, TRegister>,
    ): Promise<void> => {
      try {
        setStateSafety({ formLoading: true });

        const serviceData = await AppServices.update(UpdateDTO);
        const { register: updatedRegister, error } = serviceData.data[0];

        if (error) throw error;

        setStateSafety(oldData => {
          const otherRegisters = oldData.registerList.filter(
            register => register.uuid !== updatedRegister.uuid,
          );

          const parsedRegisterList = [updatedRegister, ...otherRegisters];

          return {
            registerShow: { ...oldData.registerShow, ...updatedRegister },
            registerList: parsedRegisterList,
            formLoading: false,
          };
        });

        toast.success('Registro atualizado!', { toastId: 'UPDATED' });

        UpdateDTO.onSuccess?.(serviceData);
      } catch (error) {
        const parsedError = error as TAppError;

        errorHandler(parsedError, () => update(UpdateDTO), {
          formLoading: false,
        });

        UpdateDTO.onError?.(error);
      }
    },
    [AppServices, errorHandler, setStateSafety],
  );

  const destroy = useCallback(
    async (
      DestroyDTO: ISharedDestroyDTO<TDestroyObject, TRegister>,
    ): Promise<void> => {
      try {
        setStateSafety({ listLoading: true });

        const serviceData = await AppServices.destroy(DestroyDTO);
        const { register: destroyedRegister, error } = serviceData.data[0];

        if (error) throw error;

        setStateSafety(oldData => {
          const otherRegisters = oldData.registerList.filter(
            register => register.uuid !== destroyedRegister.uuid,
          );

          const parsedRegisterList = otherRegisters;

          return {
            registerList: parsedRegisterList,
            listLoading: false,
          };
        });

        toast.success('Registro removido!', { toastId: 'DESTROYED' });

        DestroyDTO.onSuccess?.(serviceData);
      } catch (error) {
        const parsedError = error as TAppError;

        errorHandler(parsedError, () => destroy(DestroyDTO), {
          listLoading: false,
        });

        DestroyDTO.onError?.(error);
      }
    },
    [AppServices, errorHandler, setStateSafety],
  );

  const listSelector = useCallback(
    async (
      ListSelectorDTO: ISharedListSelectorDTO<TRegister>,
    ): Promise<TRegister[]> => {
      try {
        const serviceData = await AppServices.listSelector(ListSelectorDTO);

        ListSelectorDTO.onSuccess?.(serviceData);

        return serviceData.data;
      } catch (error) {
        const parsedError = error as TAppError;

        errorHandler(parsedError, () => listSelector(ListSelectorDTO), {
          listLoading: false,
        });

        ListSelectorDTO.onError?.(error);

        return [];
      }
    },
    [AppServices, errorHandler],
  );

  const clearState = useCallback((): void => {
    setState(oldState => ({
      ...oldState,
      formLoading: false,
      showLoading: false,
      registerShow: undefined,
      validations: undefined,
    }));
  }, [setState]);

  return {
    errorHandler,
    setStateSafety,
    formData,
    show,
    list,
    create,
    update,
    destroy,
    listSelector,
    clearState,
  };
}
