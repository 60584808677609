import { useEffect, useState } from 'react';

import { useProject } from '@modules/projects/hooks/project';
import { useDashboard } from '@modules/projects/hooks/dashboard';

import { SORT_TYPES } from '@shared/constants/shared';
import { PROJECT_PER_PAGE } from '@modules/projects/constants/project';

import { TProjectObject } from '@modules/projects/@types/TProject';
import { TSharedListValues } from '@shared/@types/TShared';

import { Page } from '@shared/components/Page';
import { ProjectListHeader } from './Header';
import { ProjectListItem } from './Item';

import {
  Container,
  GoToTopButton,
  CircularProgressContainer,
  CircularProgress,
  Text,
  Tooltip,
  IconButton,
  Refresh,
} from './styles';

export function ProjectList(): JSX.Element {
  const { registerList, listLoading, listTotal, currentPage, list } =
    useProject();
  const { formInitialData, formData } = useDashboard();

  const [pageLoaded, setPageLoaded] = useState(false);
  const [projects, setProjects] = useState<TProjectObject[]>([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [page, setPage] = useState(1);
  const [isReloaded, setIsReloaded] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [haveProjectDeleted, setHaveProjectDeleted] = useState(false);

  const [isFilterList, setIsFilterList] = useState(false);
  const [filterData, setFilterData] = useState<TSharedListValues[]>([]);

  window.onunload = () => localStorage.removeItem('dashboardFilters');

  function reloadPage() {
    window.scrollTo({ top: 0 });

    list({
      order: SORT_TYPES.desc,
      order_by: 'updated_at',
      page: 1,
      per_page: PROJECT_PER_PAGE,
      for_selector: false,
      data: [],
    });

    setProjects([]);
    setPage(1);
    setIsReloaded(true);
  }

  function handleScroll() {
    const position = window.pageYOffset;
    setScrollPosition(position);
  }

  useEffect(() => {
    if (registerList.length === listTotal && pageLoaded && !isFilterList) {
      window.scrollTo({ top: 0 });

      reloadPage();
      setIsCreated(true);
    }
  }, [listTotal]); // eslint-disable-line

  useEffect(() => {
    if (haveProjectDeleted) formData();

    if (registerList.length !== listTotal) {
      let finishUseEffect = false;

      if (isFilterList) {
        window.scrollTo({ top: 0 });
        finishUseEffect = true;

        setProjects(registerList);
        setPage(1);
        setIsFilterList(false);
      }

      if (!filterData.length && isFilterList) {
        setIsFilterList(false);
        setProjects(registerList);
      }

      if (!finishUseEffect) {
        if (!isCreated && !haveProjectDeleted) {
          setProjects(oldData => [...oldData, ...registerList]);
          setHaveProjectDeleted(false);
        } else setProjects(registerList);
      }
    } else if (registerList.length <= PROJECT_PER_PAGE)
      setProjects(registerList);
  }, [registerList]); // eslint-disable-line

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const currentScroll = Math.round(
      document.documentElement.scrollTop + window.innerHeight,
    );
    const pageScroll = document.documentElement.scrollHeight;

    if (
      currentScroll === pageScroll &&
      pageScroll > window.innerHeight &&
      registerList.length === PROJECT_PER_PAGE &&
      page < listTotal / PROJECT_PER_PAGE &&
      !listLoading
    ) {
      list({
        order: SORT_TYPES.desc,
        order_by: 'updated_at',
        page: page + 1,
        per_page: PROJECT_PER_PAGE,
        for_selector: false,
        data: filterData,
      });
      setPage(oldPage => oldPage + 1);
      setIsCreated(false);
    }
  }, [scrollPosition]); // eslint-disable-line

  useEffect(() => {
    if (!registerList.length && !pageLoaded) {
      setTimeout(async () => {
        formData();

        list({
          order: SORT_TYPES.desc,
          order_by: 'updated_at',
          page: 1,
          per_page: PROJECT_PER_PAGE,
          for_selector: false,
          data: [],
        });
      }, 200);
    } else if (!isReloaded && !pageLoaded && currentPage > 1) {
      reloadPage();
      localStorage.removeItem('dashboardFilters');
    }

    setPageLoaded(true);
  }, [pageLoaded]); //eslint-disable-line

  return (
    <Page title="Dashboard">
      <Tooltip title="Recarregar">
        <IconButton
          color="primary"
          sx={{ marginRight: '36px' }}
          onClick={reloadPage}
          disabled={listLoading}
        >
          <Refresh fontSize="large" />
        </IconButton>
      </Tooltip>

      <ProjectListHeader
        setIsFilterList={setIsFilterList}
        setFilterData={setFilterData}
        setIsReloaded={setIsReloaded}
        isReloaded={isReloaded}
        formInitialData={formInitialData}
      />

      <Container>
        <GoToTopButton />
        {listLoading && registerList.length < PROJECT_PER_PAGE ? (
          <CircularProgressContainer>
            <CircularProgress />
          </CircularProgressContainer>
        ) : (
          projects.map(project => (
            <ProjectListItem
              key={project.id}
              project={project}
              setHaveProjectDeleted={setHaveProjectDeleted}
            />
          ))
        )}
        {listLoading && projects.length > 0 && <CircularProgress />}
        {!listLoading && !projects.length && (
          <Text>Nenhum projeto encontrado</Text>
        )}
      </Container>
    </Page>
  );
}
