import { ApiProvider } from '@shared/providers/api';

import { STATUS_CREATED } from '@shared/constants/shared';

import { ISharedCreateDTO } from '@shared/dtos/IShared';
import { ISharedCreateAppRepository } from '@shared/repositories/app/implementations/IShared';

import {
  TSharedObject,
  TSharedValidatedApiReturn,
  TSharedCreateObject,
} from '@shared/@types/TShared';

export class SharedCreateAppRepository<
  TRegister extends TSharedObject,
  TCreateObject extends TSharedCreateObject,
> implements ISharedCreateAppRepository<TRegister, TCreateObject>
{
  private readonly apiProvider = new ApiProvider();

  constructor(
    private readonly baseURL: string,
    private readonly FromApi: ({
      registers,
    }: {
      registers: TRegister[];
    }) => TRegister[],
  ) {}

  public async execute(
    CreateDTO: ISharedCreateDTO<TCreateObject, TRegister>,
  ): Promise<TSharedValidatedApiReturn<TRegister>> {
    const apiResult = await this.apiProvider.execute<
      TSharedValidatedApiReturn<TRegister>
    >({
      method: 'post',
      url: `${this.baseURL}`,
      data: { data: [CreateDTO.register] },
    });

    if (apiResult.data.length && apiResult.data[0].status === STATUS_CREATED) {
      const [sanitizedRegister] = this.FromApi({
        registers: [apiResult.data[0].register],
      });

      apiResult.data[0].register = sanitizedRegister;
    }

    return apiResult;
  }
}
