import { SharedAppRepositories } from '@shared/repositories/app/shared';

import { FromApi } from '@modules/projects/sanitizers/projectStage';

import { BASE_URL } from '@modules/projects/constants/projectStage';

import {
  TProjectStageObject,
  TProjectStageShowObject,
  TProjectStageCreateObject,
  TProjectStageUpdateObject,
  TProjectStageDestroyObject,
} from '@modules/projects/@types/TProjectStage';
import { TUserObject } from '@modules/users/@types/TUser';

export class ProjectStageAppRepositories extends SharedAppRepositories<
  TProjectStageObject,
  TProjectStageShowObject,
  TProjectStageCreateObject,
  TProjectStageUpdateObject,
  TProjectStageDestroyObject,
  TUserObject
> {
  constructor() {
    super(BASE_URL, FromApi);
  }
}
