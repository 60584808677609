import { SharedAppRepositories } from '@shared/repositories/app/shared';

import { FromApi } from '@modules/templates/sanitizers/template';

import { BASE_URL } from '@modules/templates/constants/template';

import {
  TTemplateObject,
  TTemplateShowObject,
  TTemplateCreateObject,
  TTemplateUpdateObject,
  TTemplateDestroyObject,
} from '@modules/templates/@types/TTemplate';
import { TUserObject } from '@modules/users/@types/TUser';

export class TemplateAppRepositories extends SharedAppRepositories<
  TTemplateObject,
  TTemplateShowObject,
  TTemplateCreateObject,
  TTemplateUpdateObject,
  TTemplateDestroyObject,
  TUserObject
> {
  constructor() {
    super(BASE_URL, FromApi);
  }
}
