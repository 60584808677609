import { IProjectSubStageFormDataDTO } from '@modules/projects/dto/IProjectSubStage';
import { SharedFormDataAppRepository } from '@shared/repositories/app/shared/formData';
import { TProjectSubStageRelations } from '@modules/projects/@types/TProjectSubStage';

export class ProjectSubStageFormDataAppRepository extends SharedFormDataAppRepository<TProjectSubStageRelations> {
  public async execute(
    FormDataDTO: IProjectSubStageFormDataDTO,
  ): Promise<Record<string, TProjectSubStageRelations[]>> {
    const apiResult = await this.apiProvider.execute<{
      data: Record<string, TProjectSubStageRelations[]>;
    }>({
      method: 'get',
      url: `${this.baseURL}/form-data`,
      params: {
        project_uuid: FormDataDTO.project_uuid,
      },
    });

    return apiResult.data;
  }
}
