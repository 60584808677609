import styled from 'styled-components';
import MUIAutocomplete from '@material-ui/core/Autocomplete';
import MUITextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import MUIGrid from '@material-ui/core/Grid';
import MUITypography from '@material-ui/core/Typography';

import MUIFormControl from '@material-ui/core/FormControl';

import { FormGridItem } from '@shared/components/Form';

export { FormSkeleton } from '@shared/components/Form';
export { default as FormHelperText } from '@material-ui/core/FormHelperText';

export const Container = styled(FormGridItem)`
  padding-top: 0.2rem;
`;

export const Autocomplete = styled(MUIAutocomplete).attrs({
  size: 'small',
  variant: 'outlined',
  fullWidth: true,
})``;

export const FormControl = styled(MUIFormControl).attrs({
  size: 'small',
  fullWidth: true,
})``;

export const OutlinedInput = styled(MUITextField).attrs({
  color: 'primary',
})``;

export const SelectorLoading = styled(CircularProgress).attrs({ size: 26 })`
  position: absolute;

  top: 7.5px;
  right: 7.5px;
`;

export const LabelContent = styled(MUIGrid)`
  display: flex;
  flex-direction: row;
`;
export const RequiredCharacter = styled(MUITypography).attrs({
  variant: 'subtitle1',
  color: 'error',
})`
  padding-left: 4px;
`;
