import { useState, useCallback } from 'react';
import _Get from 'lodash.get';

import { TSharedObject } from '@shared/@types/TShared';
import { TActionFunction, TColumnInfo } from '@shared/components/TableList';

import noImage from '@shared/assets/noImage.png';

import {
  Container,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  MenuOpenIcon,
  DeleteIcon,
  VisibilityIcon,
  EditIcon,
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogContent,
  CloseIcon,
  ConfirmMessage,
  ConfirmButton,
  CancelButton,
  FormGridContainer,
  UserImage,
} from './styles';

type TBodyProps<TRegister extends TSharedObject> = {
  registerList: TRegister[];
  columnInfos: TColumnInfo[];
  actionFunctions?: TActionFunction<TRegister>[];
};

type TSelectedItem<TRegister extends TSharedObject> = {
  ref?: Element | ((element: Element) => Element) | null;
  register?: TRegister;
};

type TDialogState<TRegister extends TSharedObject> = {
  isOpen: boolean;
  actionFunction?: TActionFunction<TRegister>;
};

export function TBody<TRegister extends TSharedObject>({
  registerList,
  columnInfos,
  actionFunctions,
}: TBodyProps<TRegister>): JSX.Element {
  const [selectedItem, setSelectedItem] = useState<TSelectedItem<TRegister>>({
    ref: undefined,
    register: undefined,
  });
  const [dialogState, setDialogState] = useState<TDialogState<TRegister>>({
    isOpen: false,
    actionFunction: undefined,
  });

  const IconType = useCallback(({ icon }: { icon: string }): JSX.Element => {
    const iconDict: Record<string, JSX.Element> = {
      show: <VisibilityIcon />,
      update: <EditIcon />,
      destroy: <DeleteIcon />,
    };

    return iconDict[icon];
  }, []);

  function handleCloseMenu() {
    setSelectedItem({ ref: undefined, register: undefined });
  }

  function handleOpenMenu(selectedItem: TSelectedItem<TRegister>) {
    setSelectedItem(selectedItem);
  }

  function handleCloseDialog() {
    setDialogState({ isOpen: false });
  }

  function handleConfirmAction() {
    if (selectedItem.register)
      dialogState?.actionFunction?.handle(selectedItem.register);

    handleCloseDialog();
    handleCloseMenu();
  }

  function handleCancelAction() {
    handleCloseDialog();
    handleCloseMenu();
  }

  function handleMenuItemClick(actionFunction: TActionFunction<TRegister>) {
    if (actionFunction.confirm_message) {
      return setDialogState({
        isOpen: true,
        actionFunction,
      });
    }

    if (selectedItem.register) actionFunction.handle(selectedItem.register);

    return handleCloseMenu();
  }

  function getColumnInfos(itemData: TRegister, columnInfoKey: string) {
    const getColumnInfoResult = _Get(itemData, columnInfoKey) as string;

    if (columnInfoKey === 'image') {
      return (
        <UserImage
          src={(getColumnInfoResult as string) || noImage}
          alt="imagem"
        />
      );
    }
    return getColumnInfoResult;
  }

  return (
    <Container>
      <Dialog open={dialogState.isOpen} onClose={handleCloseDialog}>
        <DialogHeader container>
          <DialogTitle>Mensagem de Confirmação</DialogTitle>

          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogHeader>

        <DialogContent>
          <ConfirmMessage>
            {dialogState.actionFunction?.confirm_message}
          </ConfirmMessage>

          <FormGridContainer spacing={1} mt={1}>
            <ConfirmButton onClick={handleConfirmAction}>SIM</ConfirmButton>
            <CancelButton onClick={handleCancelAction}>NÃO</CancelButton>
          </FormGridContainer>
        </DialogContent>
      </Dialog>

      {registerList.map(itemData => (
        <TableRow hover key={itemData.id}>
          {columnInfos.map(columnInfo => (
            <TableCell key={columnInfo.key}>
              {getColumnInfos(itemData, columnInfo.key)}
            </TableCell>
          ))}
          {actionFunctions && (
            <TableCell padding="none" align="center">
              <IconButton
                color="primary"
                size="small"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  handleOpenMenu({
                    ref: event.currentTarget,
                    register: itemData,
                  });
                }}
              >
                <MenuOpenIcon />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      ))}
      {actionFunctions && (
        <Menu
          anchorEl={selectedItem.ref}
          open={!!selectedItem.ref}
          onClose={handleCloseMenu}
        >
          {actionFunctions.map(actionFunction => (
            <MenuItem
              key={actionFunction.key}
              onClick={() => handleMenuItemClick(actionFunction)}
            >
              <IconType icon={actionFunction.key} />
              <Typography>{actionFunction.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Container>
  );
}

TBody.defaultProps = {
  actionFunctions: undefined,
};
