import { Container } from './styles';

type TRecoverPasswordContent = {
  passwordToken?: string;
  onSuccess: () => void;
};

export function RecoverPasswordContent({
  passwordToken,
  onSuccess,
}: TRecoverPasswordContent): JSX.Element {
  return (
    <Container
      queryParams={['theme=dark', `password_token=${passwordToken}`]}
      onSuccess={onSuccess}
    />
  );
}

RecoverPasswordContent.defaultProps = {
  passwordToken: '',
};
