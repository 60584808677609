import styled from 'styled-components';
import MUIFormControl from '@material-ui/core/FormControl';
import MUIInputLabel from '@material-ui/core/InputLabel';
import MUIOutlinedInput from '@material-ui/core/OutlinedInput';
import MUIFormHelperText from '@material-ui/core/FormHelperText';
import MUITypography from '@material-ui/core/Typography';

import { FormGridItem } from '@shared/components/Form';

export { default as InputAdornment } from '@material-ui/core/InputAdornment';
export { default as NumberFormat } from 'react-number-format';
export { FormSkeleton } from '@shared/components/Form';

export const Container = styled(FormGridItem)`
  padding-top: 0.2rem;
`;

export const FormControl = styled(MUIFormControl).attrs({
  size: 'small',
  variant: 'outlined',
  fullWidth: true,
  color: 'primary',
})``;

export const InputLabel = styled(MUIInputLabel).attrs({
  variant: 'outlined',
})`
  display: flex;
  flex-direction: row;
`;

export const OutlinedInput = styled(MUIOutlinedInput)``;

export const FormHelperText = styled(MUIFormHelperText)``;

export const RequiredCharacter = styled(MUITypography).attrs({
  variant: 'subtitle1',
  color: 'error',
})`
  padding-left: 4px;
`;
