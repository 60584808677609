import { useSession } from '@modules/sessions/hooks/session';

import { NotSignedRoutes } from './notSigned.routes';
import { SignedRoutes } from './signed.routes';

export function SharedRoutes(): JSX.Element {
  const { isSigned } = useSession();

  return isSigned ? <SignedRoutes /> : <NotSignedRoutes />;
}
