import { useNavigate } from 'react-router-dom';

import { BASE_URL } from '@modules/templates/constants/stageName';

import { useSession } from '@modules/sessions/hooks/session';

import { useTheme } from '@shared/hooks/theme';

import {
  Container,
  SettingsButton,
  Brightness4Icon,
  Brightness7Icon,
} from './styles';

export function SettingContent(): JSX.Element {
  const { logged_user } = useSession();
  const navigate = useNavigate();

  const { mode, setTheme } = useTheme();

  function handleSwitchTheme() {
    setTheme({ mode: mode === 'light' ? 'dark' : 'light' });
  }

  function handleNavigate(path: string) {
    navigate(path);
  }

  return (
    <Container>
      <SettingsButton onClick={handleSwitchTheme}>
        Tema
        {mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
      </SettingsButton>
      {logged_user?.is_admin && (
        <SettingsButton onClick={() => handleNavigate(`/${BASE_URL}`)}>
          Etapas e Atividades
        </SettingsButton>
      )}
    </Container>
  );
}
