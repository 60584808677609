import styled from 'styled-components';

export const Container = styled.iframe.attrs<{ src: string }>(
  ({ src, theme }) => {
    return {
      src: `${src}&primary_color=${theme.palette.primary.main.slice(1)}&theme=${
        theme.palette.mode
      }`,
    };
  },
)`
  width: 100%;
  border: none;
`;
