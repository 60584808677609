import { SharedAppServices } from '@shared/services/app/shared';

import { ProjectStageAppRepositories } from '@modules/projects/repositories/app/projectStage';

import {
  TProjectStageObject,
  TProjectStageShowObject,
  TProjectStageCreateObject,
  TProjectStageUpdateObject,
  TProjectStageDestroyObject,
} from '@modules/projects/@types/TProjectStage';
import { TUserObject } from '@modules/users/@types/TUser';

export class ProjectStageAppServices extends SharedAppServices<
  TProjectStageObject,
  TProjectStageShowObject,
  TProjectStageCreateObject,
  TProjectStageUpdateObject,
  TProjectStageDestroyObject,
  TUserObject
> {
  constructor() {
    const appRepositories = new ProjectStageAppRepositories();

    super(appRepositories);
  }
}
