import styled from 'styled-components';
import MUIGrid from '@material-ui/core/Grid';

export {
  FormContainer,
  FormGridContainer,
  FormTextInput,
  FormValueInput,
  FormSelector,
  FormCancelButton,
  FormConfirmButton,
} from '@shared/components/Form';

export const ContentFormContainer = styled(MUIGrid)`
  width: 140%;
  margin-left: 20px;
  padding: 16px;
`;
