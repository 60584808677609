import {
  TBaseProjectSubStageObject,
  TProjectSubStageShowObject,
  TProjectSubStageCreateObject,
  TProjectSubStageUpdateObject,
  TProjectSubStageDestroyObject,
  TProjectSubStageObject,
} from '@modules/projects/@types/TProjectSubStage';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import {
  ToBaseShowSanitizer,
  ToBaseCreateSanitizer,
  ToBaseUpdateSanitizer,
  ToBaseDestroySanitizer,
  FromBaseApi,
} from '@shared/sanitizers/shared';

import { FromApi as projectMessageFromApi } from './projectMessage';

function ToBaseSanitizer({
  register,
}: {
  register: TBaseProjectSubStageObject;
}): TBaseProjectSubStageObject {
  const parsedObject: TBaseProjectSubStageObject = {
    project_stage_uuid: register.project_stage_uuid,
    stage_name_uuid: register.stage_name_uuid,
    template_sub_stage_uuid: register.template_sub_stage_uuid,
    responsible_uuid: register.responsible_uuid || undefined,
    description: register.description,
    status: register.status,
    is_reopen: register.is_reopen,
    notificate_users: register.notificate_users,
  };

  return parsedObject;
}

export function ToShow({
  register,
}: {
  register: TProjectSubStageShowObject;
}): TProjectSubStageShowObject {
  const parsedObject: TProjectSubStageShowObject = {
    ...ToBaseShowSanitizer({ register }),
  };

  return parsedObject;
}

export function ToCreate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TProjectSubStageCreateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TProjectSubStageCreateObject {
  const parsedObject: TProjectSubStageCreateObject = {
    ...ToBaseCreateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register: { ...register, is_reopen: false } }),
  };

  return parsedObject;
}

export function ToUpdate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TProjectSubStageUpdateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TProjectSubStageUpdateObject {
  const parsedObject: TProjectSubStageUpdateObject = {
    ...ToBaseUpdateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToDestroy({
  register,
  logged_branch,
}: {
  register: TProjectSubStageUpdateObject;
  logged_branch: TLoggedBranch;
}): TProjectSubStageDestroyObject {
  const parsedObject: TProjectSubStageDestroyObject = {
    ...ToBaseDestroySanitizer({ register, logged_branch }),
  };

  return parsedObject;
}

export function FromApi({
  registers,
}: {
  registers: TProjectSubStageObject[];
}): TProjectSubStageObject[] {
  const parsedObjects: TProjectSubStageObject[] = registers.map(register => ({
    ...register,
    ...FromBaseApi({ registers: [register] })[0],
    project_messages: projectMessageFromApi({
      registers: register?.project_messages || [],
    }),
  }));

  return parsedObjects;
}
