import { Routes, Route, Navigate } from 'react-router-dom';

import { UserList } from '@modules/users/pages/User/List';
import { UserForm } from '@modules/users/pages/User/Form';

import { ALL_PERMISSION_KEYS } from '@shared/constants/shared';

import { HasPermissionComponent } from '@shared/components/HasPermissionComponent';

import { NotFound } from '@shared/pages/NotFound';
import { Forbidden } from '@shared/pages/Forbidden';
import { useSession } from '@modules/sessions/hooks/session';

export function UserRoutes(): JSX.Element {
  const { sessionLoading } = useSession();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <HasPermissionComponent
            permission_key={[ALL_PERMISSION_KEYS.users_list]}
            element={<UserList />}
            fall_back_element={<>{!sessionLoading && <Forbidden />}</>}
          />
        }
      />
      <Route
        path="/form"
        element={
          <HasPermissionComponent
            permission_key={[ALL_PERMISSION_KEYS.users_create]}
            element={<UserForm />}
            fall_back_element={<>{!sessionLoading && <Forbidden />}</>}
          />
        }
      />

      <Route path="/not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  );
}
