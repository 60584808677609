import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { ErrorProvider } from '@shared/providers/error';

import { DashboardAppService } from '@modules/projects/services/app/dashboard';

import { useErrorHandler } from '@shared/providers/error/hook';

import { TAppError } from '@shared/providers/error/@types/TError';

import { SharedHookInitialState } from '@shared/hooks/shared';
import { TDashboardRelationsObject } from '../@types/TDashboard';

type TDashboardHookInitialState = {
  formInitialData?: TDashboardRelationsObject;
  formLoading: boolean;
  registerShow?: Record<string, unknown>;
  showLoading: boolean;
  registerList: never[];
  listLoading: boolean;
  listTotal: number;
  validations?: Record<string, string>;
};

type TDashboardHookReturn = {
  errorHandler: (
    error: TAppError,
    handleFunction: (...args: unknown[]) => unknown,
    extraData?: Partial<TDashboardHookInitialState> | undefined,
  ) => void;
  formData: () => Promise<void>;
};

const INITIAL_STATE: TDashboardHookInitialState = SharedHookInitialState;

type TDashboardContext = TDashboardHookInitialState & TDashboardHookReturn;

const DashboardContext = createContext<TDashboardContext>(
  INITIAL_STATE as TDashboardContext,
);

const AppServices = new DashboardAppService();

export function DashboardProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [state, setState] = useState<TDashboardHookInitialState>(INITIAL_STATE);

  const { setErrorHandlerData } = useErrorHandler();

  const setStateSafety = useCallback(
    (newData: Partial<TDashboardHookInitialState>) => {
      setState(oldData => ({ ...oldData, ...newData }));
    },
    [],
  );

  const errorHandler = useCallback(
    (
      error: TAppError,
      handleFunction: (...args: unknown[]) => unknown,
      extraData?: Partial<TDashboardHookInitialState>,
    ): void => {
      const parsedError =
        error instanceof ErrorProvider
          ? error
          : new ErrorProvider({
              code: error?.code || 'E_UNRECOGNIZED',
              error,
              data: { defaultMessage: error.message },
            });

      const JSONError = parsedError.toJSON();

      setErrorHandlerData({
        error: JSONError,
        handleFunction,
      });

      setStateSafety({
        validations: JSONError?.validations as Record<string, string>,
        ...(extraData || {}),
      });
    },
    [setErrorHandlerData, setStateSafety],
  );

  const formData = useCallback(async (): Promise<void> => {
    try {
      setStateSafety({ showLoading: true });

      const serviceData = await AppServices.formData();

      setStateSafety({
        formInitialData: serviceData.form_data,
        showLoading: false,
      });
    } catch (error) {
      const parsedError = error as TAppError;

      errorHandler(parsedError, () => formData(), {
        showLoading: false,
      });
    }
  }, [errorHandler, setStateSafety]);

  return (
    <DashboardContext.Provider value={{ ...state, errorHandler, formData }}>
      {children}
    </DashboardContext.Provider>
  );
}

export function useDashboard(): TDashboardContext {
  const context = useContext(DashboardContext);

  if (!context)
    throw new Error('useDashboard must be used within an DashboardProvider');

  return context;
}
