import { Routes, Route, Navigate } from 'react-router-dom';

import { BASE_URL as TEMPLATE_BASE_URL } from '@modules/templates/constants/template';
import { BASE_URL as STAGE_NAME_BASE_URL } from '@modules/templates/constants/stageName';
import { BASE_URL as SETTINGS_BASE_URL } from '@modules/settings/constants/setting';
import { BASE_URL as USERS_BASE_URL } from '@modules/users/constants/user';
import { BASE_URL as PROFILE_BASE_URL } from '@modules/sessions/constants/profile';

import { Navigation } from '@shared/components/Navigation';

import { ProjectRoutes } from '@modules/projects/routes/project.routes';
import { TemplateRoutes } from '@modules/templates/routes/template.routes';
import { StageNameRoutes } from '@modules/templates/routes/stageName.routes';
import { SettingRoutes } from '@modules/settings/routes/setting.routes';
import { UserRoutes } from '@modules/users/routes/user.routes';
import { ProfileRoutes } from '@modules/sessions/routes/profile.routes';

import { NotFound } from '@shared/pages/NotFound';

export function SignedRoutes(): JSX.Element {
  return (
    <Navigation>
      <Routes>
        <Route path="/*" element={<ProjectRoutes />} />
        <Route path={`${PROFILE_BASE_URL}/*`} element={<ProfileRoutes />} />
        <Route path={`${TEMPLATE_BASE_URL}/*`} element={<TemplateRoutes />} />
        <Route path={`${USERS_BASE_URL}/*`} element={<UserRoutes />} />
        <Route
          path={`${STAGE_NAME_BASE_URL}/*`}
          element={<StageNameRoutes />}
        />

        <Route path={`${SETTINGS_BASE_URL}/*`} element={<SettingRoutes />} />

        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/not-found" />} />
      </Routes>
    </Navigation>
  );
}
