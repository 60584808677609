import styled from 'styled-components';
import MUIGrid from '@material-ui/core/Grid';
import MUIButton from '@material-ui/core/Button';

export { default as Brightness4Icon } from '@material-ui/icons/Brightness4';
export { default as Brightness7Icon } from '@material-ui/icons/Brightness7';

export const Container = styled(MUIGrid).attrs({
  container: true,
  gap: 1,
})`
  padding: 2em;
`;

export const SettingsButton = styled(MUIButton).attrs({
  color: 'primary',
  size: 'large',
  variant: 'outlined',
})`
  &.MuiButton-root {
    padding: 2em 5em;
    max-width: 200px;
  }
`;
