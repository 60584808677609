import { ISessionShowLocalAppService } from '@modules/sessions/services/app/implementations/ISession';
import { ISessionShowLocalStorageRepository } from '@modules/sessions/repositories/localStorage/implementations/ISession';

import { TLocalStorageProps } from '@modules/sessions/@types/TSession';
import { apiHttpClientConfig } from '@shared/providers/api/configs/httpClient';

export class SessionShowLocalAppService implements ISessionShowLocalAppService {
  constructor(
    private readonly sessionShowLocalStorageRepository: ISessionShowLocalStorageRepository,
  ) {}

  public execute(): TLocalStorageProps {
    const showLocalStorageRepositoryResult =
      this.sessionShowLocalStorageRepository.execute();

    apiHttpClientConfig.defaults = {
      ...apiHttpClientConfig.defaults,
      headers: {
        ...apiHttpClientConfig.defaults.headers,
        authorization: `Bearer ${showLocalStorageRepositoryResult.token?.token}`,
        'Refresh-Token': `${showLocalStorageRepositoryResult.token?.refreshToken}`,
      },
    };

    return showLocalStorageRepositoryResult;
  }
}
