import {
  ToBaseShowSanitizer,
  ToBaseCreateSanitizer,
  ToBaseUpdateSanitizer,
  ToBaseDestroySanitizer,
  FromBaseApi,
} from '@shared/sanitizers/shared';

import {
  TBaseProjectObject,
  TProjectShowObject,
  TProjectCreateObject,
  TProjectUpdateObject,
  TProjectDestroyObject,
  TProjectObject,
  TFilterByProps,
} from '@modules/projects/@types/TProject';
import {
  TProjectStageCreateObject,
  TProjectStageObject,
} from '@modules/projects/@types/TProjectStage';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import { TTemplateObject } from '@modules/templates/@types/TTemplate';
import { TSharedValidatedApiReturn } from '@shared/@types/TShared';
import { TProjectSubStageObject } from '@modules/projects/@types/TProjectSubStage';

import {
  ToCreate as projectUserToCreate,
  FromApi as projectUserFromApi,
} from './projectUser';
import {
  ToCreate as projectStageToCreate,
  FromApi as projectStageFromApi,
} from './projectStage';

function ToBaseSanitizer({
  register,
}: {
  register: TBaseProjectObject;
}): TBaseProjectObject {
  const parsedObject: TBaseProjectObject = {
    template_uuid: register.template_uuid,
    responsible_uuid: register.responsible_uuid,
    name: register.name,
    description: register.description,
    neighborhood: register.neighborhood,
    status: register.status,
  };

  return parsedObject;
}

export function ToListFilter(register: TFilterByProps): TFilterByProps {
  return {
    project_uuid: register.project_uuid || undefined,
    stage_name_uuid: register.stage_name_uuid || undefined,
    status: register.status || undefined,
    responsible_uuid: register.responsible_uuid || undefined,
    user_uuid: register.user_uuid || undefined,
    neighborhood: register.neighborhood,
  };
}

export function ToShow({
  register,
}: {
  register: TProjectShowObject;
}): TProjectShowObject {
  const parsedObject: TProjectShowObject = {
    ...ToBaseShowSanitizer({ register }),
  };

  return parsedObject;
}

export function ToCreate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TProjectCreateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TProjectCreateObject {
  const parsedObject: TProjectCreateObject = {
    ...ToBaseCreateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
    project_users: register.project_users.map(projectUser =>
      projectUserToCreate({
        register: projectUser,
        logged_branch,
        logged_user,
      }),
    ),
    project_stages: register.project_stages.map(projectStage =>
      projectStageToCreate({
        register: projectStage,
        logged_branch,
        logged_user,
      }),
    ),
  };

  return parsedObject;
}

export function ToUpdate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TProjectUpdateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TProjectUpdateObject {
  const parsedObject: TProjectUpdateObject = {
    ...ToBaseUpdateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToDestroy({
  register,
  logged_branch,
}: {
  register: TProjectUpdateObject;
  logged_branch: TLoggedBranch;
}): TProjectDestroyObject {
  const parsedObject: TProjectDestroyObject = {
    ...ToBaseDestroySanitizer({ register, logged_branch }),
  };

  return parsedObject;
}

export function FromCreateApi({
  registers,
}: {
  registers: TProjectObject[];
}): TProjectObject[] {
  const parsedObjects: TProjectObject[] = registers.map(register => {
    const project_stages =
      register.project_stages as unknown as TSharedValidatedApiReturn<TProjectStageObject>['data'];

    const parsedProjectStages = project_stages?.map(projectStage => {
      const project_sub_stages = projectStage.register
        .project_sub_stages as unknown as TSharedValidatedApiReturn<TProjectSubStageObject>['data'];

      const parsedProjectSubStages = project_sub_stages?.map(
        projectSubStage => {
          return projectSubStage.register;
        },
      );

      const parsedProjectStage = {
        ...projectStage.register,
        project_sub_stages: parsedProjectSubStages,
      };

      return parsedProjectStage;
    });

    const parsedRegister = {
      ...register,
      ...FromBaseApi({ registers: [register] })[0],
      project_stages: parsedProjectStages,
    };

    return parsedRegister;
  });

  return parsedObjects;
}

export function FromApi({
  registers,
}: {
  registers: TProjectObject[];
}): TProjectObject[] {
  const parsedObjects: TProjectObject[] = registers.map(register => ({
    ...register,
    ...FromBaseApi({ registers: [register] })[0],
    project_users: projectUserFromApi({
      registers: register?.project_users || [],
    }),
    project_stages: projectStageFromApi({
      registers: register?.project_stages || [],
    }),
  }));

  return parsedObjects;
}

export function FromTemplate({
  register,
}: {
  register: TTemplateObject;
}): TProjectCreateObject {
  const parsedObjects: TProjectCreateObject = {
    ...register,
    ...FromBaseApi({ registers: [register] })[0],
    template_uuid: register.uuid,
    neighborhood: '',
    project_users: [],
    project_stages: projectStageFromApi({
      registers:
        register?.template_stages?.map(templateStage => ({
          ...templateStage,
          project_uuid: '',
          template_stage_uuid: templateStage.uuid,
          project_sub_stages:
            templateStage.template_sub_stages?.map(templateSubStage => ({
              ...templateSubStage,
              project_stage_uuid: '',
              project_uuid: '',
              template_sub_stage_uuid: templateSubStage.uuid,
              is_reopen: false,
            })) || [],
          is_reopen: false,
        })) || [],
    }) as TProjectStageCreateObject[],
  };

  return parsedObjects;
}
