import styled from 'styled-components';
import MUIIconButton from '@material-ui/core/IconButton';
import MUITypography from '@material-ui/core/Typography';
import MUIToolbar from '@material-ui/core/Toolbar';
import MUISearchIcon from '@material-ui/icons/Search';
import { FormGridItem } from '@shared/components/Form';

export { default as AddIcon } from '@material-ui/icons/Add';
export { default as SendIcon } from '@material-ui/icons/Send';
export { default as CircularProgress } from '@material-ui/core/CircularProgress';

export {
  FormContainer,
  FormGridContainer,
  FormSelector,
  FormTextInput,
  FormValueInput,
} from '@shared/components/Form';

export const Container = styled(MUIToolbar)`
  padding-top: 0.5rem;
`;

export const IconButtonContainer = styled(FormGridItem).attrs({
  xs: 12,
  sm: 1,
})`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding-top: 0.2rem;
`;

export const IconButton = styled(MUIIconButton).attrs({
  color: 'primary',
})``;

export const Typography = styled(MUITypography).attrs({
  variant: 'h6',
})``;

export const SearchIcon = styled(MUISearchIcon)`
  width: 36px;
  height: 36px;
`;
