import add from 'date-fns/add';

import { ToInteger } from '@shared/sanitizers/number';

import {
  TBaseTemplateStageObject,
  TTemplateStageShowObject,
  TTemplateStageCreateObject,
  TTemplateStageUpdateObject,
  TTemplateStageDestroyObject,
  TTemplateStageObject,
} from '@modules/templates/@types/TTemplateStage';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import {
  ToBaseShowSanitizer,
  ToBaseCreateSanitizer,
  ToBaseUpdateSanitizer,
  ToBaseDestroySanitizer,
  FromBaseApi,
} from '@shared/sanitizers/shared';

import { FromApi as templateSubStageFromApi } from './templateSubStage';

function ToBaseSanitizer({
  register,
}: {
  register: TBaseTemplateStageObject;
}): TBaseTemplateStageObject {
  const parsedObject: TBaseTemplateStageObject = {
    template_uuid: register.template_uuid,
    stage_name_uuid: register.stage_name_uuid,
    description: register.description,
    responsible_uuid: register.responsible_uuid,
    status: register.status || undefined,
    estimated_delivery_days: ToInteger(register.estimated_delivery_days || 0),
  };

  return parsedObject;
}

export function ToShow({
  register,
}: {
  register: TTemplateStageShowObject;
}): TTemplateStageShowObject {
  const parsedObject: TTemplateStageShowObject = {
    ...ToBaseShowSanitizer({ register }),
  };

  return parsedObject;
}

export function ToCreate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TTemplateStageCreateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TTemplateStageCreateObject {
  const parsedObject: TTemplateStageCreateObject = {
    ...ToBaseCreateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToUpdate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TTemplateStageUpdateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TTemplateStageUpdateObject {
  const parsedObject: TTemplateStageUpdateObject = {
    ...ToBaseUpdateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToDestroy({
  register,
  logged_branch,
}: {
  register: TTemplateStageUpdateObject;
  logged_branch: TLoggedBranch;
}): TTemplateStageDestroyObject {
  const parsedObject: TTemplateStageDestroyObject = {
    ...ToBaseDestroySanitizer({ register, logged_branch }),
  };

  return parsedObject;
}

export function FromApi({
  registers,
}: {
  registers: TTemplateStageObject[];
}): TTemplateStageObject[] {
  const parsedObjects: TTemplateStageObject[] = registers.map(register => ({
    ...register,
    ...FromBaseApi({ registers: [register] })[0],
    estimated_delivery_at: add(new Date(), {
      days: register.estimated_delivery_days || 0,
    }).toISOString(),
    template_sub_stages: templateSubStageFromApi({
      registers: register?.template_sub_stages || [],
    }),
  }));

  return parsedObjects;
}
