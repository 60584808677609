import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { useSession } from '@modules/sessions/hooks/session';
import { useProject } from '@modules/projects/hooks/project';
import { useProjectUser } from '@modules/projects/hooks/projectUser';

import { ToDestroy } from '@modules/projects/sanitizers/projectUser';

import { SEARCH_TYPES } from '@shared/constants/shared';

import { TProjectUserObject } from '@modules/projects/@types/TProjectUser';
import {
  TActionFunction,
  TColumnInfo,
  TFilterProps,
} from '@shared/components/TableList';
import { TFilterByOption } from '@shared/components/TableList/Header';
import { TLoggedBranch } from '@modules/sessions/@types/TSession';
import { TSharedListValues } from '@shared/@types/TShared';
import { DefaultFormContainer } from '@shared/components/Form/DefaultFormContainer';
import {
  TableList,
  Container,
  Dialog,
  DialogHeader,
  IconButton,
  CloseIcon,
  DialogTitle,
  DialogContent,
  ProjectUserFormContent,
  ContentFormContainer,
} from './styles';

const columnInfos: TColumnInfo[] = [
  { key: 'id', label: 'Código' },
  { key: 'user_uuid_view', label: 'Nome' },
];

const filterByOptions: TFilterByOption[] = [
  { key: 'id', label: 'Código', type: 'normal', format: 'integer' },
  { key: 'name', label: 'Nome', type: 'normal', format: 'string' },
];

type TProjectUserListContent = {
  project_uuid: string;
};

export function ProjectUserListContent({
  project_uuid,
}: TProjectUserListContent): JSX.Element {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const { logged_branch } = useSession();
  const { registerShow } = useProject();
  const {
    list,
    destroy,
    validations,
    registerList,
    listTotal,
    listLoading,
    formData,
    clearState,
  } = useProjectUser();

  function handleCloseCreateDialog() {
    setIsCreateDialogOpen(false);
    clearState();
  }

  function onSubmitSearch({
    filter_by,
    data,
    order,
    order_by,
    page,
    per_page,
  }: TFilterProps): void {
    const filterData: TSharedListValues[] = [
      {
        field: 'project_uuid',
        type: SEARCH_TYPES.normal,
        values: [project_uuid],
      },
    ];

    if (data && filter_by) {
      if (filter_by === 'name')
        filterData.push({
          field: 'user',
          type: SEARCH_TYPES.relation,
          values: [
            {
              field: filter_by,
              type: SEARCH_TYPES.normal,
              values: data.split(';'),
            },
          ],
        });
      else
        filterData.push({
          field: filter_by,
          type: SEARCH_TYPES.normal,
          values: data.split(';'),
        });
    }

    const searchData = {
      order,
      order_by,
      page,
      per_page,
      for_selector: false,
      data: filterData,
    };

    list(searchData);
  }

  function addFunction() {
    formData({});
    setIsCreateDialogOpen(true);
  }

  const actionFunctions = useMemo<TActionFunction<TProjectUserObject>[]>(() => {
    return [
      {
        key: 'destroy',
        label: 'Remover',
        handle: (register: TProjectUserObject) => {
          const foundResponsible = registerShow?.project_stages?.find(
            project_stage => {
              if (project_stage.responsible_uuid === register.user_uuid) {
                return project_stage;
              }

              return project_stage?.project_sub_stages?.find(
                project_sub_stage =>
                  project_sub_stage.responsible_uuid === register.user_uuid,
              );
            },
          );

          if (!foundResponsible) {
            destroy({
              register: ToDestroy({
                register,
                logged_branch: logged_branch as TLoggedBranch,
              }),
            });
          } else {
            toast.warn(
              'Este usuário está atribuído como dirigente. Para removê-lo de "Envolvidos", é necessário desvincular sua função de dirigente.',
            );
          }
        },
        confirm_message: 'Deseja mesmo desvincular este usuário do projeto?',
      },
    ];
  }, [destroy, logged_branch, registerShow?.project_stages]);

  return (
    <Container>
      <Dialog
        open={isCreateDialogOpen}
        onClose={handleCloseCreateDialog}
        maxWidth="sm"
        fullWidth
      >
        <DefaultFormContainer style={{ width: '100%', marginTop: '0px' }}>
          <DialogHeader container>
            <DialogTitle>Vincular usuário ao projeto</DialogTitle>

            <IconButton onClick={handleCloseCreateDialog}>
              <CloseIcon />
            </IconButton>
          </DialogHeader>

          <ContentFormContainer>
            <DialogContent>
              <ProjectUserFormContent
                project_uuid={project_uuid}
                onSuccess={handleCloseCreateDialog}
              />
            </DialogContent>
          </ContentFormContainer>
        </DefaultFormContainer>
      </Dialog>

      <TableList
        title="Envolvidos"
        registerList={registerList}
        listTotal={listTotal}
        addFunction={addFunction}
        columnInfos={columnInfos}
        onSubmitSearch={onSubmitSearch}
        filterByOptions={filterByOptions}
        actionFunctions={actionFunctions}
        validations={validations}
        loading={listLoading}
        height="calc(100vh - 16rem)"
      />
    </Container>
  );
}
