import {
  ADMIN_PERMISSION_KEYS,
  ADMIN_ROLE,
  BY_PASS_PERMISSION_KEYS,
  MANAGER_PERMISSION_KEYS,
  MANAGER_ROLE,
  SUBPRODUCTS_PERMISSIONS,
} from '@shared/constants/shared';

import { useSession } from '@modules/sessions/hooks/session';

export function HasPermission({
  permission_keys,
}: {
  permission_keys: string[];
}): boolean {
  const { current_accesses, logged_user } = useSession();

  const isAdminPermission = permission_keys.some(
    permission_key => ADMIN_PERMISSION_KEYS[permission_key],
  );

  const is_admin = current_accesses?.roles[ADMIN_ROLE];

  if (logged_user?.is_admin || is_admin) return true;

  if (isAdminPermission) return false;

  const is_manager = current_accesses?.roles[MANAGER_ROLE];

  const isManagerPermission = permission_keys.some(
    permission_key => MANAGER_PERMISSION_KEYS[permission_key],
  );

  if (!is_manager && isManagerPermission) return false;

  const userHasPermission = permission_keys.some(
    permission_key => current_accesses?.permissions[permission_key],
  );

  if (!userHasPermission && !is_manager) {
    const byPassPermission = permission_keys.some(
      permission_key => BY_PASS_PERMISSION_KEYS[permission_key],
    );

    if (!byPassPermission) return false;
  }

  const findedSubproduct = SUBPRODUCTS_PERMISSIONS.find(SubproductsPermission =>
    SubproductsPermission.related_permission_keys.some(relatedPermissionKey =>
      permission_keys.some(
        permission_key => permission_key === relatedPermissionKey,
      ),
    ),
  );

  if (findedSubproduct) {
    const userHasSubproduct =
      current_accesses?.permissions[findedSubproduct.subproduct_key];

    if (!userHasSubproduct) return false;
  }

  return true;
}
