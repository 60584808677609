import styled from 'styled-components';
import MUIIconButton from '@material-ui/core/IconButton';
import MUITypography from '@material-ui/core/Typography';
import MUIGrid from '@material-ui/core/Grid';

import { FormConfirmButton, FormCancelButton } from '@shared/components/Form';

export { default as Container } from '@material-ui/core/TableBody';
export { default as TableRow } from '@material-ui/core/TableRow';
export { default as TableCell } from '@material-ui/core/TableCell';
export { default as Menu } from '@material-ui/core/Menu';
export { default as MenuItem } from '@material-ui/core/MenuItem';
export { default as Dialog } from '@material-ui/core/Dialog';
export { default as DialogTitle } from '@material-ui/core/DialogTitle';
export { default as DialogContent } from '@material-ui/core/DialogContent';
export { default as CloseIcon } from '@material-ui/icons/Close';
export { default as MenuOpenIcon } from '@material-ui/icons/MenuOpen';
export { default as DeleteIcon } from '@material-ui/icons/Delete';
export { default as VisibilityIcon } from '@material-ui/icons/Visibility';
export { default as EditIcon } from '@material-ui/icons/Edit';

export { FormGridContainer } from '@shared/components/Form';

export const IconButton = styled(MUIIconButton).attrs({
  color: 'primary',
})``;

export const Typography = styled(MUITypography)`
  margin-left: 5px;
`;

export const ConfirmMessage = styled(MUITypography).attrs({ variant: 'h6' })``;

export const DialogHeader = styled(MUIGrid)`
  display: flex;
  justify-content: space-between;
`;

export const UserImage = styled('img')`
  width: 40px;
  height: 40px;

  border-radius: 50%;
`;

export const ConfirmButton = styled(FormConfirmButton).attrs({
  gridProps: { xs: 6 },
})``;

export const CancelButton = styled(FormCancelButton).attrs({
  gridProps: { xs: 6 },
})``;
