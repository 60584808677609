import { useState, useEffect } from 'react';
import { useProjectMessage } from '@modules/projects/hooks/projectMessage';
import { HasPermission } from '@shared/validators/permissionsValidator';

import { ALL_PERMISSION_KEYS } from '@shared/constants/shared';
import { ToDestroy } from '@modules/projects/sanitizers/projectMessage';
import { useSession } from '@modules/sessions/hooks/session';

import { TLoggedBranch } from '@modules/sessions/@types/TSession';
import { TProjectMessageObject } from '@modules/projects/@types/TProjectMessage';
import { HasPermissionComponent } from '../../Form/Content/styles';

import {
  MessagesContainer,
  MessageContainer,
  ImageContent,
  UserImage,
  MessageContent,
  MessageInfosContainer,
  UserName,
  MessageUpdatedAt,
  MessageAttachedFilesContainer,
  MessageAttachedFiles,
  FolderIcon,
  Link,
  MessageTextContainer,
  Message,
  GoToTopButton,
  ContainerInfos,
  DeleteIcon,
  IconButton,
  Dialog,
  DialogHeader,
  DialogTitle,
  CloseIcon,
  DialogContent,
  ConfirmMessage,
  FormGridContainer,
  ConfirmButton,
  CancelButton,
  Text,
  CircularProgressContainer,
  CircularProgress,
} from './styles';

export function ProjectMessageListContent(): JSX.Element {
  const { registerList, destroy } = useProjectMessage();
  const [listLoading, setListLoading] = useState(true);

  const { logged_branch, logged_user } = useSession();

  const [dialogState, setDialogState] = useState({
    isOpen: false,
  });

  useEffect(() => {
    setTimeout(() => {
      setListLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const container = document.querySelector('#message-container');

    return container?.scrollTo(0, container?.scrollHeight);
  }, [registerList]);

  function removeMessage(register: TProjectMessageObject) {
    destroy({
      register: ToDestroy({
        register,
        logged_branch: logged_branch as TLoggedBranch,
      }),
    });
  }

  function handleOpenMenu(register: TProjectMessageObject) {
    setDialogState({ isOpen: true });

    return register;
  }

  function handleCloseDialog() {
    setDialogState({ isOpen: false });
  }

  function handleConfirmAction(register: TProjectMessageObject) {
    removeMessage(register);
    handleCloseDialog();
  }

  function handleCancelAction() {
    handleCloseDialog();
  }

  return (
    <MessagesContainer
      style={{
        height: HasPermission({
          permission_keys: [ALL_PERMISSION_KEYS.project_message_create],
        })
          ? '50%'
          : '98%',
      }}
      id="message-container"
    >
      <GoToTopButton elementId="message-container" customtopmargin="75%" />

      {listLoading ? (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      ) : (
        registerList.map(projectMessage => (
          <MessageContainer key={projectMessage.id}>
            <ImageContent>
              <UserImage
                alt="user"
                src={projectMessage.user_alteration?.image}
              />
            </ImageContent>
            <MessageContent>
              <MessageInfosContainer>
                <ContainerInfos>
                  <UserName>{projectMessage.user_alteration?.name}</UserName>
                  <MessageUpdatedAt>
                    {projectMessage.updated_at_view}
                  </MessageUpdatedAt>
                </ContainerInfos>

                <HasPermissionComponent
                  permission_key={[ALL_PERMISSION_KEYS.project_message_delete]}
                  enabled_to_view={[!!logged_user?.is_admin]}
                  element={
                    <IconButton onClick={() => handleOpenMenu(projectMessage)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  }
                />

                <Dialog open={dialogState.isOpen} onClose={handleCloseDialog}>
                  <DialogHeader container>
                    <DialogTitle>Mensagem de Confirmação</DialogTitle>

                    <IconButton onClick={handleCloseDialog}>
                      <CloseIcon />
                    </IconButton>
                  </DialogHeader>

                  <DialogContent>
                    <ConfirmMessage>
                      Deseja mesmo remover essa mensagem?
                    </ConfirmMessage>

                    <FormGridContainer spacing={1} mt={1}>
                      <ConfirmButton
                        onClick={() => handleConfirmAction(projectMessage)}
                      >
                        SIM
                      </ConfirmButton>
                      <CancelButton onClick={handleCancelAction}>
                        NÃO
                      </CancelButton>
                    </FormGridContainer>
                  </DialogContent>
                </Dialog>
              </MessageInfosContainer>

              <MessageAttachedFilesContainer>
                {projectMessage.project_message_files.map(
                  projectMessageFile => (
                    <Link
                      key={projectMessageFile.file_uuid}
                      href={projectMessageFile.file.url}
                      download
                      target="_blank"
                    >
                      <MessageAttachedFiles>
                        <FolderIcon />

                        {projectMessageFile.file.name}
                      </MessageAttachedFiles>
                    </Link>
                  ),
                )}
              </MessageAttachedFilesContainer>
              <MessageTextContainer>
                <Message
                  dangerouslySetInnerHTML={{
                    __html: projectMessage.text,
                  }}
                />
              </MessageTextContainer>
            </MessageContent>
          </MessageContainer>
        ))
      )}
      {!listLoading && !registerList.length && (
        <Text>Nenhuma mensagem encontrada</Text>
      )}
    </MessagesContainer>
  );
}
