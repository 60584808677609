import { TFileObject } from '@modules/files/@types/TFile';

import { TSharedValidatedApiReturn } from '@shared/@types/TShared';
import { FileCreateAppRepository } from '@modules/files/repositories/app/files/create';
import { IFileCreateDTO } from '@modules/files/dtos/IFile';

export class FileCreateAppService {
  private readonly createAppRepository = new FileCreateAppRepository();

  public async execute(
    CreateDTO: IFileCreateDTO,
  ): Promise<TSharedValidatedApiReturn<TFileObject>> {
    const createAppRepositoryResult = await this.createAppRepository.execute(
      CreateDTO,
    );

    return createAppRepositoryResult;
  }
}
