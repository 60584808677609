import { SkeletonProps } from '@material-ui/core/Skeleton';

import { Container } from './styles';

type TFormSkeleton = SkeletonProps;

export function FormSkeleton({ ...rest }: TFormSkeleton): JSX.Element {
  return <Container {...rest} />;
}

FormSkeleton.defaultProps = {};
