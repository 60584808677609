import { ProjectMessageFormContent } from '@modules/projects/pages/ProjectMessage/Form/Content';
import { ProjectMessageListContent } from '@modules/projects/pages/ProjectMessage/List/Content';
import { Container } from './styles';

export function ProjectSubStageFormMessageContent(): JSX.Element {
  return (
    <Container>
      <ProjectMessageListContent />
      <ProjectMessageFormContent />
    </Container>
  );
}
