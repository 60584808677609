export const BASE_URL = 'projects';

export const PROJECT_PER_PAGE = 10;

export enum STATUSES {
  IN_PROGRESS = 'in_progress',
  TO_VALIDATE = 'to_validate',
  PENDING = 'pending',
  TO_START = 'to_start',
  DONE = 'done',
}
