import {
  ToBaseShowSanitizer,
  ToBaseCreateSanitizer,
  ToBaseUpdateSanitizer,
  ToBaseDestroySanitizer,
  FromBaseApi,
} from '@shared/sanitizers/shared';

import {
  TBaseProjectUserObject,
  TProjectUserShowObject,
  TProjectUserCreateObject,
  TProjectUserUpdateObject,
  TProjectUserDestroyObject,
  TFilterByProps,
  TProjectUserObject,
} from '@modules/projects/@types/TProjectUser';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';

function ToBaseSanitizer({
  register,
}: {
  register: TBaseProjectUserObject;
}): TBaseProjectUserObject {
  const parsedObject: TBaseProjectUserObject = {
    project_uuid: register.project_uuid,
    user_uuid: register.user_uuid,
  };

  return parsedObject;
}

export function ToListFilter(register: TFilterByProps): TFilterByProps {
  return {
    data: register.data,
  };
}

export function ToShow({
  register,
}: {
  register: TProjectUserShowObject;
}): TProjectUserShowObject {
  const parsedObject: TProjectUserShowObject = {
    ...ToBaseShowSanitizer({ register }),
  };

  return parsedObject;
}

export function ToCreate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TProjectUserCreateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TProjectUserCreateObject {
  const parsedObject: TProjectUserCreateObject = {
    ...ToBaseCreateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToUpdate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TProjectUserUpdateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TProjectUserUpdateObject {
  const parsedObject: TProjectUserUpdateObject = {
    ...ToBaseUpdateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToDestroy({
  register,
  logged_branch,
}: {
  register: TProjectUserUpdateObject;
  logged_branch: TLoggedBranch;
}): TProjectUserDestroyObject {
  const parsedObject: TProjectUserDestroyObject = {
    ...ToBaseDestroySanitizer({ register, logged_branch }),
  };

  return parsedObject;
}

export function FromApi({
  registers,
}: {
  registers: TProjectUserObject[];
}): TProjectUserObject[] {
  const parsedObjects: TProjectUserObject[] = registers.map(register => ({
    ...register,
    ...FromBaseApi({ registers: [register] })[0],
    user_uuid_view: register.user.name,
  }));

  return parsedObjects;
}
