import { createTheme, PaletteOptions, ThemeOptions } from '@material-ui/core';

import { STATUSES } from '@modules/projects/constants/project';

const palette: PaletteOptions = {
  mode: 'light',

  background: {
    default: '#F5F6F8',
  },

  secondary_background: {
    main: '#ffffff',
  },

  grey_scale_1: {
    main: '#757575',
  },

  grey_scale_2: {
    main: '#BDBDBD',
  },

  grey_scale_3: {
    main: '#E5E5E5',
  },

  grey_scale_4: {
    main: '#F8FCFF',
  },

  primary: {
    main: '#f27605',
  },

  secondary: {
    main: '#191B37',
  },

  accent_blue: {
    main: '#1D87C7',
  },

  attached_file_background: {
    main: '#D0E4F7',
  },

  input_text_color: {
    main: '#000000',
    contrastText: '#FFFFFF',
    dark: '#AAAAAA',
  },

  [STATUSES.IN_PROGRESS]: {
    main: '#D2BB17',
  },

  [STATUSES.TO_VALIDATE]: {
    main: '#10A7DF',
  },

  [STATUSES.PENDING]: {
    main: '#E44A35',
  },

  [STATUSES.TO_START]: {
    main: '#A4A4A4',
  },

  [STATUSES.DONE]: {
    main: '#369E66',
  },

  is_reopen_and_concluded: {
    main: '#05813E',
  },
};

export const theme: ThemeOptions = createTheme({
  palette,
  typography: { button: { textTransform: 'none' } },
});
