import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useProject } from '@modules/projects/hooks/project';
import { useTemplate } from '@modules/templates/hooks/template';

import { Page } from '@shared/components/Page';
import { ProjectFormContent } from './Content';

import { Container } from './styles';

export function ProjectForm(): JSX.Element {
  const { formData: formDataProject } = useProject();

  const { clearState: templateClearState } = useTemplate();

  const location = useLocation();

  useEffect(() => {
    const { state } = location;

    if (!state || state?.creating) {
      formDataProject({});
    }
    window.history.replaceState(null, '');
  }, [location, formDataProject]);

  useEffect(
    () => () => {
      templateClearState();
    },
    [templateClearState],
  );

  return (
    <Page title="Formulário de Projeto">
      <Container>
        <ProjectFormContent />
      </Container>
    </Page>
  );
}
