import { SharedAppRepositories } from '@shared/repositories/app/shared';

import { FromApi } from '@modules/templates/sanitizers/stageName';

import { BASE_URL } from '@modules/templates/constants/stageName';

import {
  TStageNameObject,
  TStageNameShowObject,
  TStageNameCreateObject,
  TStageNameUpdateObject,
  TStageNameDestroyObject,
} from '@modules/templates/@types/TStageName';
import { TUserObject } from '@modules/users/@types/TUser';

export class StageNameAppRepositories extends SharedAppRepositories<
  TStageNameObject,
  TStageNameShowObject,
  TStageNameCreateObject,
  TStageNameUpdateObject,
  TStageNameDestroyObject,
  TUserObject
> {
  constructor() {
    super(BASE_URL, FromApi);
  }
}
