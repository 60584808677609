import { ApiProvider } from '@shared/providers/api';

import { BASE_URL } from '@modules/sessions/constants/session';

import { ISessionCreateDTO } from '@modules/sessions/dtos/ISession';
import { ISessionCreateAppRepository } from '@modules/sessions/repositories/app/implementations/ISession';

import { TCodeAuthSession } from '@modules/sessions/@types/TSession';

export class SessionCreateAppRepository implements ISessionCreateAppRepository {
  private readonly apiProvider = new ApiProvider();

  constructor(
    private readonly FromApi: (_data: TCodeAuthSession) => TCodeAuthSession,
  ) {}

  public async execute(
    CreateDTO: ISessionCreateDTO,
  ): Promise<TCodeAuthSession> {
    const apiResult = await this.apiProvider.execute<TCodeAuthSession>({
      method: 'post',
      url: BASE_URL,
      data: CreateDTO,
    });

    const parsedResult = this.FromApi(apiResult);

    return parsedResult;
  }
}
