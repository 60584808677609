import { createContext, ReactNode, useContext, useState } from 'react';

import { ProjectUserAppServices } from '@modules/projects/services/app/projectUser';

import { useErrorHandler } from '@shared/providers/error/hook';

import {
  SharedHookInitialState,
  useSharedHook,
  TSharedHookInitialState,
  TSharedHookReturn,
} from '@shared/hooks/shared';
import {
  TProjectUserObject,
  TProjectUserShowObject,
  TProjectUserCreateObject,
  TProjectUserUpdateObject,
  TProjectUserDestroyObject,
} from '@modules/projects/@types/TProjectUser';
import { TProjectObject } from '@modules/projects/@types/TProject';
import { TUserObject } from '@modules/users/@types/TUser';

type TRelationsRegister = TUserObject | TProjectObject;

const INITIAL_STATE: TSharedHookInitialState<
  TProjectUserObject,
  TRelationsRegister
> = SharedHookInitialState;

type TProjectUserContext = TSharedHookInitialState<
  TProjectUserObject,
  TRelationsRegister
> &
  TSharedHookReturn<
    TProjectUserObject,
    TProjectUserShowObject,
    TProjectUserCreateObject,
    TProjectUserUpdateObject,
    TProjectUserDestroyObject,
    TRelationsRegister
  >;

const ProjectUserContext = createContext<TProjectUserContext>(
  INITIAL_STATE as TProjectUserContext,
);

const AppServices = new ProjectUserAppServices();

export function ProjectUserProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [state, setState] =
    useState<TSharedHookInitialState<TProjectUserObject, TRelationsRegister>>(
      INITIAL_STATE,
    );

  const { setErrorHandlerData } = useErrorHandler();

  const sharedHook = useSharedHook<
    TProjectUserObject,
    TProjectUserShowObject,
    TProjectUserCreateObject,
    TProjectUserUpdateObject,
    TProjectUserDestroyObject,
    TRelationsRegister
  >({
    setState,
    AppServices,
    setErrorHandlerData,
  });

  return (
    <ProjectUserContext.Provider value={{ ...state, ...sharedHook }}>
      {children}
    </ProjectUserContext.Provider>
  );
}

export function useProjectUser(): TProjectUserContext {
  const context = useContext(ProjectUserContext);

  if (!context)
    throw new Error(
      'useProjectUser must be used within an ProjectUserProvider',
    );

  return context;
}
