import { ISharedCreateDTO } from '@shared/dtos/IShared';
import { ISharedCreateAppRepository } from '@shared/repositories/app/implementations/IShared';
import { ISharedCreateAppService } from '@shared/services/app/implementations/IShared';

import {
  TSharedObject,
  TSharedValidatedApiReturn,
  TSharedCreateObject,
} from '@shared/@types/TShared';

export class SharedCreateAppService<
  TRegister extends TSharedObject,
  TCreateObject extends TSharedCreateObject,
> implements ISharedCreateAppService<TRegister, TCreateObject>
{
  constructor(
    private readonly createAppRepository: ISharedCreateAppRepository<
      TRegister,
      TCreateObject
    >,
  ) {}

  public async execute(
    CreateDTO: ISharedCreateDTO<TCreateObject, TRegister>,
  ): Promise<TSharedValidatedApiReturn<TRegister>> {
    const createAppRepositoryResult = await this.createAppRepository.execute(
      CreateDTO,
    );

    return createAppRepositoryResult;
  }
}
