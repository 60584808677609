import { ReactNode, useState, useRef } from 'react';
import { FormHandles } from '@unform/core';

import { debounceEvent } from '@shared/utils/debounce';

import { SORT_TYPES } from '@shared/constants/shared';

import { TSharedObject } from '@shared/@types/TShared';

import { Header, TFilterByProps, TFilterByOption } from './Header';
import { THead } from './THead';
import { TBody } from './TBody';
import { TPagination } from './TPagination';

import {
  Container,
  TableContainer,
  TableWrapper,
  CircularProgressContainer,
  CircularProgress,
} from './styles';

export type TColumnInfo = {
  key: string;
  label: string;
};

export type TActionFunction<TRegister extends TSharedObject> = {
  key: string;
  label: string | ReactNode;
  handle: (register: TRegister) => void;
  confirm_message?: string;
};

export type TFilterProps = {
  filter_by?: string;
  data?: string;
  page: number;
  per_page: number;
  order: SORT_TYPES;
  order_by: string;
};

type TTableListProps<TRegister extends TSharedObject> = {
  title: string;
  registerList: TRegister[];
  listTotal: number;
  columnInfos: TColumnInfo[];
  onSubmitSearch: (register: TFilterProps) => void;
  filterByOptions: TFilterByOption[];
  addFunction?: () => void;
  actionFunctions?: TActionFunction<TRegister>[];
  validations?: Record<string, string>;
  loading?: boolean;
  height?: string;
};

export function TableList<TRegister extends TSharedObject>({
  title,
  registerList,
  listTotal,
  columnInfos,
  onSubmitSearch,
  filterByOptions,
  addFunction,
  actionFunctions,
  validations,
  loading,
  height,
}: TTableListProps<TRegister>): JSX.Element {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState<SORT_TYPES>(SORT_TYPES.asc);
  const [order_by, setOrderBy] = useState('id');

  const formRef = useRef<FormHandles>(null);

  function handleSubmitSearch(formData: TFilterByProps) {
    setPage(0);

    onSubmitSearch({
      ...formData,
      page: 1,
      per_page: rowsPerPage,
      order,
      order_by,
    });
  }

  function onPageChange(newPage: number) {
    setPage(newPage);

    const formData = formRef.current?.getData();
    debounceEvent(() =>
      onSubmitSearch({
        ...formData,
        page: newPage + 1,
        per_page: rowsPerPage,
        order,
        order_by,
      }),
    )();
  }

  function onRowsPerPageChange(newRowsPerPage: number) {
    setRowsPerPage(newRowsPerPage);
    setPage(0);

    const formData = formRef.current?.getData();
    debounceEvent(() =>
      onSubmitSearch({
        ...formData,
        page: 1,
        per_page: newRowsPerPage,
        order,
        order_by,
      }),
    )();
  }
  function onRequestSort(key: string) {
    const isAsc = order_by === key && order === SORT_TYPES.asc;
    const parsedOrder = isAsc ? SORT_TYPES.desc : SORT_TYPES.asc;
    setOrder(parsedOrder);
    setOrderBy(key);

    const formData = formRef.current?.getData();
    debounceEvent(() =>
      onSubmitSearch({
        ...formData,
        page: page + 1,
        per_page: rowsPerPage,
        order: parsedOrder,
        order_by: key,
      }),
    )();
  }

  return (
    <Container>
      <Header
        title={title}
        formRef={formRef}
        addFunction={addFunction}
        onSubmitSearch={handleSubmitSearch}
        filterByOptions={filterByOptions}
        validations={validations}
        loading={loading}
      />
      <TableContainer height={height} id="table-container">
        {loading ? (
          <CircularProgressContainer>
            <CircularProgress />
          </CircularProgressContainer>
        ) : (
          <TableWrapper>
            <THead
              columnInfos={columnInfos}
              order={order}
              order_by={order_by}
              onRequestSort={onRequestSort}
              actionFunctions={actionFunctions}
            />
            <TBody
              registerList={registerList}
              columnInfos={columnInfos}
              actionFunctions={actionFunctions}
            />
          </TableWrapper>
        )}
      </TableContainer>
      <TPagination
        count={listTotal}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Container>
  );
}

TableList.defaultProps = {
  addFunction: undefined,
  actionFunctions: undefined,
  validations: undefined,
  loading: false,
  height: undefined,
};
