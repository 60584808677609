import {
  TErrorCodes,
  TMessageError,
} from '@shared/providers/error/@types/TError';

export const ERROR_MESSAGES: {
  [key in TErrorCodes]: (data?: TMessageError) => string;
} = {
  E_CANT_CONNECT: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `Não foi possível se conectar a(o) ${data?.message || ''}`,
  E_PASSWORD_MISMATCH: (data?: TMessageError): string =>
    data?.defaultMessage || `Não foi possível verificar a senha informada`,
  E_LIMIT_ACCESS: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `Limite de acessos atingidos, deseja conectar assim mesmo ?`,
  E_UNAUTHORIZED_ACCESS: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `Acesso não autorizado a(o) ${
      data?.message || ''
    }, favor entrar em contato com atendimento@facilesistemas.com.br ou com o administrador de seu sistema`,
  E_VALIDATION_FAILED: (data?: TMessageError): string =>
    data?.defaultMessage || `Ocorreu um erro de validação`,
  E_JWT_TOKEN_EXPIRED: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `Sua sessão expirou, entre novamente para prosseguir`,
  E_INVALID_JWT_REFRESH_TOKEN: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `Credenciais invalidas para permanecer online, favor sair e entrar novamente`,
  E_INVALID_JWT_TOKEN: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `Credenciais inválidas para permanecer online, favor sair e entrar novamente`,
  E_ROUTE_NOT_FOUND: (data?: TMessageError): string =>
    data?.defaultMessage || `O caminho informado não foi encontrado`,
  E_UNRECOGNIZED: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `Ocorreu um erro não catalogado nessa requisição. Caso o problema persista, favor abrir um chamado com os detalhes do processo executado`,
  E_HAS_RELATIONSHIP: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `Este(a) ${data?.entity || ''} ainda possui relacionamentos com ${
      data?.message || ''
    }. Para prosseguir será necessário remove-los`,
  E_CONFIG: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `A aplicação ainda não foi configurado corretamente para seu usuário ou empresa, como ${
      data?.message || ''
    }, favor entrar em contato com atendimento@facilesistemas.com.br ou com o administrador de seu sistema`,
  E_UNIQUE: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `Já existe um(a) ${data?.entity || ''} com respectivas características${
      data?.message || ''
    }`,
  E_UNBOOTED: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `A aplicação ainda não foi inicializada corretamente, ainda restando ${
      data?.message || ''
    }`,
  E_DATABASE: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `Ocorreu um erro ao tentar ${
      data?.message || ''
    } esse registro no banco de dados`,
  E_HAS_RELATION: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `Este registro ainda possui relacionamentos com ${
      data?.message || ''
    }. Para prosseguir será necessário remove-los`,
  E_RELATION_FAILED: (data?: TMessageError): string =>
    data?.defaultMessage || `Ocorreu um error ao criar o relacionamento`,
  E_INVALID_JSON: (data?: TMessageError): string =>
    data?.defaultMessage || `O JSON enviado é inválido`,
  E_INVALID_FIELD: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `O valor informado para o campo "${
      data?.message || ''
    }", é inválido ou não existe`,
  E_INVALID_RELATION: (data?: TMessageError): string =>
    data?.defaultMessage ||
    `O valor informado para o relacionamento "${
      data?.message || ''
    }", é inválido ou não existe`,
};
