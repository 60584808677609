import styled from 'styled-components';

import { FormConfirmButton, FormCancelButton } from '@shared/components/Form';
import MUIGrid from '@material-ui/core/Grid';
import MUITypography from '@material-ui/core/Typography';

export { FormGridContainer } from '@shared/components/Form';
export { default as Dialog } from '@material-ui/core/Dialog';
export { default as DialogTitle } from '@material-ui/core/DialogTitle';
export { default as DialogContent } from '@material-ui/core/DialogContent';
export { default as IconButton } from '@material-ui/core/IconButton';
export { default as CloseIcon } from '@material-ui/icons/Close';

export const DialogHeader = styled(MUIGrid)`
  display: flex;
  justify-content: space-between;
`;

export const DialogWarnMessage = styled(MUITypography).attrs({
  component: 'subtitle1',
  variant: 'subtitle1',
})``;

export const ConfirmButton = styled(FormConfirmButton).attrs({
  gridProps: { xs: 6 },
})``;

export const CancelButton = styled(FormCancelButton).attrs({
  gridProps: { xs: 6 },
})``;
