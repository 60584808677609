import { TFileValidatedApiReturn } from '@modules/files/@types/TFile';
import { IFileCreateDTO } from '@modules/files/dtos/IFile';
import { FileCreateAppService } from './create';

export class FileAppServices {
  protected createAppService = new FileCreateAppService();

  public async create(
    CreateDTO: IFileCreateDTO,
  ): Promise<TFileValidatedApiReturn> {
    const createAppServiceResult = await this.createAppService.execute(
      CreateDTO,
    );

    return createAppServiceResult;
  }
}
