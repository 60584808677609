import styled from 'styled-components';
import MUIGrid from '@material-ui/core/Grid';

export { Page } from '@shared/components/Page';
export { TableList } from '@shared/components/TableList';

export const Container = styled(MUIGrid).attrs({
  container: true,
  component: 'main',
})`
  width: 100%;
`;
