import { ApiProvider } from '@shared/providers/api';

import { IFileCreateDTO } from '@modules/files/dtos/IFile';

import { TFileValidatedApiReturn } from '@modules/files/@types/TFile';
import { BASE_URL } from '@modules/files/constants/file';

export class FileCreateAppRepository {
  private readonly apiProvider = new ApiProvider();

  private readonly baseURL = BASE_URL;

  public async execute(
    CreateDTO: IFileCreateDTO,
  ): Promise<TFileValidatedApiReturn> {
    const apiResult = await this.apiProvider.execute<TFileValidatedApiReturn>({
      method: 'post',
      url: `${this.baseURL}`,
      data: { data: CreateDTO.registers },
      timeout: 120000,
    });

    return apiResult;
  }
}
