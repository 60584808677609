import { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';

import { createValidatorSchema } from '@modules/projects/validators/projectMessage';

import { ToCreate as ProjectMessageToCreate } from '@modules/projects/sanitizers/projectMessage';
import { ToCreate as FilesToCreate } from '@modules/files/sanitizers/files';

import { STATUSES } from '@modules/projects/constants/project';
import { MAX_FILE_SIZE } from '@modules/files/constants/file';
import { ALL_PERMISSION_KEYS } from '@shared/constants/shared';

import { useSession } from '@modules/sessions/hooks/session';
import { useProjectMessage } from '@modules/projects/hooks/projectMessage';
import { useProjectSubStage } from '@modules/projects/hooks/projectSubStage';
import { useFile } from '@modules/files/hooks/file';

import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import { TProjectMessageCreateObject } from '@modules/projects/@types/TProjectMessage';

import { TFileValidatedApiReturn } from '@modules/files/@types/TFile';
import { ToCreate as projectMessageFileToCreate } from '@modules/projects/sanitizers/projectMessageFile';

import {
  FormContainer,
  FormGridContainer,
  FormRichTextInput,
  AttachFileIcon,
  Label,
  Input,
  FolderIcon,
  DeleteFileIcon,
  UploadedFilesContainer,
  UploadedFileContainer,
  FileName,
  AttachFileButton,
  NotificateUsersDialog,
  ConfirmButton,
  HasPermissionComponent,
  CircularProgressContainer,
  CircularProgress,
  Tooltip,
} from './styles';

const inputAccept = [
  'doc',
  'docx',
  'xls',
  'xlsx',
  'pdf',
  'txt',
  'png',
  'jpg',
  'jpeg',
  'bmp',
  'xml',
  'ppt',
  'pptx',
  'log',
  'ini',
  'csv',
  'odf',
  'ods',
  'swf',
  'cdr',
  'odt',
  'rar',
  'zip',
  'dwg',
];

export function ProjectMessageFormContent(): JSX.Element {
  const { logged_user, logged_branch } = useSession();
  const {
    formLoading,
    validations,
    create: createProjectMessage,
  } = useProjectMessage();
  const {
    registerShow: projectSubStageRegisterShow,
    showLoading: projectSubStageShowLoading,
    formLoading: projectSubStageFormLoading,
  } = useProjectSubStage();
  const { create: createFile, formLoading: fileFormLoading } = useFile();
  const [richTextFiles, setRichTextFiles] = useState<
    { alt: string; file: File }[]
  >([]);
  const [base64AttachedFiles, setBase64AttachedFiles] = useState<string[]>([]);
  const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
  const [dialogState, setDialogState] = useState<{
    isOpen: boolean;
    register?: TProjectMessageCreateObject;
  }>({
    isOpen: false,
    register: undefined,
  });

  const formRef = useRef<FormHandles>(null);
  const validatorSchema = createValidatorSchema;
  const canEdit = projectSubStageRegisterShow?.status !== STATUSES.DONE;
  let allFilesSize = 0;

  function handleSanitize(
    register: TProjectMessageCreateObject,
  ): TProjectMessageCreateObject {
    const parsedRegister = ProjectMessageToCreate({
      register: {
        ...register,
        project_sub_stage_uuid: projectSubStageRegisterShow?.uuid as string,
      },
      logged_user: logged_user as TLoggedUser,
      logged_branch: logged_branch as TLoggedBranch,
    });

    return parsedRegister;
  }

  const convertFileToBase64 = (file: File) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsDataURL(file);
    });
  };

  async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newEvent = event;
    const fileArray = Array.from(newEvent.target.files || []);
    const filesToAttach: string[] = [];

    const filteredFiles = fileArray.filter(file => {
      const fileType = file.name.split('.').pop() || '';

      if (!attachedFiles.length) allFilesSize += file.size;
      else {
        attachedFiles.forEach(attachedFile => {
          allFilesSize += attachedFile.size;
        });
        allFilesSize += file.size;
      }

      if (!inputAccept.includes(fileType)) {
        toast.warn(`O formato do arquivo '${file.name}' não é suportado`);
        return false;
      }

      if (file.size > MAX_FILE_SIZE) {
        toast.warn(
          `O tamanho do arquivo '${file.name}' excede o limite permitido de ${
            MAX_FILE_SIZE / 1000000
          } MB`,
        );
        return false;
      }

      if (allFilesSize > MAX_FILE_SIZE) {
        toast.warn(
          `O tamanho dos arquivos anexados somados ao arquivo '${
            file.name
          }' excede o limite permitido de ${MAX_FILE_SIZE / 1000000} MB`,
        );
        return false;
      }

      return true;
    });

    // eslint-disable-next-line no-restricted-syntax
    for (const filteredFile of filteredFiles) {
      // eslint-disable-next-line no-await-in-loop
      const fileContents = await convertFileToBase64(filteredFile);
      filesToAttach.push(fileContents as string);
    }

    setBase64AttachedFiles(oldData => [...oldData, ...filesToAttach]);
    setAttachedFiles(oldData => [...oldData, ...filteredFiles]);
    newEvent.target.value = '';
  }

  function handleRemoveUploadedFile(index: number) {
    setBase64AttachedFiles(oldData => {
      const newUploadedFiles = [...oldData];

      newUploadedFiles.splice(index, 1);

      return newUploadedFiles;
    });
    setAttachedFiles(oldData => {
      const newUploadedFiles = [...oldData];

      newUploadedFiles.splice(index, 1);

      allFilesSize = 0;
      newUploadedFiles.forEach(attachedFile => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        allFilesSize += attachedFile.size;
      });

      return newUploadedFiles;
    });
  }

  function handleCloseDialog() {
    setDialogState({ isOpen: false });
  }

  async function notificateUsersSubmit(
    register: TProjectMessageCreateObject,
  ): Promise<void> {
    const textFieldRef = formRef.current?.getFieldRef('text');
    const textValue = textFieldRef.getText();
    const parsedFilesRichTextToCreate: string[] = [];

    if (!textValue) {
      toast.warn('Ocorreu um erro de validação');

      formRef.current?.setErrors({ text: 'Este campo é obrigatório' });

      return;
    }

    function onSuccess() {
      const fieldRef = formRef.current?.getFieldRef('text');
      fieldRef?.handleChange('');
      setRichTextFiles([]);
      setAttachedFiles([]);
      setBase64AttachedFiles([]);
    }

    const filesRichTextToCreate = richTextFiles
      .filter(richTextFile => register.text.match(richTextFile.alt))
      .map(richTextFile => richTextFile.file);

    // eslint-disable-next-line no-restricted-syntax
    for (const fileRichTextToCreate of filesRichTextToCreate) {
      // eslint-disable-next-line no-await-in-loop
      const fileContents = await convertFileToBase64(fileRichTextToCreate);
      parsedFilesRichTextToCreate.push(fileContents as string);
    }

    let parsedText = register.text;

    const filesToCreateResult = FilesToCreate({
      registers: [
        ...base64AttachedFiles.map((base64, base64Index) => ({
          image: base64,
          req_id: '',
          branch_key: '',
          branch_name: '',
          company_key: '',
          name: attachedFiles[base64Index].name,
          url: '',
        })),
        ...parsedFilesRichTextToCreate.map((base64, base64Index) => ({
          image: base64,
          req_id: '',
          branch_key: '',
          branch_name: '',
          company_key: '',
          name: filesRichTextToCreate[base64Index].name,
          url: '',
        })),
      ],
      logged_user: logged_user as TLoggedUser,
      logged_branch: logged_branch as TLoggedBranch,
    });

    const filesToCreate = [
      ...parsedFilesRichTextToCreate,
      ...base64AttachedFiles,
    ];

    let createFileResult: TFileValidatedApiReturn | undefined;
    if (filesToCreate.length)
      createFileResult = await createFile({ registers: filesToCreateResult });

    if (parsedFilesRichTextToCreate.length) {
      const splitedImgHtml = register.text.split('<img src="');
      const parsedImgHtmlArray = [splitedImgHtml.shift()];

      splitedImgHtml.forEach(htmlItem => {
        let htmlImgStr = '<img src="';

        const splitedHtmlItem = htmlItem.split('" alt="');

        const [imgAlt] = splitedHtmlItem[1].split('"');

        const findedFile = createFileResult?.data.find(
          file => file.register.name === imgAlt,
        );
        htmlImgStr += `${findedFile?.register.url}" alt="${splitedHtmlItem[1]}`;

        parsedImgHtmlArray.push(htmlImgStr);
      });

      parsedText = parsedImgHtmlArray.join('');
    }

    const project_message_files =
      createFileResult?.data?.map(item => {
        return projectMessageFileToCreate({
          register: { file_uuid: item.register.uuid },
          logged_user: logged_user as TLoggedUser,
          logged_branch: logged_branch as TLoggedBranch,
        });
      }) || [];

    createProjectMessage({
      register: {
        ...register,
        text: parsedText,
        project_message_files,
      },
      onSuccess,
    });
  }

  async function handleSubmit(
    register: TProjectMessageCreateObject,
  ): Promise<void> {
    setDialogState({ isOpen: true, register });
  }

  useEffect(() => {
    formRef.current?.setErrors(validations || {});
  }, [validations]);

  return (
    <FormContainer<TProjectMessageCreateObject>
      formRef={formRef}
      validatorSchema={validatorSchema}
      sanitizer={handleSanitize}
      onSubmit={handleSubmit}
    >
      {formLoading && (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      )}
      <NotificateUsersDialog<TProjectMessageCreateObject>
        dialogState={dialogState}
        formRef={formRef}
        projectUuid={projectSubStageRegisterShow?.project_stage?.project_uuid}
        handleCloseDialog={handleCloseDialog}
        onSubmit={notificateUsersSubmit}
      />
      <FormGridContainer mt={1}>
        <HasPermissionComponent
          permission_key={[ALL_PERMISSION_KEYS.project_message_create]}
          element={
            <FormGridContainer alignItems="center" style={{ width: '100%' }}>
              <FormRichTextInput
                name="text"
                showLoading={projectSubStageShowLoading}
                files={richTextFiles}
                setFiles={setRichTextFiles}
              />

              <FormGridContainer
                style={{
                  display: 'grid',
                  gridTemplateColumns: '0.5fr 2.5fr 1fr',
                  justifyContent: 'center',
                }}
              >
                <Label htmlFor="file">
                  <AttachFileButton>
                    <Input
                      id="file"
                      type="file"
                      multiple
                      onChange={handleFileChange}
                    />
                    <AttachFileIcon fontSize="large" />
                    Incluir Anexo
                  </AttachFileButton>
                </Label>

                <UploadedFilesContainer>
                  {attachedFiles.map((item, index) => (
                    <Tooltip
                      title={`${(item.size / 1000000).toFixed(2)} MB`}
                      arrow
                      placement="top"
                    >
                      <UploadedFileContainer
                        key={`${index.toString()}-${item.name}`}
                      >
                        <FolderIcon />
                        <FileName>{item?.name}</FileName>

                        <DeleteFileIcon
                          onClick={() => handleRemoveUploadedFile(index)}
                        />
                      </UploadedFileContainer>
                    </Tooltip>
                  ))}
                </UploadedFilesContainer>

                <ConfirmButton
                  loading={
                    projectSubStageShowLoading ||
                    formLoading ||
                    fileFormLoading ||
                    projectSubStageFormLoading
                  }
                  disabled={
                    !canEdit ||
                    formLoading ||
                    fileFormLoading ||
                    projectSubStageFormLoading
                  }
                >
                  ENVIAR COMENTÁRIO
                </ConfirmButton>
              </FormGridContainer>
            </FormGridContainer>
          }
        />
      </FormGridContainer>
    </FormContainer>
  );
}
