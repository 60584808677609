import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';

import {
  listSelectorProject,
  statusesOptionsDashboard,
} from '@modules/projects/helpers/project';

import { listValidatorSchema } from '@modules/projects/validators/project';
import { ToListFilter } from '@modules/projects/sanitizers/project';

import { listSelectorStageName } from '@modules/projects/helpers/projectStage';
import { listSelectorUser } from '@modules/projects/helpers/users';

import { SEARCH_TYPES, SORT_TYPES } from '@shared/constants/shared';
import { safetyJSONParse } from '@shared/utils/json';

import {
  TFilterByProps,
  TProjectObject,
} from '@modules/projects/@types/TProject';
import { TStageNameObject } from '@modules/templates/@types/TStageName';
import {
  PROJECT_PER_PAGE,
  STATUSES,
} from '@modules/projects/constants/project';
import { TUserObject } from '@modules/users/@types/TUser';
import { TDashboardRelationsObject } from '@modules/projects/@types/TDashboard';
import { TSharedListValues } from '@shared/@types/TShared';

import { useProject } from '@modules/projects/hooks/project';

import {
  FormContainer,
  TextInput,
  FormSelector,
  SearchIconContainer,
  SearchIcon,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  IconButton,
  FormGridContainer,
  CancelButton,
  ConfirmButton,
  CloseIcon,
} from './styles';

type TSelectorsValues = {
  project_uuid?: TProjectObject | null;
  stage_name_uuid?: TStageNameObject | null;
  status?: { key: STATUSES; label: string } | null;
  responsible_uuid?: TUserObject | null;
  user_uuid?: TUserObject | null;
  neighborhood?: string;
};

type TFilterDialog = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsFilterList: Dispatch<SetStateAction<boolean>>;
  setFilterData: Dispatch<SetStateAction<TSharedListValues[]>>;
  setIsReloaded: Dispatch<SetStateAction<boolean>>;
  isReloaded: boolean;
  formInitialData?: TDashboardRelationsObject;
};

export function FilterDialog({
  isOpen,
  setIsOpen,
  setIsFilterList,
  setFilterData,
  setIsReloaded,
  isReloaded,
  formInitialData,
}: TFilterDialog): JSX.Element {
  const formRef = useRef<FormHandles>(null);
  const { list, listLoading, validations } = useProject();

  const [selectorsValues, setSelectorsValues] = useState<TSelectorsValues>({});
  // const [dialogIsLoaded, setDialogIsLoaded] = useState(false);

  function onSubmitSearch({
    status,
    project_uuid,
    stage_name_uuid,
    responsible_uuid,
    user_uuid,
    neighborhood,
  }: TFilterByProps): void {
    const parsedData = [];

    if (status && stage_name_uuid) {
      parsedData.push({
        field: 'project_stages',
        type: SEARCH_TYPES.relation,
        values: [
          {
            field: 'stage_name_uuid',
            type: SEARCH_TYPES.normal,
            values: [stage_name_uuid],
          },
          {
            field: 'status',
            type: SEARCH_TYPES.normal,
            values: [status],
          },
        ],
      });
    } else if (stage_name_uuid) {
      parsedData.push({
        field: 'project_stages',
        type: SEARCH_TYPES.relation,
        values: [
          {
            field: 'stage_name_uuid',
            type: SEARCH_TYPES.normal,
            values: [stage_name_uuid],
          },
        ],
      });
    } else if (status) {
      parsedData.push({
        field: 'project_stages',
        type: SEARCH_TYPES.relation,
        values: [
          {
            field: 'status',
            type: SEARCH_TYPES.normal,
            values: [status],
          },
        ],
      });
    }

    if (stage_name_uuid && responsible_uuid) {
      parsedData.push({
        field: 'project_stages',
        type: SEARCH_TYPES.relation,
        values: [
          {
            field: 'stage_name_uuid',
            type: SEARCH_TYPES.normal,
            values: [stage_name_uuid],
          },
          {
            field: 'responsible_uuid',
            type: SEARCH_TYPES.normal,
            values: [responsible_uuid],
          },
        ],
      });
    }

    if (project_uuid)
      parsedData.push({
        field: 'uuid',
        type: SEARCH_TYPES.normal,
        values: [project_uuid],
      });

    if (responsible_uuid) {
      parsedData.push({
        field: 'project_stages',
        type: SEARCH_TYPES.relation,
        values: [
          {
            field: 'responsible_uuid',
            type: SEARCH_TYPES.normal,
            values: [responsible_uuid],
          },
        ],
      });
    }

    if (user_uuid) {
      parsedData.push({
        field: 'project_users',
        type: SEARCH_TYPES.relation,
        values: [
          {
            field: 'user_uuid',
            type: SEARCH_TYPES.normal,
            values: [user_uuid],
          },
        ],
      });
    }

    if (neighborhood)
      parsedData.push({
        field: 'neighborhood',
        type: SEARCH_TYPES.normal,
        values: [neighborhood],
      });

    const searchData = {
      order: SORT_TYPES.desc,
      order_by: 'updated_at',
      page: 1,
      per_page: PROJECT_PER_PAGE,
      for_selector: false,
      data: parsedData,
    };

    const neighborhoodField = formRef.current?.getFieldValue('neighborhood');
    const fieldsValues = {
      neighborhood: neighborhoodField,
      ...selectorsValues,
    };

    list(searchData);
    setFilterData(parsedData);
    setIsFilterList(true);
    setIsOpen(false);
    localStorage.setItem('dashboardFilters', JSON.stringify(fieldsValues));
  }

  useEffect(() => {
    setTimeout(() => {
      const localStorageDashboardFilters = safetyJSONParse(
        localStorage.getItem('dashboardFilters'),
      );

      const localStorageDashboardFiltersWithType =
        localStorageDashboardFilters as Record<string, unknown>;

      const projectUuidFieldRef = formRef.current?.getFieldRef('project_uuid');
      const stageNameUuidFieldRef =
        formRef.current?.getFieldRef('stage_name_uuid');
      const statusFieldRef = formRef.current?.getFieldRef('status');
      const responsibleUuidFieldRef =
        formRef.current?.getFieldRef('responsible_uuid');
      const userUuidFieldRef = formRef.current?.getFieldRef('user_uuid');

      if (localStorageDashboardFiltersWithType?.project_uuid)
        projectUuidFieldRef?.handleChange(
          localStorageDashboardFiltersWithType.project_uuid,
        );
      if (localStorageDashboardFiltersWithType.stage_name_uuid)
        stageNameUuidFieldRef?.handleChange(
          localStorageDashboardFiltersWithType.stage_name_uuid,
        );
      if (localStorageDashboardFiltersWithType.status)
        statusFieldRef?.handleChange(
          localStorageDashboardFiltersWithType.status,
        );
      if (localStorageDashboardFiltersWithType.responsible_uuid)
        responsibleUuidFieldRef?.handleChange(
          localStorageDashboardFiltersWithType.responsible_uuid,
        );
      if (localStorageDashboardFiltersWithType.user_uuid)
        userUuidFieldRef?.handleChange(
          localStorageDashboardFiltersWithType.user_uuid,
        );
      if (localStorageDashboardFiltersWithType.neighborhood)
        formRef.current?.setFieldValue(
          'neighborhood',
          localStorageDashboardFiltersWithType.neighborhood,
        );

      if (isReloaded) {
        localStorage.removeItem('dashboardFilters');
        formRef?.current?.reset();
      }

      formRef.current?.setErrors(validations || {});
    }, 100);
  }, [validations, isReloaded, isOpen]);

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <DialogHeader container>
        <DialogTitle>Filtros</DialogTitle>

        <IconButton
          onClick={() => setIsOpen(false)}
          style={{ width: '50px', height: '50px' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogHeader>

      <DialogContent id="dialog-content">
        <FormContainer<TFilterByProps>
          formRef={formRef}
          validatorSchema={listValidatorSchema}
          sanitizer={ToListFilter}
          onSubmit={onSubmitSearch}
          initialData={{
            project_uuid: formRef.current?.getFieldValue('project_uuid'),
          }}
        >
          <FormGridContainer justifyContent="right" spacing={1}>
            <FormSelector<TProjectObject>
              label="Projeto:"
              name="project_uuid"
              idColumn="uuid"
              nameColumn="name"
              handleSearch={listSelectorProject}
              options={(formInitialData?.projects || []) as TProjectObject[]}
              onChange={project_uuid => {
                setIsReloaded(false);
                setSelectorsValues(oldData => {
                  if (!project_uuid) {
                    const auxOldData = oldData;
                    delete auxOldData.project_uuid;
                    return { ...auxOldData };
                  }
                  return { ...oldData, project_uuid };
                });
              }}
              disabled={listLoading}
              gridProps={{ xs: 6 }}
            />
            <FormSelector<TStageNameObject>
              label="Etapa:"
              name="stage_name_uuid"
              idColumn="uuid"
              nameColumn="name"
              handleSearch={listSelectorStageName}
              options={
                (formInitialData?.stage_names || []) as TStageNameObject[]
              }
              onChange={stage_name_uuid => {
                setIsReloaded(false);
                setSelectorsValues(oldData => {
                  if (!stage_name_uuid) {
                    const auxOldData = oldData;
                    delete auxOldData.stage_name_uuid;
                    return { ...auxOldData };
                  }
                  return { ...oldData, stage_name_uuid };
                });
              }}
              disabled={listLoading}
              gridProps={{ xs: 6 }}
            />
            <FormSelector
              label="Status:"
              name="status"
              idColumn="key"
              nameColumn="label"
              options={statusesOptionsDashboard}
              onChange={status => {
                setIsReloaded(false);
                setSelectorsValues(oldData => {
                  if (!status) {
                    const auxOldData = oldData;
                    delete auxOldData.status;
                    return { ...auxOldData };
                  }
                  return { ...oldData, status };
                });
              }}
              disabled={listLoading}
              gridProps={{ xs: 6 }}
            />
            <FormSelector<TUserObject>
              label="Dirigente:"
              name="responsible_uuid"
              idColumn="uuid"
              nameColumn="name"
              handleSearch={listSelectorUser}
              options={(formInitialData?.responsibles || []) as TUserObject[]}
              onChange={responsible_uuid => {
                setIsReloaded(false);
                setSelectorsValues(oldData => {
                  if (!responsible_uuid) {
                    const auxOldData = oldData;
                    delete auxOldData.responsible_uuid;
                    return { ...auxOldData };
                  }
                  return { ...oldData, responsible_uuid };
                });
              }}
              disabled={listLoading}
              gridProps={{ xs: 6 }}
            />
            <FormSelector<TUserObject>
              label="Vinculados:"
              name="user_uuid"
              idColumn="uuid"
              nameColumn="name"
              handleSearch={listSelectorUser}
              options={(formInitialData?.responsibles || []) as TUserObject[]}
              onChange={user_uuid => {
                setIsReloaded(false);
                setSelectorsValues(oldData => {
                  if (!user_uuid) {
                    const auxOldData = oldData;
                    delete auxOldData.user_uuid;
                    return { ...auxOldData };
                  }
                  return { ...oldData, user_uuid };
                });
              }}
              disabled={listLoading}
              gridProps={{ xs: 6 }}
            />
            <TextInput
              label="Bairro"
              name="neighborhood"
              disabled={listLoading}
            />
          </FormGridContainer>

          <FormGridContainer spacing={1} mt={1}>
            <CancelButton onClick={() => setIsOpen(false)}>
              CANCELAR
            </CancelButton>
            <ConfirmButton type="submit" disabled={listLoading}>
              {listLoading ? (
                <CircularProgress size={20} />
              ) : (
                <SearchIconContainer>
                  <SearchIcon />
                  FILTRAR
                </SearchIconContainer>
              )}
            </ConfirmButton>
          </FormGridContainer>
        </FormContainer>
      </DialogContent>
    </Dialog>
  );
}

FilterDialog.defaultProps = {
  formInitialData: undefined,
};
