import { ApiProvider } from '@shared/providers/api';

import { BASE_URL } from '@modules/sessions/constants/session';

import { ISessionShowAppRepository } from '@modules/sessions/repositories/app/implementations/ISession';

import { TCodeAuthSession } from '@modules/sessions/@types/TSession';

export class SessionShowAppRepository implements ISessionShowAppRepository {
  private readonly apiProvider = new ApiProvider();

  constructor(
    private readonly FromApi: (_data: TCodeAuthSession) => TCodeAuthSession,
  ) {}

  public async execute(): Promise<TCodeAuthSession> {
    const apiResult = await this.apiProvider.execute<TCodeAuthSession>({
      method: 'get',
      url: BASE_URL,
    });

    const parsedResult = this.FromApi(apiResult);

    return parsedResult;
  }
}
