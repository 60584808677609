import { AxiosResponse } from 'axios';

import { ErrorProvider } from '@shared/providers/error';

import { apiHttpClientConfig } from './configs/httpClient';

import { TApiProps } from './@types/TApi';

export class ApiProvider {
  public async execute<ResponseType = unknown>({
    method,
    url,
    params,
    data,
    headers,
    timeout,
  }: TApiProps): Promise<ResponseType> {
    const apiReturn = await apiHttpClientConfig
      .request<TApiProps['data'], AxiosResponse<ResponseType>>({
        method,
        url,
        params,
        data,
        headers: {
          ...apiHttpClientConfig.defaults.headers,
          ...headers,
        },
        timeout,
      })
      .then(res => res.data)
      .catch(err => {
        const error = new ErrorProvider({
          code: err?.response?.data?.error?.code || 'E_CANT_CONNECT',
          data: {
            message: 'Servidor',
            defaultMessage: err?.response?.data?.error?.message,
          },
          error: err?.response?.data?.error,
        });

        throw error;
      });

    return apiReturn;
  }
}
