import styled from 'styled-components';

import { FormConfirmButton } from '@shared/components/Form';
import MUIGrid from '@material-ui/core/Grid';
import MUITypography from '@material-ui/core/Typography';
import MUIButton from '@material-ui/core/Button';
import MUICloseIcon from '@material-ui/icons/Close';

export { HasPermissionComponent } from '@shared/components/HasPermissionComponent';
export { default as AttachFileIcon } from '@material-ui/icons/AttachFile';
export { default as FolderIcon } from '@material-ui/icons/Folder';
export { default as CircularProgress } from '@material-ui/core/CircularProgress';
export { default as Tooltip } from '@material-ui/core/Tooltip';

export {
  FormContainer,
  FormGridContainer,
  FormRichTextInput,
} from '@shared/components/Form';
export { NotificateUsersDialog } from '@modules/projects/components/NotificateUsersDialog';

export const AttachFileButton = styled(MUIButton).attrs({ component: 'label' })`
  width: 100%;
  height: 100%;

  color: ${({ theme }) => theme.palette.grey_scale_1.main};

  font-size: 16px;
`;

export const Label = styled.label`
  width: 100%;
  height: 100%;
  margin-top: 4px;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    display: none;
  }
`;
export const Input = styled.input``;

export const UploadedFilesContainer = styled(MUIGrid)`
  width: 95%;
  height: 9vh;

  display: flex;
  flex-direction: row;

  overflow-x: scroll;
  overflow-y: hidden;
`;

export const UploadedFileContainer = styled(MUIButton).attrs({
  variant: 'contained',
})`
  margin-top: 6px;
  margin-right: 6px;
  padding: 4px;
  border-radius: 1rem;

  background: ${({ theme }) => theme.palette.attached_file_background.main};
  color: ${({ theme }) => theme.palette.accent_blue.main};

  &:hover {
    background: ${({ theme }) => theme.palette.attached_file_background.main};
    filter: brightness(90%);
  }

  width: auto;
  height: 32px;
  display: flex;
  min-width: 100px;
  flex-direction: row;
`;

export const FileName = styled(MUITypography).attrs({ variant: 'body1' })`
  max-width: 200px;
  min-width: 50px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DeleteFileIcon = styled(MUICloseIcon)`
  color: ${({ theme }) => theme.palette.grey_scale_1.main};

  margin-left: 4px;
  padding: 2px;

  cursor: pointer;

  &:hover {
    opacity: 80%;
  }
`;

export const Divider = styled(MUIGrid)`
  width: 1px;
  height: 30px;
  background: gray;

  margin-right: 4px;
`;

export const ConfirmButton = styled(FormConfirmButton).attrs({
  gridProps: { xs: 12 },
})``;

export const CircularProgressContainer = styled(MUIGrid)`
  position: sticky;
  display: flex;
  justify-content: center;
`;
