import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useSession } from '@modules/sessions/hooks/session';

import { FileAppServices } from '@modules/files/services/app/file';

import { useErrorHandler } from '@shared/providers/error/hook';

import {
  TFileObject,
  TFileValidatedApiReturn,
} from '@modules/files/@types/TFile';
import {
  SharedHookInitialState,
  TSharedHookInitialState,
} from '@shared/hooks/shared';
import { toast } from 'react-toastify';
import { TAppError } from '@shared/providers/error/@types/TError';
import { ErrorProvider } from '@shared/providers/error';
import { IFileCreateDTO } from '@modules/files/dtos/IFile';

const INITIAL_STATE: TSharedHookInitialState<TFileObject> =
  SharedHookInitialState;

type TFileContext = TSharedHookInitialState<TFileObject> & {
  create: (CreateDTO: IFileCreateDTO) => Promise<TFileValidatedApiReturn>;
};

const FileContext = createContext<TFileContext>(INITIAL_STATE as TFileContext);

const AppServices = new FileAppServices();

export function FileProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [state, setState] =
    useState<TSharedHookInitialState<TFileObject>>(INITIAL_STATE);
  const { logged_user } = useSession();

  const { setErrorHandlerData } = useErrorHandler();

  const setStateSafety = useCallback(
    (
      newData:
        | Partial<TSharedHookInitialState<TFileObject>>
        | ((
            oldData: TSharedHookInitialState<TFileObject>,
          ) => Partial<TSharedHookInitialState<TFileObject>>),
    ) => {
      if (typeof newData === 'function')
        setState(oldData => ({ ...oldData, ...newData(oldData) }));

      setState(oldData => ({ ...oldData, ...newData }));
    },
    [setState],
  );

  const errorHandler = useCallback(
    (
      error: TAppError,
      handleFunction: (...args: unknown[]) => unknown,
      extraData?: Partial<TSharedHookInitialState<TFileObject>>,
    ): void => {
      const parsedError =
        error instanceof ErrorProvider
          ? error
          : new ErrorProvider({
              code: error?.code || 'E_UNRECOGNIZED',
              error,
              data: { defaultMessage: error.message },
            });

      const JSONError = parsedError.toJSON();

      setErrorHandlerData({
        error: JSONError,
        handleFunction,
      });

      setStateSafety({
        validations: JSONError?.validations as Record<string, string>,
        ...(extraData || {}),
      });
    },
    [setErrorHandlerData, setStateSafety],
  );

  const create = useCallback(
    async (CreateDTO: IFileCreateDTO): Promise<TFileValidatedApiReturn> => {
      try {
        setStateSafety({ formLoading: true });

        const serviceData = await AppServices.create(CreateDTO);
        const { register: createdRegister, error } = serviceData.data[0];

        if (error) throw error;

        setStateSafety(oldData => {
          const parsedRegister = {
            ...createdRegister,
            user_alteration: logged_user,
          };

          const parsedRegisterList = [...oldData.registerList, parsedRegister];

          return {
            registerList: parsedRegisterList,
            formLoading: false,
          };
        });

        toast.success('Registro criado!', { toastId: 'CREATED' });

        CreateDTO.onSuccess?.(serviceData);

        return serviceData;
      } catch (error) {
        const parsedError = error as TAppError;

        errorHandler(parsedError, () => create(CreateDTO), {
          formLoading: false,
        });

        CreateDTO.onError?.(error);

        return {} as TFileValidatedApiReturn;
      }
    },
    [errorHandler, logged_user, setStateSafety],
  );

  return (
    <FileContext.Provider value={{ ...state, create }}>
      {children}
    </FileContext.Provider>
  );
}

export function useFile(): TFileContext {
  const context = useContext(FileContext);

  if (!context) throw new Error('useFile must be used within an FileProvider');

  return context;
}
