import {
  TBaseProjectMessageFileObject,
  TProjectMessageFileShowObject,
  TProjectMessageFileCreateObject,
  TProjectMessageFileUpdateObject,
  TProjectMessageFileDestroyObject,
  TProjectMessageFileObject,
} from '@modules/projects/@types/TProjectMessageFile';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import {
  ToBaseShowSanitizer,
  ToBaseCreateSanitizer,
  ToBaseUpdateSanitizer,
  ToBaseDestroySanitizer,
  FromBaseApi,
} from '@shared/sanitizers/shared';

function ToBaseSanitizer({
  register,
}: {
  register: Omit<TBaseProjectMessageFileObject, 'project_message_uuid'>;
}): Omit<TBaseProjectMessageFileObject, 'project_message_uuid'> {
  const parsedObject: Omit<
    TBaseProjectMessageFileObject,
    'project_message_uuid'
  > = {
    file_uuid: register.file_uuid,
  };

  return parsedObject;
}

export function ToShow({
  register,
}: {
  register: TProjectMessageFileShowObject;
}): TProjectMessageFileShowObject {
  const parsedObject: TProjectMessageFileShowObject = {
    ...ToBaseShowSanitizer({ register }),
  };

  return parsedObject;
}

export function ToCreate({
  register,
  logged_user,
  logged_branch,
}: {
  register: Omit<TProjectMessageFileCreateObject, 'project_message_uuid'>;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): Omit<TProjectMessageFileCreateObject, 'project_message_uuid'> {
  const parsedObject: Omit<
    TProjectMessageFileCreateObject,
    'project_message_uuid'
  > = {
    ...ToBaseCreateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToUpdate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TProjectMessageFileUpdateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TProjectMessageFileUpdateObject {
  const parsedObject: TProjectMessageFileUpdateObject = {
    ...ToBaseUpdateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
    project_message_uuid: register.project_message_uuid,
  };

  return parsedObject;
}

export function ToDestroy({
  register,
  logged_branch,
}: {
  register: TProjectMessageFileUpdateObject;
  logged_branch: TLoggedBranch;
}): TProjectMessageFileDestroyObject {
  const parsedObject: TProjectMessageFileDestroyObject = {
    ...ToBaseDestroySanitizer({ register, logged_branch }),
  };

  return parsedObject;
}

export function FromApi({
  registers,
}: {
  registers: TProjectMessageFileObject[];
}): TProjectMessageFileObject[] {
  const parsedObjects: TProjectMessageFileObject[] = registers.map(
    register => ({
      ...register,
      ...FromBaseApi({ registers: [register] })[0],
    }),
  );

  return parsedObjects;
}
