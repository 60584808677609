import styled from 'styled-components';

import { FormTextInput } from '@shared/components/Form';
import MUIIconButton from '@material-ui/core/IconButton';

export { default as VisibilityIcon } from '@material-ui/icons/Visibility';
export { default as VisibilityOffIcon } from '@material-ui/icons/VisibilityOff';

export {
  FormContainer,
  FormGridContainer,
  FormConfirmButton,
} from '@shared/components/Form';

export const IconButton = styled(MUIIconButton).attrs({
  color: 'primary',
})``;

export const TextInput = styled(FormTextInput)`
  & .MuiFormLabel-root {
    color: ${({ theme }) => theme.palette.input_text_color.dark};

    &.Mui-focused {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.primary.main} !important;
  }

  & .MuiOutlinedInput-root {
    color: ${({ theme }) => theme.palette.input_text_color.contrastText};

    & fieldset {
      border-color: ${({ theme }) => theme.palette.input_text_color.dark};
    }

    &:hover fieldset {
      border-color: ${({ theme }) =>
        theme.palette.input_text_color.contrastText};
    }
  }
`;
