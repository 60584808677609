import * as yup from 'yup';

import {
  sharedBaseCreateValidatorSchema,
  sharedBaseUpdateValidatorSchema,
} from '@shared/validators/shared';

import { STATUSES } from '@modules/projects/constants/project';

import {
  TProjectCreateObject,
  TProjectUpdateObject,
} from '@modules/projects/@types/TProject';

import { createValidatorSchema as projetUserCreateValidatorSchema } from './projectUser';
import { createValidatorSchema as projectStageCreateValidatorSchema } from './projectStage';

export const projectBaseCreateOrUpdateValidatorSchema = yup.object().shape({
  template_uuid: yup
    .string()
    .required('Este campo é obrigatório')
    .uuid('Este campo deve ser um UUID válido'),
  responsible_uuid: yup
    .string()
    .required('Este campo é obrigatório')
    .uuid('Este campo deve possuir usuário válido'),
  name: yup
    .string()
    .required('Este campo é obrigatório')
    .max(255, 'Este campo deve conter no máximo 255 caracteres'),
  description: yup
    .string()
    .required('Este campo é obrigatório')
    .max(255, 'Este campo deve conter no máximo 255 caracteres'),
  neighborhood: yup
    .string()
    .required('Este campo é obrigatório')
    .max(255, 'Este campo deve conter no máximo 255 caracteres'),
  status: yup
    .mixed()
    .oneOf(
      Object.values(STATUSES),
      `Este campo deve ser algum dos valores: ${Object.values(STATUSES)}`,
    )
    .required('Este campo é obrigatório'),
  estimated_delivery_at: yup
    .string()
    .max(255, 'Este campo deve conter no máximo 255 caracteres'),
  project_users: yup
    .array()
    .of(projetUserCreateValidatorSchema)
    .required('Este campo é obrigatório'),
  project_stages: yup
    .array()
    .of(projectStageCreateValidatorSchema)
    .required('Este campo é obrigatório'),
});

export const createValidatorSchema = yup
  .object()
  .concat(projectBaseCreateOrUpdateValidatorSchema)
  .concat(
    sharedBaseCreateValidatorSchema,
  ) as yup.SchemaOf<TProjectCreateObject>;

export const updateValidatorSchema = yup
  .object()
  .concat(projectBaseCreateOrUpdateValidatorSchema)
  .concat(
    sharedBaseUpdateValidatorSchema,
  ) as yup.SchemaOf<TProjectUpdateObject>;

export const listValidatorSchema = yup.object().shape({
  project_uuid: yup.string().uuid('Este campo deve ser um UUID válido'),
  status: yup
    .mixed()
    .oneOf(
      Object.values(STATUSES),
      `Este campo deve ser algum dos valores: ${Object.values(STATUSES)}`,
    ),
  stage_name_uuid: yup.string().uuid('Este campo deve ser um UUID válido'),
  responsible_uuid: yup.string().uuid('Este campo deve ser um UUID válido'),
  user_uuid: yup.string().uuid('Este campo deve ser um UUID válido'),
  neighborhood: yup.string(),
});
