import { MAX_INTEGER_SIZE } from '@shared/constants/shared';

export function ToInteger(value: string | number): number {
  const integerValue = String(value)
    .replace(/[^0-9]+/g, '')
    .slice(0, MAX_INTEGER_SIZE);

  const positiveValue = Math.abs(Number(integerValue));

  return positiveValue || 0;
}
