import styled from 'styled-components';

import MUIContainer from '@material-ui/core/Container';

export const Container = styled(MUIContainer).attrs({
  maxWidth: false,
  disableGutters: true,
})`
  display: flex;
  height: calc(100vh - 4rem);
`;
