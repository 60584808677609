import styled from 'styled-components';
import MUIContainer from '@material-ui/core/Container';
import MUIPaper from '@material-ui/core/Paper';
import MUITypography from '@material-ui/core/Typography';
import MUIDivider from '@material-ui/core/Divider';

import { FormGridContainer as AppFormGridContainer } from '@shared/components/Form';

export {
  FormContainer,
  FormSelector,
  FormDatePicker,
  FormConfirmButton,
} from '@shared/components/Form';
export { NotificateUsersDialog } from '@modules/projects/components/NotificateUsersDialog';
export { HasPermissionComponent } from '@shared/components/HasPermissionComponent';

export const Container = styled(MUIContainer).attrs({
  component: MUIPaper,
  maxWidth: false,
  disableGutters: true,
})`
  width: 22rem;
  padding: 0.5rem;
  margin: 0rem;

  background: ${({ theme }) => theme.palette.grey_scale_3.main};
`;

export const KeyLabel = styled(MUITypography).attrs({
  variant: 'body2',
})``;

export const ValueLabel = styled(MUITypography).attrs({
  variant: 'h6',
})``;

export const ValueBoldLabel = styled(MUITypography).attrs({
  variant: 'h5',
})`
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid;
  margin: 0 0.5rem;
`;

export const Divider = styled(MUIDivider)`
  margin: 1rem 0;
`;

export const FormGridContainer = styled(AppFormGridContainer)`
  margin-top: 2rem;
`;
