import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useTemplate } from '@modules/templates/hooks/template';

import { Page } from '@shared/components/Page';
import { TemplateFormContent } from './Content';

import { Container } from './styles';

export function TemplateForm(): JSX.Element {
  const { clearState, formData } = useTemplate();

  const location = useLocation();

  useEffect(() => {
    const { state } = location;

    if (!state || state?.creating) formData({});

    window.history.replaceState(null, '');
  }, [location, formData]);

  useEffect(() => () => clearState(), [clearState]);

  return (
    <Page title="Template">
      <Container>
        <TemplateFormContent />
      </Container>
    </Page>
  );
}
