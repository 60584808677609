import { Routes, Route, Navigate } from 'react-router-dom';

import { TemplateList } from '@modules/templates/pages/Template/List';
import { TemplateForm } from '@modules/templates/pages/Template/Form';

import { ALL_PERMISSION_KEYS } from '@shared/constants/shared';

import { HasPermissionComponent } from '@shared/components/HasPermissionComponent';

import { NotFound } from '@shared/pages/NotFound';
import { Forbidden } from '@shared/pages/Forbidden';
import { useSession } from '@modules/sessions/hooks/session';

export function TemplateRoutes(): JSX.Element {
  const { sessionLoading } = useSession();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <HasPermissionComponent
            permission_key={[ALL_PERMISSION_KEYS.templates_list]}
            element={<TemplateList />}
            fall_back_element={<>{!sessionLoading && <Forbidden />}</>}
          />
        }
      />
      <Route
        path="/form"
        element={
          <HasPermissionComponent
            permission_key={[ALL_PERMISSION_KEYS.templates_create]}
            element={<TemplateForm />}
            fall_back_element={<>{!sessionLoading && <Forbidden />}</>}
          />
        }
      />

      <Route path="/not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  );
}
