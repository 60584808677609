import { Page } from '@shared/components/Page';

import { Container, ProfileCodeAuth } from './styles';

export function Profile(): JSX.Element {
  return (
    <Page title="Perfil">
      <Container>
        <ProfileCodeAuth />
      </Container>
    </Page>
  );
}
