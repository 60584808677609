import { STATUSES } from '@modules/projects/constants/project';

import { SEARCH_TYPES } from '@shared/constants/shared';

import { ProjectAppServices } from '../services/app/project';
import { TProjectObject } from '../@types/TProject';

export const statusesOptions: { key: STATUSES; label: string }[] = [
  {
    key: STATUSES.IN_PROGRESS,
    label: 'Em andamento',
  },
  {
    key: STATUSES.TO_START,
    label: 'A iniciar',
  },
  {
    key: STATUSES.DONE,
    label: 'Concluído',
  },
  {
    key: STATUSES.TO_VALIDATE,
    label: 'A validar',
  },
  {
    key: STATUSES.PENDING,
    label: 'Paralisado',
  },
];

export const statusesOptionsDashboard: { key: STATUSES; label: string }[] = [
  {
    key: STATUSES.IN_PROGRESS,
    label: 'Em andamento',
  },
  {
    key: STATUSES.TO_START,
    label: 'A iniciar',
  },
  {
    key: STATUSES.DONE,
    label: 'Concluído',
  },
];

const projectAppServices = new ProjectAppServices();

export async function listSelectorProject({
  search,
}: {
  search: string;
}): Promise<TProjectObject[]> {
  const projects = await projectAppServices.listSelector({
    search: [
      {
        field: 'name',
        type: SEARCH_TYPES.normal,
        values: [search],
      },
    ],
  });

  return projects.data;
}
