import { SharedAppServices } from '@shared/services/app/shared';

import { ProjectMessageAppRepositories } from '@modules/projects/repositories/app/projectMessage';

import {
  TProjectMessageObject,
  TProjectMessageShowObject,
  TProjectMessageCreateObject,
  TProjectMessageUpdateObject,
  TProjectMessageDestroyObject,
} from '@modules/projects/@types/TProjectMessage';
import { TUserObject } from '@modules/users/@types/TUser';

export class ProjectMessageAppServices extends SharedAppServices<
  TProjectMessageObject,
  TProjectMessageShowObject,
  TProjectMessageCreateObject,
  TProjectMessageUpdateObject,
  TProjectMessageDestroyObject,
  TUserObject
> {
  constructor() {
    const appRepositories = new ProjectMessageAppRepositories();

    super(appRepositories);
  }
}
