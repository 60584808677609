import { useRef, useCallback, useEffect } from 'react';
import { useField } from '@unform/core';
import { GridProps } from '@material-ui/core/Grid';

import {
  FormSkeleton,
  Container,
  FormControlLabel,
  Checkbox,
  LabelContent,
  BoldText,
  RequiredCharacter,
} from './styles';

type TFormCheckbox = {
  name: string;
  label: string;
  bold?: boolean;
  value?: boolean;
  onChange?: (newValue: boolean) => void;
  showLoading?: boolean;
  required?: boolean;
  gridProps?: GridProps;
  disabled?: boolean;
};

export function FormCheckbox({
  name,
  label,
  bold,
  value,
  onChange,
  showLoading,
  required,
  gridProps,
  disabled,
  ...rest
}: TFormCheckbox): JSX.Element {
  const { fieldName, registerField, error, clearError } = useField(name);

  const inputRef = useRef({
    checked: false,
    handleChange: (_newValue: boolean): void => {
      //
    },
  });

  const handleChange = useCallback(
    (newValue: boolean) => {
      if (inputRef.current) {
        inputRef.current.checked = newValue;

        if (onChange) onChange(newValue);
      }

      if (error) clearError();
    },
    [onChange, error, clearError],
  );

  useEffect(() => {
    inputRef.current.handleChange = handleChange;
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField, handleChange]);

  return (
    <Container {...gridProps}>
      {showLoading ? (
        <FormSkeleton />
      ) : (
        <FormControlLabel
          label={
            <LabelContent>
              {bold ? <BoldText>{label}</BoldText> : label}
              {required && <RequiredCharacter>*</RequiredCharacter>}
            </LabelContent>
          }
          disabled={disabled}
          {...rest}
          control={
            <Checkbox
              checked={value}
              onChange={(_, checked) => handleChange(checked)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
        />
      )}
    </Container>
  );
}

FormCheckbox.defaultProps = {
  bold: false,
  value: false,
  showLoading: false,
  required: false,
  gridProps: undefined,
  onChange: null,
  disabled: false,
};
