import {
  ISharedListDTO,
  ISharedShowDTO,
  ISharedCreateDTO,
  ISharedUpdateDTO,
  ISharedDestroyDTO,
  ISharedFormDataDTO,
  ISharedListSelectorDTO,
} from '@shared/dtos/IShared';
import {
  ISharedAppServices,
  ISharedListAppService,
  ISharedShowAppService,
  ISharedCreateAppService,
  ISharedUpdateAppService,
  ISharedDestroyAppService,
  ISharedFormDataAppService,
  ISharedListSelectorAppService,
} from '@shared/services/app/implementations/IShared';
import { ISharedAppRepositories } from '@shared/repositories/app/implementations/IShared';

import {
  TSharedObject,
  TSharedPaginatedList,
  TSharedShowObject,
  TSharedShowApiReturn,
  TSharedCreateObject,
  TSharedUpdateObject,
  TSharedDestroyObject,
  TSharedValidatedApiReturn,
  TSharedListValues,
} from '@shared/@types/TShared';

import { SharedFormDataAppService } from './formData';
import { SharedShowAppService } from './show';
import { SharedListAppService } from './list';
import { SharedCreateAppService } from './create';
import { SharedUpdateAppService } from './update';
import { SharedDestroyAppService } from './destroy';
import { SharedListSelectorAppService } from './listSelector';

export class SharedAppServices<
  TRegister extends TSharedObject,
  TShowObject extends TSharedShowObject,
  TCreateObject extends TSharedCreateObject,
  TUpdateObject extends TSharedUpdateObject,
  TDestroyObject extends TSharedDestroyObject,
  TRelationsRegister extends TSharedObject = TSharedObject,
> implements
    ISharedAppServices<
      TRegister,
      TShowObject,
      TCreateObject,
      TUpdateObject,
      TDestroyObject,
      TRelationsRegister
    >
{
  protected formDataAppService: ISharedFormDataAppService<TRelationsRegister>;

  protected showAppService: ISharedShowAppService<
    TRegister,
    TShowObject,
    TRelationsRegister
  >;

  protected listAppService: ISharedListAppService<TRegister>;

  protected createAppService: ISharedCreateAppService<TRegister, TCreateObject>;

  protected updateAppService: ISharedUpdateAppService<TRegister, TUpdateObject>;

  protected destroyAppService: ISharedDestroyAppService<
    TRegister,
    TDestroyObject
  >;

  protected listSelectorAppService: ISharedListSelectorAppService<TRegister>;

  constructor(
    private readonly appRepositories: ISharedAppRepositories<
      TRegister,
      TShowObject,
      TCreateObject,
      TUpdateObject,
      TDestroyObject,
      TRelationsRegister
    >,
    private readonly listSelectorFilters: TSharedListValues[] = [],
  ) {
    this.showAppService = new SharedShowAppService(
      this.appRepositories.showAppRepository,
    );
    this.formDataAppService = new SharedFormDataAppService(
      this.appRepositories.formDataAppRepository,
    );
    this.listAppService = new SharedListAppService(
      this.appRepositories.listAppRepository,
    );
    this.createAppService = new SharedCreateAppService(
      this.appRepositories.createAppRepository,
    );
    this.updateAppService = new SharedUpdateAppService(
      this.appRepositories.updateAppRepository,
    );
    this.destroyAppService = new SharedDestroyAppService(
      this.appRepositories.destroyAppRepository,
    );
    this.listSelectorAppService = new SharedListSelectorAppService(
      this.appRepositories.listAppRepository,
      listSelectorFilters,
    );
  }

  public async formData(
    FormDataDTO: ISharedFormDataDTO<TRelationsRegister>,
  ): Promise<{
    form_data: Record<string, TRelationsRegister[]>;
  }> {
    const formDataAppServiceResult = await this.formDataAppService.execute(
      FormDataDTO,
    );

    return formDataAppServiceResult;
  }

  public async show(
    ShowDTO: ISharedShowDTO<TShowObject, TRegister, TRelationsRegister>,
  ): Promise<TSharedShowApiReturn<TRegister, TRelationsRegister>> {
    const showAppServiceResult = await this.showAppService.execute(ShowDTO);

    return showAppServiceResult;
  }

  public async list(
    ListDTO: ISharedListDTO<TRegister>,
  ): Promise<TSharedPaginatedList<TRegister>> {
    const listAppServiceResult = await this.listAppService.execute(ListDTO);

    return listAppServiceResult;
  }

  public async create(
    CreateDTO: ISharedCreateDTO<TCreateObject, TRegister>,
  ): Promise<TSharedValidatedApiReturn<TRegister>> {
    const createAppServiceResult = await this.createAppService.execute(
      CreateDTO,
    );

    return createAppServiceResult;
  }

  public async update(
    UpdateDTO: ISharedUpdateDTO<TUpdateObject, TRegister>,
  ): Promise<TSharedValidatedApiReturn<TRegister>> {
    const updateAppServiceResult = await this.updateAppService.execute(
      UpdateDTO,
    );

    return updateAppServiceResult;
  }

  public async destroy(
    DestroyDTO: ISharedDestroyDTO<TDestroyObject, TRegister>,
  ): Promise<TSharedValidatedApiReturn<TRegister>> {
    const destroyAppServiceResult = await this.destroyAppService.execute(
      DestroyDTO,
    );

    return destroyAppServiceResult;
  }

  public async listSelector(
    ListSelectorDTO: ISharedListSelectorDTO<TRegister>,
  ): Promise<TSharedPaginatedList<TRegister>> {
    const listSelectorAppServiceResult =
      await this.listSelectorAppService.execute(ListSelectorDTO);

    return listSelectorAppServiceResult;
  }
}
