import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import MUIBox from '@material-ui/core/Box';

export { SettingContent } from './Content';
export { SettingCodeAuth } from './CodeAuth';
export { Page } from '@shared/components/Page';
export { default as Paper } from '@material-ui/core/Paper';
export { default as Tabs } from '@material-ui/core/Tabs';
export { default as Tab } from '@material-ui/core/Tab';

export const Container = styled(MUIBox)`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100vh - 110px);
`;

export const SwipeableContainer = styled(SwipeableViews)`
  height: calc(100vh - 158px);
  .react-swipeable-view-container {
    height: 100%;
  }
`;
