import { useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import { createValidatorSchema } from '@modules/projects/validators/projectUser';

import { ToCreate } from '@modules/projects/sanitizers/projectUser';

import { BASE_URL } from '@modules/projects/constants/projectUser';

import { useSession } from '@modules/sessions/hooks/session';
import { useProjectUser } from '@modules/projects/hooks/projectUser';
import { useUser } from '@modules/users/hooks/user';

import { TUserObject } from '@modules/users/@types/TUser';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import { TProjectUserCreateObject } from '@modules/projects/@types/TProjectUser';

import {
  FormCancelButton,
  FormConfirmButton,
  FormContainer,
  FormGridContainer,
  FormSelector,
} from './styles';

type TProjectUserFormContent = {
  project_uuid: string;
  onSuccess?: () => void;
};

export function ProjectUserFormContent({
  project_uuid,
  onSuccess: fatherOnSuccess,
}: TProjectUserFormContent): JSX.Element {
  const { logged_user, logged_branch } = useSession();
  const {
    create,
    formInitialData,
    registerShow,
    showLoading,
    formLoading,
    validations,
  } = useProjectUser();
  const { listSelector } = useUser();

  const navigate = useNavigate();
  const location = useLocation();

  const canEdit = useMemo(() => {
    const { state } = location;

    return !state || !registerShow || state?.creating || state?.updating;
  }, [location, registerShow]);

  const formRef = useRef<FormHandles>(null);

  const validatorSchema = createValidatorSchema;

  function handleSanitize(
    register: TProjectUserCreateObject,
  ): TProjectUserCreateObject {
    const parsedRegister = ToCreate({
      register: { ...register, project_uuid },
      logged_user: logged_user as TLoggedUser,
      logged_branch: logged_branch as TLoggedBranch,
    });

    return parsedRegister;
  }

  function handleSubmit(register: TProjectUserCreateObject): void {
    function onSuccess() {
      if (location.pathname === `/${BASE_URL}/form`) navigate(-1);
    }

    create({ register, onSuccess: fatherOnSuccess || onSuccess });
  }

  useEffect(() => {
    formRef.current?.setErrors(validations || {});
  }, [validations]);

  return (
    <FormContainer<TProjectUserCreateObject>
      formRef={formRef}
      validatorSchema={validatorSchema}
      sanitizer={handleSanitize}
      onSubmit={handleSubmit}
      initialData={registerShow}
    >
      <FormGridContainer>
        <FormGridContainer gap={1}>
          <FormSelector<TUserObject>
            label="Usuário"
            name="user_uuid"
            idColumn="uuid"
            nameColumn="name"
            handleSearch={listSelector}
            options={(formInitialData?.users || []) as TUserObject[]}
            disabled={!canEdit || formLoading}
            showLoading={showLoading}
            gridProps={{ xs: 6, sm: 4 }}
          />
        </FormGridContainer>

        <FormGridContainer gap={1}>
          <FormCancelButton
            loading={showLoading || formLoading}
            gridProps={{ xs: 6, sm: 4 }}
            onClick={fatherOnSuccess}
          >
            CANCELAR
          </FormCancelButton>
          <FormConfirmButton
            loading={showLoading || formLoading}
            gridProps={{ xs: 6, sm: 4 }}
            disabled={!canEdit || formLoading}
          >
            SALVAR
          </FormConfirmButton>
        </FormGridContainer>
      </FormGridContainer>
    </FormContainer>
  );
}

ProjectUserFormContent.defaultProps = {
  onSuccess: undefined,
};
