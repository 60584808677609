import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { ProjectAppServices } from '@modules/projects/services/app/project';

import { PER_PAGE, SORT_TYPES } from '@shared/constants/shared';

import { useErrorHandler } from '@shared/providers/error/hook';

import { ISharedCreateDTO, ISharedShowDTO } from '@shared/dtos/IShared';

import {
  TProjectObject,
  TProjectShowObject,
  TProjectCreateObject,
  TProjectUpdateObject,
  TProjectDestroyObject,
} from '@modules/projects/@types/TProject';
import {
  SharedHookInitialState,
  useSharedHook,
  TSharedHookInitialState,
  TSharedHookReturn,
} from '@shared/hooks/shared';
import { TUserObject } from '@modules/users/@types/TUser';
import { TTemplateObject } from '@modules/templates/@types/TTemplate';
import { TStageNameObject } from '@modules/templates/@types/TStageName';
import {
  TSharedShowApiReturn,
  TSharedValidatedApiReturn,
} from '@shared/@types/TShared';

import { useProjectUser } from './projectUser';

type TRelationsRegister =
  | TUserObject
  | TTemplateObject
  | TStageNameObject
  | TProjectObject;

const INITIAL_STATE: TSharedHookInitialState<
  TProjectObject,
  TRelationsRegister
> = SharedHookInitialState;

type TProjectContext = TSharedHookInitialState<
  TProjectObject,
  TRelationsRegister
> &
  TSharedHookReturn<
    TProjectObject,
    TProjectShowObject,
    TProjectCreateObject,
    TProjectUpdateObject,
    TProjectDestroyObject,
    TRelationsRegister
  >;

const ProjectContext = createContext<TProjectContext>(
  INITIAL_STATE as TProjectContext,
);

const AppServices = new ProjectAppServices();

export function ProjectProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [state, setState] =
    useState<TSharedHookInitialState<TProjectObject, TRelationsRegister>>(
      INITIAL_STATE,
    );

  const { setErrorHandlerData } = useErrorHandler();
  const { setStateSafety: projectUserSetStateSafety } = useProjectUser();

  const sharedHook = useSharedHook<
    TProjectObject,
    TProjectShowObject,
    TProjectCreateObject,
    TProjectUpdateObject,
    TProjectDestroyObject,
    TRelationsRegister
  >({
    setState,
    AppServices,
    setErrorHandlerData,
  });

  const create = useCallback(
    async (
      CreateDTO: ISharedCreateDTO<TProjectCreateObject, TProjectObject>,
    ): Promise<void> => {
      function onSuccess(
        serviceData: TSharedValidatedApiReturn<TProjectObject>,
      ) {
        sharedHook.list({
          order: SORT_TYPES.desc,
          order_by: 'updated_at',
          page: 1,
          per_page: PER_PAGE,
          for_selector: false,
          data: [],
        });
        CreateDTO.onSuccess?.(serviceData);
      }

      sharedHook.create({ ...CreateDTO, onSuccess });
    },
    [sharedHook],
  );

  const show = useCallback(
    async (
      ShowDTO: ISharedShowDTO<
        TProjectShowObject,
        TProjectObject,
        TRelationsRegister
      >,
    ): Promise<void> => {
      function onSuccess(
        serviceData: TSharedShowApiReturn<TProjectObject, TRelationsRegister>,
      ) {
        projectUserSetStateSafety({
          registerList: serviceData.register.project_users,
        });
        ShowDTO.onSuccess?.(serviceData);
      }

      sharedHook.show({ ...ShowDTO, onSuccess });
    },
    [sharedHook, projectUserSetStateSafety],
  );

  const clearState = useCallback((): void => {
    setState(oldState => ({
      ...oldState,
      registerList: [],
      formLoading: false,
      showLoading: false,
      registerShow: undefined,
      validations: undefined,
    }));
  }, [setState]);

  return (
    <ProjectContext.Provider
      value={{ ...state, ...sharedHook, create, show, clearState }}
    >
      {children}
    </ProjectContext.Provider>
  );
}

export function useProject(): TProjectContext {
  const context = useContext(ProjectContext);

  if (!context)
    throw new Error('useProject must be used within an ProjectProvider');

  return context;
}
