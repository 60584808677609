import { SORT_TYPES } from '@shared/constants/shared';

import { TSharedObject } from '@shared/@types/TShared';
import { TActionFunction, TColumnInfo } from '@shared/components/TableList';

import {
  Container,
  TableRow,
  TableCell,
  TableSortLabel,
  CellBox,
} from './styles';

type THeadProps<TRegister extends TSharedObject> = {
  columnInfos: TColumnInfo[];
  order: SORT_TYPES;
  order_by: string;
  onRequestSort: (key: string) => void;
  actionFunctions?: TActionFunction<TRegister>[];
};

export function THead<TRegister extends TSharedObject>({
  columnInfos,
  order,
  order_by,
  onRequestSort,
  actionFunctions,
}: THeadProps<TRegister>): JSX.Element {
  function HeadCells(): JSX.Element {
    return (
      <>
        {columnInfos.map(columnInfo => (
          <TableCell
            key={columnInfo.key}
            sortDirection={order_by === columnInfo.key ? order : false}
          >
            <TableSortLabel
              active={order_by === columnInfo.key}
              direction={order_by === columnInfo.key ? order : SORT_TYPES.asc}
              onClick={() => onRequestSort(columnInfo.key)}
            >
              {columnInfo.label}
              {order_by === columnInfo.key ? (
                <CellBox>
                  {order === SORT_TYPES.desc
                    ? 'sorted descending'
                    : 'sorted ascending'}
                </CellBox>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </>
    );
  }

  return (
    <Container>
      <TableRow>
        <HeadCells />
        {actionFunctions && <TableCell align="center">Ações</TableCell>}
      </TableRow>
    </Container>
  );
}

THead.defaultProps = {
  actionFunctions: undefined,
};
