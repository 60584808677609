import { STATUSES } from '@modules/projects/constants/project';
import { Container } from './styles';

type TReopenProject = {
  is_reopen: boolean;
  status: STATUSES;
  size: number;
};

export function ReopenProject({
  is_reopen,
  status,
  size,
}: TReopenProject): JSX.Element {
  return <Container is_reopen={+is_reopen} status={status} size={size} />;
}
