import { SharedAppRepositories } from '@shared/repositories/app/shared';

import { BASE_URL } from '@modules/users/constants/user';

import {
  TUserObject,
  TUserShowObject,
  TUserCreateObject,
  TUserUpdateObject,
  TUserDestroyObject,
} from '@modules/users/@types/TUser';

export class UserAppRepositories extends SharedAppRepositories<
  TUserObject,
  TUserShowObject,
  TUserCreateObject,
  TUserUpdateObject,
  TUserDestroyObject
> {
  constructor() {
    super(BASE_URL);
  }
}
