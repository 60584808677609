import { ApiProvider } from '@shared/providers/api';

import { STATUS_SUCCESS } from '@shared/constants/shared';

import { ISharedUpdateDTO } from '@shared/dtos/IShared';
import { ISharedUpdateAppRepository } from '@shared/repositories/app/implementations/IShared';

import {
  TSharedObject,
  TSharedValidatedApiReturn,
  TSharedUpdateObject,
} from '@shared/@types/TShared';

export class SharedUpdateAppRepository<
  TRegister extends TSharedObject,
  TUpdateObject extends TSharedUpdateObject,
> implements ISharedUpdateAppRepository<TRegister, TUpdateObject>
{
  private readonly apiProvider = new ApiProvider();

  constructor(
    private readonly baseURL: string,
    private readonly FromApi: ({
      registers,
    }: {
      registers: TRegister[];
    }) => TRegister[],
  ) {}

  public async execute(
    UpdateDTO: ISharedUpdateDTO<TUpdateObject, TRegister>,
  ): Promise<TSharedValidatedApiReturn<TRegister>> {
    const apiResult = await this.apiProvider.execute<
      TSharedValidatedApiReturn<TRegister>
    >({
      method: 'put',
      url: `${this.baseURL}`,
      data: { data: [UpdateDTO.register] },
    });

    if (apiResult.data.length && apiResult.data[0].status === STATUS_SUCCESS) {
      const [sanitizedRegister] = this.FromApi({
        registers: [apiResult.data[0].register],
      });

      apiResult.data[0].register = sanitizedRegister;
    }

    return apiResult;
  }
}
