import * as yup from 'yup';

import {
  sharedBaseCreateValidatorSchema,
  sharedBaseUpdateValidatorSchema,
} from '@shared/validators/shared';

import {
  TUserCreateObject,
  TUserUpdateObject,
} from '@modules/users/@types/TUser';

export const userBaseCreateOrUpdateValidatorSchema = yup.object().shape({
  uuid: yup
    .string()
    .required('Este campo é obrigatório')
    .uuid('Este campo deve ser um UUID válido'),
  user_key: yup
    .string()
    .max(100, 'Este campo deve conter no máximo 100 caracteres')
    .required('Este campo é obrigatório'),
  name: yup
    .string()
    .max(255, 'Este campo deve conter no máximo 255 caracteres')
    .required('Este campo é obrigatório'),
  image: yup.string(),
});

export const createValidatorSchema = yup
  .object()
  .concat(userBaseCreateOrUpdateValidatorSchema)
  .concat(sharedBaseCreateValidatorSchema) as yup.SchemaOf<TUserCreateObject>;

export const updateValidatorSchema = yup
  .object()
  .concat(userBaseCreateOrUpdateValidatorSchema)
  .concat(sharedBaseUpdateValidatorSchema) as yup.SchemaOf<TUserUpdateObject>;
