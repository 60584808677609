import { useState, useRef, useCallback, useEffect } from 'react';
import { useField } from '@unform/core';
import { GridProps } from '@material-ui/core/Grid';

import { debounceEvent } from '@shared/utils/debounce';

import {
  FormSkeleton,
  Container,
  DatePicker,
  OutlinedInput,
  LabelContent,
  RequiredCharacter,
} from './styles';

type TFormDatePicker = {
  name: string;
  label: string;
  onChange?: (newValue: Date | null) => void;
  showLoading?: boolean;
  required?: boolean;
  gridProps?: GridProps;
  disabled?: boolean;
  inputBox?: 'outlined' | 'filled' | 'standard';
};

export function FormDatePicker({
  name,
  label,
  onChange,
  showLoading,
  required,
  gridProps,
  disabled,
  inputBox,
  ...rest
}: TFormDatePicker): JSX.Element {
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField(name);

  const [value, setValue] = useState<Date | null>(null);

  const inputRef = useRef({
    value: '',
    handleChange: (_newValue: Date | null): void => {
      //
    },
  });

  const handleChange = useCallback(
    (newValue: Date | null) => {
      setValue(newValue);

      if (inputRef.current) {
        debounceEvent(() => {
          const parsedDate = newValue?.getTime() ? newValue : null;

          inputRef.current.value = parsedDate?.toISOString() || '';

          if (onChange) onChange(parsedDate);
        }, 500)();
      }

      if (error) clearError();
    },
    [onChange, error, clearError],
  );

  useEffect(() => {
    inputRef.current.handleChange = handleChange;
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, handleChange]);

  useEffect(() => {
    setValue(defaultValue || null);
  }, [defaultValue]);

  return (
    <Container {...gridProps}>
      {showLoading ? (
        <FormSkeleton />
      ) : (
        <DatePicker
          value={value}
          onChange={newValue => handleChange(newValue as Date)}
          disabled={disabled}
          {...rest}
          renderInput={params => (
            <OutlinedInput
              {...params}
              label={
                <LabelContent>
                  {label} {required && <RequiredCharacter>*</RequiredCharacter>}
                </LabelContent>
              }
              error={!!error}
              helperText={error || ' '}
              variant={inputBox || 'outlined'}
            />
          )}
        />
      )}
    </Container>
  );
}

FormDatePicker.defaultProps = {
  showLoading: false,
  required: false,
  gridProps: undefined,
  onChange: null,
  disabled: false,
  inputBox: null,
};
