import styled from 'styled-components';

import MUIGrid from '@material-ui/core/Grid';

export const Container = styled(MUIGrid)`
  margin: auto;
  border-radius: 6px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  background: ${({ theme }) => theme.palette.secondary_background.main};
  overflow-x: hidden;
  margin-top: 40px;
`;
