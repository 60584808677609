import { SharedAppRepositories } from '@shared/repositories/app/shared';

import { FromApi } from '@modules/projects/sanitizers/projectUser';

import { BASE_URL } from '@modules/projects/constants/projectUser';

import {
  TProjectUserObject,
  TProjectUserShowObject,
  TProjectUserCreateObject,
  TProjectUserUpdateObject,
  TProjectUserDestroyObject,
} from '@modules/projects/@types/TProjectUser';
import { TUserObject } from '@modules/users/@types/TUser';

export class ProjectUserAppRepositories extends SharedAppRepositories<
  TProjectUserObject,
  TProjectUserShowObject,
  TProjectUserCreateObject,
  TProjectUserUpdateObject,
  TProjectUserDestroyObject,
  TUserObject
> {
  constructor() {
    super(BASE_URL, FromApi);
  }
}
