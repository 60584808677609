import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import { ProjectMessageAppServices } from '@modules/projects/services/app/projectMessage';

import { useSession } from '@modules/sessions/hooks/session';
import { useErrorHandler } from '@shared/providers/error/hook';

import { IProjectMessageCreateDTO } from '@modules/projects/dto/IProjectMessage';
import {
  TProjectMessageObject,
  TProjectMessageShowObject,
  TProjectMessageCreateObject,
  TProjectMessageUpdateObject,
  TProjectMessageDestroyObject,
} from '@modules/projects/@types/TProjectMessage';
import {
  SharedHookInitialState,
  useSharedHook,
  TSharedHookInitialState,
  TSharedHookReturn,
} from '@shared/hooks/shared';
import { TUserObject } from '@modules/users/@types/TUser';
import { TAppError } from '@shared/providers/error/@types/TError';

const INITIAL_STATE: TSharedHookInitialState<
  TProjectMessageObject,
  TUserObject
> = SharedHookInitialState;

type TProjectMessageContext = TSharedHookInitialState<
  TProjectMessageObject,
  TUserObject
> &
  TSharedHookReturn<
    TProjectMessageObject,
    TProjectMessageShowObject,
    TProjectMessageCreateObject,
    TProjectMessageUpdateObject,
    TProjectMessageDestroyObject,
    TUserObject
  >;

const ProjectMessageContext = createContext<TProjectMessageContext>(
  INITIAL_STATE as TProjectMessageContext,
);

const AppServices = new ProjectMessageAppServices();

export function ProjectMessageProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const { logged_user } = useSession();

  const [state, setState] =
    useState<TSharedHookInitialState<TProjectMessageObject, TUserObject>>(
      INITIAL_STATE,
    );

  const { setErrorHandlerData } = useErrorHandler();

  const sharedHook = useSharedHook<
    TProjectMessageObject,
    TProjectMessageShowObject,
    TProjectMessageCreateObject,
    TProjectMessageUpdateObject,
    TProjectMessageDestroyObject,
    TUserObject
  >({
    setState,
    AppServices,
    setErrorHandlerData,
  });

  const create = useCallback(
    async (CreateDTO: IProjectMessageCreateDTO): Promise<void> => {
      try {
        sharedHook.setStateSafety({ formLoading: true });

        const serviceData = await AppServices.create(CreateDTO);
        const { register: createdRegister, error } = serviceData.data[0];

        if (error) throw error;

        sharedHook.setStateSafety(oldData => {
          const parsedRegister = {
            ...createdRegister,
            user_alteration: logged_user,
          };

          const parsedRegisterList = [...oldData.registerList, parsedRegister];

          return {
            registerList: parsedRegisterList,
            formLoading: false,
          };
        });

        toast.success('Registro criado!', { toastId: 'CREATED' });

        CreateDTO.onSuccess?.(serviceData);
      } catch (error) {
        const parsedError = error as TAppError;

        sharedHook.errorHandler(parsedError, () => create(CreateDTO), {
          formLoading: false,
        });

        CreateDTO.onError?.(error);
      }
    },
    [logged_user, sharedHook],
  );

  return (
    <ProjectMessageContext.Provider value={{ ...state, ...sharedHook, create }}>
      {children}
    </ProjectMessageContext.Provider>
  );
}

export function useProjectMessage(): TProjectMessageContext {
  const context = useContext(ProjectMessageContext);

  if (!context)
    throw new Error(
      'useProjectMessage must be used within an ProjectMessageProvider',
    );

  return context;
}
