import { ISessionLocalStorageRepositories } from '@modules/sessions/repositories/localStorage/implementations/ISession';

import { TLocalStorageProps } from '@modules/sessions/@types/TSession';

import { SessionShowLocalStorageRepository } from './show';
import { SessionUpdateLocalStorageRepository } from './update';
import { SessionDestroyLocalStorageRepository } from './destroy';

export class SessionLocalStorageRepositories
  implements ISessionLocalStorageRepositories
{
  public readonly sessionShowLocalStorageRepository =
    new SessionShowLocalStorageRepository();

  public readonly sessionUpdateLocalStorageRepository =
    new SessionUpdateLocalStorageRepository();

  public readonly sessionDestroyLocalStorageRepository =
    new SessionDestroyLocalStorageRepository();

  public show(): TLocalStorageProps {
    const showLocalStorageRepositoryResult =
      this.sessionShowLocalStorageRepository.execute();

    return showLocalStorageRepositoryResult;
  }

  public update(LocalStorageProps: Partial<TLocalStorageProps>): void {
    this.sessionUpdateLocalStorageRepository.execute(LocalStorageProps);
  }

  public destroy(): void {
    this.sessionDestroyLocalStorageRepository.execute();
  }
}
