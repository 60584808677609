import { createContext, ReactNode, useContext, useState } from 'react';

import { TemplateAppServices } from '@modules/templates/services/app/template';

import { useErrorHandler } from '@shared/providers/error/hook';

import {
  TTemplateObject,
  TTemplateShowObject,
  TTemplateCreateObject,
  TTemplateUpdateObject,
  TTemplateDestroyObject,
} from '@modules/templates/@types/TTemplate';
import {
  SharedHookInitialState,
  useSharedHook,
  TSharedHookInitialState,
  TSharedHookReturn,
} from '@shared/hooks/shared';
import { TUserObject } from '@modules/users/@types/TUser';

const INITIAL_STATE: TSharedHookInitialState<TTemplateObject, TUserObject> =
  SharedHookInitialState;

type TTemplateContext = TSharedHookInitialState<TTemplateObject, TUserObject> &
  TSharedHookReturn<
    TTemplateObject,
    TTemplateShowObject,
    TTemplateCreateObject,
    TTemplateUpdateObject,
    TTemplateDestroyObject,
    TUserObject
  >;

const TemplateContext = createContext<TTemplateContext>(
  INITIAL_STATE as TTemplateContext,
);

const AppServices = new TemplateAppServices();

export function TemplateProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [state, setState] =
    useState<TSharedHookInitialState<TTemplateObject, TUserObject>>(
      INITIAL_STATE,
    );

  const { setErrorHandlerData } = useErrorHandler();

  const sharedHook = useSharedHook<
    TTemplateObject,
    TTemplateShowObject,
    TTemplateCreateObject,
    TTemplateUpdateObject,
    TTemplateDestroyObject,
    TUserObject
  >({
    setState,
    AppServices,
    setErrorHandlerData,
  });

  return (
    <TemplateContext.Provider value={{ ...state, ...sharedHook }}>
      {children}
    </TemplateContext.Provider>
  );
}

export function useTemplate(): TTemplateContext {
  const context = useContext(TemplateContext);

  if (!context)
    throw new Error('useTemplate must be used within an TemplateProvider');

  return context;
}
