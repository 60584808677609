import styled from 'styled-components';
import MUIGrid from '@material-ui/core/Grid';
import { STATUSES } from '@modules/projects/constants/project';

type TContainer = {
  is_reopen: number;
  status: STATUSES;
  size: number;
};

export const Container = styled(MUIGrid)<TContainer>`
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  border: 5px solid
    ${props =>
      props.is_reopen && props.status === STATUSES.DONE
        ? props.theme.palette.grey_scale_2.main
        : props.theme.palette.done.main};
  border-radius: 50%;
  background: ${props =>
    props.is_reopen && props.status === STATUSES.DONE
      ? props.theme.palette.is_reopen_and_concluded.main
      : props.theme.palette.done.main};

  display: ${props => (props.is_reopen ? 'flex' : 'none')};
`;
