import {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
  FormSkeleton,
  EditorContainer,
  Container,
  FormHelperText,
} from './styles';

type TFormRichTextInput = {
  name: string;
  showLoading?: boolean;
  files: { alt: string; file: File }[];
  setFiles: Dispatch<SetStateAction<{ alt: string; file: File }[]>>;
};

const inputAccept = [
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg',
];

export function FormRichTextInput({
  name,
  showLoading,
  files,
  setFiles,
  ...rest
}: TFormRichTextInput): JSX.Element {
  const { fieldName, registerField, error } = useField(name);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  const inputRef = useRef({
    value: '',
    handleChange: (_newValue: string): void => {
      //
    },
    getText: (): string => '',
  });

  function manualChange(newValue: string) {
    const parsedNewValue = htmlToDraft(newValue);

    if (parsedNewValue) {
      const contentState = ContentState.createFromBlockArray(
        parsedNewValue.contentBlocks,
      );
      const newEditorState = EditorState.createWithContent(contentState);

      setEditorState(newEditorState);
    }

    inputRef.current.value = newValue;
  }

  const getText = useCallback(() => {
    const text = editorState.getCurrentContent().getPlainText();

    return text;
  }, [editorState]);

  function handleChange(newValue: EditorState) {
    const html = draftToHtml(convertToRaw(newValue.getCurrentContent()));

    const hasNewImage = html.match('alt="undefined"');

    if (hasNewImage) {
      const lastFile = [...files].pop();

      const parsedHtml = html.replace(
        'alt="undefined"',
        `alt="${lastFile?.alt}"`,
      );

      manualChange(parsedHtml);
    } else {
      inputRef.current.value = html;

      setEditorState(newValue);
    }
  }

  function getFileBase64(
    file: File,
    callback: (value: string | ArrayBuffer | null) => void,
  ) {
    const parsedFile = new File([file], file.name, {
      type: file.type,
      lastModified: file.lastModified,
    });

    setFiles((oldData: { alt: string; file: File }[]) => [
      ...oldData,
      { alt: parsedFile.name, file: parsedFile },
    ]);
    const reader = new FileReader();
    reader.readAsDataURL(parsedFile);
    reader.onload = () => {
      callback(reader.result);
    };
  }

  function imageUploadCallback(file: File) {
    if (!inputAccept.includes(file.type))
      return new Promise(resolve => resolve(null));

    return new Promise(resolve =>
      getFileBase64(file, (data: unknown) => {
        resolve({ data: { link: data } });
      }),
    );
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.handleChange = manualChange;
      inputRef.current.getText = getText;
    }

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, showLoading, getText]);

  return showLoading ? (
    <FormSkeleton />
  ) : (
    <EditorContainer>
      <Container
        editorState={editorState}
        onEditorStateChange={handleChange}
        toolbar={{
          options: [
            'inline',
            'colorPicker',
            'emoji',
            'image',
            'link',
            'remove',
            'history',
          ],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
          image: {
            uploadCallback: imageUploadCallback,
            previewImage: true,
            inputAccept: inputAccept.join(', '),
            defaultSize: {
              height: 240,
              width: 420,
            },
          },
        }}
        {...rest}
      />
      <FormHelperText>{error || '⠀'}</FormHelperText>
    </EditorContainer>
  );
}

FormRichTextInput.defaultProps = {
  showLoading: false,
};
