import * as yup from 'yup';

const sharedBaseCreateOrUpdateValidatorSchema = yup.object().shape({
  req_id: yup
    .string()
    .max(255, 'Este campo deve conter no máximo 255 caracteres')
    .required('Este campo é obrigatório'),
  company_key: yup
    .string()
    .min(8, 'Este campo deve conter no minimo 8 caracteres')
    .max(8, 'Este campo deve conter no máximo 8 caracteres')
    .required('Este campo é obrigatório'),
  branch_key: yup
    .string()
    .min(14, 'Este campo deve conter no minimo 14 caracteres')
    .max(14, 'Este campo deve conter no máximo 14 caracteres')
    .required('Este campo é obrigatório'),
  branch_name: yup
    .string()
    .max(100, 'Este campo deve conter no máximo 100 caracteres')
    .required('Este campo é obrigatório'),
});

export const sharedBaseCreateValidatorSchema = yup
  .object()
  .concat(sharedBaseCreateOrUpdateValidatorSchema);

export const sharedBaseUpdateValidatorSchema = yup
  .object()
  .shape({
    uuid: yup
      .string()
      .uuid('Este campo deve ser um UUID válido')
      .required('Este campo é obrigatório'),
  })
  .concat(sharedBaseCreateOrUpdateValidatorSchema);

export const sharedBaseShowValidatorSchema = yup.object().shape({
  uuid: yup
    .string()
    .uuid('Este campo deve ser um UUID válido')
    .required('Este campo é obrigatório'),
});
