import styled from 'styled-components';
import MUICheckbox from '@material-ui/core/Checkbox';
import MUIGrid from '@material-ui/core/Grid';
import MUITypography from '@material-ui/core/Typography';

import { FormGridItem } from '@shared/components/Form';

export { FormSkeleton } from '@shared/components/Form';
export { default as FormControlLabel } from '@material-ui/core/FormControlLabel';

export const Container = styled(FormGridItem)``;

export const Checkbox = styled(MUICheckbox)``;

export const LabelContent = styled(MUIGrid)`
  display: flex;
  flex-direction: row;
`;

export const BoldText = styled(MUITypography).attrs({
  variant: 'h6',
})`
  font-weight: 600;
`;

export const RequiredCharacter = styled(MUITypography).attrs({
  variant: 'subtitle1',
  color: 'error',
})`
  padding-left: 4px;
`;
