import { Routes, Route, Navigate } from 'react-router-dom';

import { SignIn } from '@modules/sessions/pages/SignIn';

import { NotFound } from '@shared/pages/NotFound';

export function SessionRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  );
}
