import * as yup from 'yup';

import {
  sharedBaseCreateValidatorSchema,
  sharedBaseUpdateValidatorSchema,
} from '@shared/validators/shared';

import { STATUSES } from '@modules/projects/constants/project';

import {
  TProjectSubStageCreateObject,
  TProjectSubStageUpdateObject,
} from '@modules/projects/@types/TProjectSubStage';

export const projectSubStageBaseCreateOrUpdateValidatorSchema = yup
  .object()
  .shape({
    project_stage_uuid: yup
      .string()
      .required('Este campo é obrigatório')
      .uuid('Este campo deve ser um UUID válido'),
    stage_name_uuid: yup
      .string()
      .required('Este campo é obrigatório')
      .uuid('Este campo deve ser um UUID válido'),
    template_sub_stage_uuid: yup
      .string()
      .required('Este campo é obrigatório')
      .uuid('Este campo deve ser um UUID válido'),
    responsible_uuid: yup.string().notRequired(),
    description: yup
      .string()
      .required('Este campo é obrigatório')
      .max(255, 'Este campo deve conter no máximo 255 caracteres'),
    status: yup
      .mixed()
      .oneOf(
        Object.values(STATUSES),
        `Este campo deve ser algum dos valores: ${Object.values(STATUSES)}`,
      )
      .required('Este campo é obrigatório'),
    estimated_delivery_at: yup
      .string()
      .max(255, 'Este campo deve conter no máximo 255 caracteres'),
    is_reopen: yup.boolean(),
    notificate_users: yup.array().of(yup.string()),
  });

export const createValidatorSchema = yup
  .object()
  .concat(projectSubStageBaseCreateOrUpdateValidatorSchema)
  .concat(
    sharedBaseCreateValidatorSchema,
  ) as yup.SchemaOf<TProjectSubStageCreateObject>;

export const updateValidatorSchema = yup
  .object()
  .concat(projectSubStageBaseCreateOrUpdateValidatorSchema)
  .concat(
    sharedBaseUpdateValidatorSchema,
  ) as yup.SchemaOf<TProjectSubStageUpdateObject>;
