import { LocalesProvider } from '@shared/providers/locales';

import { ERROR_CODES } from '@shared/constants/shared';

import {
  TAppError,
  TErrorCodes,
  TProviderErrorBaseProps,
} from './@types/TError';

export class ErrorProvider {
  private code!: TErrorCodes;

  private message!: string;

  private details?: Record<string, unknown>;

  private validations?: Record<string, string>;

  private localesProvider = new LocalesProvider();

  constructor({
    code,
    error,
    data,
  }: { code: TErrorCodes } & TProviderErrorBaseProps) {
    // eslint-disable-next-line
    // @ts-ignore
    if (this[code]) this[code]({ error, data });
    else if (ERROR_CODES.includes(code)) this.execute({ code, error, data });
    else this.execute({ code: 'E_UNRECOGNIZED', error, data });
  }

  private execute({
    code,
    error,
    data,
  }: {
    code: TErrorCodes;
  } & TProviderErrorBaseProps) {
    this.code = code;
    this.message = this.localesProvider.formatErrorMessage({ code, data });

    if (process.env.NODE_ENV !== 'production' && code === 'E_UNRECOGNIZED') {
      const details: Record<string, unknown> = error?.details || {
        ...(Object.keys(error || {}).length ? error : { error }),
        code: error?.code,
        message: error?.message,
        stack: error?.stack && String(error?.stack).split('\n'),
      };

      const detailsIsNotEmpty = Object.keys(details || {}).filter(
        key => details[key],
      ).length;

      this.details = detailsIsNotEmpty ? details : undefined;
    }
  }

  private E_UNAUTHORIZED_ACCESS({ error, data }: TProviderErrorBaseProps) {
    this.execute({
      code: 'E_UNAUTHORIZED_ACCESS',
      error,
      data: { ...(data || {}), defaultMessage: error?.message },
    });
  }

  private E_VALIDATION_FAILED({ error, data }: TProviderErrorBaseProps) {
    const parsedValidations: Record<string, string> = {};
    if (Array.isArray(error?.validations))
      error?.validations?.forEach(validation => {
        parsedValidations[validation.field] = validation.message;
      });

    this.validations = parsedValidations;

    this.execute({
      code: 'E_VALIDATION_FAILED',
      error,
      data,
    });
  }

  public toJSON(props?: Record<string, unknown>): TAppError {
    const errorProvider = {
      code: this.code,
      message: this.message,
      details: this.details,
      validations: this.validations,
      ...(props || {}),
    };

    return errorProvider;
  }
}
