import { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';

import { sessionCreateValidatorSchema } from '@modules/sessions/validators/session';

import { ToCreate } from '@modules/sessions/sanitizers/session';

import { useSession } from '@modules/sessions/hooks/session';

import { TSessionCreateObject } from '@modules/sessions/@types/TSession';

import {
  IconButton,
  VisibilityIcon,
  VisibilityOffIcon,
  FormConfirmButton,
  FormContainer,
  FormGridContainer,
  TextInput,
} from './styles';

export function SignInContent(): JSX.Element {
  const { create, sessionLoading, validations } = useSession();

  const formRef = useRef<FormHandles>(null);

  const [showPassword, setShowPassword] = useState(false);

  function handleTogglePassword(): void {
    setShowPassword(!showPassword);
  }

  function handleSubmit(sessionCreateObject: TSessionCreateObject): void {
    create({
      ...sessionCreateObject,
      email: sessionCreateObject.email.toLowerCase(),
    });
  }

  useEffect(() => {
    formRef.current?.setErrors(validations || {});
  }, [validations]);

  return (
    <FormContainer<TSessionCreateObject>
      formRef={formRef}
      validatorSchema={sessionCreateValidatorSchema}
      sanitizer={ToCreate}
      onSubmit={handleSubmit}
    >
      <FormGridContainer>
        <TextInput
          label="Informe seu email"
          name="email"
          gridProps={{ xs: 12 }}
          inputProps={{
            autoComplete: 'email',
          }}
        />
        <TextInput
          label="Informe sua senha"
          name="password"
          gridProps={{ xs: 12 }}
          inputProps={{
            type: showPassword ? 'text' : 'password',
            autoComplete: 'current-password',
          }}
          endAdornment={
            <IconButton onClick={handleTogglePassword}>
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          }
        />
        <FormConfirmButton
          loading={sessionLoading}
          disabled={sessionLoading}
          gridProps={{ xs: 12 }}
        >
          ENTRAR
        </FormConfirmButton>
      </FormGridContainer>
    </FormContainer>
  );
}
