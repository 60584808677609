import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useStageName } from '@modules/templates/hooks/stageName';

import { StageNameFormContent } from './Content';

import { Container, Page } from './styles';

export function StageNameForm(): JSX.Element {
  const { clearState, formData } = useStageName();

  const location = useLocation();

  useEffect(() => {
    const { state } = location;

    if (!state || state?.creating) formData({});

    window.history.replaceState(null, '');
  }, [location, formData]);

  useEffect(() => () => clearState(), [clearState]);

  return (
    <Page>
      <Container>
        <StageNameFormContent />
      </Container>
    </Page>
  );
}
