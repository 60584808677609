import { useEffect, ReactNode } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import { SchemaOf } from 'yup';
import { toast } from 'react-toastify';

import { validate } from '@shared/providers/validator';

import { debounceEvent } from '@shared/utils/debounce';
import { clearFormErrors } from '@shared/utils/form';

import { Container } from './styles';

type TFormContainer<TRegister> = {
  formRef: React.MutableRefObject<FormHandles | null>;
  validatorSchema: SchemaOf<TRegister>;
  sanitizer: (register: TRegister) => TRegister;
  onSubmit: (register: TRegister) => void;
  children: ReactNode;
  initialData?: TRegister;
};

export function FormContainer<TRegister>({
  formRef,
  validatorSchema,
  sanitizer,
  onSubmit,
  children,
  initialData,
  ...rest
}: TFormContainer<TRegister>): JSX.Element {
  async function handleSubmit(formData: TRegister): Promise<void> {
    const { parsedData, errorMessages } = await validate<TRegister>({
      formData,
      sanitizer,
      validatorSchema,
    });

    if (errorMessages) {
      // eslint-disable-next-line
      console.log({ errorMessages });
      toast.warn('Ocorreu um erro de validação', {
        toastId: 'E_VALIDATION_FAILED',
      });

      formRef.current?.setErrors(errorMessages);
    } else {
      clearFormErrors({ formData: parsedData || {}, formRef });

      if (parsedData) onSubmit(parsedData);
    }
  }

  useEffect(() => {
    formRef.current?.setData(initialData || {});
  }, [formRef, initialData]);

  return (
    <Container
      initialData={initialData as Record<string, any>}
      ref={formRef}
      onSubmit={
        debounceEvent(handleSubmit, 200) as unknown as SubmitHandler<unknown>
      }
      {...rest}
    >
      {children}
    </Container>
  );
}

FormContainer.defaultProps = {
  initialData: undefined,
};
