import * as yup from 'yup';

type TValidate<TRegister> = {
  formData: TRegister;
  sanitizer: (register: TRegister) => TRegister;
  validatorSchema: yup.SchemaOf<TRegister>;
};

export async function validate<TRegister>({
  formData,
  sanitizer,
  validatorSchema,
}: TValidate<TRegister>): Promise<{
  parsedData?: TRegister;
  errorMessages?: { [Prop in keyof TRegister]: string };
}> {
  try {
    const parsedData = sanitizer(formData);

    await validatorSchema.validate(parsedData, { abortEarly: false });

    return { parsedData };
  } catch (error) {
    if (!(error instanceof yup.ValidationError)) throw error;

    const errorMessages = {} as { [Prop in keyof TRegister]: string };

    error.inner.forEach(err => {
      if (err.path) errorMessages[err.path as keyof TRegister] = err.message;
    });

    return { errorMessages };
  }
}
