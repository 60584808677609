import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { APP_KEY_NAME } from '@shared/constants/shared';

import { useSession } from '@modules/sessions/hooks/session';
import { useUser } from '@modules/users/hooks/user';

import {
  TToken,
  TLoggedBranch,
  TLoggedUser,
} from '@modules/sessions/@types/TSession';
import { TUserUpdateObject } from '@modules/users/@types/TUser';
import { ToUpdate } from '@modules/users/sanitizers/user';

import { Container } from './styles';

type TMessageTypes =
  | 'sendToastValidationError'
  | 'sendToastNoEmailFound'
  | 'errorForgotPassword'
  | 'sendTokenValidateError'
  | 'sendPasswordTokenInvalid'
  | 'sendToastValidationSuccess'
  | 'sendUpdatePasswordSuccess'
  | 'sendUpdateUserSuccess';

type TCodeAuthIframe = {
  url: string;
  queryParams?: string[];
  onSuccess?: () => void;
};

export function CodeAuthIframe({
  url,
  queryParams,
  onSuccess,
  ...rest
}: TCodeAuthIframe): JSX.Element {
  const navigate = useNavigate();

  const { signOut, updateLocal, logged_branch, logged_user } = useSession();
  const { update } = useUser();

  const parsedQueryParams = queryParams ? `&${queryParams.join('&')}` : '';
  const parsedUrl = `${process.env.REACT_APP_CODE_AUTH_FRONT_URL}/${url}`;

  const messageDict: Record<TMessageTypes, (data: unknown) => void> = useMemo(
    () => ({
      sendToastValidationError: () => {
        toast.warn('Ocorreu um erro de validação', {
          toastId: 'sendToastValidationError',
        });
      },
      sendToastNoEmailFound: () => {
        toast.warn('Email não encontrado', {
          toastId: 'sendToastNoEmailFound',
        });
      },
      errorForgotPassword: () => {
        toast.warn('Ocorreu um erro ao se conectar com o Code Auth', {
          toastId: 'errorForgotPassword',
        });
      },
      sendTokenValidateError: () => {
        toast.warn('Link para alterar senha inválido ou expirado', {
          toastId: 'sendTokenValidateError',
        });
        navigate('/', { replace: true });
      },
      sendPasswordTokenInvalid: () => {
        toast.warn('Link para alterar senha inválido ou expirado', {
          toastId: 'sendPasswordTokenInvalid',
        });
        navigate('/', { replace: true });
      },
      sendToastValidationSuccess: () => {
        toast.success('E-mail enviado com sucesso', {
          toastId: 'sendToastValidationSuccess',
        });
        navigate('/', { replace: true });
        onSuccess?.();
      },
      sendUpdatePasswordSuccess: () => {
        toast.success('Senha alterada com sucesso', {
          toastId: 'sendUpdatePasswordSuccess',
        });
        navigate('/', { replace: true });
        onSuccess?.();
      },
      sendUpdateUserSuccess: (updateUser: unknown) => {
        const parsedUpdateUser = updateUser as {
          userData: TUserUpdateObject & { email: string };
        };
        const register = ToUpdate({
          register: {
            ...parsedUpdateUser.userData,
            user_key: parsedUpdateUser.userData.email,
          },
          logged_user: logged_user as TLoggedUser,
          logged_branch: logged_branch as TLoggedBranch,
        });
        update({ register });
        navigate('/');
      },
      singnOutCodeAuth: () => {
        signOut();
      },
      refreshToken: ({ token }: { token: TToken }) => {
        updateLocal({ token });
      },
    }),
    [
      logged_branch,
      logged_user,
      navigate,
      onSuccess,
      signOut,
      update,
      updateLocal,
    ],
  );

  const handleIframeToast = useCallback(
    (event: MessageEvent) => {
      if (event.origin !== `${process.env.REACT_APP_CODE_AUTH_FRONT_URL}`)
        return;

      messageDict[String(event.data?.message || event.data) as TMessageTypes]?.(
        event.data,
      );
    },
    [messageDict],
  );

  useEffect(() => {
    window.addEventListener('message', handleIframeToast);
    return () => window.removeEventListener('message', handleIframeToast);
  }, [handleIframeToast]);

  return (
    <Container
      src={`${parsedUrl}/?application_key=${APP_KEY_NAME}&url=${process.env.REACT_APP_FRONT_URL}${parsedQueryParams}`}
      {...rest}
    />
  );
}

CodeAuthIframe.defaultProps = {
  queryParams: [],
  onSuccess: undefined,
};
