import { TDashboardRelationsObject } from '@modules/projects/@types/TDashboard';
import { DashboardAppRepositories } from '@modules/projects/repositories/app/dashboard';

export class DashboardAppService {
  protected formDataAppRepository = new DashboardAppRepositories();

  public async formData(): Promise<{
    form_data: TDashboardRelationsObject;
  }> {
    const formDataAppRepositoryResult =
      await this.formDataAppRepository.formData();

    return { form_data: formDataAppRepositoryResult };
  }
}
