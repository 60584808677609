import { ISessionDestroyAppService } from '@modules/sessions/services/app/implementations/ISession';
import { ISessionDestroyLocalStorageRepository } from '@modules/sessions/repositories/localStorage/implementations/ISession';

import { apiHttpClientConfig } from '@shared/providers/api/configs/httpClient';

export class SessionDestroyAppService implements ISessionDestroyAppService {
  constructor(
    private readonly sessionDestroyLocalStorageRepository: ISessionDestroyLocalStorageRepository,
  ) {}

  public async execute(): Promise<void> {
    this.sessionDestroyLocalStorageRepository.execute();

    apiHttpClientConfig.defaults = {
      ...apiHttpClientConfig.defaults,
      headers: {
        ...apiHttpClientConfig.defaults.headers,
        authorization: `Bearer`,
        'Refresh-Token': ``,
      },
    };
  }
}
