import styled from 'styled-components';
import MUIButton from '@material-ui/core/Button';
import MUILink from '@material-ui/core/Link';
import MUIGrid from '@material-ui/core/Grid';
import MUIAvatar from '@material-ui/core/Avatar';
import MUIBox from '@material-ui/core/Box';
import MUITypography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';

export { default as GroupIcon } from '@material-ui/icons/Group';
export { RecoverPasswordContent } from '@modules/sessions/pages/RecoverPassword/Content';
export { ForgotPasswordContent } from '@modules/sessions/pages/ForgotPassword/Content';

export const Container = styled(MUIGrid).attrs({
  container: true,
  component: 'main',
})`
  height: 100vh;
  background: ${({ theme }) => theme.palette.secondary.main};
`;

export const SwipeableContainer = styled(SwipeableViews).attrs({
  disabled: true,
})``;

export const ImageContent = styled(MUIGrid).attrs({
  item: true,
  xs: false,
  sm: 4,
  md: 7,
})`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;

  img {
    width: 600px;
    height: 350px;
  }
`;

export const ArchitectImage = styled.img``;

export const FormContent = styled(MUIGrid).attrs({
  item: true,
  xs: 12,
  sm: 8,
  md: 5,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0 2rem;
`;

export const Grid = styled(MUIGrid)``;

export const Divider = styled(MUIGrid)`
  width: 10px;
  height: 350px;
  min-width: 10px;

  background: ${({ theme }) => theme.palette.primary.main};

  position: absolute;

  align-self: right;
  justify-self: right;
`;

export const LogoImage = styled.img`
  width: 500px;
`;

export const ForgotButton = styled(MUIButton)``;

export const Link = styled(MUILink).attrs({ variant: 'body2' })`
  margin-top: 1rem;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Copyright = styled(MUITypography).attrs({
  variant: 'body2',
})`
  margin-top: 1rem;
  color: ${({ theme }) => theme.palette.input_text_color.contrastText};
`;

export const Title = styled(MUITypography).attrs({
  variant: 'h5',
})`
  color: ${({ theme }) => theme.palette.input_text_color.contrastText};
`;

export const Avatar = styled(MUIAvatar)``;

export const SwipeableBox = styled(MUIBox)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
