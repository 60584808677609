import styled from 'styled-components';

import MUIGrid from '@material-ui/core/Grid';
import { Page as SharedPage } from '@shared/components/Page';

export const Container = styled(MUIGrid).attrs({
  container: true,
  component: 'main',
})`
  padding: 0.5rem;
  width: 100%;
`;

export const Page = styled(SharedPage).attrs({
  title: 'Etapas e Atividades',
})``;
