import { UserAppServices } from '@modules/users/services/app/user';
import { SEARCH_TYPES } from '@shared/constants/shared';

import { TUserObject } from '@modules/users/@types/TUser';

const userAppServices = new UserAppServices();

export async function listSelectorUser({
  search,
}: {
  search: string;
}): Promise<TUserObject[]> {
  const users = await userAppServices.listSelector({
    search: [
      {
        field: 'name',
        type: SEARCH_TYPES.normal,
        values: [search],
      },
    ],
  });

  return users.data;
}
