import { APP_KEY_NAME } from '@shared/constants/shared';

import { ISessionUpdateLocalStorageRepository } from '@modules/sessions/repositories/localStorage/implementations/ISession';

import { TLocalStorageProps } from '@modules/sessions/@types/TSession';

export class SessionUpdateLocalStorageRepository
  implements ISessionUpdateLocalStorageRepository
{
  public execute(LocalStorageProps: TLocalStorageProps): void {
    localStorage.setItem(
      `@${APP_KEY_NAME}/session:token`,
      JSON.stringify(LocalStorageProps.token),
    );
  }
}
