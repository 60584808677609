import { ApiProvider } from '@shared/providers/api';

import { ISharedShowDTO } from '@shared/dtos/IShared';
import { ISharedShowAppRepository } from '@shared/repositories/app/implementations/IShared';

import {
  TSharedObject,
  TSharedShowApiReturn,
  TSharedShowObject,
} from '@shared/@types/TShared';

export class SharedShowAppRepository<
  TRegister extends TSharedObject,
  TShowObject extends TSharedShowObject,
  TRelationsRegister extends TSharedObject = TSharedObject,
> implements
    ISharedShowAppRepository<TRegister, TShowObject, TRelationsRegister>
{
  private readonly apiProvider = new ApiProvider();

  constructor(
    private readonly baseURL: string,
    private readonly FromApi: ({
      registers,
    }: {
      registers: TRegister[];
    }) => TRegister[],
  ) {}

  public async execute(
    ShowDTO: ISharedShowDTO<TShowObject, TRegister, TRelationsRegister>,
  ): Promise<TSharedShowApiReturn<TRegister, TRelationsRegister>> {
    const apiResult = await this.apiProvider.execute<{
      data: TSharedShowApiReturn<TRegister, TRelationsRegister>;
    }>({
      method: 'get',
      url: `${this.baseURL}/${ShowDTO.register.uuid}`,
    });

    const [sanitizedRegister] = this.FromApi({
      registers: [apiResult.data.register],
    });

    const parsedResult = {
      ...apiResult.data,
      register: sanitizedRegister,
    };

    return parsedResult;
  }
}
