import { SharedAppServices } from '@shared/services/app/shared';

import { ProjectUserAppRepositories } from '@modules/projects/repositories/app/projectUser';

import {
  TProjectUserObject,
  TProjectUserShowObject,
  TProjectUserCreateObject,
  TProjectUserUpdateObject,
  TProjectUserDestroyObject,
} from '@modules/projects/@types/TProjectUser';
import { TUserObject } from '@modules/users/@types/TUser';

export class ProjectUserAppServices extends SharedAppServices<
  TProjectUserObject,
  TProjectUserShowObject,
  TProjectUserCreateObject,
  TProjectUserUpdateObject,
  TProjectUserDestroyObject,
  TUserObject
> {
  constructor() {
    const appRepositories = new ProjectUserAppRepositories();

    super(appRepositories);
  }
}
