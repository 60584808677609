import { Dispatch, SetStateAction } from 'react';

import { TProjectObject } from '@modules/projects/@types/TProject';
import { TProjectStageObject } from '@modules/projects/@types/TProjectStage';
import { TProjectSubStageObject } from '@modules/projects/@types/TProjectSubStage';

import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogContent,
  IconButton,
  CloseIcon,
  DialogWarnMessage,
  FormGridContainer,
  ConfirmButton,
  CancelButton,
} from './styles';

type TRelationsObject =
  | TProjectObject
  | TProjectStageObject
  | TProjectSubStageObject;

type TRemoveItemDialog<TRegister extends TRelationsObject> = {
  isOpen: boolean;
  confirmRegister?: TRegister;
  warnMessage?: string;
  setIsOpen: Dispatch<SetStateAction<{ isOpen: boolean }>>;
  handleConfirmFunction: (register: TRegister) => void;
};

export function RemoveItemDialog<TRegister extends TRelationsObject>({
  isOpen,
  confirmRegister,
  warnMessage,
  setIsOpen,
  handleConfirmFunction,
}: TRemoveItemDialog<TRegister>): JSX.Element {
  function handleClickInConfirm(register: TRegister) {
    handleConfirmFunction(register);
    setIsOpen({ isOpen: false });
  }

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen({ isOpen: false })}>
      <DialogHeader container>
        <DialogTitle>Deseja mesmo remover esse item?</DialogTitle>

        <IconButton
          onClick={() => setIsOpen({ isOpen: false })}
          style={{ width: '60px' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogHeader>

      <DialogContent>
        <DialogWarnMessage>{warnMessage}</DialogWarnMessage>

        <FormGridContainer spacing={1} mt={1}>
          <CancelButton onClick={() => setIsOpen({ isOpen: false })}>
            CANCELAR
          </CancelButton>
          <ConfirmButton
            onClick={() => handleClickInConfirm(confirmRegister as TRegister)}
          >
            REMOVER
          </ConfirmButton>
        </FormGridContainer>
      </DialogContent>
    </Dialog>
  );
}

RemoveItemDialog.defaultProps = {
  warnMessage: '',
  confirmRegister: undefined,
};
