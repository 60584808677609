import { ReactNode, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useProject } from '@modules/projects/hooks/project';

import { useProjectSubStage } from '@modules/projects/hooks/projectSubStage';
import { useSession } from '@modules/sessions/hooks/session';

import {
  BASE_URL as PROJECT_BASE_URL,
  STATUSES,
} from '@modules/projects/constants/project';
import { BASE_URL as PROJECT_SUB_STAGE_BASE_URL } from '@modules/projects/constants/projectSubStage';
import { BASE_URL as STAGE_NAME_BASE_URL } from '@modules/templates/constants/stageName';
import { BASE_URL as SETTING_BASE_URL } from '@modules/settings/constants/setting';
import { BASE_URL as USER_BASE_URL } from '@modules/users/constants/user';
import { BASE_URL as PROFILE_BASE_URL } from '@modules/sessions/constants/profile';

import LogoHenriqueGasparini from '@shared/assets/logoHenriqueGasparini.svg';
import noImage from '@shared/assets/noImage.png';

import { HasPermission } from '@shared/validators/permissionsValidator';
import { ALL_PERMISSION_KEYS } from '@shared/constants/shared';

import {
  Container,
  Dialog,
  DialogHeader,
  CloseIcon,
  DialogTitle,
  DialogContent,
  StageStatusExplanationContent,
  StageStatusExplanation,
  StageStatusName,
  Main,
  useTheme,
  ChevronLeftIcon,
  Divider,
  IconButton,
  InboxIcon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuIconStyled,
  InitialOfHeaderContent,
  HeaderTitle,
  NavigationBarTitle,
  DrawerContent,
  DrawerHeader,
  HeaderContent,
  LogoImg,
  DashboardOutlinedIcon,
  AccountTreeOutlinedIcon,
  GroupIcon,
  SocialMediaContent,
  ExitToApp,
  Instagram,
  Facebook,
  Tooltip,
  ProfileMenuContainer,
  ProfileImage,
  ProfileInfosContainer,
  ProfileName,
  ProfileEmail,
  SettingsIcon,
  SubtitlesIcon,
  ArrowBackIcon,
  ReopenProjectContainer,
  ReopenProjectBall,
} from './styles';

export const MENU = [
  { key: '/', label: 'Dashboard' },
  { key: `/${PROJECT_BASE_URL}/form`, label: 'Incluir Projeto' },
  { key: `/${USER_BASE_URL}`, label: 'Gerenciar Usuários' },
];

const STATUS_EXPLANATION = [
  { id: 1, status: STATUSES.TO_START, label: 'A Iniciar' },
  { id: 2, status: STATUSES.PENDING, label: 'Paralisado' },
  { id: 3, status: STATUSES.IN_PROGRESS, label: 'Em Andamento' },
  { id: 4, status: STATUSES.TO_VALIDATE, label: 'A Validar' },
  { id: 5, status: STATUSES.DONE, label: 'Concluído' },
];

const SUBTITLE_BASE_URL = 'subtitles';

export function Navigation({ children }: { children: ReactNode }): JSX.Element {
  const { signOut, logged_user } = useSession();
  const { registerShow: projectRegisterShow, clearState } = useProject();
  const { registerShow: projectSubStageRegisterShow } = useProjectSubStage();

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const MENU = [{ key: '/', label: 'Dashboard' }];

  if (
    HasPermission({ permission_keys: [ALL_PERMISSION_KEYS.project_create] })
  ) {
    MENU.push({ key: `/${PROJECT_BASE_URL}/form`, label: 'Incluir Projeto' });
  }

  if (HasPermission({ permission_keys: [ALL_PERMISSION_KEYS.users_show] })) {
    MENU.push({ key: `/${USER_BASE_URL}`, label: 'Gerenciar Usuários' });
  }

  function IconSiderBar({ menuText }: { menuText: string }): JSX.Element {
    const dicIcon: Record<string, JSX.Element> = {
      '/': <DashboardOutlinedIcon />,
      [`/${PROJECT_BASE_URL}/form`]: <AccountTreeOutlinedIcon />,
      [`/${SETTING_BASE_URL}`]: <SettingsIcon />,
      [`/${USER_BASE_URL}`]: <GroupIcon />,
      [`/${SUBTITLE_BASE_URL}`]: <SubtitlesIcon />,
    };

    return dicIcon[menuText] || <InboxIcon />;
  }

  const locationPage = useMemo(() => {
    const pagesDict: Record<string, string> = {
      '/': 'Dashboard',
      [`/${PROJECT_BASE_URL}/form`]: 'Projetos',
      [`/${PROJECT_BASE_URL}/${projectRegisterShow?.uuid}`]:
        'Gerenciar Projeto',
      [`/${PROJECT_SUB_STAGE_BASE_URL}/${projectSubStageRegisterShow?.uuid}`]:
        'Gerenciar Atividade',
      [`/${USER_BASE_URL}`]: 'Usuários',
      [`/${USER_BASE_URL}/form`]: 'Usuários',
      [`/${PROFILE_BASE_URL}`]: 'Perfil',
      [`/${SETTING_BASE_URL}`]: 'Configurações',
      [`/${STAGE_NAME_BASE_URL}`]: 'Nomes de Etapa e Atividade',
      [`/${STAGE_NAME_BASE_URL}/form`]: 'Nomes de Etapa e Atividade',
    };

    return { title: pagesDict[location?.pathname] || '' };
  }, [location, projectRegisterShow, projectSubStageRegisterShow]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function handleGoBack() {
    navigate(-1);
  }

  async function handleNavigate(path: string) {
    if (location.pathname !== path) navigate(path);
  }

  async function handleSignOut() {
    clearState();
    signOut();
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }
  function handleOpenModal() {
    setIsModalOpen(true);
  }

  return (
    <Container>
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogHeader container>
          <DialogTitle>Legendas</DialogTitle>

          <IconButton
            onClick={handleCloseModal}
            style={{ width: '55px', height: '55px' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogHeader>

        <DialogContent>
          {STATUS_EXPLANATION.map(stageExplanation => (
            <StageStatusExplanationContent key={stageExplanation.id}>
              <Tooltip
                title={`Projetos/Etapas/Atividades que estão com processo ${stageExplanation.label.toLowerCase()}`}
                arrow
                placement="bottom-start"
              >
                <StageStatusExplanation status={stageExplanation.status}>
                  <StageStatusName>{stageExplanation.label}</StageStatusName>
                </StageStatusExplanation>
              </Tooltip>
            </StageStatusExplanationContent>
          ))}

          <ReopenProjectContainer>
            <Tooltip
              title="Etapas ou Atividades que foram reabertas"
              arrow
              placement="bottom-start"
            >
              <ReopenProjectBall />
            </Tooltip>

            <Tooltip
              title="Etapas ou Atividades reabertas que foram concluídas novamente"
              arrow
              placement="bottom-start"
            >
              <ReopenProjectBall is_reopen />
            </Tooltip>
          </ReopenProjectContainer>
        </DialogContent>
      </Dialog>

      <HeaderContent
        open={open}
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
        }}
      >
        <InitialOfHeaderContent>
          <IconButton
            color="primary"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIconStyled />
          </IconButton>

          {location.pathname !== '/' && window.history.length > 2 && (
            <Tooltip title="Voltar">
              <IconButton
                color="primary"
                sx={{ marginRight: '36px' }}
                onClick={handleGoBack}
              >
                <ArrowBackIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          )}

          <LogoImg src={LogoHenriqueGasparini} alt="henrique gasparini logo" />
        </InitialOfHeaderContent>
        <HeaderTitle>{locationPage.title}</HeaderTitle>
        <SocialMediaContent>
          <Tooltip title="Facebook" arrow placement="bottom-start">
            <IconButton
              style={{
                alignSelf: 'center',
                justifySelf: 'right',
              }}
              component="a"
              href="https://www.facebook.com/arquiteturah/"
              target="_blank"
            >
              <Facebook />
            </IconButton>
          </Tooltip>

          <Tooltip title="Instagram" arrow placement="bottom-start">
            <IconButton
              style={{
                alignSelf: 'center',
                justifySelf: 'right',
              }}
              component="a"
              href="https://www.instagram.com/henriquegaspariniarquitetura/"
              target="_blank"
            >
              <Instagram />
            </IconButton>
          </Tooltip>

          <Tooltip title="Sair" arrow placement="bottom-start">
            <IconButton
              onClick={handleSignOut}
              style={{
                alignSelf: 'center',
                justifySelf: 'right',
              }}
            >
              <ExitToApp />
            </IconButton>
          </Tooltip>
        </SocialMediaContent>
      </HeaderContent>
      <DrawerContent variant="permanent" open={open}>
        <DrawerHeader style={{ justifyContent: 'space-between' }}>
          <NavigationBarTitle style={{ fontWeight: 600 }}>
            Menu
          </NavigationBarTitle>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? null : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <Tooltip key="profile" title="Perfil" arrow placement="right-start">
            <ListItem
              button
              onClick={() => {
                handleNavigate(`/${PROFILE_BASE_URL}`);
              }}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <ProfileMenuContainer>
                <ProfileImage
                  alt="Usuário Image"
                  src={logged_user?.image || noImage}
                />

                {open && (
                  <ProfileInfosContainer>
                    <ProfileName>{logged_user?.name}</ProfileName>
                    <ProfileEmail>{logged_user?.user_key}</ProfileEmail>
                  </ProfileInfosContainer>
                )}
              </ProfileMenuContainer>
            </ListItem>
          </Tooltip>
        </List>
        <List>
          {MENU.map(item => (
            <Tooltip
              key={item.key}
              title={item.label}
              arrow
              placement="right-start"
            >
              <ListItem button onClick={() => handleNavigate(item.key)}>
                <ListItemIcon
                  navigate={`${
                    location.pathname === item.key ||
                    location.pathname.includes(`${item.key}/`)
                  }`}
                >
                  <IconSiderBar menuText={item.key} />
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  navigate={`${
                    location.pathname === item.key ||
                    location.pathname.includes(`${item.key}/`)
                  }`}
                />
              </ListItem>
            </Tooltip>
          ))}
        </List>
        <Divider style={{ marginTop: 'auto' }} />
        <List>
          <Tooltip
            key="subtitles"
            title="Legendas"
            arrow
            placement="right-start"
          >
            <ListItem button onClick={() => handleOpenModal()}>
              <ListItemIcon>
                <IconSiderBar menuText="/subtitles" />
              </ListItemIcon>
              <ListItemText primary="Legendas" />
            </ListItem>
          </Tooltip>

          <Tooltip
            key="settings"
            title="Configurações"
            arrow
            placement="right-start"
          >
            <ListItem button onClick={() => handleNavigate('/settings')}>
              <ListItemIcon navigate={`${location.pathname === '/settings'}`}>
                <IconSiderBar menuText="/settings" />
              </ListItemIcon>
              <ListItemText
                primary="Configurações"
                navigate={`${location.pathname === '/settings'}`}
              />
            </ListItem>
          </Tooltip>
        </List>
      </DrawerContent>
      <Main component="main" sx={{ flexGrow: 1, p: 0 }}>
        {children}
      </Main>
    </Container>
  );
}
