import { ApiProvider } from '@shared/providers/api';

import { ISharedDestroyDTO } from '@shared/dtos/IShared';
import { ISharedDestroyAppRepository } from '@shared/repositories/app/implementations/IShared';

import {
  TSharedObject,
  TSharedValidatedApiReturn,
  TSharedDestroyObject,
} from '@shared/@types/TShared';

export class SharedDestroyAppRepository<
  TRegister extends TSharedObject,
  TDestroyObject extends TSharedDestroyObject,
> implements ISharedDestroyAppRepository<TRegister, TDestroyObject>
{
  private readonly apiProvider = new ApiProvider();

  constructor(private readonly baseURL: string) {}

  public async execute(
    DestroyDTO: ISharedDestroyDTO<TDestroyObject, TRegister>,
  ): Promise<TSharedValidatedApiReturn<TRegister>> {
    const apiResult = await this.apiProvider.execute<
      TSharedValidatedApiReturn<TRegister>
    >({
      method: 'delete',
      url: `${this.baseURL}`,
      data: { data: [DestroyDTO.register] },
    });

    return apiResult;
  }
}
