import { ISharedDestroyDTO } from '@shared/dtos/IShared';
import { ISharedDestroyAppRepository } from '@shared/repositories/app/implementations/IShared';
import { ISharedDestroyAppService } from '@shared/services/app/implementations/IShared';

import {
  TSharedObject,
  TSharedValidatedApiReturn,
  TSharedDestroyObject,
} from '@shared/@types/TShared';

export class SharedDestroyAppService<
  TRegister extends TSharedObject,
  TDestroyObject extends TSharedDestroyObject,
> implements ISharedDestroyAppService<TRegister, TDestroyObject>
{
  constructor(
    private readonly destroyAppRepository: ISharedDestroyAppRepository<
      TRegister,
      TDestroyObject
    >,
  ) {}

  public async execute(
    DestroyDTO: ISharedDestroyDTO<TDestroyObject, TRegister>,
  ): Promise<TSharedValidatedApiReturn<TRegister>> {
    const destroyAppRepositoryResult = await this.destroyAppRepository.execute(
      DestroyDTO,
    );

    return destroyAppRepositoryResult;
  }
}
