import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Theme, CSSObject } from '@material-ui/core/styles';
import MUITypography from '@material-ui/core/Typography';

import MuiAppBar, {
  AppBarProps as MuiAppBarProps,
} from '@material-ui/core/AppBar';
import MuiDrawer from '@material-ui/core/Drawer';
import styledMaterial from '@material-ui/core/styles/styled';
import Toolbar from '@material-ui/core/Toolbar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import ListItemIconStyled from '@material-ui/core/ListItemIcon';
import ListItemTextStyled from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import MUIGrid from '@material-ui/core/Grid';
import MUIBox from '@material-ui/core/Box';
import { STATUSES } from '@modules/projects/constants/project';

export { default as useTheme } from '@material-ui/core/styles/useTheme';
export { default as List } from '@material-ui/core/List';
export { default as Typography } from '@material-ui/core/Typography';
export { default as Divider } from '@material-ui/core/Divider';
export { default as Tooltip } from '@material-ui/core/Tooltip';
export { default as ListItem } from '@material-ui/core/ListItem';
export { default as ClickAwayListener } from '@material-ui/core/ClickAwayListener';
export { default as IconButton } from '@material-ui/core/IconButton';
export { default as ChevronLeftIcon } from '@material-ui/icons/ChevronLeft';
export { default as InboxIcon } from '@material-ui/icons/MoveToInbox';
export { default as Person } from '@material-ui/icons/Person';
export { default as PeopleOutlinedIcon } from '@material-ui/icons/PeopleOutlined';
export { default as DashboardOutlinedIcon } from '@material-ui/icons/DashboardOutlined';
export { default as AccountTreeOutlinedIcon } from '@material-ui/icons/AccountTreeOutlined';
export { default as SettingsIcon } from '@material-ui/icons/Settings';
export { default as GroupIcon } from '@material-ui/icons/Group';
export { default as SubtitlesIcon } from '@material-ui/icons/Subtitles';
export { default as Dialog } from '@material-ui/core/Dialog';
export { default as DialogTitle } from '@material-ui/core/DialogTitle';
export { default as DialogContent } from '@material-ui/core/DialogContent';
export { default as CloseIcon } from '@material-ui/icons/Close';
export { default as ArrowBackIcon } from '@material-ui/icons/ArrowBack';

const drawerWidth = 240;

interface IHeaderContent extends MuiAppBarProps {
  open?: boolean;
}

export const Container = styled(MUIBox).attrs({
  sx: { display: 'flex' },
})``;

export const Main = styled(MUIBox)`
  display: flex;
  height: 100vh;
  padding-top: 4rem;

  & > :first-child {
    width: 100%;
  }
`;

export const DialogHeader = styled(MUIGrid)`
  display: flex;
  justify-content: space-between;
`;

export const StageStatusExplanationContent = styled(MUIGrid)``;

type TStageStatus = {
  status: STATUSES;
};

export const StageStatusExplanation = styled(MUIGrid)<TStageStatus>`
  width: 180px;
  height: 60px;

  border-radius: 0.5rem;
  margin-bottom: 1rem;
  margin-right: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme, status }) => theme.palette[status].main};
  color: white;
`;

export const StageStatusName = styled(MUITypography).attrs({ variant: 'h5' })``;

type TReopenProject = {
  is_reopen?: boolean;
};

export const ReopenProjectContainer = styled(MUIGrid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ReopenProjectBall = styled(MUIGrid)<TReopenProject>`
  width: 24px;
  height: 24px;
  border: 5px solid
    ${props =>
      props.is_reopen
        ? props.theme.palette.grey_scale_2.main
        : props.theme.palette.done.main};
  border-radius: 50%;
  background: ${props =>
    props.is_reopen
      ? props.theme.palette.is_reopen_and_concluded.main
      : props.theme.palette.done.main};
  margin-bottom: 10px;
`;

export const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

export const MenuIconStyled = styled(MenuIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const DrawerHeader = styledMaterial('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const HeaderContent = styledMaterial(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<IHeaderContent>(({ theme, open }) => ({
  background: `${theme.palette.secondary.main}`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const NavigationBarTitle = styled(MUITypography).attrs({
  variant: 'h5',
})``;

export const HeaderTitle = styled(MUITypography).attrs({
  variant: 'h5',
})`
  color: ${({ theme }) => theme.palette.input_text_color.contrastText};

  align-self: center;
  justify-self: center;
`;

export const DrawerContent = styledMaterial(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const InitialOfHeaderContent = styled(Toolbar)``;

export const LogoImg = styled.img`
  object-fit: contain;
  display: flex;
  justify-content: flex-start;

  width: 120px;
`;

export const LinkButton = styled(Link)`
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;

export const ProfileMenuContainer = styled(MUIGrid).attrs({
  container: true,
})`
  width: 100%;

  display: flex;
`;

export const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  margin-right: 8px;
`;

export const ProfileInfosContainer = styled(MUIGrid)`
  display: flex;
  flex: 1;
  flex-direction: column;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const ProfileName = styled(MUITypography)`
  font-weight: 500;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const ProfileEmail = styled(MUITypography)`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.grey_scale_1.main};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const SocialMediaContent = styled(MUIGrid).attrs({
  container: true,
})`
  display: grid;
  grid-template-columns: 40px 40px 80px 80px;
  justify-content: right;
`;

export const ExitToApp = styled(ExitToAppIcon).attrs({
  fontSize: 'large',
})`
  color: ${({ theme }) => theme.palette.primary.main};

  cursor: pointer;

  &:hover {
    opacity: 90%;
  }
`;

export const Instagram = styled(InstagramIcon).attrs({
  fontSize: 'large',
})`
  color: ${({ theme }) => theme.palette.pending.main};
`;

export const Facebook = styled(FacebookIcon).attrs({
  fontSize: 'large',
})`
  color: ${({ theme }) => theme.palette.accent_blue.main};
`;

interface IListItemText {
  navigate?: string;
}

interface IListItemIcon {
  navigate?: string;
}

export const ListItemText = styled(ListItemTextStyled)<IListItemText>`
  color: ${props =>
    props.navigate === 'true' ? props.theme.palette.primary.main : null};
`;

export const ListItemIcon = styled(ListItemIconStyled)<IListItemIcon>`
  color: ${props =>
    props.navigate === 'true' ? props.theme.palette.primary.main : null};
`;
