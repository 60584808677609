import styled from 'styled-components';
import MUIGrid from '@material-ui/core/Grid';
import MUIIconButton from '@material-ui/core/IconButton';

export { TableList } from '@shared/components/TableList';
export { default as Dialog } from '@material-ui/core/Dialog';
export { default as DialogTitle } from '@material-ui/core/DialogTitle';
export { default as DialogContent } from '@material-ui/core/DialogContent';
export { default as CloseIcon } from '@material-ui/icons/Close';
export { ProjectUserFormContent } from '@modules/projects/pages/ProjectUser/Form/Content';

export const Container = styled(MUIGrid).attrs({
  container: true,
  component: 'main',
})`
  width: 100%;
`;

export const IconButton = styled(MUIIconButton)`
  width: 55px;
  height: 55px;
`;

export const DialogHeader = styled(MUIGrid)`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.grey_scale_4.main};
`;

export const ContentFormContainer = styled(MUIGrid)`
  width: 140%;
`;
