import { Routes, Route, Navigate } from 'react-router-dom';

import { useSession } from '@modules/sessions/hooks/session';

import { StageNameList } from '@modules/templates/pages/StageName/List';
import { StageNameForm } from '@modules/templates/pages/StageName/Form';

import { NotFound } from '@shared/pages/NotFound';
import { Forbidden } from '@shared/pages/Forbidden';

export function StageNameRoutes(): JSX.Element {
  const { logged_user, sessionLoading } = useSession();

  return (
    <Routes>
      <Route
        path="/"
        element={
          logged_user?.is_admin ? (
            <StageNameList />
          ) : (
            !sessionLoading && <Forbidden />
          )
        }
      />
      <Route
        path="/form"
        element={
          logged_user?.is_admin ? (
            <StageNameForm />
          ) : (
            !sessionLoading && <Forbidden />
          )
        }
      />

      <Route path="/not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  );
}
