import { useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import {
  createValidatorSchema,
  updateValidatorSchema,
} from '@modules/templates/validators/stageName';

import { ToCreate, ToUpdate } from '@modules/templates/sanitizers/stageName';

import { stageNamesOptions } from '@modules/templates/helpers/stageName';

import { BASE_URL } from '@modules/templates/constants/stageName';

import { useSession } from '@modules/sessions/hooks/session';
import { useStageName } from '@modules/templates/hooks/stageName';

import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import { DefaultFormContainer } from '@shared/components/Form/DefaultFormContainer';
import {
  TStageNameCreateObject,
  TStageNameUpdateObject,
} from '@modules/templates/@types/TStageName';

import {
  FormCancelButton,
  FormConfirmButton,
  FormContainer,
  FormGridContainer,
  FormTextInput,
  FormSelector,
  ContentFormContainer,
} from './styles';

export function StageNameFormContent(): JSX.Element {
  const { logged_user, logged_branch } = useSession();
  const {
    create,
    update,
    registerShow,
    showLoading,
    formLoading,
    validations,
  } = useStageName();

  const navigate = useNavigate();
  const location = useLocation();

  const canEdit = useMemo(() => {
    const { state } = location;

    return !state || !registerShow || state?.creating || state?.updating;
  }, [location, registerShow]);

  const formRef = useRef<FormHandles>(null);

  const validatorSchema = registerShow?.uuid
    ? updateValidatorSchema
    : createValidatorSchema;

  function handleSanitize(
    register: TStageNameCreateObject | TStageNameUpdateObject,
  ): TStageNameCreateObject | TStageNameUpdateObject {
    const parsedRegister = registerShow?.uuid
      ? ToUpdate({
          register: { ...register, uuid: registerShow.uuid },
          logged_user: logged_user as TLoggedUser,
          logged_branch: logged_branch as TLoggedBranch,
        })
      : ToCreate({
          register,
          logged_user: logged_user as TLoggedUser,
          logged_branch: logged_branch as TLoggedBranch,
        });

    return parsedRegister;
  }

  function handleSubmit(
    register: TStageNameCreateObject | TStageNameUpdateObject,
  ): void {
    function onSuccess() {
      if (location.pathname === `/${BASE_URL}/form`) navigate(-1);
    }

    if (registerShow?.uuid)
      update({ register: { ...register, uuid: registerShow.uuid }, onSuccess });
    else create({ register, onSuccess });
  }

  function handleCancel(): void {
    navigate(-1);
  }

  useEffect(() => {
    formRef.current?.setErrors(validations || {});
  }, [validations]);

  return (
    <FormContainer<TStageNameCreateObject | TStageNameUpdateObject>
      formRef={formRef}
      validatorSchema={validatorSchema}
      sanitizer={handleSanitize}
      onSubmit={handleSubmit}
      initialData={registerShow}
    >
      <DefaultFormContainer style={{ width: '75%' }}>
        <ContentFormContainer>
          <FormGridContainer>
            <FormGridContainer gap={1}>
              <FormTextInput
                label="Nome"
                name="name"
                required
                disabled={!canEdit || formLoading}
                showLoading={showLoading}
                gridProps={{ xs: 6, sm: 4 }}
              />
              <FormSelector
                label="Tipo"
                name="type"
                idColumn="key"
                nameColumn="label"
                options={stageNamesOptions}
                required
                disabled={!canEdit || formLoading}
                showLoading={showLoading}
                gridProps={{ xs: 6, sm: 4 }}
              />
            </FormGridContainer>

            <FormGridContainer gap={1}>
              <FormCancelButton
                loading={showLoading || formLoading}
                gridProps={{ xs: 6, sm: 4 }}
                onClick={handleCancel}
              >
                CANCELAR
              </FormCancelButton>
              <FormConfirmButton
                loading={showLoading || formLoading}
                gridProps={{ xs: 6, sm: 4 }}
                disabled={!canEdit || formLoading}
              >
                SALVAR
              </FormConfirmButton>
            </FormGridContainer>
          </FormGridContainer>
        </ContentFormContainer>
      </DefaultFormContainer>
    </FormContainer>
  );
}
