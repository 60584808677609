import { SharedAppRepositories } from '@shared/repositories/app/shared';
import { ISharedFormDataAppRepository } from '@shared/repositories/app/implementations/IShared';

import { FromApi } from '@modules/projects/sanitizers/projectSubStage';

import { BASE_URL } from '@modules/projects/constants/projectSubStage';

import {
  TProjectSubStageObject,
  TProjectSubStageShowObject,
  TProjectSubStageCreateObject,
  TProjectSubStageUpdateObject,
  TProjectSubStageDestroyObject,
  TProjectSubStageRelations,
} from '@modules/projects/@types/TProjectSubStage';
import { ProjectSubStageFormDataAppRepository } from './formData';

export class ProjectSubStageAppRepositories extends SharedAppRepositories<
  TProjectSubStageObject,
  TProjectSubStageShowObject,
  TProjectSubStageCreateObject,
  TProjectSubStageUpdateObject,
  TProjectSubStageDestroyObject,
  TProjectSubStageRelations
> {
  constructor() {
    super(BASE_URL, FromApi);

    this.formDataAppRepository = new ProjectSubStageFormDataAppRepository(
      BASE_URL,
    ) as unknown as ISharedFormDataAppRepository<TProjectSubStageRelations>;
  }
}
