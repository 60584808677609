import { ISharedUpdateDTO } from '@shared/dtos/IShared';
import { ISharedUpdateAppRepository } from '@shared/repositories/app/implementations/IShared';
import { ISharedUpdateAppService } from '@shared/services/app/implementations/IShared';

import {
  TSharedObject,
  TSharedValidatedApiReturn,
  TSharedUpdateObject,
} from '@shared/@types/TShared';

export class SharedUpdateAppService<
  TRegister extends TSharedObject,
  TUpdateObject extends TSharedUpdateObject,
> implements ISharedUpdateAppService<TRegister, TUpdateObject>
{
  constructor(
    private readonly updateAppRepository: ISharedUpdateAppRepository<
      TRegister,
      TUpdateObject
    >,
  ) {}

  public async execute(
    UpdateDTO: ISharedUpdateDTO<TUpdateObject, TRegister>,
  ): Promise<TSharedValidatedApiReturn<TRegister>> {
    const updateAppRepositoryResult = await this.updateAppRepository.execute(
      UpdateDTO,
    );

    return updateAppRepositoryResult;
  }
}
