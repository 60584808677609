import { BASE_URL as TEMPLATE_BASE_URL } from '@modules/templates/constants/template';
import { BASE_URL as TEMPLATE_STAGE_BASE_URL } from '@modules/templates/constants/templateStage';
import { BASE_URL as TEMPLATE_SUB_STAGE_BASE_URL } from '@modules/templates/constants/templateSubStage';
import { BASE_URL as PROJECT_BASE_URL } from '@modules/projects/constants/project';
import { BASE_URL as PROJECT_STAGE_BASE_URL } from '@modules/projects/constants/projectStage';
import { BASE_URL as PROJECT_SUB_STAGE_BASE_URL } from '@modules/projects/constants/projectSubStage';
import { BASE_URL as PROJECT_USER_BASE_URL } from '@modules/projects/constants/projectUser';
import { BASE_URL as PROJECT_MESSAGE_BASE_URL } from '@modules/projects/constants/projectMessage';
import { BASE_URL as STAGE_NAME_BASE_URL } from '@modules/templates/constants/stageName';
import { BASE_URL as USER_BASE_URL } from '@modules/users/constants/user';

export const APP_SHORT_NAME = 'Plataforma H';
export const APP_KEY_NAME = 'plataforma_h';
export const APP_COPYRIGHT_NAME = 'Facile Sistemas';

export const PER_PAGE = 50;
export const PER_PAGE_SELECTOR = 20;

export const ADMIN_ROLE = `${APP_KEY_NAME}.admin`;
export const MANAGER_ROLE = `${APP_KEY_NAME}.manager`;

export const MAX_INTEGER_SIZE = 9;
export const MAX_INTEGER_VALUE = 999999999;

export const STATUS_SUCCESS = 200;
export const STATUS_CREATED = 201;

export const PERMISSION_KEYS: string[] = [];

export const SHOW_METHOD = ':uuid.get';
export const LIST_METHOD = 'get';
export const CREATE_METHOD = 'post';
export const UPDATE_METHOD = 'put';
export const DESTROY_METHOD = 'delete';

export const ALL_PERMISSION_KEYS = {
  templates_update: `${APP_KEY_NAME}.${TEMPLATE_BASE_URL}.${UPDATE_METHOD}`,
  templates_create: `${APP_KEY_NAME}.${TEMPLATE_BASE_URL}.${CREATE_METHOD}`,
  templates_delete: `${APP_KEY_NAME}.${TEMPLATE_BASE_URL}.${DESTROY_METHOD}`,
  templates_show: `${APP_KEY_NAME}.${TEMPLATE_BASE_URL}/${SHOW_METHOD}`,
  templates_list: `${APP_KEY_NAME}.${TEMPLATE_BASE_URL}.${LIST_METHOD}`,

  template_stages_update: `${APP_KEY_NAME}.${TEMPLATE_STAGE_BASE_URL}.${UPDATE_METHOD}`,
  template_stages_create: `${APP_KEY_NAME}.${TEMPLATE_STAGE_BASE_URL}.${CREATE_METHOD}`,
  template_stages_delete: `${APP_KEY_NAME}.${TEMPLATE_STAGE_BASE_URL}.${DESTROY_METHOD}`,
  template_stages_show: `${APP_KEY_NAME}.${TEMPLATE_STAGE_BASE_URL}/${SHOW_METHOD}`,
  template_stages_list: `${APP_KEY_NAME}.${TEMPLATE_STAGE_BASE_URL}.${LIST_METHOD}`,

  template_sub_stages_update: `${APP_KEY_NAME}.${TEMPLATE_SUB_STAGE_BASE_URL}.${UPDATE_METHOD}`,
  template_sub_stages_create: `${APP_KEY_NAME}.${TEMPLATE_SUB_STAGE_BASE_URL}.${CREATE_METHOD}`,
  template_sub_stages_delete: `${APP_KEY_NAME}.${TEMPLATE_SUB_STAGE_BASE_URL}.${DESTROY_METHOD}`,
  template_sub_stages_show: `${APP_KEY_NAME}.${TEMPLATE_SUB_STAGE_BASE_URL}/${SHOW_METHOD}`,
  template_sub_stages_list: `${APP_KEY_NAME}.${TEMPLATE_SUB_STAGE_BASE_URL}.${LIST_METHOD}`,

  project_update: `${APP_KEY_NAME}.${PROJECT_BASE_URL}.${UPDATE_METHOD}`,
  project_create: `${APP_KEY_NAME}.${PROJECT_BASE_URL}.${CREATE_METHOD}`,
  project_delete: `${APP_KEY_NAME}.${PROJECT_BASE_URL}.${DESTROY_METHOD}`,
  project_show: `${APP_KEY_NAME}.${PROJECT_BASE_URL}/${SHOW_METHOD}`,
  project_list: `${APP_KEY_NAME}.${PROJECT_BASE_URL}.${LIST_METHOD}`,

  project_stage_update: `${APP_KEY_NAME}.${PROJECT_STAGE_BASE_URL}.${UPDATE_METHOD}`,
  project_stage_create: `${APP_KEY_NAME}.${PROJECT_STAGE_BASE_URL}.${CREATE_METHOD}`,
  project_stage_delete: `${APP_KEY_NAME}.${PROJECT_STAGE_BASE_URL}.${DESTROY_METHOD}`,
  project_stage_show: `${APP_KEY_NAME}.${PROJECT_STAGE_BASE_URL}/${SHOW_METHOD}`,
  project_stage_list: `${APP_KEY_NAME}.${PROJECT_STAGE_BASE_URL}.${LIST_METHOD}`,

  project_sub_stage_update: `${APP_KEY_NAME}.${PROJECT_SUB_STAGE_BASE_URL}.${UPDATE_METHOD}`,
  project_sub_stage_create: `${APP_KEY_NAME}.${PROJECT_SUB_STAGE_BASE_URL}.${CREATE_METHOD}`,
  project_sub_stage_delete: `${APP_KEY_NAME}.${PROJECT_SUB_STAGE_BASE_URL}.${DESTROY_METHOD}`,
  project_sub_stage_show: `${APP_KEY_NAME}.${PROJECT_SUB_STAGE_BASE_URL}/${SHOW_METHOD}`,
  project_sub_stage_list: `${APP_KEY_NAME}.${PROJECT_SUB_STAGE_BASE_URL}.${LIST_METHOD}`,

  project_message_update: `${APP_KEY_NAME}.${PROJECT_MESSAGE_BASE_URL}.${UPDATE_METHOD}`,
  project_message_create: `${APP_KEY_NAME}.${PROJECT_MESSAGE_BASE_URL}.${CREATE_METHOD}`,
  project_message_delete: `${APP_KEY_NAME}.${PROJECT_MESSAGE_BASE_URL}.${DESTROY_METHOD}`,
  project_message_show: `${APP_KEY_NAME}.${PROJECT_MESSAGE_BASE_URL}/${SHOW_METHOD}`,
  project_message_list: `${APP_KEY_NAME}.${PROJECT_MESSAGE_BASE_URL}.${LIST_METHOD}`,

  project_users_update: `${APP_KEY_NAME}.${PROJECT_USER_BASE_URL}.${UPDATE_METHOD}`,
  project_users_create: `${APP_KEY_NAME}.${PROJECT_USER_BASE_URL}.${CREATE_METHOD}`,
  project_users_delete: `${APP_KEY_NAME}.${PROJECT_USER_BASE_URL}.${DESTROY_METHOD}`,
  project_users_show: `${APP_KEY_NAME}.${PROJECT_USER_BASE_URL}/${SHOW_METHOD}`,
  project_users_list: `${APP_KEY_NAME}.${PROJECT_USER_BASE_URL}.${LIST_METHOD}`,

  stage_names_update: `${APP_KEY_NAME}.${STAGE_NAME_BASE_URL}.${UPDATE_METHOD}`,
  stage_names_create: `${APP_KEY_NAME}.${STAGE_NAME_BASE_URL}.${CREATE_METHOD}`,
  stage_names_delete: `${APP_KEY_NAME}.${STAGE_NAME_BASE_URL}.${DESTROY_METHOD}`,
  stage_names_show: `${APP_KEY_NAME}.${STAGE_NAME_BASE_URL}/${SHOW_METHOD}`,
  stage_names_list: `${APP_KEY_NAME}.${STAGE_NAME_BASE_URL}.${LIST_METHOD}`,

  users_update: `${APP_KEY_NAME}.${USER_BASE_URL}.${UPDATE_METHOD}`,
  users_create: `${APP_KEY_NAME}.${USER_BASE_URL}.${CREATE_METHOD}`,
  users_delete: `${APP_KEY_NAME}.${USER_BASE_URL}.${DESTROY_METHOD}`,
  users_show: `${APP_KEY_NAME}.${USER_BASE_URL}/${SHOW_METHOD}`,
  users_list: `${APP_KEY_NAME}.${USER_BASE_URL}.${LIST_METHOD}`,
};

export const ADMIN_PERMISSION_KEYS = {
  [ALL_PERMISSION_KEYS.templates_update]: true,
  [ALL_PERMISSION_KEYS.templates_list]: true,
  [ALL_PERMISSION_KEYS.templates_create]: true,
  [ALL_PERMISSION_KEYS.templates_delete]: true,

  [ALL_PERMISSION_KEYS.template_stages_update]: true,
  [ALL_PERMISSION_KEYS.template_stages_list]: true,
  [ALL_PERMISSION_KEYS.template_stages_create]: true,
  [ALL_PERMISSION_KEYS.template_stages_delete]: true,

  [ALL_PERMISSION_KEYS.template_sub_stages_update]: true,
  [ALL_PERMISSION_KEYS.template_sub_stages_list]: true,
  [ALL_PERMISSION_KEYS.template_sub_stages_create]: true,
  [ALL_PERMISSION_KEYS.template_sub_stages_delete]: true,
  [ALL_PERMISSION_KEYS.template_sub_stages_show]: true,

  [ALL_PERMISSION_KEYS.project_update]: true,
  [ALL_PERMISSION_KEYS.project_delete]: true,

  [ALL_PERMISSION_KEYS.project_stage_update]: true,
  [ALL_PERMISSION_KEYS.project_stage_delete]: true,

  [ALL_PERMISSION_KEYS.project_message_delete]: true,
  [ALL_PERMISSION_KEYS.project_message_update]: true,

  [ALL_PERMISSION_KEYS.project_sub_stage_delete]: true,
};

export const MANAGER_PERMISSION_KEYS = {
  [ALL_PERMISSION_KEYS.templates_show]: true,

  [ALL_PERMISSION_KEYS.project_create]: true,

  [ALL_PERMISSION_KEYS.project_stage_create]: true,

  [ALL_PERMISSION_KEYS.project_sub_stage_create]: true,

  [ALL_PERMISSION_KEYS.stage_names_delete]: true,
  [ALL_PERMISSION_KEYS.stage_names_update]: true,
  [ALL_PERMISSION_KEYS.stage_names_create]: true,
  [ALL_PERMISSION_KEYS.stage_names_list]: true,

  [ALL_PERMISSION_KEYS.users_delete]: true,
  [ALL_PERMISSION_KEYS.users_update]: true,
  [ALL_PERMISSION_KEYS.users_create]: true,
  [ALL_PERMISSION_KEYS.users_list]: true,
  [ALL_PERMISSION_KEYS.users_show]: true,

  [ALL_PERMISSION_KEYS.project_users_delete]: true,
  [ALL_PERMISSION_KEYS.project_users_update]: true,
  [ALL_PERMISSION_KEYS.project_users_create]: true,
  [ALL_PERMISSION_KEYS.project_users_list]: true,
};

export const BY_PASS_PERMISSION_KEYS = {} as Record<string, boolean>;

export const SUBPRODUCTS_PERMISSIONS: {
  related_permission_keys: string[];
  subproduct_key: string;
  name: string;
}[] = [];

export const ERROR_CODES = [
  'E_CANT_CONNECT',
  'E_PASSWORD_MISMATCH',
  'E_LIMIT_ACCESS',
  'E_UNAUTHORIZED_ACCESS',
  'E_VALIDATION_FAILED',
  'E_JWT_TOKEN_EXPIRED',
  'E_INVALID_JWT_REFRESH_TOKEN',
  'E_INVALID_JWT_TOKEN',
  'E_ROUTE_NOT_FOUND',
  'E_UNRECOGNIZED',
  'E_HAS_RELATIONSHIP',
  'E_CONFIG',
  'E_UNIQUE',
  'E_UNBOOTED',
  'E_DATABASE',
  'E_HAS_RELATION',
  'E_RELATION_FAILED',
  'E_INVALID_JWT_TOKEN',
  'E_INVALID_JSON',
  'E_INVALID_FIELD',
  'E_INVALID_RELATION',
] as const;

export enum SORT_TYPES {
  asc = 'asc',
  desc = 'desc',
}

export enum SEARCH_TYPES {
  normal = 'normal',
  range = 'range',
  relation = 'relation',
}
