import { FromBaseApi } from '@shared/sanitizers/shared';

import {
  ISharedAppRepositories,
  ISharedCreateAppRepository,
  ISharedDestroyAppRepository,
  ISharedFormDataAppRepository,
  ISharedListAppRepository,
  ISharedShowAppRepository,
  ISharedUpdateAppRepository,
} from '@shared/repositories/app/implementations/IShared';
import {
  ISharedCreateDTO,
  ISharedDestroyDTO,
  ISharedFormDataDTO,
  ISharedListDTO,
  ISharedShowDTO,
  ISharedUpdateDTO,
} from '@shared/dtos/IShared';

import {
  TSharedCreateObject,
  TSharedDestroyObject,
  TSharedObject,
  TSharedPaginatedList,
  TSharedShowApiReturn,
  TSharedShowObject,
  TSharedUpdateObject,
  TSharedValidatedApiReturn,
} from '@shared/@types/TShared';

import { SharedFormDataAppRepository } from './formData';
import { SharedShowAppRepository } from './show';
import { SharedListAppRepository } from './list';
import { SharedCreateAppRepository } from './create';
import { SharedUpdateAppRepository } from './update';
import { SharedDestroyAppRepository } from './destroy';

export class SharedAppRepositories<
  TRegister extends TSharedObject,
  TShowObject extends TSharedShowObject,
  TCreateObject extends TSharedCreateObject,
  TUpdateObject extends TSharedUpdateObject,
  TDestroyObject extends TSharedDestroyObject,
  TRelationsRegister extends TSharedObject = TSharedObject,
> implements
    ISharedAppRepositories<
      TRegister,
      TShowObject,
      TCreateObject,
      TUpdateObject,
      TDestroyObject,
      TRelationsRegister
    >
{
  public formDataAppRepository: ISharedFormDataAppRepository<TRelationsRegister>;

  public readonly showAppRepository: ISharedShowAppRepository<
    TRegister,
    TShowObject,
    TRelationsRegister
  >;

  public readonly listAppRepository: ISharedListAppRepository<TRegister>;

  public readonly createAppRepository: ISharedCreateAppRepository<
    TRegister,
    TCreateObject
  >;

  public readonly updateAppRepository: ISharedUpdateAppRepository<
    TRegister,
    TUpdateObject
  >;

  public readonly destroyAppRepository: ISharedDestroyAppRepository<
    TRegister,
    TDestroyObject
  >;

  constructor(
    private readonly baseURL: string,
    private readonly FromApi?: ({
      registers,
    }: {
      registers: TRegister[];
    }) => TRegister[],
    private readonly FromCreateApi?: ({
      registers,
    }: {
      registers: TRegister[];
    }) => TRegister[],
  ) {
    this.formDataAppRepository =
      new SharedFormDataAppRepository<TRelationsRegister>(this.baseURL);
    this.showAppRepository = new SharedShowAppRepository<
      TRegister,
      TShowObject,
      TRelationsRegister
    >(this.baseURL, FromApi || FromBaseApi);
    this.listAppRepository = new SharedListAppRepository<TRegister>(
      this.baseURL,
      FromApi || FromBaseApi,
    );
    this.createAppRepository = new SharedCreateAppRepository<
      TRegister,
      TCreateObject
    >(this.baseURL, FromCreateApi || FromApi || FromBaseApi);
    this.updateAppRepository = new SharedUpdateAppRepository<
      TRegister,
      TUpdateObject
    >(this.baseURL, FromApi || FromBaseApi);
    this.destroyAppRepository = new SharedDestroyAppRepository<
      TRegister,
      TDestroyObject
    >(this.baseURL);
  }

  public async formData(
    FormDataDTO: ISharedFormDataDTO<TRelationsRegister>,
  ): Promise<Record<string, TRelationsRegister[]>> {
    const formDataAppRepositoryResult =
      await this.formDataAppRepository.execute(FormDataDTO);

    return formDataAppRepositoryResult;
  }

  public async show(
    ShowDTO: ISharedShowDTO<TShowObject, TRegister, TRelationsRegister>,
  ): Promise<TSharedShowApiReturn<TRegister, TRelationsRegister>> {
    const showAppRepositoryResult = await this.showAppRepository.execute(
      ShowDTO,
    );

    return showAppRepositoryResult;
  }

  public async list(
    ListDTO: ISharedListDTO<TRegister>,
  ): Promise<TSharedPaginatedList<TRegister>> {
    const listAppRepositoryResult = await this.listAppRepository.execute(
      ListDTO,
    );

    return listAppRepositoryResult;
  }

  public async create(
    CreateDTO: ISharedCreateDTO<TCreateObject, TRegister>,
  ): Promise<TSharedValidatedApiReturn<TRegister>> {
    const createAppRepositoryResult = await this.createAppRepository.execute(
      CreateDTO,
    );

    return createAppRepositoryResult;
  }

  public async update(
    UpdateDTO: ISharedUpdateDTO<TUpdateObject, TRegister>,
  ): Promise<TSharedValidatedApiReturn<TRegister>> {
    const updateAppRepositoryResult = await this.updateAppRepository.execute(
      UpdateDTO,
    );

    return updateAppRepositoryResult;
  }

  public async destroy(
    DestroyDTO: ISharedDestroyDTO<TDestroyObject, TRegister>,
  ): Promise<TSharedValidatedApiReturn<TRegister>> {
    const destroyAppRepositoryResult = await this.destroyAppRepository.execute(
      DestroyDTO,
    );

    return destroyAppRepositoryResult;
  }
}
