import { useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import { createValidatorSchema } from '@modules/users/validators/user';

import { setFormData } from '@shared/utils/form';

import { ToCreate } from '@modules/users/sanitizers/user';

import { BASE_URL } from '@modules/users/constants/user';

import { useSession } from '@modules/sessions/hooks/session';
import { useUser } from '@modules/users/hooks/user';

import { TUserObject, TUserCreateObject } from '@modules/users/@types/TUser';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import { DefaultFormContainer } from '@shared/components/Form/DefaultFormContainer';

import {
  ContentFormContainer,
  FormCancelButton,
  FormConfirmButton,
  FormContainer,
  FormGridContainer,
  FormSelector,
  FormTextInput,
} from './styles';

export function UserFormContent(): JSX.Element {
  const { logged_user, logged_branch } = useSession();
  const { create, formInitialData, showLoading, formLoading, validations } =
    useUser();

  const navigate = useNavigate();
  const location = useLocation();

  const canEdit = useMemo(() => {
    const { state } = location;

    return !state || state?.creating || state?.updating;
  }, [location]);

  const formRef = useRef<FormHandles>(null);

  const validatorSchema = createValidatorSchema;

  function handleSanitize(register: TUserCreateObject): TUserCreateObject {
    const parsedRegister = ToCreate({
      register,
      logged_user: logged_user as TLoggedUser,
      logged_branch: logged_branch as TLoggedBranch,
    });

    return parsedRegister;
  }

  function handleSubmit(register: TUserCreateObject): void {
    function onSuccess() {
      if (location.pathname === `/${BASE_URL}/form`) navigate(-1);
    }

    create({ register, onSuccess });
  }

  function handleCancel(): void {
    navigate(-1);
  }

  function handleChangeUser(newValue: TUserObject | null) {
    const formData = {
      name: newValue?.name || '',
      image: newValue?.image || '',
      user_key: newValue?.user_key || '',
    };

    setFormData({ formRef, formData });
  }

  useEffect(() => {
    formRef.current?.setErrors(validations || {});
  }, [validations]);

  return (
    <FormContainer<TUserCreateObject>
      formRef={formRef}
      validatorSchema={validatorSchema}
      sanitizer={handleSanitize}
      onSubmit={handleSubmit}
    >
      <DefaultFormContainer style={{ width: '65%' }}>
        <ContentFormContainer>
          <FormGridContainer gap={1}>
            <FormSelector<TUserObject>
              label="Selecione um Usuário"
              name="uuid"
              idColumn="uuid"
              nameColumn="name"
              options={
                (formInitialData?.code_auth_users || []) as TUserObject[]
              }
              onChange={handleChangeUser}
              showLoading={showLoading}
              gridProps={{ xs: 6, sm: 4 }}
            />
            <FormTextInput
              label="Imagem"
              name="image"
              disabled
              showLoading={showLoading}
              gridProps={{ xs: 6, sm: 4 }}
            />
          </FormGridContainer>

          <FormGridContainer gap={1}>
            <FormTextInput
              label="Nome"
              name="name"
              required
              disabled
              showLoading={showLoading}
              gridProps={{ xs: 6, sm: 4 }}
            />
            <FormTextInput
              label="Email"
              name="user_key"
              required
              disabled
              showLoading={showLoading}
              gridProps={{ xs: 6, sm: 4 }}
            />
          </FormGridContainer>
          <FormGridContainer gap={1}>
            <FormCancelButton
              loading={showLoading || formLoading}
              gridProps={{ xs: 6, sm: 4 }}
              onClick={handleCancel}
            >
              CANCELAR
            </FormCancelButton>
            <FormConfirmButton
              loading={showLoading || formLoading}
              gridProps={{ xs: 6, sm: 4 }}
              disabled={!canEdit || formLoading}
            >
              SALVAR
            </FormConfirmButton>
          </FormGridContainer>
        </ContentFormContainer>
      </DefaultFormContainer>
    </FormContainer>
  );
}
