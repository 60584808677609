import { useState } from 'react';
import { useSession } from '@modules/sessions/hooks/session';

import {
  Page,
  Container,
  Paper,
  Tabs,
  Tab,
  SwipeableContainer,
  SettingContent,
  SettingCodeAuth,
} from './styles';

export function Setting(): JSX.Element {
  const [tabIndex, setTabIndex] = useState(0);
  const { logged_user } = useSession();

  function handleChangeIndex(newTabIndex: number) {
    setTabIndex(newTabIndex);
  }

  return (
    <Page title="Configurações">
      <Container>
        <Paper>
          <Tabs
            value={tabIndex}
            onChange={(_, index) => handleChangeIndex(index)}
            indicatorColor="primary"
            centered
          >
            <Tab label="Gerenciamento" />
            {logged_user?.is_admin || logged_user?.is_manager ? (
              <Tab label="Autenticação" />
            ) : (
              <></>
            )}
          </Tabs>
        </Paper>

        <SwipeableContainer index={tabIndex}>
          <SettingContent />
          <SettingCodeAuth />
        </SwipeableContainer>
      </Container>
    </Page>
  );
}
