import styled from 'styled-components';

import MUIGrid from '@material-ui/core/Grid';
import MUISearchIcon from '@material-ui/icons/Search';

import {
  FormConfirmButton,
  FormCancelButton,
  FormTextInput,
} from '@shared/components/Form';

export {
  FormGridContainer,
  FormContainer,
  FormSelector,
} from '@shared/components/Form';
export { default as Dialog } from '@material-ui/core/Dialog';
export { default as DialogTitle } from '@material-ui/core/DialogTitle';
export { default as DialogContent } from '@material-ui/core/DialogContent';
export { default as IconButton } from '@material-ui/core/IconButton';
export { default as Typography } from '@material-ui/core/Typography';
export { default as CircularProgress } from '@material-ui/core/CircularProgress';
export { default as CloseIcon } from '@material-ui/icons/Close';

export const DialogHeader = styled(MUIGrid)`
  display: flex;
  justify-content: space-between;
`;

export const ConfirmButton = styled(FormConfirmButton).attrs({
  gridProps: { xs: 6 },
})``;

export const CancelButton = styled(FormCancelButton).attrs({
  gridProps: { xs: 6 },
})``;

export const TextInput = styled(FormTextInput).attrs({
  gridProps: { xs: 6 },
})``;

export const SearchIconContainer = styled(MUIGrid)`
  display: flex;
  align-items: center;
`;
export const SearchIcon = styled(MUISearchIcon)``;
