import * as yup from 'yup';

import {
  sharedBaseCreateValidatorSchema,
  sharedBaseUpdateValidatorSchema,
} from '@shared/validators/shared';

import { STATUSES } from '@modules/projects/constants/project';

import {
  TProjectStageCreateObject,
  TProjectStageUpdateObject,
} from '@modules/projects/@types/TProjectStage';

import { createValidatorSchema as subStageCreateValidatorSchema } from './projectSubStage';

export const projectStageBaseCreateOrUpdateValidatorSchema = yup
  .object()
  .shape({
    project_uuid: yup
      .string()
      .required('Este campo é obrigatório')
      .uuid('Este campo deve ser um UUID válido'),
    stage_name_uuid: yup
      .string()
      .required('Este campo é obrigatório')
      .uuid('Este campo deve ser um UUID válido'),
    template_stage_uuid: yup
      .string()
      .required('Este campo é obrigatório')
      .uuid('Este campo deve ser um UUID válido'),
    responsible_uuid: yup
      .string()
      .required('Este campo é obrigatório')
      .uuid('Este campo deve possuir usuário válido'),
    description: yup
      .string()
      .required('Este campo é obrigatório')
      .max(255, 'Este campo deve conter no máximo 255 caracteres'),
    status: yup
      .mixed()
      .oneOf(
        Object.values(STATUSES),
        `Este campo deve ser algum dos valores: ${Object.values(STATUSES)}`,
      )
      .required('Este campo é obrigatório'),
    estimated_delivery_at: yup
      .string()
      .max(255, 'Este campo deve conter no máximo 255 caracteres'),
    is_reopen: yup.boolean().default(false),
  });

export const projectSubStagesArray = yup.object().shape({
  project_sub_stages: yup
    .array()
    .of(subStageCreateValidatorSchema)
    .required('Este campo é obrigatório'),
});

export const createValidatorSchema = yup
  .object()
  .concat(projectStageBaseCreateOrUpdateValidatorSchema)
  .concat(projectSubStagesArray)
  .concat(
    sharedBaseCreateValidatorSchema,
  ) as yup.SchemaOf<TProjectStageCreateObject>;

export const updateValidatorSchema = yup
  .object()
  .concat(projectStageBaseCreateOrUpdateValidatorSchema)
  .concat(
    sharedBaseUpdateValidatorSchema,
  ) as yup.SchemaOf<TProjectStageUpdateObject>;
