import styled from 'styled-components';
import MUITablePagination from '@material-ui/core/TablePagination';
import MUIGrid from '@material-ui/core/Grid';

export const Container = styled(MUIGrid)`
  display: flex;
`;

export const PaginationComponent = styled(MUITablePagination).attrs({
  component: 'div',
})``;
