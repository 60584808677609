import { SessionAppRepositories } from '@modules/sessions/repositories/app/session';
import { SessionLocalStorageRepositories } from '@modules/sessions/repositories/localStorage/session';

import { FromApi } from '@modules/sessions/sanitizers/session';

import { ISessionCreateDTO } from '@modules/sessions/dtos/ISession';

import {
  TCodeAuthSession,
  TLocalStorageProps,
} from '@modules/sessions/@types/TSession';

import { ISessionAppServices } from '@modules/sessions/services/app/implementations/ISession';

import { SessionShowAppService } from './show';
import { SessionShowLocalAppService } from './showLocal';
import { SessionCreateAppService } from './create';
import { SessionUpdateAppService } from './update';
import { SessionUpdateLocalAppService } from './updateLocal';
import { SessionDestroyAppService } from './destroy';

export class SessionAppServices implements ISessionAppServices {
  private readonly sessionAppRepositories = new SessionAppRepositories(FromApi);

  private readonly sessionLocalStorageRepositories =
    new SessionLocalStorageRepositories();

  private readonly sessionShowAppService = new SessionShowAppService(
    this.sessionAppRepositories.sessionShowAppRepository,
  );

  private readonly sessionShowLocalAppService = new SessionShowLocalAppService(
    this.sessionLocalStorageRepositories.sessionShowLocalStorageRepository,
  );

  private readonly sessionCreateAppService = new SessionCreateAppService(
    this.sessionAppRepositories.sessionCreateAppRepository,
    this.sessionLocalStorageRepositories.sessionUpdateLocalStorageRepository,
  );

  private readonly sessionUpdateAppService = new SessionUpdateAppService(
    this.sessionAppRepositories.sessionUpdateAppRepository,
    this.sessionLocalStorageRepositories.sessionUpdateLocalStorageRepository,
  );

  private readonly sessionUpdateLocalAppService =
    new SessionUpdateLocalAppService(
      this.sessionLocalStorageRepositories.sessionUpdateLocalStorageRepository,
    );

  private readonly sessionDestroyAppService = new SessionDestroyAppService(
    this.sessionLocalStorageRepositories.sessionDestroyLocalStorageRepository,
  );

  public async show(): Promise<TCodeAuthSession> {
    const showAppServiceResult = await this.sessionShowAppService.execute();

    return showAppServiceResult;
  }

  public showLocal(): TLocalStorageProps {
    const showLocalAppServiceResult = this.sessionShowLocalAppService.execute();

    return showLocalAppServiceResult;
  }

  public async create(CreateDTO: ISessionCreateDTO): Promise<TCodeAuthSession> {
    const createAppServiceResult = await this.sessionCreateAppService.execute(
      CreateDTO,
    );

    return createAppServiceResult;
  }

  public async update(): Promise<TCodeAuthSession> {
    const updateAppServiceResult = await this.sessionUpdateAppService.execute();

    return updateAppServiceResult;
  }

  public updateLocal(UpdateLocalDTO: Partial<TLocalStorageProps>): void {
    this.sessionUpdateLocalAppService.execute(UpdateLocalDTO);
  }

  public async destroy(): Promise<void> {
    await this.sessionDestroyAppService.execute();
  }
}
