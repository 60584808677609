import styled from 'styled-components';
import MUIGrid from '@material-ui/core/Grid';

import {
  FormTextInput,
  FormDatePicker,
  FormCancelButton,
  FormConfirmButton,
} from '@shared/components/Form';

export {
  FormContainer,
  FormGridContainer,
  FormSelector,
  FormDatePicker,
} from '@shared/components/Form';
export { NotificateUsersDialog } from '@modules/projects/components/NotificateUsersDialog';

export const TextInput = styled(FormTextInput).attrs({
  gridProps: { xs: 8, sm: 5 },
})``;

export const DatePicker = styled(FormDatePicker).attrs({
  gridProps: { xs: 8, sm: 5 },
})``;

export const ConfirmButton = styled(FormConfirmButton).attrs({
  gridProps: { xs: 8, sm: 5 },
})``;

export const CancelButton = styled(FormCancelButton).attrs({
  gridProps: { xs: 8, sm: 5 },
})``;

export const ContentFormContainer = styled(MUIGrid)`
  width: 115%;
  margin-left: 20px;
  padding: 16px;
`;
