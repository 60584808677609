import { ISharedShowDTO } from '@shared/dtos/IShared';
import { ISharedShowAppRepository } from '@shared/repositories/app/implementations/IShared';
import { ISharedShowAppService } from '@shared/services/app/implementations/IShared';

import {
  TSharedObject,
  TSharedShowApiReturn,
  TSharedShowObject,
} from '@shared/@types/TShared';

export class SharedShowAppService<
  TRegister extends TSharedObject,
  TShowObject extends TSharedShowObject,
  TRelationsRegister extends TSharedObject,
> implements ISharedShowAppService<TRegister, TShowObject, TRelationsRegister>
{
  constructor(
    private readonly showAppRepository: ISharedShowAppRepository<
      TRegister,
      TShowObject,
      TRelationsRegister
    >,
  ) {}

  public async execute(
    ShowDTO: ISharedShowDTO<TShowObject, TRegister, TRelationsRegister>,
  ): Promise<TSharedShowApiReturn<TRegister, TRelationsRegister>> {
    const showAppRepositoryResult = await this.showAppRepository.execute(
      ShowDTO,
    );

    return showAppRepositoryResult;
  }
}
