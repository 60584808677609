import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { ProjectSubStageAppServices } from '@modules/projects/services/app/projectSubStage';

import { useErrorHandler } from '@shared/providers/error/hook';

import { ISharedShowDTO } from '@shared/dtos/IShared';

import {
  TProjectSubStageObject,
  TProjectSubStageShowObject,
  TProjectSubStageCreateObject,
  TProjectSubStageUpdateObject,
  TProjectSubStageDestroyObject,
  TProjectSubStageRelations,
} from '@modules/projects/@types/TProjectSubStage';
import {
  SharedHookInitialState,
  useSharedHook,
  TSharedHookInitialState,
  TSharedHookReturn,
} from '@shared/hooks/shared';
import { TSharedShowApiReturn } from '@shared/@types/TShared';

import { useProjectMessage } from './projectMessage';
import { IProjectSubStageFormDataDTO } from '../dto/IProjectSubStage';

const INITIAL_STATE: TSharedHookInitialState<
  TProjectSubStageObject,
  TProjectSubStageRelations
> = SharedHookInitialState;

type TProjectSubStageContext = TSharedHookInitialState<
  TProjectSubStageObject,
  TProjectSubStageRelations
> &
  Omit<
    TSharedHookReturn<
      TProjectSubStageObject,
      TProjectSubStageShowObject,
      TProjectSubStageCreateObject,
      TProjectSubStageUpdateObject,
      TProjectSubStageDestroyObject,
      TProjectSubStageRelations
    >,
    'formData'
  > & { formData: (FormDataDTO: IProjectSubStageFormDataDTO) => Promise<void> };

const ProjectSubStageContext = createContext<TProjectSubStageContext>(
  INITIAL_STATE as TProjectSubStageContext,
);

const AppServices = new ProjectSubStageAppServices();

export function ProjectSubStageProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [state, setState] =
    useState<
      TSharedHookInitialState<TProjectSubStageObject, TProjectSubStageRelations>
    >(INITIAL_STATE);

  const { setErrorHandlerData } = useErrorHandler();
  const { setStateSafety } = useProjectMessage();
  const navigate = useNavigate();

  const sharedHook = useSharedHook<
    TProjectSubStageObject,
    TProjectSubStageShowObject,
    TProjectSubStageCreateObject,
    TProjectSubStageUpdateObject,
    TProjectSubStageDestroyObject,
    TProjectSubStageRelations
  >({
    setState,
    AppServices,
    setErrorHandlerData,
  });

  const formData = useCallback(
    async (FormDataDTO: IProjectSubStageFormDataDTO): Promise<void> => {
      sharedHook.formData(FormDataDTO);
    },
    [sharedHook],
  );

  const show = useCallback(
    async (
      ShowDTO: ISharedShowDTO<
        TProjectSubStageShowObject,
        TProjectSubStageObject,
        TProjectSubStageRelations
      >,
    ): Promise<void> => {
      function onSuccess(
        serviceData: TSharedShowApiReturn<
          TProjectSubStageObject,
          TProjectSubStageRelations
        >,
      ) {
        setStateSafety({
          registerList: serviceData.register.project_messages || [],
        });
        ShowDTO.onSuccess?.(serviceData);
      }

      function onError() {
        navigate('/not-found', { replace: true });
      }

      sharedHook.show({ ...ShowDTO, onSuccess, onError });
    },
    [sharedHook, setStateSafety, navigate],
  );

  return (
    <ProjectSubStageContext.Provider
      value={{ ...state, ...sharedHook, formData, show }}
    >
      {children}
    </ProjectSubStageContext.Provider>
  );
}

export function useProjectSubStage(): TProjectSubStageContext {
  const context = useContext(ProjectSubStageContext);

  if (!context)
    throw new Error(
      'useProjectSubStage must be used within an ProjectSubStageProvider',
    );

  return context;
}
