import { ISessionCreateDTO } from '@modules/sessions/dtos/ISession';
import { ISessionAppRepositories } from '@modules/sessions/repositories/app/implementations/ISession';

import { TCodeAuthSession } from '@modules/sessions/@types/TSession';

import { SessionShowAppRepository } from './show';
import { SessionCreateAppRepository } from './create';
import { SessionUpdateAppRepository } from './update';

export class SessionAppRepositories implements ISessionAppRepositories {
  constructor(
    private readonly FromApi: (_data: TCodeAuthSession) => TCodeAuthSession,
  ) {}

  public readonly sessionShowAppRepository = new SessionShowAppRepository(
    this.FromApi,
  );

  public readonly sessionCreateAppRepository = new SessionCreateAppRepository(
    this.FromApi,
  );

  public readonly sessionUpdateAppRepository = new SessionUpdateAppRepository(
    this.FromApi,
  );

  public async show(): Promise<TCodeAuthSession> {
    const showAppRepositoryResult =
      await this.sessionShowAppRepository.execute();

    return showAppRepositoryResult;
  }

  public async create(CreateDTO: ISessionCreateDTO): Promise<TCodeAuthSession> {
    const createAppRepositoryResult =
      await this.sessionCreateAppRepository.execute(CreateDTO);

    return createAppRepositoryResult;
  }

  public async update(): Promise<TCodeAuthSession> {
    const updateAppRepositoryResult =
      await this.sessionUpdateAppRepository.execute();

    return updateAppRepositoryResult;
  }
}
