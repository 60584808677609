import { PER_PAGE_SELECTOR, SORT_TYPES } from '@shared/constants/shared';

import { ISharedListSelectorDTO } from '@shared/dtos/IShared';
import { ISharedListAppRepository } from '@shared/repositories/app/implementations/IShared';
import { ISharedListSelectorAppService } from '@shared/services/app/implementations/IShared';

import {
  TSharedListValues,
  TSharedObject,
  TSharedPaginatedList,
} from '@shared/@types/TShared';

export class SharedListSelectorAppService<TRegister extends TSharedObject>
  implements ISharedListSelectorAppService<TRegister>
{
  constructor(
    private readonly listAppRepository: ISharedListAppRepository<TRegister>,
    private readonly listSelectorFilters: TSharedListValues[],
  ) {}

  public async execute(
    ListSelectorDTO: ISharedListSelectorDTO<TRegister>,
  ): Promise<TSharedPaginatedList<TRegister>> {
    const parsedSelectorFilters: TSharedListValues[] =
      typeof ListSelectorDTO.search !== 'string'
        ? ListSelectorDTO.search
        : this.listSelectorFilters.map(listSelectorFilters => ({
            ...listSelectorFilters,
            values: [ListSelectorDTO.search as string],
          }));

    const listAppRepositoryResult = await this.listAppRepository.execute({
      order: SORT_TYPES.asc,
      order_by: 'id',
      page: 1,
      per_page: PER_PAGE_SELECTOR,
      for_selector: true,
      data: parsedSelectorFilters,
    });

    return listAppRepositoryResult;
  }
}
