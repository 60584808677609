import { ISessionCreateDTO } from '@modules/sessions/dtos/ISession';
import { ISessionCreateAppService } from '@modules/sessions/services/app/implementations/ISession';
import { ISessionCreateAppRepository } from '@modules/sessions/repositories/app/implementations/ISession';
import { ISessionUpdateLocalStorageRepository } from '@modules/sessions/repositories/localStorage/implementations/ISession';

import { TCodeAuthSession } from '@modules/sessions/@types/TSession';
import { apiHttpClientConfig } from '@shared/providers/api/configs/httpClient';

export class SessionCreateAppService implements ISessionCreateAppService {
  constructor(
    private readonly sessionCreateAppRepository: ISessionCreateAppRepository,
    private readonly sessionUpdateLocalStorageRepository: ISessionUpdateLocalStorageRepository,
  ) {}

  public async execute(
    CreateDTO: ISessionCreateDTO,
  ): Promise<TCodeAuthSession> {
    const createAppRepositoryResult =
      await this.sessionCreateAppRepository.execute(CreateDTO);

    this.sessionUpdateLocalStorageRepository.execute(createAppRepositoryResult);

    apiHttpClientConfig.defaults = {
      ...apiHttpClientConfig.defaults,
      headers: {
        ...apiHttpClientConfig.defaults.headers,
        authorization: `Bearer ${createAppRepositoryResult.token?.token}`,
        'Refresh-Token': `${createAppRepositoryResult.token?.refreshToken}`,
      },
    };

    return createAppRepositoryResult;
  }
}
