import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ALL_PERMISSION_KEYS } from '@shared/constants/shared';
import { BASE_URL } from '@modules/projects/constants/project';

import { TSharedListValues } from '@shared/@types/TShared';
import { TDashboardRelationsObject } from '@modules/projects/@types/TDashboard';

import { FilterDialog } from '@modules/projects/components/FilterDialog';

import {
  Container,
  AddProjectContainer,
  AddIcon,
  IconButton,
  Typography,
  HasPermissionComponent,
  ConfirmButton,
} from './styles';

type TProjectListHeader = {
  setIsFilterList: Dispatch<SetStateAction<boolean>>;
  setFilterData: Dispatch<SetStateAction<TSharedListValues[]>>;
  setIsReloaded: Dispatch<SetStateAction<boolean>>;
  isReloaded: boolean;
  formInitialData?: TDashboardRelationsObject;
};

export function ProjectListHeader({
  setIsFilterList,
  setFilterData,
  setIsReloaded,
  isReloaded,
  formInitialData,
}: TProjectListHeader): JSX.Element {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  function addFunction() {
    navigate(`/${BASE_URL}/form`, { state: { creating: true } });
  }

  function handleOpenDialog(): void {
    setIsOpen(true);
  }

  return (
    <Container>
      <AddProjectContainer>
        <Typography>Projetos</Typography>
        <HasPermissionComponent
          permission_key={[ALL_PERMISSION_KEYS.project_create]}
          element={
            <IconButton onClick={addFunction}>
              <AddIcon />
            </IconButton>
          }
        />
      </AddProjectContainer>

      <ConfirmButton onClick={handleOpenDialog}>FILTRAR PROJETOS</ConfirmButton>

      <FilterDialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setIsFilterList={setIsFilterList}
        setFilterData={setFilterData}
        setIsReloaded={setIsReloaded}
        isReloaded={isReloaded}
        formInitialData={formInitialData}
      />
    </Container>
  );
}

ProjectListHeader.defaultProps = {
  formInitialData: undefined,
};
