import { ISessionUpdateLocalAppService } from '@modules/sessions/services/app/implementations/ISession';
import { ISessionUpdateLocalStorageRepository } from '@modules/sessions/repositories/localStorage/implementations/ISession';

import { TLocalStorageProps } from '@modules/sessions/@types/TSession';
import { apiHttpClientConfig } from '@shared/providers/api/configs/httpClient';

export class SessionUpdateLocalAppService
  implements ISessionUpdateLocalAppService
{
  constructor(
    private readonly sessionUpdateLocalStorageRepository: ISessionUpdateLocalStorageRepository,
  ) {}

  public execute(UpdateLocalDTO: Partial<TLocalStorageProps>): void {
    this.sessionUpdateLocalStorageRepository.execute(UpdateLocalDTO);

    if (UpdateLocalDTO.token)
      apiHttpClientConfig.defaults = {
        ...apiHttpClientConfig.defaults,
        headers: {
          ...apiHttpClientConfig.defaults.headers,
          authorization: `Bearer ${UpdateLocalDTO.token?.token}`,
          'Refresh-Token': `${UpdateLocalDTO.token?.refreshToken}`,
        },
      };
  }
}
