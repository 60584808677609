import { ReactNode } from 'react';

import { ThemeProvider } from '@shared/hooks/theme';
import { ErrorHandlerProvider } from '@shared/providers/error/hook';
import { TemplateProvider } from '@modules/templates/hooks/template';
import { StageNameProvider } from '@modules/templates/hooks/stageName';
import { ProjectProvider } from '@modules/projects/hooks/project';
import { ProjectUserProvider } from '@modules/projects/hooks/projectUser';
import { ProjectSubStageProvider } from '@modules/projects/hooks/projectSubStage';
import { ProjectStageProvider } from '@modules/projects/hooks/projectStage';
import { ProjectMessageProvider } from '@modules/projects/hooks/projectMessage';
import { SessionProvider } from '@modules/sessions/hooks/session';
import { UserProvider } from '@modules/users/hooks/user';
import { FileProvider } from '@modules/files/hooks/file';
import { DashboardProvider } from '@modules/projects/hooks/dashboard';

export function AppHooks({ children }: { children: ReactNode }): JSX.Element {
  return (
    <ThemeProvider>
      <ErrorHandlerProvider>
        <SessionProvider>
          <UserProvider>
            <TemplateProvider>
              <ProjectUserProvider>
                <ProjectProvider>
                  <DashboardProvider>
                    <FileProvider>
                      <ProjectMessageProvider>
                        <ProjectSubStageProvider>
                          <ProjectStageProvider>
                            <StageNameProvider>{children}</StageNameProvider>
                          </ProjectStageProvider>
                        </ProjectSubStageProvider>
                      </ProjectMessageProvider>
                    </FileProvider>
                  </DashboardProvider>
                </ProjectProvider>
              </ProjectUserProvider>
            </TemplateProvider>
          </UserProvider>
        </SessionProvider>
      </ErrorHandlerProvider>
    </ThemeProvider>
  );
}
