import { ReactNode, forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box } from '@material-ui/core';

import { APP_SHORT_NAME } from '@shared/constants/shared';

type TPage = {
  children: ReactNode;
  title: string;
};

export const Page = forwardRef(({ children, title, ...rest }: TPage, ref) => (
  <Box ref={ref} {...rest}>
    <Helmet>
      <title>
        {title} | {APP_SHORT_NAME}
      </title>
    </Helmet>
    {children}
  </Box>
));
