import styled from 'styled-components';
import MUIGrid from '@material-ui/core/Grid';
import {
  FormTextInput,
  FormConfirmButton,
  FormDatePicker,
  FormSelector,
  FormCheckbox,
} from '@shared/components/Form';

export { FormContainer, FormGridContainer } from '@shared/components/Form';
export { GoToTopButton } from '@shared/components/GoToTopButton';

export const ProjectFormContainer = styled(MUIGrid)`
  background: ${({ theme }) => theme.palette.secondary_background.main};

  width: 90%;
  padding: 24px;
  padding-left: 40px;
  border-radius: 0px 0px 1rem 0px;
`;

export const ConfirmButton = styled(FormConfirmButton).attrs({
  gridProps: { xs: 6, sm: 4, md: 2 },
})``;

export const SearchButton = styled(FormConfirmButton).attrs({
  gridProps: { xs: 6, sm: 4 },
})``;

export const DatePicker = styled(FormDatePicker).attrs({
  gridProps: { xs: 2, sm: 2 },
  inputBox: 'standard',
})``;

export const TextInput = styled(FormTextInput).attrs(props => ({
  gridProps: { xs: 6, sm: 4, ...props.gridProps },
  inputBox: 'standard',
}))`` as unknown as typeof FormTextInput;

export const Selector = styled(FormSelector).attrs(props => ({
  gridProps: { xs: 4, sm: 4, ...props.gridProps },
}))`` as unknown as typeof FormSelector;

export const Checkbox = styled(FormCheckbox).attrs(props => ({
  gridProps: { xs: 6, sm: 1, ...props.gridProps },
}))`` as unknown as typeof FormCheckbox;
