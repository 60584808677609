import styled from 'styled-components';
import MUIBox from '@material-ui/core/Box';
import { CodeAuthIframe as SharedCodeAuthIframe } from '@shared/components/CodeAuthIframe';

export const Container = styled(MUIBox)`
  display: flex;
  height: 100%;
`;

export const CodeAuthIframe = styled(SharedCodeAuthIframe).attrs({
  url: 'login',
})`
  height: 100%;
`;
