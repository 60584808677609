import { APP_KEY_NAME } from '@shared/constants/shared';

import { safetyJSONParse } from '@shared/utils/json';

import { ISessionShowLocalStorageRepository } from '@modules/sessions/repositories/localStorage/implementations/ISession';

import { TLocalStorageProps } from '@modules/sessions/@types/TSession';

export class SessionShowLocalStorageRepository
  implements ISessionShowLocalStorageRepository
{
  public execute(): TLocalStorageProps {
    const result: TLocalStorageProps = {
      token: safetyJSONParse(
        localStorage.getItem(`@${APP_KEY_NAME}/session:token`),
      ),
    };

    return result;
  }
}
