import { GridProps } from '@material-ui/core';

import { Container } from './styles';

type TFormGridContainer = GridProps;

export function FormGridContainer({
  children,
  ...rest
}: TFormGridContainer): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
