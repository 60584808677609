import styled from 'styled-components';
import MUIContainer from '@material-ui/core/Container';
import MUITypography from '@material-ui/core/Typography';

export const Container = styled(MUIContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ForbiddenText = styled(MUITypography).attrs({ variant: 'h1' })`
  font-weight: 800;
`;
export const ForbiddenSubText = styled(MUITypography).attrs({ variant: 'h3' })`
  margin-bottom: 4rem;
`;
export const ForbiddenErrorText = styled(MUITypography).attrs({
  variant: 'h6',
})``;
