import { ReactNode } from 'react';
import { Container } from './styles';

type TDefaultForm = {
  children: ReactNode;
  style?: Record<string, unknown>;
};

export function DefaultFormContainer({ children, style }: TDefaultForm) {
  return <Container style={style}>{children}</Container>;
}

DefaultFormContainer.defaultProps = {
  style: undefined,
};
