import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { SessionAppServices } from '@modules/sessions/services/app/session';

import { useErrorHandler } from '@shared/providers/error/hook';

import { ErrorProvider } from '@shared/providers/error';

import { debounceEvent } from '@shared/utils/debounce';

import { ISessionCreateDTO } from '@modules/sessions/dtos/ISession';

import { TAppError } from '@shared/providers/error/@types/TError';
import {
  TCodeAuthSession,
  TLocalStorageProps,
} from '@modules/sessions/@types/TSession';

type TSessionInitialState = TCodeAuthSession & {
  isSigned: boolean;
  sessionLoading: boolean;
  validations?: Record<string, string>;
};

const INITIAL_STATE: TSessionInitialState = {
  logged_user: undefined,
  logged_branch: undefined,
  contract: undefined,
  token: undefined,
  accesses: undefined,
  current_accesses: undefined,
  isSigned: false,
  sessionLoading: false,
  validations: undefined,
};

type TSessionContext = TSessionInitialState & {
  setStateSafety: (
    newData:
      | Partial<Partial<TSessionInitialState>>
      | ((
          oldData: Partial<TSessionInitialState>,
        ) => Partial<Partial<TSessionInitialState>>),
  ) => void;
  create: (CreateDTO: ISessionCreateDTO) => Promise<void>;
  update: (handleFunction: (...args: unknown[]) => unknown) => Promise<void>;
  updateLocal: (UpdateLocalDTO: Partial<TLocalStorageProps>) => void;
  signOut: () => Promise<void>;
};

const SessionContext = createContext<TSessionContext>(
  INITIAL_STATE as TSessionContext,
);

const AppServices = new SessionAppServices();

export function SessionProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const navigate = useNavigate();

  const [state, setState] = useState<TSessionInitialState>(() => {
    const serviceData = AppServices.showLocal();

    const isSigned = !!serviceData.token?.token;

    return { ...INITIAL_STATE, ...serviceData, isSigned };
  });

  const setStateSafety = useCallback(
    (
      newData:
        | Partial<Partial<TSessionInitialState>>
        | ((
            oldData: Partial<TSessionInitialState>,
          ) => Partial<Partial<TSessionInitialState>>),
    ) => {
      if (typeof newData === 'function')
        setState(oldData => ({ ...oldData, ...newData(oldData) }));

      setState(oldData => ({ ...oldData, ...newData }));
    },
    [setState],
  );

  const { setErrorHandlerData } = useErrorHandler();

  const errorHandler = useCallback(
    (
      error: TAppError,
      handleFunction: (...args: unknown[]) => unknown,
      extraData?: Partial<TSessionInitialState>,
    ): void => {
      const parsedError =
        error instanceof ErrorProvider
          ? error
          : new ErrorProvider({ code: error?.code || 'E_UNRECOGNIZED', error });

      const JSONError = parsedError.toJSON();

      setErrorHandlerData({
        error: JSONError,
        handleFunction,
      });

      setStateSafety({
        validations: JSONError?.validations as Record<string, string>,
        ...(extraData || {}),
      });
    },
    [setErrorHandlerData, setStateSafety],
  );

  const show = useCallback(async (): Promise<void> => {
    try {
      setStateSafety({ sessionLoading: true });

      const serviceData = await AppServices.show();

      setStateSafety({
        ...serviceData,
        sessionLoading: false,
        validations: undefined,
      });
    } catch (error) {
      const parsedError = error as TAppError;

      errorHandler(parsedError, () => show(), { sessionLoading: false });
    }
  }, [errorHandler, setStateSafety]);

  const create = useCallback(
    async (CreateDTO: ISessionCreateDTO): Promise<void> => {
      try {
        setStateSafety({ sessionLoading: true });

        const serviceData = await AppServices.create(CreateDTO);

        setStateSafety({
          ...serviceData,
          isSigned: !!serviceData.token?.token,
          sessionLoading: false,
          validations: undefined,
        });
      } catch (error) {
        const parsedError = error as TAppError;

        errorHandler(parsedError, () => create(CreateDTO), {
          sessionLoading: false,
        });
      }
    },
    [errorHandler, setStateSafety],
  );

  const signOut = useCallback(async (): Promise<void> => {
    try {
      setState(INITIAL_STATE);

      await AppServices.destroy();

      navigate('/');
    } catch (error) {
      const parsedError = error as TAppError;

      errorHandler(parsedError, () => signOut(), { sessionLoading: false });
    }
  }, [errorHandler, navigate]);

  const update = useCallback(
    async (handleFunction: (...args: unknown[]) => unknown): Promise<void> => {
      try {
        setStateSafety({ sessionLoading: true });

        const serviceData = await AppServices.update();

        setStateSafety({
          ...serviceData,
          isSigned: !!serviceData.token?.token,
          sessionLoading: false,
          validations: undefined,
        });

        debounceEvent(handleFunction, 200)();

        navigate('/');
      } catch (error) {
        const parsedError = error as TAppError;

        errorHandler(parsedError, () => update(handleFunction), {
          sessionLoading: false,
        });

        signOut();
      }
    },
    [errorHandler, setStateSafety, signOut, navigate],
  );

  const updateLocal = useCallback(
    (UpdateLocalDTO: Partial<TLocalStorageProps>): void => {
      try {
        AppServices.updateLocal(UpdateLocalDTO);

        setStateSafety({
          isSigned: !!UpdateLocalDTO.token?.token,
          token: UpdateLocalDTO.token,
        });
      } catch (error) {
        const parsedError = error as TAppError;

        errorHandler(parsedError, () => updateLocal(UpdateLocalDTO));

        signOut();
      }
    },
    [errorHandler, setStateSafety, signOut],
  );

  useEffect(() => {
    if (!state.logged_user?.user_key && state.token?.token) show();
  }, [state.logged_user?.user_key, state.token?.token, show]);

  return (
    <SessionContext.Provider
      value={{ ...state, setStateSafety, create, update, updateLocal, signOut }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export function useSession(): TSessionContext {
  const context = useContext(SessionContext);

  if (!context)
    throw new Error('useSession must be used within an SessionProvider');

  return context;
}
