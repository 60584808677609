import * as yup from 'yup';

import {
  sharedBaseCreateValidatorSchema,
  sharedBaseUpdateValidatorSchema,
} from '@shared/validators/shared';

import {
  TProjectUserCreateObject,
  TProjectUserUpdateObject,
} from '@modules/projects/@types/TProjectUser';

export const projectUserBaseCreateOrUpdateValidatorSchema = yup.object().shape({
  project_uuid: yup.string().required('Este campo é obrigatório'),
  user_uuid: yup
    .string()
    .required('Este campo é obrigatório')
    .uuid('Este campo deve ser um UUID válido'),
});

export const createValidatorSchema = yup
  .object()
  .concat(projectUserBaseCreateOrUpdateValidatorSchema)
  .concat(
    sharedBaseCreateValidatorSchema,
  ) as yup.SchemaOf<TProjectUserCreateObject>;

export const updateValidatorSchema = yup
  .object()
  .concat(projectUserBaseCreateOrUpdateValidatorSchema)
  .concat(
    sharedBaseUpdateValidatorSchema,
  ) as yup.SchemaOf<TProjectUserUpdateObject>;
