import styled from 'styled-components';
import MUIDatePicker from '@material-ui/lab/DatePicker';
import MUITextField from '@material-ui/core/TextField';
import MUIGrid from '@material-ui/core/Grid';
import MUITypography from '@material-ui/core/Typography';

import { FormGridItem } from '@shared/components/Form';

export { FormSkeleton } from '@shared/components/Form';

export const Container = styled(FormGridItem)`
  padding-top: 0.2rem;
`;

export const DatePicker = styled(MUIDatePicker)``;

export const OutlinedInput = styled(MUITextField).attrs({
  size: 'small' as 'small' | 'medium' | undefined,
  fullWidth: true as boolean | undefined,
  color: 'primary' as
    | 'error'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | undefined,
})``;

export const LabelContent = styled(MUIGrid)`
  display: flex;
  flex-direction: row;
`;
export const RequiredCharacter = styled(MUITypography).attrs({
  variant: 'subtitle1',
  color: 'error',
})`
  padding-left: 4px;
`;
