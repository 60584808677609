import { useEffect, useRef, forwardRef } from 'react';
import { useField } from '@unform/core';
import { InputBaseComponentProps } from '@material-ui/core';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { GridProps } from '@material-ui/core/Grid';
import { NumberFormatProps } from 'react-number-format';

import {
  Container,
  FormSkeleton,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  NumberFormat,
  RequiredCharacter,
} from './styles';

const NumberFormatCustom = forwardRef<NumberFormat<number>, unknown>(
  function NumberFormatCustom(props: any, inputRef): JSX.Element {
    const { ...rest } = props;

    return (
      <NumberFormat
        getInputRef={inputRef}
        thousandSeparator="."
        decimalSeparator=","
        fixedDecimalScale
        decimalScale={2}
        allowNegative={false}
        isAllowed={values => {
          const { floatValue } = values;

          return floatValue === undefined || floatValue <= 999999999.99;
        }}
        {...rest}
      />
    );
  },
);

type TFormTextInput = {
  name: string;
  label: string;
  helperText?: string;
  required?: boolean;
  gridProps?: GridProps;
  inputProps?: NumberFormatProps<OutlinedInputProps>;
  showLoading?: boolean;
  disabled?: boolean;
};

export function FormValueInput({
  name,
  label,
  helperText,
  required,
  gridProps,
  inputProps,
  showLoading,
  disabled,
  ...rest
}: TFormTextInput): JSX.Element {
  const { fieldName, registerField, error, clearError, defaultValue } =
    useField(name);

  const inputRef = useRef({ value: '' });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;

    if (error) clearError();

    inputRef.current.value = value;
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, showLoading]);

  return (
    <Container {...gridProps}>
      {showLoading ? (
        <FormSkeleton />
      ) : (
        <FormControl error={!!error} disabled={disabled}>
          <InputLabel>
            {label} {required && <RequiredCharacter>*</RequiredCharacter>}
          </InputLabel>
          <OutlinedInput
            inputRef={inputRef}
            onChange={handleChange}
            inputComponent={
              NumberFormatCustom as React.ElementType<InputBaseComponentProps>
            }
            label={`${label}${required ? ' *' : ''}`}
            error={!!error}
            defaultValue={defaultValue}
            inputProps={{ ...inputProps } as InputBaseComponentProps}
            {...rest}
            endAdornment={
              inputProps?.endAdornment && (
                <InputAdornment position="end">
                  {inputProps.endAdornment}
                </InputAdornment>
              )
            }
          />
          <FormHelperText>{error || helperText || ' '}</FormHelperText>
        </FormControl>
      )}
    </Container>
  );
}

FormValueInput.defaultProps = {
  helperText: undefined,
  required: undefined,
  gridProps: undefined,
  inputProps: undefined,
  showLoading: false,
  disabled: false,
};
