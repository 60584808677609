import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useProjectSubStage } from '@modules/projects/hooks/projectSubStage';

import { Page } from '@shared/components/Page';
import { Forbidden } from '@shared/pages/Forbidden';

import { useSession } from '@modules/sessions/hooks/session';
import { useProjectUser } from '@modules/projects/hooks/projectUser';
import { TSharedListValues } from '@shared/@types/TShared';
import { PER_PAGE, SEARCH_TYPES, SORT_TYPES } from '@shared/constants/shared';
import { ProjectSubStageFormAside } from './Aside';
import { ProjectSubStageFormMessageContent } from './MessageContent';

import { Container } from './styles';

export function ProjectSubStageForm(): JSX.Element {
  const { clearState, show, registerShow, showLoading } = useProjectSubStage();
  const { list, registerList } = useProjectUser();
  const { logged_user, current_accesses, sessionLoading } = useSession();

  const [userCanAccess, setUserCanAcess] = useState(false);

  const params = useParams();
  const project_uuid = registerShow?.project_stage?.project_uuid || '';

  useEffect(() => {
    const filterData: TSharedListValues[] = [
      {
        field: 'project_uuid',
        type: SEARCH_TYPES.normal,
        values: [project_uuid],
      },
    ];
    if (!registerList.length && project_uuid) {
      list({
        order: SORT_TYPES.desc,
        order_by: 'updated_at',
        page: 1,
        per_page: PER_PAGE,
        for_selector: false,
        data: filterData,
      });
    }

    registerList.forEach(userProject => {
      if (userProject.user.uuid === logged_user?.uuid) {
        setUserCanAcess(true);
      }
    });
  }, [list, logged_user?.uuid, project_uuid, registerList, userCanAccess]);

  useEffect(() => {
    show({ register: { uuid: params.uuid || '' } });
  }, []); // eslint-disable-line

  useEffect(() => () => clearState(), [clearState]);

  return (
    <Page title="Gerenciar Atividade">
      {(userCanAccess && current_accesses?.roles['plataforma_h.customer']) ||
      (userCanAccess && !(registerShow?.stage_name?.name === 'CONTRATO')) ||
      logged_user?.is_manager ? (
        <Container>
          <ProjectSubStageFormAside />
          <ProjectSubStageFormMessageContent />
        </Container>
      ) : (
        <>
          {!showLoading && !userCanAccess && !sessionLoading && <Forbidden />}
        </>
      )}
    </Page>
  );
}
