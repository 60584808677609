import {
  TErrorCodes,
  TMessageError,
} from '@shared/providers/error/@types/TError';
import { TLanguages, TLanguagesDict } from './@types/TLocales';

import * as ErrorsPt from './pt/error';

const languagesDict: TLanguagesDict = {
  pt: {
    ERROR_MESSAGES: ErrorsPt.ERROR_MESSAGES,
  },
};

export class LocalesProvider {
  private language: TLanguages;

  constructor(data?: { language?: TLanguages }) {
    this.language = data?.language || process.env.REACT_APP_APP_LOCALE;
  }

  public formatErrorMessage({
    code,
    data,
  }: {
    code: TErrorCodes;
    data?: TMessageError;
  }): string {
    const typesDict = languagesDict[this.language];

    const codesDict = typesDict.ERROR_MESSAGES;

    const formatedMessage = codesDict[code](data);

    return formatedMessage;
  }
}
