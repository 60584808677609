import { SharedAppRepositories } from '@shared/repositories/app/shared';

import { FromApi, FromCreateApi } from '@modules/projects/sanitizers/project';

import { BASE_URL } from '@modules/projects/constants/project';

import {
  TProjectObject,
  TProjectShowObject,
  TProjectCreateObject,
  TProjectUpdateObject,
  TProjectDestroyObject,
} from '@modules/projects/@types/TProject';
import { TUserObject } from '@modules/users/@types/TUser';

export class ProjectAppRepositories extends SharedAppRepositories<
  TProjectObject,
  TProjectShowObject,
  TProjectCreateObject,
  TProjectUpdateObject,
  TProjectDestroyObject,
  TUserObject
> {
  constructor() {
    super(BASE_URL, FromApi, FromCreateApi);
  }
}
