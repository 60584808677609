import styled from 'styled-components';
import { visuallyHidden } from '@mui/utils';
import MUIBox from '@material-ui/core/Box';

export { default as Container } from '@material-ui/core/TableHead';
export { default as TableRow } from '@material-ui/core/TableRow';
export { default as TableCell } from '@material-ui/core/TableCell';
export { default as TableSortLabel } from '@material-ui/core/TableSortLabel';

export const CellBox = styled(MUIBox).attrs({
  sx: visuallyHidden,
})``;
