import { Routes, Route, Navigate } from 'react-router-dom';

import { BASE_URL as PROJECT_BASE_URL } from '@modules/projects/constants/project';
import { BASE_URL as PROJECT_SUB_STAGE_BASE_URL } from '@modules/projects/constants/projectSubStage';

import { HasPermissionComponent } from '@shared/components/HasPermissionComponent';
import { ALL_PERMISSION_KEYS } from '@shared/constants/shared';

import { ProjectList } from '@modules/projects/pages/Project/List';
import { ProjectForm } from '@modules/projects/pages/Project/Form';
import { ProjectSubStageForm } from '@modules/projects/pages/ProjectSubStage/Form';
import { ProjectManage } from '@modules/projects/pages/Project/Manage';

import { NotFound } from '@shared/pages/NotFound';
import { Forbidden } from '@shared/pages/Forbidden';
import { useSession } from '@modules/sessions/hooks/session';

export function ProjectRoutes(): JSX.Element {
  const { sessionLoading } = useSession();

  return (
    <Routes>
      <Route path="/" element={<ProjectList />} />
      <Route
        path={`/${PROJECT_BASE_URL}/form`}
        element={
          <HasPermissionComponent
            permission_key={[ALL_PERMISSION_KEYS.project_create]}
            element={<ProjectForm />}
            fall_back_element={<>{!sessionLoading && <Forbidden />}</>}
          />
        }
      />
      <Route
        path={`/${PROJECT_BASE_URL}/:uuid`}
        element={
          <HasPermissionComponent
            permission_key={[ALL_PERMISSION_KEYS.project_list]}
            element={<ProjectManage />}
            fall_back_element={<>{!sessionLoading && <Forbidden />}</>}
          />
        }
      />
      <Route
        path={`/${PROJECT_SUB_STAGE_BASE_URL}/:uuid`}
        element={
          <HasPermissionComponent
            permission_key={[ALL_PERMISSION_KEYS.project_sub_stage_show]}
            element={<ProjectSubStageForm />}
            fall_back_element={<>{!sessionLoading && <Forbidden />}</>}
          />
        }
      />
      <Route path="/not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  );
}
