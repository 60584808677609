import styled from 'styled-components';

import MUIGrid from '@material-ui/core/Grid';
import MUITypography from '@material-ui/core/Typography';
import MUIButton from '@material-ui/core/Button';

import { STATUSES } from '@modules/projects/constants/project';

export { ReopenProject } from '@modules/projects/components/ReopenProject';
export { RemoveItemDialog } from '@modules/projects/components/RemoveItemDialog';
export { default as DeleteIcon } from '@material-ui/icons/Delete';

type TCard = {
  status: STATUSES;
};
type TManageProjectButton = {
  borderLeft?: boolean;
};

export const ManageProjectButton = styled(MUIButton)<TManageProjectButton>`
  width: 100%;
  margin-bottom: -9px;
  color: ${({ theme }) => theme.palette.grey_scale_1.main};
  border-left: ${({ borderLeft, theme }) =>
    borderLeft ? `1px solid ${theme.palette.grey[300]}` : '0px'};
`;

export const ProjectHeader = styled(MUIGrid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DeleteButtonContainer = styled(MUIGrid)`
  width: 8%;
  display: flex;
  justify-content: center;
`;

export const ProjectContent = styled(MUIGrid).attrs({
  container: true,
  component: 'main',
})`
  width: 100%;
`;

export const Container = styled(MUIGrid).attrs({
  container: true,
  component: 'main',
})`
  width: 98%;
  min-height: 400px;
  margin-bottom: 2rem;
  background: ${({ theme }) => theme.palette.secondary_background.main};
  box-shadow: 0 2px 2px
    ${({ theme }) =>
      theme.palette.mode === 'light' ? theme.palette.grey[400] : 'unset'};
  border-radius: 0.25rem;
  justify-content: center;
`;

export const Header = styled(MUIGrid).attrs({
  container: true,
  component: 'main',
})`
  display: flex;
`;

export const Content = styled(MUIGrid).attrs({
  container: true,
  component: 'main',
})`
  display: flex;
`;

export const Title = styled(MUITypography).attrs({
  component: 'h1',
  variant: 'h6',
})``;

export const SubTitleContent = styled(MUIGrid)`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-bottom: 8px;
`;

export const SubTitleMarker = styled(MUIGrid)<TCard>`
  height: 20px;
  width: 20px;
  margin-right: 1rem;
  margin-left: 1rem;
  border: 4px solid ${({ theme, status }) => theme.palette[status].main};
  border-radius: 50%;
`;

export const SubTitleGrid = styled(MUIGrid)``;

export const SubTitle = styled(MUITypography)``;

export const StepsOfProjectContainer = styled(MUIGrid)`
  height: 278px;
  padding: 10px;
  width: calc(95vw - 115px);
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  overflow-x: auto;
  border: 2px solid ${({ theme }) => theme.palette.grey[300]};
`;

export const CardsContainer = styled(MUIGrid).attrs({
  container: true,
  component: 'main',
})`
  width: 120px;
`;

export const CardContainer = styled(MUIGrid)`
  height: 48%;
`;

export const Card = styled(MUIGrid).attrs({
  container: true,
  component: 'main',
})<TCard>`
  width: 150px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 0.5rem;
  color: white;
  transition: 0.3s;
  background: ${({ theme, status }) =>
    theme.palette[status || STATUSES.PENDING].main};
  &:hover {
    opacity: 85%;
    cursor: pointer;
    transition: 0.3s;
  }
`;

export const ReopenAndNameContainer = styled(MUIGrid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TitleOfCard = styled(MUITypography).attrs({
  variant: 'subtitle2',
})`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextsOfCard = styled(MUITypography).attrs({
  variant: 'caption',
})`
  padding: 0;
  margin: 0 0 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SupportCardContainer = styled(MUIGrid).attrs({
  container: true,
  component: 'main',
})`
  width: 150px;
  height: 45px;
  display: flex;
  justify-content: center;
`;

export const SupportCard = styled(MUIGrid)`
  height: 100%;
  width: 3px;
  background: ${({ theme }) => theme.palette.grey_scale_1.main};
`;

export const StepsOfProjectsBar = styled(MUIGrid)`
  width: 150px;
  height: 4.5%;
  background: ${({ theme }) => theme.palette.grey_scale_2.main};
`;

export const Div = styled(MUIGrid)`
  width: 100%;
  height: 1px;
  margin-top: 8px;
  background: ${({ theme }) => theme.palette.grey[300]};
`;

export const Ball = styled(MUIGrid)`
  width: 32px;
  height: 32px;
  min-width: 32px;
  position: relative;
  top: 44%;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.grey_scale_1.main};
`;
