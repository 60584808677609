import { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';

import { updateValidatorSchema } from '@modules/projects/validators/projectSubStage';

import { statusesOptions } from '@modules/projects/helpers/project';

import { STATUSES } from '@modules/projects/constants/project';
import { ALL_PERMISSION_KEYS, SEARCH_TYPES } from '@shared/constants/shared';

import { ToUpdate } from '@modules/projects/sanitizers/projectSubStage';

import { useSession } from '@modules/sessions/hooks/session';
import { useProjectSubStage } from '@modules/projects/hooks/projectSubStage';
import { useUser } from '@modules/users/hooks/user';

import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import { TProjectSubStageUpdateObject } from '@modules/projects/@types/TProjectSubStage';
import { TUserObject } from '@modules/users/@types/TUser';

import {
  Container,
  KeyLabel,
  ValueLabel,
  ValueBoldLabel,
  Divider,
  FormContainer,
  FormGridContainer,
  FormSelector,
  FormDatePicker,
  FormConfirmButton,
  NotificateUsersDialog,
  HasPermissionComponent,
} from './styles';

export function ProjectSubStageFormAside(): JSX.Element {
  const { logged_user, logged_branch } = useSession();
  const {
    update,
    formInitialData,
    registerShow,
    showLoading,
    formLoading,
    validations,
  } = useProjectSubStage();
  const { listSelector } = useUser();

  const formRef = useRef<FormHandles>(null);

  const canEdit = registerShow?.status !== STATUSES.DONE;

  const [isChanged, setIsChanged] = useState(false);
  const [dialogState, setDialogState] = useState<{
    isOpen: boolean;
    register?: TProjectSubStageUpdateObject;
  }>({
    isOpen: false,
    register: undefined,
  });

  const validatorSchema = updateValidatorSchema;

  function handleAnyChange() {
    setIsChanged(true);
  }

  function handleSanitize(
    register: TProjectSubStageUpdateObject,
  ): TProjectSubStageUpdateObject {
    const parsedRegister = ToUpdate({
      register: {
        ...registerShow,
        ...register,
        uuid: registerShow?.uuid as string,
      },
      logged_user: logged_user as TLoggedUser,
      logged_branch: logged_branch as TLoggedBranch,
    });

    return parsedRegister;
  }

  function handleCloseDialog() {
    setDialogState({ isOpen: false });
  }

  function handleSubmit(register: TProjectSubStageUpdateObject) {
    setDialogState({ isOpen: true, register });
  }

  function notificateUsersSubmit(register: TProjectSubStageUpdateObject): void {
    function onSuccess() {
      setIsChanged(false);
    }

    let is_reopen = false;

    if (
      (registerShow?.status === STATUSES.DONE &&
        register.status !== STATUSES.DONE) ||
      registerShow?.is_reopen
    )
      is_reopen = true;

    update({
      register: {
        ...register,
        is_reopen,
      },
      onSuccess,
    });
  }

  function handleSearch({
    search,
  }: {
    search: string;
  }): Promise<TUserObject[]> {
    const projectUuid = registerShow?.project_stage?.project_uuid || '';

    return listSelector({
      search: [
        {
          field: 'project_users',
          type: SEARCH_TYPES.relation,
          values: [
            {
              field: 'project_uuid',
              type: SEARCH_TYPES.normal,
              values: [projectUuid],
            },
          ],
        },
        { field: 'name', type: SEARCH_TYPES.normal, values: [search] },
      ],
    });
  }

  useEffect(() => {
    formRef.current?.setErrors(validations || {});
  }, [validations]);

  return (
    <Container>
      <KeyLabel>Projeto</KeyLabel>
      <ValueLabel>{registerShow?.project_stage?.project?.name}</ValueLabel>
      <KeyLabel>Etapa</KeyLabel>
      <ValueLabel>{registerShow?.project_stage?.description}</ValueLabel>
      <Divider />
      <KeyLabel>Atividade</KeyLabel>
      <ValueBoldLabel>{registerShow?.description}</ValueBoldLabel>
      <FormContainer<TProjectSubStageUpdateObject>
        formRef={formRef}
        validatorSchema={validatorSchema}
        sanitizer={handleSanitize}
        onSubmit={handleSubmit}
        initialData={registerShow}
      >
        <NotificateUsersDialog<TProjectSubStageUpdateObject>
          dialogState={dialogState}
          formRef={formRef}
          projectUuid={registerShow?.project_stage?.project_uuid}
          handleCloseDialog={handleCloseDialog}
          onSubmit={notificateUsersSubmit}
        />
        <FormGridContainer>
          <FormSelector<TUserObject>
            label="Dirigente"
            name="responsible_uuid"
            idColumn="uuid"
            nameColumn="name"
            onChange={handleAnyChange}
            handleSearch={handleSearch}
            options={(formInitialData?.responsibles || []) as TUserObject[]}
            disabled={
              (!canEdit && !logged_user?.is_manager) ||
              formLoading ||
              (registerShow?.responsible_uuid === logged_user?.uuid &&
                !logged_user?.is_manager) ||
              !logged_user?.is_manager
            }
            showLoading={showLoading}
            gridProps={{ xs: 12 }}
          />
          <FormDatePicker
            label="Ultima atualização"
            name="updated_at"
            onChange={handleAnyChange}
            disabled
            showLoading={showLoading}
            gridProps={{ xs: 12 }}
          />
          <FormSelector
            label="Status"
            name="status"
            idColumn="key"
            nameColumn="label"
            options={statusesOptions}
            required
            onChange={handleAnyChange}
            disabled={
              (!canEdit && !logged_user?.is_manager) ||
              formLoading ||
              (registerShow?.responsible_uuid !== logged_user?.uuid &&
                !logged_user?.is_manager)
            }
            showLoading={showLoading}
            gridProps={{ xs: 12 }}
          />
          <HasPermissionComponent
            permission_key={[ALL_PERMISSION_KEYS.project_sub_stage_update]}
            element={
              <FormConfirmButton
                loading={showLoading || formLoading}
                gridProps={{ xs: 12 }}
                disabled={
                  (!canEdit && !logged_user?.is_manager) ||
                  formLoading ||
                  !isChanged
                }
              >
                SALVAR
              </FormConfirmButton>
            }
            enabled_to_view={[
              registerShow?.responsible_uuid === logged_user?.uuid,
              logged_user?.is_admin as boolean,
              logged_user?.is_manager as boolean,
            ]}
          />
        </FormGridContainer>
      </FormContainer>
    </Container>
  );
}
