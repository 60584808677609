import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useUser } from '@modules/users/hooks/user';

import { Page } from '@shared/components/Page';
import { UserFormContent } from './Content';

import { Container } from './styles';

export function UserForm(): JSX.Element {
  const { clearState, formData } = useUser();

  const location = useLocation();

  useEffect(() => {
    const { state } = location;

    if (!state || state?.creating) formData({});

    window.history.replaceState(null, '');
  }, [location, formData]);

  useEffect(() => () => clearState(), [clearState]);

  return (
    <Page title="Usuários">
      <Container>
        <UserFormContent />
      </Container>
    </Page>
  );
}
