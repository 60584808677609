import { APP_KEY_NAME } from '@shared/constants/shared';

import {
  TCodeAuthSession,
  TCurrentAccess,
  TSessionCreateObject,
} from '@modules/sessions/@types/TSession';

export function ToCreate(
  sessionCreateObject: TSessionCreateObject,
): TSessionCreateObject {
  const parsedData: TSessionCreateObject = {
    email: sessionCreateObject.email,
    password: sessionCreateObject.password,
    branch_key: process.env.REACT_APP_APP_DEFAULT_BRANCH,
  };

  return parsedData;
}

export function FromApi(data: TCodeAuthSession): TCodeAuthSession {
  const current_accesses = { permissions: {}, roles: {} } as TCurrentAccess;

  const findedAccess = data.accesses?.find(
    access => access.application_key === APP_KEY_NAME,
  );

  const company_key = data.logged_branch?.branch_key.slice(0, 8);

  const findedCompany = findedAccess?.companies.find(
    company => company.company_key === company_key,
  );

  const findedBranch = findedCompany?.branches.find(
    branch => branch.branch_key === data.logged_branch?.branch_key,
  );

  findedBranch?.permissions.forEach(permission => {
    current_accesses.permissions[permission.permission_key] = true;
  });

  findedBranch?.roles.forEach(role => {
    current_accesses.roles[role.role_key] = true;
  });

  return { ...data, current_accesses };
}
