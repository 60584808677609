import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { TAppError } from '@shared/providers/error/@types/TError';

type TErrorHandlerInitialState = {
  handleFunction: (...args: unknown[]) => unknown;
  error?: TAppError;
};

const INITIAL_STATE: TErrorHandlerInitialState = {
  handleFunction: () => {
    //
  },
  error: undefined,
};

export type TSetErrorHandlerDataProps = Required<TErrorHandlerInitialState>;

type TErrorHandlerContext = TErrorHandlerInitialState & {
  setErrorHandlerData: (
    setErrorHandlerDataProps: TSetErrorHandlerDataProps,
  ) => void;
  clearState: () => void;
};

const ErrorHandlerContext = createContext<TErrorHandlerContext>(
  INITIAL_STATE as TErrorHandlerContext,
);

function errorLogger({ error }: { error: TAppError }): void {
  if (error.code === 'E_UNRECOGNIZED') console.error({ error }); // eslint-disable-line
}

export function ErrorHandlerProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [state, setState] = useState<TErrorHandlerInitialState>(INITIAL_STATE);

  const setStateSafety = useCallback(
    (
      newData:
        | Partial<TErrorHandlerInitialState>
        | ((
            oldData: TErrorHandlerInitialState,
          ) => Partial<TErrorHandlerInitialState>),
    ) => {
      if (typeof newData === 'function')
        setState(oldData => ({ ...oldData, ...newData(oldData) }));

      setState(oldData => ({ ...oldData, ...newData }));
    },
    [setState],
  );

  const setErrorHandlerData = useCallback(
    ({ error, handleFunction }: TSetErrorHandlerDataProps): void => {
      errorLogger({ error });

      setStateSafety({ error, handleFunction });
    },
    [setStateSafety],
  );

  const clearState = useCallback(() => {
    setStateSafety(INITIAL_STATE);
  }, [setStateSafety]);

  return (
    <ErrorHandlerContext.Provider
      value={{ ...state, setErrorHandlerData, clearState }}
    >
      {children}
    </ErrorHandlerContext.Provider>
  );
}

export function useErrorHandler(): TErrorHandlerContext {
  const context = useContext(ErrorHandlerContext);

  if (!context)
    throw new Error(
      'useErrorHandler must be used within an ErrorHandlerProvider',
    );

  return context;
}
