import { STAGE_NAME_TYPES } from '@modules/templates/constants/stageName';

import {
  TBaseStageNameObject,
  TStageNameShowObject,
  TStageNameCreateObject,
  TStageNameUpdateObject,
  TStageNameDestroyObject,
  TStageNameObject,
} from '@modules/templates/@types/TStageName';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import {
  ToBaseShowSanitizer,
  ToBaseCreateSanitizer,
  ToBaseUpdateSanitizer,
  ToBaseDestroySanitizer,
  FromBaseApi,
} from '@shared/sanitizers/shared';

function ToBaseSanitizer({
  register,
}: {
  register: TBaseStageNameObject;
}): TBaseStageNameObject {
  const parsedObject: TBaseStageNameObject = {
    type: register.type,
    name: register.name,
  };

  return parsedObject;
}

export function ToShow({
  register,
}: {
  register: TStageNameShowObject;
}): TStageNameShowObject {
  const parsedObject: TStageNameShowObject = {
    ...ToBaseShowSanitizer({ register }),
  };

  return parsedObject;
}

export function ToCreate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TStageNameCreateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TStageNameCreateObject {
  const parsedObject: TStageNameCreateObject = {
    ...ToBaseCreateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToUpdate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TStageNameUpdateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TStageNameUpdateObject {
  const parsedObject: TStageNameUpdateObject = {
    ...ToBaseUpdateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToDestroy({
  register,
  logged_branch,
}: {
  register: TStageNameUpdateObject;
  logged_branch: TLoggedBranch;
}): TStageNameDestroyObject {
  const parsedObject: TStageNameDestroyObject = {
    ...ToBaseDestroySanitizer({ register, logged_branch }),
  };

  return parsedObject;
}

const typeDict = {
  [STAGE_NAME_TYPES.STAGE]: 'Etapa',
  [STAGE_NAME_TYPES.SUB_STAGE]: 'Atividade',
};

export function FromApiType(type: STAGE_NAME_TYPES): string {
  const parsedType = typeDict[type];

  return parsedType;
}

export function FromApi({
  registers,
}: {
  registers: TStageNameObject[];
}): TStageNameObject[] {
  const parsedObjects: TStageNameObject[] = registers.map(register => ({
    ...register,
    ...FromBaseApi({ registers: [register] })[0],
    type_view: FromApiType(register.type),
  }));

  return parsedObjects;
}
