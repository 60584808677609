import { ApiProvider } from '@shared/providers/api';

import { ISharedListDTO } from '@shared/dtos/IShared';
import { ISharedListAppRepository } from '@shared/repositories/app/implementations/IShared';

import { TSharedObject, TSharedPaginatedList } from '@shared/@types/TShared';

export class SharedListAppRepository<TRegister extends TSharedObject>
  implements ISharedListAppRepository<TRegister>
{
  private readonly apiProvider = new ApiProvider();

  constructor(
    private readonly baseURL: string,
    private readonly FromApi: ({
      registers,
    }: {
      registers: TRegister[];
    }) => TRegister[],
  ) {}

  public async execute(
    ListDTO: ISharedListDTO<TRegister>,
  ): Promise<TSharedPaginatedList<TRegister>> {
    const parsedOrderBy = ListDTO.order_by.replace(/_view/g, '');

    const apiResult = await this.apiProvider.execute<
      TSharedPaginatedList<TRegister>
    >({
      method: 'get',
      url: this.baseURL,
      params: {
        ...ListDTO,
        order_by: parsedOrderBy,
        data: ListDTO.data && JSON.stringify(ListDTO.data),
      },
    });

    const sanitizedRegisters = this.FromApi({ registers: apiResult.data });

    const parsedResult = {
      ...apiResult,
      data: sanitizedRegisters,
    };

    return parsedResult;
  }
}
