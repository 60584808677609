import { createContext, ReactNode, useContext, useState } from 'react';

import { StageNameAppServices } from '@modules/templates/services/app/stageName';

import { useErrorHandler } from '@shared/providers/error/hook';

import {
  TStageNameObject,
  TStageNameShowObject,
  TStageNameCreateObject,
  TStageNameUpdateObject,
  TStageNameDestroyObject,
} from '@modules/templates/@types/TStageName';
import {
  SharedHookInitialState,
  useSharedHook,
  TSharedHookInitialState,
  TSharedHookReturn,
} from '@shared/hooks/shared';
import { TUserObject } from '@modules/users/@types/TUser';

const INITIAL_STATE: TSharedHookInitialState<TStageNameObject, TUserObject> =
  SharedHookInitialState;

type TStageNameContext = TSharedHookInitialState<
  TStageNameObject,
  TUserObject
> &
  TSharedHookReturn<
    TStageNameObject,
    TStageNameShowObject,
    TStageNameCreateObject,
    TStageNameUpdateObject,
    TStageNameDestroyObject,
    TUserObject
  >;

const StageNameContext = createContext<TStageNameContext>(
  INITIAL_STATE as TStageNameContext,
);

const AppServices = new StageNameAppServices();

export function StageNameProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [state, setState] =
    useState<TSharedHookInitialState<TStageNameObject, TUserObject>>(
      INITIAL_STATE,
    );

  const { setErrorHandlerData } = useErrorHandler();

  const sharedHook = useSharedHook<
    TStageNameObject,
    TStageNameShowObject,
    TStageNameCreateObject,
    TStageNameUpdateObject,
    TStageNameDestroyObject,
    TUserObject
  >({
    setState,
    AppServices,
    setErrorHandlerData,
  });

  return (
    <StageNameContext.Provider value={{ ...state, ...sharedHook }}>
      {children}
    </StageNameContext.Provider>
  );
}

export function useStageName(): TStageNameContext {
  const context = useContext(StageNameContext);

  if (!context)
    throw new Error('useStageName must be used within an StageNameProvider');

  return context;
}
