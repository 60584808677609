import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';
import MUIBox from '@material-ui/core/Box';
import MUIGrid from '@material-ui/core/Grid';

export { Page } from '@shared/components/Page';
export { default as Paper } from '@material-ui/core/Paper';
export { default as Tabs } from '@material-ui/core/Tabs';
export { default as Tab } from '@material-ui/core/Tab';
export { default as CircularProgress } from '@material-ui/core/CircularProgress';

export { ProjectManageContent } from './Content';
export { ProjectUserListContent } from '@modules/projects/pages/ProjectUser/List/Content';

export const Container = styled(MUIBox)`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

export const CircularProgressContainer = styled(MUIGrid)`
  position: fixed;
  top: 60%;
  left: 50%;
`;

export const SwipeableContainer = styled(SwipeableViews)``;
