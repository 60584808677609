import { GridProps } from '@material-ui/core';

import { Container } from './styles';

type TFormGridItem = GridProps;

export function FormGridItem({
  children,
  ...rest
}: TFormGridItem): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
