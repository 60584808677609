import { APP_KEY_NAME } from '@shared/constants/shared';

import { useSession } from '@modules/sessions/hooks/session';

import { Container, CodeAuthIframe } from './styles';

export function SettingCodeAuth(): JSX.Element {
  const { token, current_accesses, logged_branch, contract } = useSession();

  const auxToken = token?.token;
  const type = token?.type;
  const refreshToken = token?.refreshToken;

  const appOrigin = process.env.REACT_APP_FRONT_URL;

  const application_key = APP_KEY_NAME;
  const auxContract = {
    uuid: contract?.uuid,
    contract_key: contract?.contract_key,
  };

  const loggedBranch = {
    company_key: logged_branch?.company_key,
    branch_key: logged_branch?.branch_key,
  };

  const tokenParams = [
    auxToken,
    type,
    refreshToken,
    JSON.stringify(auxContract),
    appOrigin,
    JSON.stringify(current_accesses),
    application_key,
    JSON.stringify(loggedBranch),
    'settings',
  ].map(param => `param=${param}`);

  return (
    <Container>
      <CodeAuthIframe queryParams={tokenParams} />
    </Container>
  );
}
