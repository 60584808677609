import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useProject } from '@modules/projects/hooks/project';
import { useProjectStage } from '@modules/projects/hooks/projectStage';
import { useProjectSubStage } from '@modules/projects/hooks/projectSubStage';
import { useSession } from '@modules/sessions/hooks/session';

import { BASE_URL as PROJECT_SUB_STAGE_BASE_URL } from '@modules/projects/constants/projectSubStage';

import { TProjectStageObject } from '@modules/projects/@types/TProjectStage';
import {
  TProjectSubStageObject,
  TProjectSubStageShowObject,
} from '@modules/projects/@types/TProjectSubStage';
import { TLoggedBranch } from '@modules/sessions/@types/TSession';

import {
  ToShow,
  ToDestroy as ToDestroyProjectStage,
} from '@modules/projects/sanitizers/projectStage';
import { ToDestroy as ToDestroyProjectSubStage } from '@modules/projects/sanitizers/projectSubStage';

import { ALL_PERMISSION_KEYS } from '@shared/constants/shared';
import {
  Container,
  ProjectContainer,
  TitleContent,
  PrefixOfLabels,
  HeaderLabel,
  BodyLabel,
  Title,
  StagesContainer,
  ProjectCardContainer,
  ProjectCard,
  ProjectCardHeader,
  ProjectCardHeaderLabelContainer,
  ProjectCardBody,
  BodyLinesContainer,
  BodyLine,
  BodyButton,
  ProjectCardStatus,
  Divider,
  SubStageContainer,
  ArrowContainer,
  VerticalLine,
  LastVerticalLine,
  HorizontalLine,
  ArrowForward,
  Dialog,
  DialogHeader,
  IconButton,
  CloseIcon,
  DialogTitle,
  ProjectSubStageFormContent,
  ReopenProject,
  HasPermissionComponent,
  ArrowDropDownIcon,
  ArrowLeftIcon,
  GoToTopButton,
  DialogContentContainer,
  CardCollapse,
  StageActionsContainer,
  Tooltip,
  EditIcon,
  DeleteIcon,
  RemoveItemDialog,
  CircularProgress,
  LoadingAfterDeleteContainer,
} from './styles';

export function ProjectManageContent(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const { logged_branch } = useSession();
  const { show: projectShow, registerShow, showLoading } = useProject();
  const {
    show: projectStageShow,
    clearState: projectStageClearState,
    destroy: projectStageDestroy,
    registerShow: projectStageRegisterShow,
    listLoading: projectStageListLoading,
  } = useProjectStage();
  const {
    formData: projectSubStageFormData,
    clearState: projectSubStageClearState,
    destroy: projectSubStageDestroy,
    listLoading: projectSubStageListLoading,
  } = useProjectSubStage();
  const { logged_user, current_accesses } = useSession();

  const [registerSelected, setRegisterSelected] = useState<{
    isCreateDialogOpen: boolean;
    register: string;
    toUpdate: boolean;
  }>({ isCreateDialogOpen: false, register: '', toUpdate: false });
  const [collapsedProjectStageUuids, setCollapsedProjectStageUuids] = useState<
    Record<string, boolean>
  >({});
  const [selectedCard, setSelectedCard] = useState(false);
  const [dialogState, setDialogState] = useState<{
    isOpen: boolean;
    register?: TProjectStageObject | TProjectSubStageObject;
  }>({
    isOpen: false,
    register: undefined,
  });

  function handleOpenDialog(
    project_stage: TProjectSubStageShowObject,
    toUpdate: boolean,
  ) {
    projectSubStageFormData({ project_uuid: registerShow?.uuid as string });

    if (toUpdate)
      projectStageShow({ register: ToShow({ register: project_stage }) });

    setRegisterSelected(oldData => ({
      ...oldData,
      isCreateDialogOpen: true,
      register: project_stage.uuid,
      toUpdate,
    }));
  }

  function handleCloseDialog() {
    setRegisterSelected(oldData => ({
      ...oldData,
      isCreateDialogOpen: false,
    }));

    projectSubStageClearState();
    projectStageClearState();
  }

  function handleCollapseSubStage(projectStageUuid: string) {
    setCollapsedProjectStageUuids(oldData => ({
      ...oldData,
      [projectStageUuid]: !oldData[projectStageUuid],
    }));

    setSelectedCard(true);
  }

  async function handleRemoveProjectStageOrSubStage(
    register: TProjectStageObject | TProjectSubStageObject,
  ) {
    if (register.project_sub_stages)
      await projectStageDestroy({
        register: ToDestroyProjectStage({
          register: register as TProjectStageObject,
          logged_branch: logged_branch as TLoggedBranch,
        }),
      });
    else
      await projectSubStageDestroy({
        register: ToDestroyProjectSubStage({
          register: register as TProjectSubStageObject,
          logged_branch: logged_branch as TLoggedBranch,
        }),
      });

    await projectShow({ register: { uuid: registerShow?.uuid || '' } });
  }

  useEffect(() => {
    const { state } = location;

    if (state) {
      setSelectedCard(true);
      registerShow?.project_stages?.forEach(project_stage => {
        setCollapsedProjectStageUuids(oldData => ({
          ...oldData,
          [project_stage.uuid]: true,
          [location.state.stage_uuid]: false,
        }));
      });
      setTimeout(() => {
        document.getElementById('project-manage-container')?.scrollTo({
          top: document.getElementById(location.state.stage_uuid)?.offsetTop,
        });
      }, 200);
    } else {
      setSelectedCard(false);
      registerShow?.project_stages?.forEach(project_stage => {
        setCollapsedProjectStageUuids(oldData => ({
          ...oldData,
          [project_stage.uuid]: true,
        }));
      });
    }
  }, [location, registerShow?.project_stages]);

  return (
    <Container id="project-manage-container">
      <GoToTopButton elementId="project-manage-container" />
      <Dialog
        fullScreen
        open={registerSelected.isCreateDialogOpen}
        onClose={handleCloseDialog}
      >
        <DialogHeader container>
          <DialogTitle>
            {projectStageRegisterShow ? 'Editar  Atividade' : 'Nova Atividade'}
          </DialogTitle>

          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogHeader>

        <DialogContentContainer>
          <ProjectSubStageFormContent
            project_stage_uuid={registerSelected.register}
            toUpdate={registerSelected.toUpdate}
            onSuccess={handleCloseDialog}
            onCancel={handleCloseDialog}
          />
        </DialogContentContainer>
      </Dialog>

      <RemoveItemDialog
        isOpen={dialogState.isOpen}
        confirmRegister={dialogState.register}
        warnMessage="Ao remover esta etapa, todas mensagens em atividades serão
        removidas e outra etapa não poderá ser criada neste projeto."
        setIsOpen={setDialogState}
        handleConfirmFunction={handleRemoveProjectStageOrSubStage}
      />

      <ProjectContainer>
        <TitleContent container>
          <PrefixOfLabels>Projeto</PrefixOfLabels>

          <Title>{registerShow?.name}</Title>
        </TitleContent>

        {registerShow?.project_stages?.map(project_stage => (
          <StagesContainer
            container
            key={project_stage.uuid}
            id={project_stage.uuid}
          >
            <ProjectCardContainer container>
              <ProjectCard
                container
                justifyContent="space-between"
                direction="column"
              >
                <ProjectCardHeader container>
                  <ProjectCardHeaderLabelContainer>
                    <PrefixOfLabels>Etapa:</PrefixOfLabels>
                    <HeaderLabel>{project_stage?.stage_name?.name}</HeaderLabel>
                  </ProjectCardHeaderLabelContainer>

                  <ReopenProject
                    is_reopen={project_stage.is_reopen}
                    status={project_stage.status}
                    size={24}
                  />
                </ProjectCardHeader>

                <Divider />

                <ProjectCardBody container>
                  <BodyLinesContainer container>
                    <BodyLine container>
                      <PrefixOfLabels>Dirigente:</PrefixOfLabels>
                      <BodyLabel>{project_stage.responsible?.name}</BodyLabel>
                    </BodyLine>
                  </BodyLinesContainer>

                  <HasPermissionComponent
                    permission_key={[
                      ALL_PERMISSION_KEYS.project_sub_stage_create,
                    ]}
                    element={
                      <BodyButton
                        backgroundcolor="primary"
                        onClick={() => handleOpenDialog(project_stage, false)}
                      >
                        Nova Atividade
                      </BodyButton>
                    }
                  />
                </ProjectCardBody>

                <ProjectCardStatus status={project_stage.status} />
              </ProjectCard>

              <StageActionsContainer>
                {project_stage?.project_sub_stages?.length ? (
                  <IconButton
                    onClick={() => handleCollapseSubStage(project_stage.uuid)}
                  >
                    {!collapsedProjectStageUuids[project_stage.uuid] ? (
                      <ArrowDropDownIcon fontSize="large" />
                    ) : (
                      <ArrowLeftIcon fontSize="large" />
                    )}
                  </IconButton>
                ) : (
                  <></>
                )}

                {logged_user?.is_manager && (
                  <>
                    <Tooltip title="Editar" arrow placement="right-start">
                      <IconButton
                        onClick={() => handleOpenDialog(project_stage, true)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      title="Remover Etapa"
                      arrow
                      placement="right-start"
                    >
                      <IconButton
                        onClick={() =>
                          setDialogState({
                            isOpen: true,
                            register: project_stage,
                          })
                        }
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </StageActionsContainer>
            </ProjectCardContainer>

            {projectStageListLoading ||
            projectSubStageListLoading ||
            showLoading ? (
              <LoadingAfterDeleteContainer>
                <CircularProgress />
              </LoadingAfterDeleteContainer>
            ) : (
              <>
                {project_stage?.project_sub_stages?.map(project_sub_stage => (
                  <CardCollapse
                    in={
                      !collapsedProjectStageUuids[project_stage.uuid] &&
                      selectedCard
                    }
                  >
                    <SubStageContainer container key={project_sub_stage.uuid}>
                      <ArrowContainer container>
                        {project_stage?.project_sub_stages &&
                        project_sub_stage ===
                          project_stage?.project_sub_stages[
                            project_stage?.project_sub_stages?.length - 1
                          ] ? (
                          <LastVerticalLine />
                        ) : (
                          <VerticalLine />
                        )}

                        <HorizontalLine />

                        <ArrowForward />
                      </ArrowContainer>

                      <ProjectCardContainer
                        container
                        sub_stage="true"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '600px',
                        }}
                      >
                        <ProjectCard
                          container
                          justifyContent="space-between"
                          direction="column"
                        >
                          <ProjectCardHeader container>
                            <ProjectCardHeaderLabelContainer>
                              <PrefixOfLabels>Atividade:</PrefixOfLabels>
                              <HeaderLabel>
                                {project_sub_stage?.stage_name?.name}
                              </HeaderLabel>
                            </ProjectCardHeaderLabelContainer>

                            <ReopenProject
                              is_reopen={project_sub_stage.is_reopen}
                              status={project_sub_stage.status}
                              size={24}
                            />
                          </ProjectCardHeader>

                          <Divider />

                          <ProjectCardBody container>
                            <BodyLinesContainer container>
                              <BodyLine container>
                                <PrefixOfLabels>Dirigente:</PrefixOfLabels>
                                <BodyLabel>
                                  {project_sub_stage.responsible?.name}
                                </BodyLabel>
                              </BodyLine>

                              <BodyLine container>
                                <PrefixOfLabels>
                                  Última atualização:
                                </PrefixOfLabels>
                                <BodyLabel>
                                  {project_sub_stage.updated_at_view}
                                </BodyLabel>
                              </BodyLine>
                            </BodyLinesContainer>

                            {!logged_user?.is_manager &&
                            !current_accesses?.roles['plataforma_h.customer'] &&
                            project_sub_stage?.stage_name?.name ===
                              'CONTRATO' ? (
                              <></>
                            ) : (
                              <BodyButton
                                backgroundcolor="secondary"
                                onClick={() =>
                                  navigate(
                                    `/${PROJECT_SUB_STAGE_BASE_URL}/${project_sub_stage.uuid}`,
                                    { state: { showing: true } },
                                  )
                                }
                              >
                                Gerenciar
                              </BodyButton>
                            )}
                          </ProjectCardBody>

                          <ProjectCardStatus
                            status={project_sub_stage.status}
                          />
                        </ProjectCard>

                        {logged_user?.is_manager && (
                          <StageActionsContainer>
                            <Tooltip
                              title="Remover Atividade"
                              arrow
                              placement="right-start"
                            >
                              <IconButton
                                onClick={() =>
                                  setDialogState({
                                    isOpen: true,
                                    register: project_sub_stage,
                                  })
                                }
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </StageActionsContainer>
                        )}
                      </ProjectCardContainer>
                    </SubStageContainer>
                  </CardCollapse>
                ))}
              </>
            )}
          </StagesContainer>
        ))}
      </ProjectContainer>
    </Container>
  );
}
