import styled from 'styled-components';
import MUIGrid from '@material-ui/core/Grid';
import MUITypography from '@material-ui/core/Typography';
import MUIAvatar from '@material-ui/core/Avatar';
import MUILink from '@material-ui/core/Link';
import MUIIconButton from '@material-ui/core/IconButton';

import { FormConfirmButton, FormCancelButton } from '@shared/components/Form';

export { default as FolderIcon } from '@material-ui/icons/Folder';
export { default as DeleteIcon } from '@material-ui/icons/Delete';
export { default as Dialog } from '@material-ui/core/Dialog';
export { default as DialogTitle } from '@material-ui/core/DialogTitle';
export { default as CloseIcon } from '@material-ui/icons/Close';
export { default as DialogContent } from '@material-ui/core/DialogContent';
export { default as CircularProgress } from '@material-ui/core/CircularProgress';

export { GoToTopButton } from '@shared/components/GoToTopButton';
export { FormGridContainer } from '@shared/components/Form';

export const MessagesContainer = styled(MUIGrid).attrs({ container: true })`
  overflow: auto;
`;

export const MessageContainer = styled(MUIGrid).attrs({
  container: true,
})`
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey_scale_3.main};
`;

export const ImageContent = styled(MUIGrid).attrs({
  item: true,
  xs: false,
})`
  padding: 0.5rem;
`;

export const UserImage = styled(MUIAvatar)``;

export const MessageContent = styled(MUIGrid).attrs({
  item: true,
  xs: true,
})`
  width: 100%;
  padding: 0.5rem;
`;

export const MessageInfosContainer = styled(MUIGrid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const UserName = styled(MUITypography).attrs({
  variant: 'subtitle1',
})`
  margin-right: 6px;
  font-weight: 500;
`;

export const MessageUpdatedAt = styled(MUITypography).attrs({
  variant: 'body2',
})`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const MessageAttachedFilesContainer = styled(MUIGrid).attrs({
  container: true,
})``;

export const MessageAttachedFiles = styled(MUIGrid)`
  margin-right: 6px;
  padding: 4px;
  border-radius: 1rem;
  background: ${({ theme }) => theme.palette.attached_file_background.main};
  color: ${({ theme }) => theme.palette.accent_blue.main};
  &:hover {
    background: ${({ theme }) => theme.palette.attached_file_background.main};
    filter: brightness(90%);
  }
  width: auto;
  display: flex;
  flex-direction: row;
`;

export const Link = styled(MUILink)`
  text-decoration: none;
`;

export const MessageTextContainer = styled(MUIGrid)`
  img {
    max-width: 640px;
    max-height: 480px;
  }
`;

export const Message = styled(MUITypography).attrs({
  variant: 'body2',
})`
  margin-top: 0.3rem;
  img {
    max-width: 240;
    max-height: 160;
    overflow: hidden;
  }
`;

export const ContainerInfos = styled(MUIGrid)`
  display: flex;
  align-items: center;
`;

export const IconButton = styled(MUIIconButton)``;

export const DialogHeader = styled(MUIGrid)`
  display: flex;
  justify-content: space-between;
`;

export const ConfirmMessage = styled(MUITypography).attrs({ variant: 'h6' })``;

export const ConfirmButton = styled(FormConfirmButton).attrs({
  gridProps: { xs: 6 },
})``;

export const CancelButton = styled(FormCancelButton).attrs({
  gridProps: { xs: 6 },
})``;

export const Text = styled(MUITypography).attrs({ variant: 'h4' })`
  color: ${({ theme }) => theme.palette.grey_scale_1.main};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CircularProgressContainer = styled(MUIGrid)`
  position: fixed;
  top: 30%;
  left: 60%;
`;
