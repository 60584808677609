import { createTheme, PaletteOptions, ThemeOptions } from '@material-ui/core';

import { STATUSES } from '@modules/projects/constants/project';

const palette: PaletteOptions = {
  mode: 'dark',

  secondary_background: {
    main: '#212121',
  },

  grey_scale_1: {
    main: '#E0E0E0',
  },

  grey_scale_2: {
    main: '#757575',
  },

  grey_scale_3: {
    main: '#3D3D3D',
  },

  grey_scale_4: {
    main: '#3F3F3F',
  },

  primary: {
    main: '#FF9300',
  },

  secondary: {
    main: '#191B37',
  },

  accent_blue: {
    main: '#1D87C7',
  },

  attached_file_background: {
    main: '#404850',
  },

  input_text_color: {
    main: '#FFFFFF',
    contrastText: '#FFFFFF',
    dark: '#AAAAAA',
  },

  [STATUSES.IN_PROGRESS]: {
    main: '#D2BB17',
  },

  [STATUSES.TO_VALIDATE]: {
    main: '#10A7DF',
  },

  [STATUSES.PENDING]: {
    main: '#E44A35',
  },

  [STATUSES.TO_START]: {
    main: '#A4A4A4',
  },

  [STATUSES.DONE]: {
    main: '#369E66',
  },

  is_reopen_and_concluded: {
    main: '#3DC60E',
  },
};

export const theme: ThemeOptions = createTheme({
  palette,
  typography: { button: { textTransform: 'none' } },
});
