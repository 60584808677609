import * as yup from 'yup';

export const sessionCreateValidatorSchema = yup.object().shape({
  email: yup
    .string()
    .max(100, 'Este campo deve conter no máximo 100 caracteres')
    .required('Este campo é obrigatório'),
  password: yup
    .string()
    .min(6, 'Este campo deve conter no mínimo 6 caracteres')
    .max(100, 'Este campo deve conter no máximo 100 caracteres')
    .required('Este campo é obrigatório'),
  branch_key: yup
    .string()
    .min(8, 'Este campo deve conter no mínimo 8 caracteres')
    .max(14, 'Este campo deve conter no máximo 14 caracteres')
    .required('Este campo é obrigatório'),
});
