import styled from 'styled-components';
import MUIGrid from '@material-ui/core/Grid';

export const Container = styled(MUIGrid).attrs({
  container: true,
})`
  display: block;
  padding: 0.5rem;
  width: 100%;
  overflow-x: auto;

  background: ${({ theme }) => theme.palette.secondary_background.main};
`;
