import { HasPermission } from '@shared/validators/permissionsValidator';

type THasPermissionComponentObject = {
  permission_key: string[];
  element: JSX.Element;
  fall_back_element?: JSX.Element;
  enabled_to_view?: boolean[];
};

export function HasPermissionComponent({
  permission_key,
  element,
  fall_back_element,
  enabled_to_view,
}: THasPermissionComponentObject): JSX.Element {
  return HasPermission({
    permission_keys: permission_key,
  }) && enabled_to_view?.includes(true)
    ? element
    : fall_back_element || <></>;
}

HasPermissionComponent.defaultProps = {
  fall_back_element: undefined,
  enabled_to_view: [true],
};
