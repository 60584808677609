import { SharedAppRepositories } from '@shared/repositories/app/shared';

import { FromCreateApi } from '@modules/projects/sanitizers/projectMessage';

import { BASE_URL } from '@modules/projects/constants/projectMessage';

import {
  TProjectMessageObject,
  TProjectMessageShowObject,
  TProjectMessageCreateObject,
  TProjectMessageUpdateObject,
  TProjectMessageDestroyObject,
} from '@modules/projects/@types/TProjectMessage';
import { TUserObject } from '@modules/users/@types/TUser';

export class ProjectMessageAppRepositories extends SharedAppRepositories<
  TProjectMessageObject,
  TProjectMessageShowObject,
  TProjectMessageCreateObject,
  TProjectMessageUpdateObject,
  TProjectMessageDestroyObject,
  TUserObject
> {
  constructor() {
    super(BASE_URL, undefined, FromCreateApi);
  }
}
