import {
  TBaseProjectMessageObject,
  TProjectMessageShowObject,
  TProjectMessageCreateObject,
  TProjectMessageUpdateObject,
  TProjectMessageDestroyObject,
  TProjectMessageObject,
} from '@modules/projects/@types/TProjectMessage';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import { TSharedValidatedApiReturn } from '@shared/@types/TShared';
import {
  ToBaseShowSanitizer,
  ToBaseCreateSanitizer,
  ToBaseUpdateSanitizer,
  ToBaseDestroySanitizer,
  FromBaseApi,
} from '@shared/sanitizers/shared';
import { TProjectMessageFileObject } from '../@types/TProjectMessageFile';

function ToBaseSanitizer({
  register,
}: {
  register: TBaseProjectMessageObject;
}): TBaseProjectMessageObject {
  const parsedObject: TBaseProjectMessageObject = {
    project_sub_stage_uuid: register.project_sub_stage_uuid,
    text: register.text,
    notificate_users: register.notificate_users,
  };

  return parsedObject;
}

export function ToShow({
  register,
}: {
  register: TProjectMessageShowObject;
}): TProjectMessageShowObject {
  const parsedObject: TProjectMessageShowObject = {
    ...ToBaseShowSanitizer({ register }),
  };

  return parsedObject;
}

export function ToCreate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TProjectMessageCreateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TProjectMessageCreateObject {
  const parsedObject: TProjectMessageCreateObject = {
    ...ToBaseCreateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
    project_message_files: [],
  };

  return parsedObject;
}

export function ToUpdate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TProjectMessageUpdateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TProjectMessageUpdateObject {
  const parsedObject: TProjectMessageUpdateObject = {
    ...ToBaseUpdateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToDestroy({
  register,
  logged_branch,
}: {
  register: TProjectMessageUpdateObject;
  logged_branch: TLoggedBranch;
}): TProjectMessageDestroyObject {
  const parsedObject: TProjectMessageDestroyObject = {
    ...ToBaseDestroySanitizer({ register, logged_branch }),
  };

  return parsedObject;
}

export function FromCreateApi({
  registers,
}: {
  registers: TProjectMessageObject[];
}): TProjectMessageObject[] {
  const parsedObjects: TProjectMessageObject[] = registers.map(register => {
    const project_message_files =
      register.project_message_files as unknown as TSharedValidatedApiReturn<TProjectMessageFileObject>['data'];

    const parsedProjectMessageFiles = project_message_files?.map(
      projectStage => projectStage.register,
    );

    const parsedRegister = {
      ...register,
      ...FromBaseApi({ registers: [register] })[0],
      project_message_files: parsedProjectMessageFiles,
    };

    return parsedRegister;
  });

  return parsedObjects;
}

export function FromApi({
  registers,
}: {
  registers: TProjectMessageObject[];
}): TProjectMessageObject[] {
  const parsedObjects: TProjectMessageObject[] = registers.map(register => ({
    ...register,
    ...FromBaseApi({ registers: [register] })[0],
  }));

  return parsedObjects;
}
