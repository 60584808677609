import { SharedAppServices } from '@shared/services/app/shared';

import { TemplateAppRepositories } from '@modules/templates/repositories/app/template';

import {
  TTemplateObject,
  TTemplateShowObject,
  TTemplateCreateObject,
  TTemplateUpdateObject,
  TTemplateDestroyObject,
} from '@modules/templates/@types/TTemplate';
import { TUserObject } from '@modules/users/@types/TUser';

export class TemplateAppServices extends SharedAppServices<
  TTemplateObject,
  TTemplateShowObject,
  TTemplateCreateObject,
  TTemplateUpdateObject,
  TTemplateDestroyObject,
  TUserObject
> {
  constructor() {
    const appRepositories = new TemplateAppRepositories();

    super(appRepositories);
  }
}
