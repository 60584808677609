import { Routes, Route, Navigate } from 'react-router-dom';

import { SessionRoutes } from '@modules/sessions/routes/session.routes';

export function NotSignedRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/*" element={<SessionRoutes />} />

      <Route path="/not-found" element={<Navigate replace to="/" />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}
