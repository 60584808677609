import { SharedAppServices } from '@shared/services/app/shared';

import { ProjectAppRepositories } from '@modules/projects/repositories/app/project';

import {
  TProjectObject,
  TProjectShowObject,
  TProjectCreateObject,
  TProjectUpdateObject,
  TProjectDestroyObject,
} from '@modules/projects/@types/TProject';
import { TUserObject } from '@modules/users/@types/TUser';

export class ProjectAppServices extends SharedAppServices<
  TProjectObject,
  TProjectShowObject,
  TProjectCreateObject,
  TProjectUpdateObject,
  TProjectDestroyObject,
  TUserObject
> {
  constructor() {
    const appRepositories = new ProjectAppRepositories();

    super(appRepositories);
  }
}
