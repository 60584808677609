import * as yup from 'yup';

import {
  sharedBaseCreateValidatorSchema,
  sharedBaseShowValidatorSchema,
  sharedBaseUpdateValidatorSchema,
} from '@shared/validators/shared';

import { STAGE_NAME_TYPES } from '@modules/templates/constants/stageName';

import {
  TStageNameCreateObject,
  TStageNameShowObject,
  TStageNameUpdateObject,
} from '@modules/templates/@types/TStageName';

export const templateBaseCreateOrUpdateValidatorSchema = yup.object().shape({
  type: yup
    .mixed()
    .oneOf(
      Object.values(STAGE_NAME_TYPES),
      `Este campo deve ser algum dos valores: ${Object.values(
        STAGE_NAME_TYPES,
      )}`,
    )
    .required('Este campo é obrigatório'),
  name: yup
    .string()
    .max(255, 'Este campo deve conter no máximo 255 caracteres')
    .required('Este campo é obrigatório'),
});

export const createValidatorSchema = yup
  .object()
  .concat(templateBaseCreateOrUpdateValidatorSchema)
  .concat(
    sharedBaseCreateValidatorSchema,
  ) as yup.SchemaOf<TStageNameCreateObject>;

export const updateValidatorSchema = yup
  .object()
  .concat(templateBaseCreateOrUpdateValidatorSchema)
  .concat(
    sharedBaseUpdateValidatorSchema,
  ) as yup.SchemaOf<TStageNameUpdateObject>;

export const showValidatorSchema = yup
  .object()
  .concat(sharedBaseShowValidatorSchema) as yup.SchemaOf<TStageNameShowObject>;
