import styled from 'styled-components';
import { Editor } from 'react-draft-wysiwyg';
import MUIFormHelperText from '@material-ui/core/FormHelperText';
import MUIGrid from '@material-ui/core/Grid';

export { FormSkeleton } from '@shared/components/Form';

export const EditorContainer = styled(MUIGrid)`
  width: 100%;

  .DraftEditor-root {
    img {
      max-width: 640px;
      max-height: 480px;
    }
  }
`;

export const Container = styled(Editor).attrs({
  placeholder: 'Escreva...',
  editorStyle: {
    height: '20vh',
    border: '1px solid #E5E5E5',
    borderRadius: '0.25rem',
    padding: '0 4px',
  },
  wrapperStyle: { width: '100%' },
})``;

export const FormHelperText = styled(MUIFormHelperText)`
  color: ${({ theme }) => theme.palette.error.main};
`;
