import { ISessionUpdateAppService } from '@modules/sessions/services/app/implementations/ISession';
import { ISessionUpdateAppRepository } from '@modules/sessions/repositories/app/implementations/ISession';
import { ISessionUpdateLocalStorageRepository } from '@modules/sessions/repositories/localStorage/implementations/ISession';

import { TCodeAuthSession } from '@modules/sessions/@types/TSession';
import { apiHttpClientConfig } from '@shared/providers/api/configs/httpClient';

export class SessionUpdateAppService implements ISessionUpdateAppService {
  constructor(
    private readonly sessionUpdateAppRepository: ISessionUpdateAppRepository,
    private readonly sessionUpdateLocalStorageRepository: ISessionUpdateLocalStorageRepository,
  ) {}

  public async execute(): Promise<TCodeAuthSession> {
    const updateAppRepositoryResult =
      await this.sessionUpdateAppRepository.execute();

    this.sessionUpdateLocalStorageRepository.execute(updateAppRepositoryResult);

    apiHttpClientConfig.defaults = {
      ...apiHttpClientConfig.defaults,
      headers: {
        ...apiHttpClientConfig.defaults.headers,
        authorization: `Bearer ${updateAppRepositoryResult.token?.token}`,
        'Refresh-Token': `${updateAppRepositoryResult.token?.refreshToken}`,
      },
    };

    return updateAppRepositoryResult;
  }
}
