import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProject } from '@modules/projects/hooks/project';

import { ALL_PERMISSION_KEYS } from '@shared/constants/shared';

import { HasPermission } from '@shared/validators/permissionsValidator';
import { useSession } from '@modules/sessions/hooks/session';
import { Forbidden } from '@shared/pages/Forbidden';

import {
  Page,
  Container,
  Paper,
  Tabs,
  Tab,
  SwipeableContainer,
  ProjectManageContent,
  ProjectUserListContent,
  CircularProgressContainer,
  CircularProgress,
} from './styles';

export function ProjectManage(): JSX.Element {
  const { show, registerShow, showLoading } = useProject();
  const params = useParams();
  const [userCanAccess, setUserCanAccess] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const { logged_user, sessionLoading } = useSession();

  const handleChangeIndex = (newTabIndex: number) => setTabIndex(newTabIndex);

  useEffect(() => {
    registerShow?.project_users?.forEach(user => {
      if (user.user_uuid === logged_user?.uuid) {
        setUserCanAccess(true);
      }
    });
  }, [logged_user?.uuid, registerShow?.project_users]);

  useEffect(() => {
    show({ register: { uuid: params.uuid || '' } });
  }, []); // eslint-disable-line

  return (
    <Page title="Gerenciar Projeto">
      {showLoading && (
        <CircularProgressContainer>
          <CircularProgress />
        </CircularProgressContainer>
      )}
      {userCanAccess || logged_user?.is_manager ? (
        <Container>
          <Paper>
            <Tabs
              value={tabIndex}
              onChange={(_, index) => handleChangeIndex(index)}
              indicatorColor="primary"
              centered
            >
              <Tab label="Projeto" />
              {HasPermission({
                permission_keys: [ALL_PERMISSION_KEYS.project_users_list],
              }) && <Tab label="Envolvidos" />}
            </Tabs>
          </Paper>

          {!registerShow?.uuid ? (
            <Container />
          ) : (
            <SwipeableContainer index={tabIndex}>
              <ProjectManageContent />
              <ProjectUserListContent project_uuid={registerShow.uuid} />
            </SwipeableContainer>
          )}
        </Container>
      ) : (
        <>
          {!showLoading && !userCanAccess && !sessionLoading && <Forbidden />}
        </>
      )}
    </Page>
  );
}
