import { v4 as uuidv4 } from 'uuid';

import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import {
  TBaseSanitizerSharedObject,
  TSharedShowObject,
  TSharedCreateObject,
  TSharedDestroyObject,
  TSharedUpdateObject,
  TSharedObject,
} from '@shared/@types/TShared';

function ToBaseSanitizer({
  logged_branch,
}: {
  logged_branch: TLoggedBranch;
}): TBaseSanitizerSharedObject {
  const parsedObject: TBaseSanitizerSharedObject = {
    req_id: uuidv4(),
    company_key: logged_branch.company_key,
    branch_key: logged_branch.branch_key,
    branch_name: logged_branch.first_name,
  };

  return parsedObject;
}

export function ToBaseShowSanitizer({
  register,
}: {
  register: TSharedShowObject;
}): TSharedShowObject {
  const parsedObject: TSharedShowObject = {
    uuid: register.uuid,
  };

  return parsedObject;
}

export function ToBaseCreateSanitizer({
  logged_branch,
}: {
  register: Record<string, unknown>;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TSharedCreateObject {
  const parsedObject: TSharedCreateObject = {
    ...ToBaseSanitizer({
      logged_branch,
    }),
  };

  return parsedObject;
}

export function ToBaseUpdateSanitizer({
  register,
  logged_branch,
}: {
  register: TSharedUpdateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TSharedUpdateObject {
  const parsedObject: TSharedUpdateObject = {
    ...ToBaseSanitizer({
      logged_branch,
    }),
    uuid: register.uuid,
  };

  return parsedObject;
}

export function ToBaseDestroySanitizer({
  register,
  logged_branch,
}: {
  register: TSharedUpdateObject;
  logged_branch: TLoggedBranch;
}): TSharedDestroyObject {
  const parsedObject: TSharedDestroyObject = {
    req_id: uuidv4(),
    uuid: register.uuid,
    company_key: logged_branch.company_key,
    branch_key: logged_branch.branch_key,
    branch_name: logged_branch.first_name,
  };

  return parsedObject;
}

export function FromBaseApi<TRegister extends TSharedObject = TSharedObject>({
  registers,
}: {
  registers: TRegister[];
}): TRegister[] {
  const parsedObjects: TRegister[] = registers.map(register => ({
    ...register,
    updated_at_view: new Date(register.updated_at).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }),
  }));

  return parsedObjects;
}
