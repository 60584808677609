import {
  Container,
  ForbiddenText,
  ForbiddenSubText,
  ForbiddenErrorText,
} from './styles';

export function Forbidden(): JSX.Element {
  return (
    <Container>
      <ForbiddenText>403</ForbiddenText>
      <ForbiddenSubText>Acesso Negado</ForbiddenSubText>
      <ForbiddenErrorText>
        Você não possui permissão para acessar essa página.
      </ForbiddenErrorText>
    </Container>
  );
}
