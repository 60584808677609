import { SEARCH_TYPES } from '@shared/constants/shared';

import { StageNameAppServices } from '@modules/templates/services/app/stageName';

import { TStageNameObject } from '@modules/templates/@types/TStageName';

const stageNameAppServices = new StageNameAppServices();

export async function listSelectorStageName({
  search,
}: {
  search: string;
}): Promise<TStageNameObject[]> {
  const stageNames = await stageNameAppServices.listSelector({
    search: [
      {
        field: 'type',
        type: SEARCH_TYPES.range,
        values: ['stage', 'stage'],
      },
      {
        field: 'name',
        type: SEARCH_TYPES.normal,
        values: [search],
      },
    ],
  });

  return stageNames.data;
}
