import styled from 'styled-components';
import MUIButton from '@material-ui/core/Button';

import MUIGrid from '@material-ui/core/Grid';
import { FormGridItem } from '@shared/components/Form';

export { default as CircularProgress } from '@material-ui/core/CircularProgress';

export const Container = styled(FormGridItem)`
  padding: 0.3rem 0;
`;

export const Button = styled(MUIButton).attrs({
  type: 'submit' as 'button' | 'submit' | 'reset' | undefined,
  fullWidth: true as boolean,
  variant: 'contained' as 'text' | 'outlined' | 'contained' | undefined,
  color: 'primary' as
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined,
})`
  color: white;
`;

export const ContainerChildren = styled(MUIGrid)``;
