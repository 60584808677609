import notFoundAsset from '@shared/assets/notFound.svg';

import { Container } from './styles';

export function NotFound(): JSX.Element {
  return (
    <Container>
      <img src={notFoundAsset} alt="404" />
    </Container>
  );
}
