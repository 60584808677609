import { useState, useEffect, RefObject } from 'react';
import { toast } from 'react-toastify';
import { FormHandles } from '@unform/core';

import { useProjectUser } from '@modules/projects/hooks/projectUser';

import { TProjectMessageCreateObject } from '@modules/projects/@types/TProjectMessage';
import {
  TProjectSubStageUpdateObject,
  TProjectSubStageCreateObject,
} from '@modules/projects/@types/TProjectSubStage';
import { TProjectStageUpdateObject } from '@modules/projects/@types/TProjectStage';
import { TSharedListValues } from '@shared/@types/TShared';

import { SORT_TYPES, SEARCH_TYPES, PER_PAGE } from '@shared/constants/shared';

import {
  Container,
  GridContainer,
  FormControlLabel,
  Checkbox,
  FormGridContainer,
  FormTextInput,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  DialogHeader,
  DialogTitle,
  DialogContent,
  IconButton,
  CloseIcon,
  ConfirmButton,
  CancelButton,
  PaginationComponent,
  SearchIcon,
  CircularProgressContainer,
  CircularProgress,
} from './styles';

type TRelationsObject =
  | TProjectMessageCreateObject
  | TProjectSubStageUpdateObject
  | TProjectSubStageCreateObject
  | TProjectStageUpdateObject;

type TNotificateUsers<TRegister extends TRelationsObject> = {
  dialogState: {
    isOpen: boolean;
    register?: TRegister;
    toUpdate?: boolean;
  };
  formRef: RefObject<FormHandles>;
  projectUuid?: string;
  handleCloseDialog: () => void;
  onSubmit: (register: TRegister) => void;
};

export function NotificateUsersDialog<TRegister extends TRelationsObject>({
  dialogState,
  formRef,
  projectUuid,
  handleCloseDialog,
  onSubmit,
}: TNotificateUsers<TRegister>): JSX.Element {
  const { registerList, listTotal, listLoading, list } = useProjectUser();

  const [selectedRegisters, setSelectedRegisters] = useState<
    Record<string, boolean>
  >({});
  const [isAllRegistersSelected, setIsAllRegistersSelected] = useState(false);
  const [page, setPage] = useState(0);

  const project_users_data: TSharedListValues[] = [
    {
      field: 'project_uuid',
      type: SEARCH_TYPES.normal,
      values: [projectUuid || ''],
    },
  ];

  function handleClose() {
    handleCloseDialog();
    setSelectedRegisters({});
    setIsAllRegistersSelected(false);
    setPage(0);
  }

  function handleSelectUser(newValue: string) {
    setSelectedRegisters(oldData => ({
      ...oldData,
      [newValue]: !oldData[newValue],
    }));
  }

  function handleSelectAllUsers(
    _event: React.ChangeEvent<HTMLInputElement>,
    newValue: boolean,
  ) {
    registerList
      .map(register => register.user)
      .forEach(projectUser => {
        setSelectedRegisters(oldData => ({
          ...oldData,
          [projectUser.uuid]: newValue,
        }));
      });
    setIsAllRegistersSelected(newValue);
  }

  function onPageChange(newPage: number) {
    setPage(newPage);

    list({
      data: project_users_data,
      page: newPage + 1,
      per_page: PER_PAGE,
      order: SORT_TYPES.desc,
      order_by: 'updated_at',
      for_selector: false,
    });
  }

  function handleSearch() {
    const data: TSharedListValues[] = [
      {
        field: 'project_uuid',
        type: SEARCH_TYPES.normal,
        values: [projectUuid || ''],
      },
      {
        field: 'user',
        type: SEARCH_TYPES.relation,
        values: [
          {
            field: 'name',
            type: SEARCH_TYPES.normal,
            values: [formRef.current?.getFieldValue('name')],
          },
        ],
      },
    ];
    setPage(0);

    list({
      data,
      page: 1,
      per_page: PER_PAGE,
      order: SORT_TYPES.desc,
      order_by: 'updated_at',
      for_selector: false,
    });
  }

  function notificateSubmit() {
    const notificate_users: string[] = Object.keys(selectedRegisters).filter(
      userUuid => selectedRegisters[userUuid],
    );

    if (!dialogState.register || !notificate_users.length) {
      toast.warn('Selecione ao menos um usuário para ser notificado');
    } else {
      onSubmit({
        ...dialogState.register,
        notificate_users,
      });

      handleClose();
    }
  }

  function notNotificateSubmit() {
    if (dialogState.register) {
      onSubmit({
        ...dialogState.register,
        notificate_users: [],
      });
    }

    handleClose();
  }

  useEffect(() => {
    const registerValues = Object.values(selectedRegisters);
    if (!registerValues.includes(true)) setIsAllRegistersSelected(false);
  }, [selectedRegisters]);

  useEffect(() => {
    if (dialogState.isOpen) {
      list({
        data: project_users_data,
        page: 1,
        per_page: PER_PAGE,
        order: SORT_TYPES.desc,
        order_by: 'updated_at',
        for_selector: false,
      });
    }
  }, [dialogState.isOpen]); // eslint-disable-line

  return (
    <Container open={dialogState.isOpen} onClose={handleClose}>
      <DialogHeader container>
        <DialogTitle>Usuários a serem notificados</DialogTitle>

        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogHeader>

      <DialogContent>
        <FormGridContainer>
          <FormTextInput
            label="Usuário:"
            name="name"
            gridProps={{ xs: 8, sm: 8, md: 8 }}
          />
          <IconButton
            color="primary"
            style={{ width: '45px', height: '45px' }}
            onClick={handleSearch}
            disabled={listLoading}
          >
            {listLoading ? <CircularProgress /> : <SearchIcon />}
          </IconButton>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAllRegistersSelected}
                onChange={handleSelectAllUsers}
              />
            }
            label="Marcar todos"
          />
        </FormGridContainer>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer style={{ height: '250px', overflow: 'auto' }}>
            {listLoading ? (
              <CircularProgressContainer>
                <CircularProgress />
              </CircularProgressContainer>
            ) : (
              <Table size="small">
                <TableBody>
                  {registerList
                    .map(register => register.user)
                    .map(projectUser => (
                      <TableRow hover key={projectUser.uuid} role="checkbox">
                        <TableCell padding="checkbox" style={{ width: '100%' }}>
                          <FormControlLabel
                            key={projectUser.uuid}
                            control={
                              <Checkbox
                                checked={
                                  selectedRegisters[projectUser.uuid] || false
                                }
                                onChange={() =>
                                  handleSelectUser(projectUser.uuid)
                                }
                              />
                            }
                            label={projectUser.name}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>

          <PaginationComponent
            rowsPerPageOptions={[PER_PAGE]}
            count={listTotal}
            page={page}
            onPageChange={(_event, newPage) => onPageChange(newPage)}
            rowsPerPage={PER_PAGE}
          />
        </Paper>

        <GridContainer spacing={1}>
          <CancelButton onClick={notNotificateSubmit}>
            NÃO NOTIFICAR
          </CancelButton>
          <ConfirmButton onClick={notificateSubmit}>NOTIFICAR</ConfirmButton>
        </GridContainer>
      </DialogContent>
    </Container>
  );
}

NotificateUsersDialog.defaultProps = {
  projectUuid: undefined,
};
