import styled from 'styled-components';

import MUIGrid from '@material-ui/core/Grid';

export const Container = styled(MUIGrid).attrs({
  container: true,
  component: 'main',
})`
  padding: 0.5rem;
  width: 100%;
`;
