import { ButtonProps } from '@material-ui/core/Button';
import { GridProps } from '@material-ui/core/Grid';
import { ReactNode } from 'react';

import {
  Container,
  Button,
  CircularProgress,
  ContainerChildren,
} from './styles';

type TFormCancelButton = ButtonProps & {
  gridProps?: GridProps;
  loading?: boolean;
  children: ReactNode;
};

export function FormCancelButton({
  children,
  gridProps,
  loading,
  ...rest
}: TFormCancelButton): JSX.Element {
  return (
    <Container {...gridProps}>
      <Button {...rest}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <ContainerChildren>{children}</ContainerChildren>
        )}
      </Button>
    </Container>
  );
}

FormCancelButton.defaultProps = {
  gridProps: undefined,
  loading: undefined,
};
