import { useEffect, RefObject, useState, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import { GridProps } from '@material-ui/core';

import * as yup from 'yup';

import {
  Container,
  IconButton,
  AddIcon,
  SearchIcon,
  CircularProgress,
  Typography,
  FormContainer,
  FormGridContainer,
  FormSelector,
  IconButtonContainer,
  FormTextInput,
  FormValueInput,
} from './styles';

export type TFilterByProps = { filter_by?: string; data?: string };

const validatorSchema = yup.object().shape(
  {
    filter_by: yup
      .string()
      .max(100, 'Este campo deve conter no máximo 100 caracteres')
      .when('data', {
        is: (data: string) => data?.length,
        then: yup.string().required('Preencha todos campos'),
        otherwise: yup.string(),
      }),
    data: yup
      .string()
      .max(100, 'Este campo deve conter no máximo 100 caracteres')
      .when('filter_by', {
        is: (filter_by: string) => filter_by?.length,
        then: yup.string().required('Preencha todos campos'),
        otherwise: yup.string(),
      }),
  },
  [
    ['data', 'filter_by'],
    ['data', 'filter_by'],
  ],
);

function sanitizer(register: TFilterByProps): TFilterByProps {
  return {
    filter_by: register.filter_by,
    data: register.data,
  };
}

export type TFilterByOption = {
  key: string;
  label: string;
  type: 'normal' | 'range';
  format: 'string' | 'integer' | 'date';
};

type THeaderProps = {
  title: string;
  formRef: RefObject<FormHandles>;
  addFunction?: () => void;
  onSubmitSearch: (register: TFilterByProps) => void;
  filterByOptions: TFilterByOption[];
  validations?: Record<string, string>;
  loading?: boolean;
};

export function Header({
  title,
  formRef,
  addFunction,
  onSubmitSearch,
  filterByOptions,
  validations,
  loading,
}: THeaderProps): JSX.Element {
  const [selectedOption, setSelectedOption] = useState<TFilterByOption | null>(
    null,
  );

  function handleChangeOption(newValue: TFilterByOption | null): void {
    setSelectedOption(newValue);

    formRef.current?.setFieldValue('data', '');
  }

  const SearchInput = useCallback(() => {
    const defaultProps = {
      label: 'Buscar por:',
      name: 'data',
      gridProps: { xs: 12, sm: 3 } as GridProps<'div', unknown>,
      disabled: !selectedOption,
      helperText: selectedOption ? '' : 'Selecione um campo.',
    };

    const defaultInput = () => <FormTextInput {...defaultProps} />;

    const inputDict: {
      [key in TFilterByOption['format']]: () => JSX.Element;
    } = {
      string: defaultInput,
      integer: () => (
        <FormValueInput
          {...defaultProps}
          inputProps={{ decimalScale: 0, thousandSeparator: '' }}
        />
      ),
      date: defaultInput,
    };

    return (
      !selectedOption ? defaultInput : inputDict[selectedOption.format]
    )();
  }, [selectedOption]);

  useEffect(() => {
    formRef.current?.setErrors(validations || {});
  }, [formRef, validations]);

  return (
    <Container>
      <Typography>{title}</Typography>
      {addFunction && (
        <IconButton onClick={addFunction}>
          <AddIcon />
        </IconButton>
      )}
      <FormContainer<TFilterByProps>
        formRef={formRef}
        validatorSchema={validatorSchema}
        sanitizer={sanitizer}
        onSubmit={onSubmitSearch}
        initialData={{ data: formRef.current?.getFieldValue('data') }}
      >
        <FormGridContainer justifyContent="right" gap={1}>
          <FormSelector<TFilterByOption>
            label="No Campo:"
            name="filter_by"
            idColumn="key"
            nameColumn="label"
            options={filterByOptions}
            onChange={handleChangeOption}
            gridProps={{ xs: 12, sm: 3 }}
          />
          <SearchInput />
          <IconButtonContainer>
            <IconButton type="submit" disabled={loading}>
              {loading ? <CircularProgress size={26} /> : <SearchIcon />}
            </IconButton>
          </IconButtonContainer>
        </FormGridContainer>
      </FormContainer>
    </Container>
  );
}

Header.defaultProps = {
  addFunction: undefined,
  validations: undefined,
  loading: false,
};
