import styled from 'styled-components';
import MUIFormControl from '@material-ui/core/FormControl';
import MUIGrid from '@material-ui/core/Grid';
import MUITextField from '@material-ui/core/TextField';
import MUIFormHelperText from '@material-ui/core/FormHelperText';
import MUITypography from '@material-ui/core/Typography';

import { FormGridItem } from '@shared/components/Form';

export { default as InputAdornment } from '@material-ui/core/InputAdornment';
export { FormSkeleton } from '@shared/components/Form';

export const Container = styled(FormGridItem)`
  padding-top: 0.2rem;
`;

export const FormControl = styled(MUIFormControl).attrs({
  size: 'small',
  fullWidth: true,
  color: 'primary',
})``;

export const OutlinedInput = styled(MUITextField)``;

export const FormHelperText = styled(MUIFormHelperText)``;

export const LabelContent = styled(MUIGrid)`
  display: flex;
  flex-direction: row;
`;

export const RequiredCharacter = styled(MUITypography).attrs({
  variant: 'subtitle1',
  color: 'error',
})`
  padding-left: 4px;
`;
