import { SharedAppServices } from '@shared/services/app/shared';

import { UserAppRepositories } from '@modules/users/repositories/app/user';

import { SEARCH_TYPES } from '@shared/constants/shared';

import {
  TUserObject,
  TUserShowObject,
  TUserCreateObject,
  TUserUpdateObject,
  TUserDestroyObject,
} from '@modules/users/@types/TUser';

export class UserAppServices extends SharedAppServices<
  TUserObject,
  TUserShowObject,
  TUserCreateObject,
  TUserUpdateObject,
  TUserDestroyObject
> {
  constructor() {
    const appRepositories = new UserAppRepositories();

    const listSelectorFilters = [
      {
        field: 'name',
        type: SEARCH_TYPES.normal,
        values: [],
      },
    ];

    super(appRepositories, listSelectorFilters);
  }
}
