import {
  TBaseUserObject,
  TUserShowObject,
  TUserCreateObject,
  TUserUpdateObject,
  TUserDestroyObject,
} from '@modules/users/@types/TUser';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import {
  ToBaseShowSanitizer,
  ToBaseCreateSanitizer,
  ToBaseUpdateSanitizer,
  ToBaseDestroySanitizer,
} from '@shared/sanitizers/shared';

function ToBaseSanitizer({
  register,
}: {
  register: TBaseUserObject;
}): TBaseUserObject {
  const parsedObject: TBaseUserObject = {
    uuid: register.uuid,
    user_key: register.user_key,
    name: register.name,
    image: register.image || '',
  };

  return parsedObject;
}

export function ToShow({
  register,
}: {
  register: TUserShowObject;
}): TUserShowObject {
  const parsedObject: TUserShowObject = {
    ...ToBaseShowSanitizer({ register }),
  };

  return parsedObject;
}

export function ToCreate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TUserCreateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TUserCreateObject {
  const parsedObject: TUserCreateObject = {
    ...ToBaseCreateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToUpdate({
  register,
  logged_user,
  logged_branch,
}: {
  register: TUserUpdateObject;
  logged_user: TLoggedUser;
  logged_branch: TLoggedBranch;
}): TUserUpdateObject {
  const parsedObject: TUserUpdateObject = {
    ...ToBaseUpdateSanitizer({ register, logged_user, logged_branch }),
    ...ToBaseSanitizer({ register }),
  };

  return parsedObject;
}

export function ToDestroy({
  register,
  logged_branch,
}: {
  register: TUserUpdateObject;
  logged_branch: TLoggedBranch;
}): TUserDestroyObject {
  const parsedObject: TUserDestroyObject = {
    ...ToBaseDestroySanitizer({ register, logged_branch }),
  };

  return parsedObject;
}
