import MUIGrid from '@material-ui/core/Grid';
import styled from 'styled-components';

export {
  FormCancelButton,
  FormConfirmButton,
  FormContainer,
  FormGridContainer,
  FormSelector,
  FormTextInput,
} from '@shared/components/Form';

export const ContentFormContainer = styled(MUIGrid)`
  width: 145%;
  margin-left: 4px;
  padding: 16px;
`;
