import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSession } from '@modules/sessions/hooks/session';
import { useTemplate } from '@modules/templates/hooks/template';

import { ToDestroy, ToShow } from '@modules/templates/sanitizers/template';

import { PER_PAGE, SEARCH_TYPES, SORT_TYPES } from '@shared/constants/shared';
import { BASE_URL } from '@modules/templates/constants/template';

import { TTemplateObject } from '@modules/templates/@types/TTemplate';
import {
  TActionFunction,
  TColumnInfo,
  TFilterProps,
} from '@shared/components/TableList';
import { TFilterByOption } from '@shared/components/TableList/Header';

import { TLoggedBranch } from '@modules/sessions/@types/TSession';

import { Page } from '@shared/components/Page';
import { TableList, Container } from './styles';

const columnInfos: TColumnInfo[] = [
  { key: 'id', label: 'Código' },
  { key: 'name', label: 'Nome' },
  { key: 'description', label: 'Descrição' },
];

const filterByOptions: TFilterByOption[] = [
  { key: 'id', label: 'Código', type: 'normal', format: 'integer' },
  { key: 'name', label: 'Nome', type: 'normal', format: 'string' },
];

export function TemplateList(): JSX.Element {
  const [pageLoaded, setPageLoaded] = useState(false);

  const navigate = useNavigate();

  const { logged_branch } = useSession();
  const {
    list,
    show,
    destroy,
    validations,
    registerList,
    listTotal,
    listLoading,
  } = useTemplate();

  function onSubmitSearch({
    filter_by,
    data,
    order,
    order_by,
    page,
    per_page,
  }: TFilterProps): void {
    const searchData = {
      order,
      order_by,
      page,
      per_page,
      for_selector: false,
      data:
        data && filter_by
          ? [
              {
                field: filter_by,
                type: SEARCH_TYPES.normal,
                values: data.split(';'),
              },
            ]
          : undefined,
    };

    list(searchData);
  }

  function addFunction() {
    navigate(`/${BASE_URL}/form`, { state: { creating: true } });
  }

  const actionFunctions = useMemo<TActionFunction<TTemplateObject>[]>(() => {
    return [
      {
        key: 'show',
        label: 'Visualizar',
        handle: (register: TTemplateObject) => {
          navigate(`/${BASE_URL}/form`, { state: { showing: true } });

          show({ register: ToShow({ register }) });
        },
      },
      {
        key: 'update',
        label: 'Atualizar',
        handle: (register: TTemplateObject) => {
          navigate(`/${BASE_URL}/form`, { state: { updating: true } });

          show({ register: ToShow({ register }) });
        },
      },
      {
        key: 'destroy',
        label: 'Remover',
        handle: (register: TTemplateObject) => {
          destroy({
            register: ToDestroy({
              register,
              logged_branch: logged_branch as TLoggedBranch,
            }),
          });
        },
        confirm_message: 'Deseja mesmo remover este template?',
      },
    ];
  }, [navigate, show, destroy, logged_branch]);

  useEffect(() => {
    if (!registerList.length && !pageLoaded)
      list({
        order: SORT_TYPES.asc,
        order_by: 'id',
        page: 1,
        per_page: PER_PAGE,
        for_selector: false,
        data: [],
      });

    setPageLoaded(true);
  }, [list, registerList.length, pageLoaded]);

  return (
    <Page title="TemplateList">
      <Container>
        <TableList
          title="TemplateList"
          registerList={registerList}
          listTotal={listTotal}
          addFunction={addFunction}
          columnInfos={columnInfos}
          onSubmitSearch={onSubmitSearch}
          filterByOptions={filterByOptions}
          actionFunctions={actionFunctions}
          validations={validations}
          loading={listLoading}
        />
      </Container>
    </Page>
  );
}
