import { useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { useSession } from '@modules/sessions/hooks/session';
import { useErrorHandler } from '@shared/providers/error/hook';

import { debounceEvent } from '@shared/utils/debounce';

import {
  SIGN_OUT_ERROR_CODES,
  TOKEN_ERROR_CODES,
} from '@modules/sessions/constants/session';

import { TAppError } from '@shared/providers/error/@types/TError';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from './styles';

type TDialogState = {
  isOpen: boolean;
  cancelFunction?: (...args: unknown[]) => unknown;
  confirmFunction?: (...args: unknown[]) => unknown;
};

export function ErrorHandlerDialog(): JSX.Element {
  const { update, signOut } = useSession();
  const { error, handleFunction, clearState } = useErrorHandler();

  const [dialogState, setDialogState] = useState<TDialogState>({
    isOpen: false,
    cancelFunction: undefined,
    confirmFunction: undefined,
  });

  const [parsedError, setParsedError] = useState<TAppError | undefined>();

  const handleCancel = useCallback(() => {
    dialogState?.cancelFunction?.();

    debounceEvent(setParsedError, 200)();

    setDialogState({
      isOpen: false,
      cancelFunction: undefined,
      confirmFunction: undefined,
    });
  }, [dialogState]);

  const handleConfirm = useCallback(() => {
    dialogState?.confirmFunction?.();

    debounceEvent(setParsedError, 200)();

    setDialogState({
      isOpen: false,
      cancelFunction: undefined,
      confirmFunction: undefined,
    });
  }, [dialogState]);

  useEffect(() => {
    if (error) {
      toast.warn(error.message, { toastId: error.code });

      if (SIGN_OUT_ERROR_CODES.includes(error.code)) {
        signOut();
      }

      if (TOKEN_ERROR_CODES.includes(error.code)) {
        // setDialogState({
        //   isOpen: true,
        //   cancelFunction: signOut,
        //   confirmFunction: () => update(handleFunction),
        // }); // TO-DO: Fix this comment - branch 209#
        signOut();
      }

      debounceEvent(clearState, 200)();
      setParsedError(error);
    }
  }, [clearState, error, handleFunction, signOut, update]);

  return (
    <Dialog open={dialogState.isOpen} onClose={handleCancel}>
      <DialogTitle>Algo deu errado 💥</DialogTitle>
      <DialogContent>
        <DialogContentText>{parsedError?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {dialogState?.cancelFunction && (
          <Button onClick={handleCancel}>CANCELAR</Button>
        )}
        <Button onClick={handleConfirm} autoFocus>
          CONTINUAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}
