import { SharedAppServices } from '@shared/services/app/shared';

import { StageNameAppRepositories } from '@modules/templates/repositories/app/stageName';

import {
  TStageNameObject,
  TStageNameShowObject,
  TStageNameCreateObject,
  TStageNameUpdateObject,
  TStageNameDestroyObject,
} from '@modules/templates/@types/TStageName';
import { TUserObject } from '@modules/users/@types/TUser';

export class StageNameAppServices extends SharedAppServices<
  TStageNameObject,
  TStageNameShowObject,
  TStageNameCreateObject,
  TStageNameUpdateObject,
  TStageNameDestroyObject,
  TUserObject
> {
  constructor() {
    const appRepositories = new StageNameAppRepositories();

    super(appRepositories);
  }
}
