import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useProject } from '@modules/projects/hooks/project';
import { useSession } from '@modules/sessions/hooks/session';

import { BASE_URL as PROJECT_BASE_URL } from '@modules/projects/constants/project';

import { TProjectObject } from '@modules/projects/@types/TProject';
import { TLoggedBranch } from '@modules/sessions/@types/TSession';

import { ToDestroy } from '@modules/projects/sanitizers/project';

import {
  Container,
  ProjectContent,
  ProjectHeader,
  DeleteButtonContainer,
  ManageProjectButton,
  Header,
  Content,
  Title,
  SubTitleContent,
  SubTitleMarker,
  SubTitle,
  StepsOfProjectContainer,
  CardsContainer,
  CardContainer,
  Card,
  ReopenAndNameContainer,
  TitleOfCard,
  TextsOfCard,
  SupportCardContainer,
  SupportCard,
  StepsOfProjectsBar,
  Div,
  Ball,
  SubTitleGrid,
  ReopenProject,
  DeleteIcon,
  RemoveItemDialog,
} from './styles';

type TProjectListItem = {
  project: TProjectObject;
  setHaveProjectDeleted: Dispatch<SetStateAction<boolean>>;
};

export function ProjectListItem({
  project,
  setHaveProjectDeleted,
}: TProjectListItem): JSX.Element {
  const { destroy } = useProject();
  const { logged_branch, logged_user } = useSession();
  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = useState({ isOpen: false });

  function handleNavigateToProject(stage_uuid?: string) {
    navigate(`${PROJECT_BASE_URL}/${project.uuid}`, {
      state: { stage_uuid },
    });
  }

  function handleRemoveProject(register: TProjectObject) {
    destroy({
      register: ToDestroy({
        register,
        logged_branch: logged_branch as TLoggedBranch,
      }),
    });
    setHaveProjectDeleted(true);
  }

  return (
    <Container container justifyContent="center">
      <RemoveItemDialog
        isOpen={isDialogOpen.isOpen}
        confirmRegister={project}
        warnMessage="Ao remover este projeto, todas mensagens em atividades serão removidas."
        setIsOpen={setIsDialogOpen}
        handleConfirmFunction={handleRemoveProject}
      />

      <ProjectContent>
        <ProjectHeader>
          <ManageProjectButton onClick={() => handleNavigateToProject()}>
            <Header item justifyContent="center">
              <Title>{project.name}</Title>
            </Header>
          </ManageProjectButton>
          {logged_user?.is_manager && (
            <DeleteButtonContainer>
              <ManageProjectButton
                onClick={() => setIsDialogOpen({ isOpen: true })}
                borderLeft
              >
                <DeleteIcon sx={{ fontSize: 25 }} color="error" />
              </ManageProjectButton>
            </DeleteButtonContainer>
          )}
        </ProjectHeader>

        <Div />

        <SubTitleContent container>
          <SubTitleMarker item status={project.status} />

          <SubTitleGrid item>
            <SubTitle>{project.description}</SubTitle>
          </SubTitleGrid>
        </SubTitleContent>

        <Content key={project.id}>
          <StepsOfProjectContainer>
            <Ball />
            {project.project_stages?.map((stage, index: number) => (
              <CardsContainer key={stage.uuid}>
                <CardContainer style={{ paddingTop: '17px' }}>
                  {index % 2 === 0 ? (
                    <>
                      <Card
                        status={stage.status}
                        onClick={() => handleNavigateToProject(stage.uuid)}
                      >
                        <TitleOfCard>{stage.stage_name?.name}</TitleOfCard>
                        <ReopenAndNameContainer>
                          <TextsOfCard>{stage.responsible?.name}</TextsOfCard>
                          <ReopenProject
                            is_reopen={stage.is_reopen}
                            status={stage.status}
                            size={18}
                          />
                        </ReopenAndNameContainer>
                      </Card>

                      <SupportCardContainer>
                        <SupportCard />
                      </SupportCardContainer>
                    </>
                  ) : null}
                </CardContainer>
                <StepsOfProjectsBar />
                <CardContainer>
                  {index % 2 === 1 ? (
                    <>
                      <SupportCardContainer style={{ height: '41px' }}>
                        <SupportCard />
                      </SupportCardContainer>

                      <Card
                        status={stage.status}
                        onClick={() => handleNavigateToProject(stage.uuid)}
                      >
                        <TitleOfCard>{stage.stage_name?.name}</TitleOfCard>
                        <ReopenAndNameContainer>
                          <TextsOfCard>{stage.responsible?.name}</TextsOfCard>
                          <ReopenProject
                            is_reopen={stage.is_reopen}
                            status={stage.status}
                            size={18}
                          />
                        </ReopenAndNameContainer>
                      </Card>
                    </>
                  ) : null}
                </CardContainer>
              </CardsContainer>
            ))}
            <Ball />
          </StepsOfProjectContainer>
        </Content>
      </ProjectContent>
    </Container>
  );
}
