import { createContext, ReactNode, useContext, useState } from 'react';

import { ProjectStageAppServices } from '@modules/projects/services/app/projectStage';

import { useErrorHandler } from '@shared/providers/error/hook';

import { TStageNameObject } from '@modules/templates/@types/TStageName';
import {
  TProjectStageObject,
  TProjectStageShowObject,
  TProjectStageCreateObject,
  TProjectStageUpdateObject,
  TProjectStageDestroyObject,
} from '@modules/projects/@types/TProjectStage';
import {
  SharedHookInitialState,
  useSharedHook,
  TSharedHookInitialState,
  TSharedHookReturn,
} from '@shared/hooks/shared';
import { TUserObject } from '@modules/users/@types/TUser';

const INITIAL_STATE: TSharedHookInitialState<TProjectStageObject, TUserObject> =
  SharedHookInitialState;

type TProjectStageContext = TSharedHookInitialState<
  TProjectStageObject,
  TUserObject | TStageNameObject
> &
  TSharedHookReturn<
    TProjectStageObject,
    TProjectStageShowObject,
    TProjectStageCreateObject,
    TProjectStageUpdateObject,
    TProjectStageDestroyObject,
    TUserObject | TStageNameObject
  >;

const ProjectStageContext = createContext<TProjectStageContext>(
  INITIAL_STATE as TProjectStageContext,
);

const AppServices = new ProjectStageAppServices();

export function ProjectStageProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [state, setState] =
    useState<
      TSharedHookInitialState<
        TProjectStageObject,
        TUserObject | TStageNameObject
      >
    >(INITIAL_STATE);

  const { setErrorHandlerData } = useErrorHandler();

  const sharedHook = useSharedHook<
    TProjectStageObject,
    TProjectStageShowObject,
    TProjectStageCreateObject,
    TProjectStageUpdateObject,
    TProjectStageDestroyObject,
    TUserObject | TStageNameObject
  >({
    setState,
    AppServices,
    setErrorHandlerData,
  });

  return (
    <ProjectStageContext.Provider value={{ ...state, ...sharedHook }}>
      {children}
    </ProjectStageContext.Provider>
  );
}

export function useProjectStage(): TProjectStageContext {
  const context = useContext(ProjectStageContext);

  if (!context)
    throw new Error(
      'useProjectStage must be used within an ProjectStageProvider',
    );

  return context;
}
