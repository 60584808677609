import styled from 'styled-components';

import MUIIconButton from '@material-ui/core/IconButton';

export { default as ArrowUpwardIcon } from '@material-ui/icons/ArrowUpward';

type TContainer = {
  customtopmargin?: string;
};

export const Container = styled(MUIIconButton)<TContainer>`
  z-index: 999;

  position: sticky;
  left: 90%;
  top: ${props => props.customtopmargin || '85%'};
  background: ${({ theme }) => theme.palette.primary.main};
  color: white;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.main};
    opacity: 80%;
  }
`;
