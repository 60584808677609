import {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';

type TThemeModeState = {
  mode: 'dark' | 'light';
};

const INITIAL_STATE: TThemeModeState = {
  mode: 'light',
};

type TThemeModeContext = TThemeModeState & {
  setTheme: (newData: TThemeModeState) => void;
};

const ThemeContext = createContext<TThemeModeContext>(
  INITIAL_STATE as TThemeModeContext,
);
export function ThemeProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [mode, setMode] = useState<TThemeModeState>(INITIAL_STATE);

  const setTheme = useCallback(
    (newData: TThemeModeState) => {
      setMode(oldData => ({ ...oldData, ...newData }));
    },
    [setMode],
  );

  return (
    <ThemeContext.Provider value={{ ...mode, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme(): TThemeModeContext {
  const context = useContext(ThemeContext);

  if (!context)
    throw new Error('useThemeMode must be used within an ThemeProvider');

  return context;
}
