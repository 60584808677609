import { ISharedFormDataAppRepository } from '@shared/repositories/app/implementations/IShared';
import { ISharedFormDataAppService } from '@shared/services/app/implementations/IShared';
import { ISharedFormDataDTO } from '@shared/dtos/IShared';

import { TSharedObject } from '@shared/@types/TShared';

export class SharedFormDataAppService<TRelationsRegister extends TSharedObject>
  implements ISharedFormDataAppService<TRelationsRegister>
{
  constructor(
    private readonly formDataAppRepository: ISharedFormDataAppRepository<TRelationsRegister>,
  ) {}

  public async execute(
    FormDataDTO: ISharedFormDataDTO<TRelationsRegister>,
  ): Promise<{
    form_data: Record<string, TRelationsRegister[]>;
  }> {
    const formDataAppRepositoryResult =
      await this.formDataAppRepository.execute(FormDataDTO);

    return { form_data: formDataAppRepositoryResult };
  }
}
