import { useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import {
  createValidatorSchema,
  updateValidatorSchema,
} from '@modules/templates/validators/template';

import { ToCreate, ToUpdate } from '@modules/templates/sanitizers/template';

import { statusesOptions } from '@modules/projects/helpers/project';

import { BASE_URL } from '@modules/templates/constants/template';

import { useSession } from '@modules/sessions/hooks/session';
import { useTemplate } from '@modules/templates/hooks/template';
import { useUser } from '@modules/users/hooks/user';

import { TUserObject } from '@modules/users/@types/TUser';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import {
  TTemplateCreateObject,
  TTemplateUpdateObject,
} from '@modules/templates/@types/TTemplate';
import { DefaultFormContainer } from '@shared/components/Form/DefaultFormContainer';
import {
  FormCancelButton,
  FormConfirmButton,
  FormContainer,
  FormGridContainer,
  FormTextInput,
  FormValueInput,
  FormSelector,
  ContentFormContainer,
} from './styles';

export function TemplateFormContent(): JSX.Element {
  const { logged_user, logged_branch } = useSession();
  const {
    create,
    update,
    formInitialData,
    registerShow,
    showLoading,
    formLoading,
    validations,
  } = useTemplate();
  const { listSelector } = useUser();

  const navigate = useNavigate();
  const location = useLocation();

  const canEdit = useMemo(() => {
    const { state } = location;

    return !state || !registerShow || state?.creating || state?.updating;
  }, [location, registerShow]);

  const formRef = useRef<FormHandles>(null);

  const validatorSchema = registerShow?.uuid
    ? updateValidatorSchema
    : createValidatorSchema;

  function handleSanitize(
    register: TTemplateCreateObject | TTemplateUpdateObject,
  ): TTemplateCreateObject | TTemplateUpdateObject {
    const parsedRegister = registerShow?.uuid
      ? ToUpdate({
          register: { ...register, uuid: registerShow.uuid },
          logged_user: logged_user as TLoggedUser,
          logged_branch: logged_branch as TLoggedBranch,
        })
      : ToCreate({
          register,
          logged_user: logged_user as TLoggedUser,
          logged_branch: logged_branch as TLoggedBranch,
        });

    return parsedRegister;
  }

  function handleSubmit(
    register: TTemplateCreateObject | TTemplateUpdateObject,
  ): void {
    function onSuccess() {
      if (location.pathname === `/${BASE_URL}/form`) navigate(-1);
    }

    if (registerShow?.uuid)
      update({ register: { ...register, uuid: registerShow.uuid }, onSuccess });
    else create({ register, onSuccess });
  }

  function handleCancel(): void {
    navigate(-1);
  }

  useEffect(() => {
    formRef.current?.setErrors(validations || {});
  }, [validations]);

  return (
    <FormContainer<TTemplateCreateObject | TTemplateUpdateObject>
      formRef={formRef}
      validatorSchema={validatorSchema}
      sanitizer={handleSanitize}
      onSubmit={handleSubmit}
      initialData={registerShow}
    >
      <DefaultFormContainer style={{ width: '85%' }}>
        <ContentFormContainer>
          <FormGridContainer>
            <FormGridContainer gap={1}>
              <FormTextInput
                label="Nome"
                name="name"
                required
                disabled={!canEdit || formLoading}
                showLoading={showLoading}
                gridProps={{ xs: 7, sm: 4 }}
              />
              <FormTextInput
                label="Descrição"
                name="description"
                required
                disabled={!canEdit || formLoading}
                showLoading={showLoading}
                gridProps={{ xs: 7, sm: 4 }}
              />
            </FormGridContainer>
            <FormGridContainer gap={1}>
              <FormSelector<TUserObject>
                label="Dirigente"
                name="responsible_uuid"
                idColumn="uuid"
                nameColumn="name"
                handleSearch={listSelector}
                options={formInitialData?.responsibles || []}
                disabled={!canEdit || formLoading}
                showLoading={showLoading}
                gridProps={{ xs: 7, sm: 4 }}
              />
              <FormValueInput
                label="Prazo em dias"
                name="estimated_delivery_days"
                required
                disabled={!canEdit || formLoading}
                showLoading={showLoading}
                gridProps={{ xs: 7, sm: 4 }}
                inputProps={{ decimalScale: 0 }}
              />
            </FormGridContainer>
            <FormGridContainer gap={1}>
              <FormSelector
                label="Status"
                name="status"
                idColumn="key"
                nameColumn="label"
                options={statusesOptions}
                required
                disabled={!canEdit || formLoading}
                showLoading={showLoading}
                gridProps={{ xs: 7, sm: 4 }}
              />
            </FormGridContainer>

            <FormGridContainer gap={1}>
              <FormCancelButton
                loading={showLoading || formLoading}
                gridProps={{ xs: 7, sm: 4 }}
                onClick={handleCancel}
              >
                CANCELAR
              </FormCancelButton>
              <FormConfirmButton
                loading={showLoading || formLoading}
                gridProps={{ xs: 7, sm: 4 }}
                disabled={!canEdit || formLoading}
              >
                SALVAR
              </FormConfirmButton>
            </FormGridContainer>
          </FormGridContainer>
        </ContentFormContainer>
      </DefaultFormContainer>
    </FormContainer>
  );
}
