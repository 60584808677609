import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { createValidatorSchema } from '@modules/projects/validators/project';
import { showValidatorSchema } from '@modules/templates/validators/template';

import { FromTemplate, ToCreate } from '@modules/projects/sanitizers/project';
import { ToShow } from '@modules/templates/sanitizers/template';

import { STATUSES } from '@modules/projects/constants/project';

import { useSession } from '@modules/sessions/hooks/session';
import { useUser } from '@modules/users/hooks/user';
import { useProject } from '@modules/projects/hooks/project';
import { useTemplate } from '@modules/templates/hooks/template';

import { TUserObject } from '@modules/users/@types/TUser';
import { TLoggedBranch, TLoggedUser } from '@modules/sessions/@types/TSession';
import { TProjectCreateObject } from '@modules/projects/@types/TProject';
import {
  TTemplateObject,
  TTemplateShowObject,
} from '@modules/templates/@types/TTemplate';
import { TTemplateSubStageObject } from '@modules/templates/@types/TTemplateSubStage';
import { TProjectStageObject } from '@modules/projects/@types/TProjectStage';
import { TProjectUserCreateObject } from '@modules/projects/@types/TProjectUser';
import { DefaultFormContainer } from '@shared/components/Form/DefaultFormContainer';
import {
  FormContainer,
  FormGridContainer,
  ProjectFormContainer,
  TextInput,
  Selector,
  Checkbox,
  ConfirmButton,
  GoToTopButton,
  SearchButton,
} from './styles';

export function ProjectFormContent(): JSX.Element {
  const { logged_user, logged_branch } = useSession();
  const { create, formInitialData, showLoading, formLoading, validations } =
    useProject();
  const {
    show: templateShow,
    registerShow: templateRegisterShow,
    listSelector: templateListSelector,
    showLoading: templateShowLoading,
  } = useTemplate();
  const { listSelector: userListSelector } = useUser();

  const navigate = useNavigate();

  const formRef = useRef<FormHandles>(null);

  const [checkeds, setCheckeds] = useState<Record<string, boolean>>({
    field: false,
  });

  const projectFromTemplate = useMemo(() => {
    const parsedProject = templateRegisterShow
      ? FromTemplate({ register: templateRegisterShow })
      : undefined;

    return parsedProject;
  }, [templateRegisterShow]);

  const validatorSchema = createValidatorSchema;

  function handleSanitize(
    register: TProjectCreateObject,
  ): TProjectCreateObject {
    const projectUsers: TProjectUserCreateObject[] = [];
    const projectSubStages: Array<TTemplateSubStageObject[]> = [];

    templateRegisterShow?.template_stages?.forEach(projectStage => {
      projectSubStages.push(projectStage.template_sub_stages || []);
    });

    function addProjectUsers(user_uuid: string | undefined) {
      const userAreadyIn = projectUsers.some(
        projectUser => projectUser.user_uuid === user_uuid,
      );

      if (!userAreadyIn)
        projectUsers.push({
          ...register,
          project_uuid: uuidv4(), // 'api will generate'
          user_uuid,
        });
    }

    const onlyCheckedRegisters = {
      ...register,
      status: STATUSES.TO_START,
      template_uuid: templateRegisterShow?.uuid as string,
      project_stages: register.project_stages
        .map((projectStage, projectStageIndex) => ({
          ...projectStage,
          status: STATUSES.TO_START,
          description:
            templateRegisterShow?.template_stages?.[projectStageIndex]
              .description || '',
          stage_name_uuid:
            templateRegisterShow?.template_stages?.[projectStageIndex]
              .stage_name_uuid || '',
          project_uuid: templateRegisterShow?.uuid || '',
          template_stage_uuid:
            templateRegisterShow?.template_stages?.[projectStageIndex].uuid ||
            '',
          project_sub_stages: projectSubStages[projectStageIndex]
            .map(projectSubStage => ({
              ...projectSubStage,
              responsible_uuid: projectStage.responsible_uuid,
              status: STATUSES.TO_START,
              project_stage_uuid: projectSubStage.template_stage_uuid || '',
              template_sub_stage_uuid: projectSubStage.uuid || '',
              is_reopen: false,
            }))
            .filter(projectSubStage => {
              addProjectUsers(projectSubStage.responsible_uuid);

              return true;
            }),
        }))
        .filter((projectStage, projectStageIndex) => {
          if (!checkeds[`project_stages[${projectStageIndex}].checked`])
            return false;

          addProjectUsers(projectStage.responsible_uuid);

          return true;
        }),
    };

    addProjectUsers(onlyCheckedRegisters.responsible_uuid);

    const parsedRegister = ToCreate({
      register: { ...onlyCheckedRegisters, project_users: projectUsers },
      logged_user: logged_user as TLoggedUser,
      logged_branch: logged_branch as TLoggedBranch,
    });

    return parsedRegister;
  }

  function handleSubmit(register: TProjectCreateObject): void {
    console.log({ register });
    function onSuccess() {
      navigate('/');
    }

    if (!register.project_stages.length) {
      toast.warn(`É necessário que ao menos uma etapa seja selecionada.`);
      return;
    }

    create({ register, onSuccess });
  }

  function handleCheckAll(newValue: boolean) {
    const newCheckeds = { ...checkeds };

    newCheckeds.all_checked = newValue;

    const formData = formRef.current?.getData();

    formData?.project_stages?.forEach(
      (_projectStage: TProjectStageObject, projectStageIndex: number) => {
        const projectStageFieldName = `project_stages[${projectStageIndex}].checked`;
        newCheckeds[projectStageFieldName] = newValue;
        formRef.current?.setFieldValue(projectStageFieldName, newValue);
      },
    );

    setCheckeds(newCheckeds);
  }

  function isAllChecked({
    newCheckeds,
  }: {
    newCheckeds: Record<string, boolean>;
  }): boolean {
    const formData = formRef.current?.getData();

    const isAll = formData?.project_stages?.every(
      (_projectStage: TProjectStageObject, projectStageIndex: number) =>
        newCheckeds[`project_stages[${projectStageIndex}].checked`],
    );

    if (isAll) handleCheckAll(true);

    return isAll;
  }

  function handleChangeProjectStage(
    newValue: boolean,
    projectStageIndex: number,
  ): void {
    const newCheckeds = { ...checkeds };

    newCheckeds[`project_stages[${projectStageIndex}].checked`] = newValue;
    newCheckeds.all_checked = isAllChecked({ newCheckeds });

    setCheckeds(newCheckeds);
  }

  useEffect(() => {
    formRef.current?.setErrors(validations || {});
  }, [validations]);

  return !templateRegisterShow ? (
    <FormContainer<TTemplateShowObject>
      formRef={formRef}
      validatorSchema={showValidatorSchema}
      sanitizer={register => ToShow({ register })}
      onSubmit={register => templateShow({ register })}
      initialData={templateRegisterShow}
    >
      <DefaultFormContainer style={{ width: '55%' }}>
        <FormGridContainer
          gap={1}
          mt={1}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Selector<TTemplateObject>
            label="Selecione um Template"
            name="uuid"
            idColumn="uuid"
            nameColumn="name"
            handleSearch={templateListSelector}
            options={(formInitialData?.templates || []) as TTemplateObject[]}
            disabled={templateShowLoading}
            showLoading={showLoading}
            gridProps={{ xs: 6, sm: 6 }}
          />
          <SearchButton
            loading={showLoading || templateShowLoading}
            disabled={templateShowLoading}
          >
            BUSCAR
          </SearchButton>
        </FormGridContainer>
      </DefaultFormContainer>
    </FormContainer>
  ) : (
    <>
      <GoToTopButton />
      <FormContainer<TProjectCreateObject>
        formRef={formRef}
        validatorSchema={validatorSchema}
        sanitizer={handleSanitize}
        onSubmit={handleSubmit}
        initialData={projectFromTemplate}
      >
        <ProjectFormContainer>
          <FormGridContainer mt={1}>
            <FormGridContainer
              gap={1}
              style={{
                marginBottom: '12px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <TextInput
                label="Nome do Projeto"
                name="name"
                required
                disabled={formLoading}
                showLoading={showLoading}
                gridProps={{ xs: 8, sm: 6 }}
              />
              <ConfirmButton
                loading={showLoading || formLoading}
                disabled={formLoading}
              >
                SALVAR
              </ConfirmButton>
            </FormGridContainer>

            <FormGridContainer gap={1}>
              <TextInput
                label="Descrição do Projeto"
                name="description"
                required
                disabled={formLoading}
                showLoading={showLoading}
                gridProps={{ xs: 12, sm: 12 }}
              />
            </FormGridContainer>
            <FormGridContainer gap={1}>
              <Selector<TUserObject>
                label="Dirigente do Projeto"
                name="responsible_uuid"
                idColumn="uuid"
                nameColumn="name"
                required
                handleSearch={userListSelector}
                options={(formInitialData?.responsibles || []) as TUserObject[]}
                disabled={formLoading}
                showLoading={showLoading}
                inputBox="standard"
              />
              <TextInput
                label="Bairro"
                name="neighborhood"
                required
                disabled={formLoading}
                showLoading={showLoading}
              />
            </FormGridContainer>

            <FormGridContainer gap={1}>
              <Checkbox
                label="Marcar/desmarcar tudo"
                name="check_all"
                value={!!checkeds.all_checked}
                disabled={formLoading}
                showLoading={showLoading}
                gridProps={{ xs: 6, sm: 8 }}
                onChange={handleCheckAll}
              />
            </FormGridContainer>
            {projectFromTemplate?.project_stages?.map(
              (projectStage, projectStageIndex) => (
                <FormGridContainer
                  key={`stage_${projectStageIndex.toString()}`}
                  ml={2}
                >
                  <FormGridContainer
                    gap={1}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '4px',
                    }}
                  >
                    <Checkbox
                      label={projectStage.description}
                      bold
                      name={`project_stages[${projectStageIndex}].checked`}
                      value={
                        !!checkeds[
                          `project_stages[${projectStageIndex}].checked`
                        ]
                      }
                      disabled={formLoading}
                      showLoading={showLoading}
                      onChange={newValue =>
                        handleChangeProjectStage(newValue, projectStageIndex)
                      }
                      gridProps={{ xs: 4, sm: 4 }}
                    />
                    <Selector<TUserObject>
                      label="Dirigente"
                      name={`project_stages[${projectStageIndex}].responsible_uuid`}
                      idColumn="uuid"
                      nameColumn="name"
                      handleSearch={userListSelector}
                      options={
                        (formInitialData?.responsibles || []) as TUserObject[]
                      }
                      disabled={
                        formLoading ||
                        !checkeds[
                          `project_stages[${projectStageIndex}].checked`
                        ]
                      }
                      showLoading={showLoading}
                      inputBox="standard"
                    />
                  </FormGridContainer>
                </FormGridContainer>
              ),
            )}
          </FormGridContainer>
        </ProjectFormContainer>
      </FormContainer>
    </>
  );
}
