import * as yup from 'yup';

import {
  sharedBaseCreateValidatorSchema,
  sharedBaseShowValidatorSchema,
  sharedBaseUpdateValidatorSchema,
} from '@shared/validators/shared';

import { MAX_INTEGER_VALUE } from '@shared/constants/shared';
import { STATUSES } from '@modules/projects/constants/project';

import {
  TTemplateCreateObject,
  TTemplateShowObject,
  TTemplateUpdateObject,
} from '@modules/templates/@types/TTemplate';

export const templateBaseCreateOrUpdateValidatorSchema = yup.object().shape({
  name: yup
    .string()
    .required('Este campo é obrigatório')
    .max(255, 'Este campo deve conter no máximo 255 caracteres'),
  description: yup
    .string()
    .required('Este campo é obrigatório')
    .max(255, 'Este campo deve conter no máximo 255 caracteres'),
  responsible_uuid: yup
    .string()
    .required('Este campo é obrigatório')
    .uuid('Este campo deve ser um UUID válido'),
  status: yup
    .mixed()
    .oneOf(
      Object.values(STATUSES),
      `Este campo deve ser algum dos valores: ${Object.values(STATUSES)}`,
    )
    .required('Este campo é obrigatório'),
  estimated_delivery_days: yup
    .number()
    .max(
      MAX_INTEGER_VALUE,
      `Este campo deve ser no máximo ${MAX_INTEGER_VALUE}`,
    )
    .required('Este campo é obrigatório'),
});

export const createValidatorSchema = yup
  .object()
  .concat(templateBaseCreateOrUpdateValidatorSchema)
  .concat(
    sharedBaseCreateValidatorSchema,
  ) as yup.SchemaOf<TTemplateCreateObject>;

export const updateValidatorSchema = yup
  .object()
  .concat(templateBaseCreateOrUpdateValidatorSchema)
  .concat(
    sharedBaseUpdateValidatorSchema,
  ) as yup.SchemaOf<TTemplateUpdateObject>;

export const showValidatorSchema = yup
  .object()
  .concat(sharedBaseShowValidatorSchema) as yup.SchemaOf<TTemplateShowObject>;
